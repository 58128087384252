import React, { useEffect, useState, useContext, useCallback } from 'react';
import apiUnidade from '../../services/apis/apiUnidade';
import CardLayout from '../common/CardLayout';
import EditIcon from '../common/EditIcon';
import { AuthContext } from '../../contexts/AuthContext';
import { Popover, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import InputGeneric from '../common/InputGeneric';
import { Button } from '../ui/button';
import { ToastContext } from '../../contexts/ToastContext';

const SobreUnidade = ({ unidadeID }) => {
	const { verificarCargo } = useContext(AuthContext);
	const [unidade, setUnidade] = useState();
	const [isOpen, setIsOpen] = useState(false);
	const [autorizado, setAutorizado] = useState(false);
	const [tensao, setTensao] = useState(0);
	const [fatorPotencia, setFatorPotencia] = useState(0);
	const [diaMesTrabalhados, setDiaMesTrabalhados] = useState(0);
	const [horasDiaTrabalhadas, setHorasDiaTrabalhadas] = useState(0);
	const [isLoading, setIsLoading] = useState(false);
	const { erro, sucesso } = useContext(ToastContext);

	const fetchData = useCallback(async () => {
		try {
			const res = await apiUnidade.getById(unidadeID);
			setUnidade(res.data);
		} catch (error) {
			console.error(error.message);
		}
	}, [unidadeID]);

	useEffect(() => {
		fetchData();
	}, [unidadeID, fetchData]);

	useEffect(() => {
		setTensao(unidade?.tensao || 0);
		setFatorPotencia(unidade?.fatorPotencia || 0);
		setDiaMesTrabalhados(unidade?.diasDeTrabalhoMes || 0);
		setHorasDiaTrabalhadas(unidade?.horasTrabalhadasDia || 0);
	}, [unidade]);

	const handlePutUnidade = () => {
		setIsLoading(true);
		apiUnidade
			.putApi(unidadeID, {
				nome: unidade.nome,
				empresaId: parseInt(unidade.empresaId),
				tipoUnidade: unidade.tipoUnidade,
				diasDeTrabalhoMes: parseInt(diaMesTrabalhados),
				horasTrabalhadasDia: parseFloat(horasDiaTrabalhadas),
				fatorPotencia: parseFloat(fatorPotencia),
				tensao: parseInt(tensao),
			})
			.then(
				(res) =>
					res.status === 200 &&
					sucesso(
						`Unidade ${unidade?.nome} editada com sucesso!`
					)
			)
			.catch((error) => {
				erro(
					'Não foi possível alterar os dados da unidade, verifique se os dados foram preenchidos corretamente.'
				);
			})
			.finally(() => {
				setIsLoading(false);
				fetchData();
			});
	};

	useEffect(() => {
		verificarCargo('SUPER_USER', 'ADMIN').then((res) => {
			setAutorizado(res);
		});
	}, [verificarCargo]);

	return (
		<>
			{unidade && (
				<div className="flex flex-col w-full">
					<h1 className="text-center mb-4 text-4xl font-extrabold leading-none tracking-tight md:text-5xl lg:text-6xl text-white drop-shadow-1xl">
						{unidade?.nome}
					</h1>

					<CardLayout>
						<div className="flex flex-col xl:flex-row gap-x-8 px-4 py-2">
							{/* Informações Unidade */}
							<div className="flex w-full order-2 xl:order-1 xl:w-[45%] p-4 text-lg">
								<table className="w-full max-w-[500px] mx-auto">
									<tbody>
										<tr>
											<td className="text-secondary">
												Tipo de
												Unidade:
											</td>
											{unidade.tipoUnidade && (
												<td className="text-white font-bold text-center">
													{
														unidade.tipoUnidade
													}
												</td>
											)}
										</tr>
										<tr>
											<td className="text-secondary">
												Média de
												dias
												trabalhados
												no mês:
											</td>
											{unidade.diasDeTrabalhoMes && (
												<td className="text-white font-bold text-center">
													{
														unidade.diasDeTrabalhoMes
													}
												</td>
											)}
										</tr>
										<tr>
											<td className="text-secondary">
												Média de
												horas
												trabalhadas
												por dia:
											</td>
											{unidade.horasTrabalhadasDia && (
												<td className="text-white font-bold text-center">
													<div className="flex justify-center">
														{
															unidade.horasTrabalhadasDia
														}
													</div>
												</td>
											)}
										</tr>
									</tbody>
								</table>
							</div>
							{/* Informações Energia */}
							<div className="flex w-full order-3 xl:order-2 xl:w-[35%] p-4 text-lg">
								<table className="w-full max-w-[500px] mx-auto">
									<tbody>
										<tr>
											<td className="text-secondary">
												Tensão
												de
												alimentação
												(volts):
											</td>
											{unidade.tensao && (
												<td className="text-white font-bold text-center">
													{
														unidade.tensao
													}
												</td>
											)}
										</tr>
										<tr>
											<td className="text-secondary">
												Fator de
												potência:
											</td>
											{unidade.fatorPotencia && (
												<td className="text-white font-bold text-right">
													<div className="flex justify-center">
														{
															unidade.fatorPotencia
														}
													</div>
												</td>
											)}
										</tr>
									</tbody>
								</table>
							</div>
							{/* Popover para editar sobre a unidade */}
							<div className="flex justify-center order-1 xl:order-3 items-center w-full xl:w-[10%] py-auto">
								<Popover
									as="div"
									className="relative z-10"
								>
									{autorizado ? (
										<Popover.Button
											className="flex outline-none"
											onClick={() =>
												setIsOpen(
													!isOpen
												)
											}
										>
											<EditIcon
												status={
													autorizado
														? 'unlock'
														: 'lock'
												}
											/>
										</Popover.Button>
									) : (
										<EditIcon
											status={
												autorizado
													? 'unlock'
													: 'lock'
											}
										/>
									)}
									{/* Popover para editar sobre a unidade */}
									<Transition
										as={Fragment}
										enter="transition ease-in duration-200"
										enterFrom="opacity-0 translate-y-20"
										enterTo="opacity-100 translate-y-0"
										leave="transition ease-in duration-150"
										leaveFrom="opacity-100 -translate-y-10"
										leaveTo="opacity-0 translate-y-20"
									>
										<Popover.Panel className="bg-darkaccent border shadow-2xl rounded-md border-glassColor absolute z-10 md:-translate-x-[50%] -translate-x-[50%] py-4 px-8 xl:-top-32 top-0 w-96">
											<div className="text-white flex-col gap-y-1 flex w-full">
												<div className="flex justify-between lg:text-lg">
													<label
														name="diaMesTrabalhados"
														className="text-secondary"
													>
														Dia
														/
														Mês
														de
														trabalho:
													</label>
													<div className="max-w-24">
														<InputGeneric
															value={
																diaMesTrabalhados
															}
															onChange={(
																event
															) =>
																setDiaMesTrabalhados(
																	event
																		.target
																		.value
																)
															}
															id="diaMesTrabalhados"
															name="diaMesTrabalhados"
															type="number"
														/>
													</div>
												</div>
												<div className="flex justify-between lg:text-lg">
													<label
														name="horasDiaTrabalhadas"
														className="text-secondary"
													>
														Horas
														/
														Dia
														de
														trabalho:
													</label>
													<div className="w-full max-w-24">
														<InputGeneric
															value={
																horasDiaTrabalhadas
															}
															onChange={(
																event
															) =>
																setHorasDiaTrabalhadas(
																	event
																		.target
																		.value
																)
															}
															id="horasDiaTrabalhadas"
															name="horasDiaTrabalhadas"
															type="number"
														/>
													</div>
												</div>
												<div className="flex justify-between lg:text-lg">
													<label
														name="fatorPotenciaSobreUnidade"
														className="text-secondary"
													>
														Fator
														de
														potência:
													</label>
													<div className="w-full max-w-24">
														<InputGeneric
															value={
																fatorPotencia
															}
															onChange={(
																event
															) =>
																setFatorPotencia(
																	event
																		.target
																		.value
																)
															}
															id="fatorPotenciaSobreUnidade"
															name="fatorPotenciaSobreUnidade"
															type="number"
														/>
													</div>
												</div>
												<div className="flex justify-between lg:text-lg">
													<label
														name="tensaoUnidade"
														className="text-secondary"
													>
														Tensão
														(V):
													</label>
													<div className="w-full max-w-24">
														<InputGeneric
															value={
																tensao
															}
															onChange={(
																event
															) =>
																setTensao(
																	event
																		.target
																		.value
																)
															}
															id="tensaoUnidade"
															name="tensaoUnidade"
															type="number"
														/>
													</div>
												</div>
												<div className="flex flex-col text-lg">
													<Button
														onClick={
															handlePutUnidade
														}
														loading={
															isLoading
														}
													>
														Salvar
													</Button>
												</div>
											</div>
										</Popover.Panel>
									</Transition>
								</Popover>
							</div>
						</div>
						<div className="flex justify-center items-center text-glassColor">
							<svg
								className="flex-shrink-0 inline w-4 h-4 me-3"
								aria-hidden="true"
								xmlns="http://www.w3.org/2000/svg"
								fill="currentColor"
								viewBox="0 0 20 20"
							>
								<path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
							</svg>
							<p className="text-glassColor">
								Dados necessários para cálculos
								e estimativas.
							</p>
						</div>
					</CardLayout>
				</div>
			)}
		</>
	);
};

export default SobreUnidade;
