import * as React from 'react';
import * as TabsPrimitive from '@radix-ui/react-tabs';

import { cn } from '../../lib/utils/utils';

const Tabs = TabsPrimitive.Root;

const TabsList = React.forwardRef(({ className, ...props }, ref) => (
	<TabsPrimitive.List
		ref={ref}
		className={cn(
			'flex h-full lg:inline-flex lg:h-11 4xl:h-16 items-center justify-center rounded-md bg-slate-100  text-slate-500 dark:bg-slate-800 dark:text-slate-400 shadow-lg border-[1px] border-gray-700',
			className
		)}
		{...props}
	/>
));
TabsList.displayName = TabsPrimitive.List.displayName;

const TabsTrigger = React.forwardRef(({ className, ...props }, ref) => (
	<TabsPrimitive.Trigger
		ref={ref}
		className={cn(
			'inline-flex w-full max-w-md items-center justify-center whitespace-nowrap border-[1px] border-transparent rounded-sm px-auto py-1.5 my-[1px] text-sm font-medium ring-offset-white transition-all duration-300 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-darkaccent focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:bg-white data-[state=active]:text-darkaccent data-[state=active]:shadow-sm dark:ring-offset-darkaccent dark:focus-visible:ring-slate-300 dark:data-[state=active]:bg-darkaccent dark:data-[state=active]:text-slate-50 data-[state=active]:border-gray-700',
			className
		)}
		{...props}
	/>
));
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName;

const TabsContent = React.forwardRef(({ className, ...props }, ref) => (
	<TabsPrimitive.Content
		ref={ref}
		className={cn(
			'mt-2 ring-offset-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-darkaccent focus-visible:ring-offset-2 dark:ring-offset-darkaccent dark:focus-visible:ring-slate-300',
			className
		)}
		{...props}
	/>
));
TabsContent.displayName = TabsPrimitive.Content.displayName;

export { Tabs, TabsList, TabsTrigger, TabsContent };
