import React from 'react';
import Logo from '../../assets/imgs/cess.png';
import Container from './Container';
/* import ToggleDarkMode from './ToggleDarkMode'; */
import Hamburguer from './MobileMenu';
import UserConfig from './UserConfig';
// import BellNotification from './BellNotification';
import HeaderLinks from './HeaderLinks';
import { Link } from 'react-router-dom';

const Header = () => {
	return (
		<header className="w-full top-0 left-0 h-16 fixed bg-darkaccent z-20 m-0 drop-shadow-1xl">
			<nav>
				<Container>
					<div className="flex items-center justify-center">
						<div className="w-full flex p-2 opacity-100 z-20">
							<Link to={'/login'}>
								<img
									className="w-28 sm:w-32 drop-shadow-1xl"
									src={Logo}
									alt="Logo"
								/>
							</Link>
						</div>
						<div className="items-center hidden flex-shrink-0 lg:flex gap-x-4 px-2">
							<HeaderLinks />
							<UserConfig />
						</div>
						<div className="items-center flex-shrink-0 flex gap-x-4">
							{/* <BellNotification /> */}
							{/* <ToggleDarkMode /> */}
							<Hamburguer className="p-4 m-4" />
						</div>
					</div>
				</Container>
			</nav>
		</header>
	);
};

export default Header;
