import React, { useEffect, useState, useCallback } from 'react';
import CardLayout from '../../../common/CardLayout';
import StatusOnOff from '../../../common/StatusOnOff';
import InputGeneric from '../../../common/InputGeneric';
import apiLeituraVentilador from '../../../../services/apis/Leituras/apiUltimaLeituraVentilador';
import apiVentiladores from '../../../../services/apis/apiVentilador';
import SignalService from '../../../../services/SignalService';
import { apiLink } from '../../../../constants/env';

const fetchUltimaLeitura = async (vId) => {
	try {
		const res = await apiLeituraVentilador.fetchApi(vId);
		return res.data;
	} catch (error) {
		console.error(
			`Erro fetching ultima leitura [Monitoramento Geral - Condensadores]: ${error}`
		);
	}
};

export default function StatusVentiladores({
	leituraCondensadores,
	condensacao,
}) {
	const [hubConnection, setHubConnection] = useState();
	const [ventiladores, setVentiladores] = useState([]);
	const [condensadores, setCondensadores] = useState([]);
	const [condensadoresData, setCondensadoresData] = useState([]);
	const [leituraVT, setLeituraVT] = useState([]);
	const [leituraAtualVT, setLeituraAtualVT] = useState([]);
	const [ventiladoresData, setVentiladoresData] = useState([]);
	const unidadeId = condensacao?.unidadeId;

	const fetchData = useCallback(async () => {
		if (!unidadeId) return;
		const res = await apiVentiladores.getByUnidadeId(unidadeId);
		setVentiladores(res.data);

		if (res.data.length > 0) {
			const leituras = await Promise.all(
				res.data.map((ventilador) => {
					return fetchUltimaLeitura(ventilador.id);
				})
			);
			setLeituraVT(leituras);
		}
	}, [unidadeId]);

	useEffect(() => {
		setCondensadores(leituraCondensadores);
		fetchData();
	}, [fetchData, leituraCondensadores]);

	useEffect(() => {
		if (condensadores.length > 0) {
			const up = condensadores.map((cd) => {
				const vt = ventiladores.filter(
					(v) => v.condensadorId === cd.id
				);
				const VTLeitura = vt.map((ventilador) => {
					const read = leituraVT.find(
						(l) => l && l.ventiladorId === ventilador.id
					);
					return {
						...ventilador,
						leituraVentilador: read || {},
					};
				});
				return {
					...cd,
					ventiladores: VTLeitura,
				};
			});
			setCondensadoresData(up);
		}
	}, [condensadores, leituraVT, ventiladores]);

	useEffect(() => {
		setHubConnection(
			SignalService.startConnection(
				`${apiLink}LeituraVentiladorHub`
			)
		);
	}, []);

	useEffect(() => {
		if (hubConnection) {
			hubConnection
				.start()
				.then(() => {
					hubConnection.on(
						'AtualizarDadosVentilador',
						(message) => {
							setLeituraAtualVT(message);
						}
					);
				})
				.catch((err) => {
					console.error(
						`Erro on start connection [Monitoramento Geral - Ventiladores]: ${err}`
					);
				});
		}
	}, [hubConnection]);

	useEffect(() => {
		if (leituraAtualVT) {
			const index = condensadoresData.findIndex((condensador) =>
				condensador.ventiladores.some(
					(ventilador) =>
						ventilador.id ===
						leituraAtualVT.ventiladorId
				)
			);
			if (index !== -1) {
				const updatedData = condensadoresData.map(
					(condensador) => {
						if (
							condensador.ventiladores.some(
								(ventilador) =>
									ventilador.id ===
									leituraAtualVT.ventiladorId
							)
						) {
							return {
								...condensador,
								ventiladores:
									condensador.ventiladores.map(
										(ventilador) =>
											ventilador.id ===
											leituraAtualVT.ventiladorId
												? {
														...ventilador,
														leituraVentilador:
															leituraAtualVT,
												  }
												: ventilador
									),
							};
						}
						return condensador;
					}
				);
				setVentiladoresData(updatedData);
			} else {
				setVentiladoresData([...condensadoresData]);
			}
		}
	}, [condensadoresData, leituraAtualVT]);

	return (
		<CardLayout>
			<div className="flex flex-wrap w-full gap-1">
				{ventiladoresData.map((condensador) => (
					<div className="flex flex-1 justify-center items-center  rounded-bl-md border-[1px] border-gray-700 rounded-tl-md rounded-md">
						<div
							key={condensador.id}
							className="flex w-full h-full justify-center items-center bg-accent/20  flex-1 gap-x-1 rounded-bl-md rounded-tl-md"
						>
							<h2 className="text-lg  font-semibold text-accent drop-shadow-1xl px-4 min-w-20 text-center w-full whitespace-nowrap">
								{condensador.nome}
							</h2>
						</div>
						{/* divider */}
						<div className="w-px h-full bg-gray-700"></div>
						<div className="flex  justify-center items-center gap-[1px] w-full ">
							{condensador.ventiladores?.length >
							0 ? (
								<>
									{condensador.ventiladores.map(
										(vt) => (
											<div
												key={
													vt.id
												}
												className=" ml-1 flex w-full gap-x-1 mx-auto justify-center items-center"
											>
												<div className="flex flex-col jutify-center items-center w-full px-auto mx-[1px]">
													<p className="text-gray-400 drop-shadow-1xl font-semibold text-center w-full whitespace-nowrap">
														{
															vt.nome
														}
													</p>
													{vt
														.leituraVentilador
														?.ligado ? (
														<StatusOnOff
															status={
																vt
																	.leituraVentilador
																	.ligado
															}
														/>
													) : (
														<StatusOnOff
															status={
																false
															}
														/>
													)}
												</div>
												{vt
													.leituraVentilador
													?.corrente ||
												vt
													.leituraVentilador
													?.frequencia ? (
													<div className="flex flex-col w-full px-auto px-2 py-1">
														<div className="flex justify-center items-center text-sm text-secondary drop-shadow-1xl gap-1 h-full">
															{vt
																.leituraVentilador
																?.corrente && (
																<>
																	<InputGeneric
																		value={vt.leituraVentilador.corrente.toLocaleString(
																			'pt-br',
																			{
																				maximumFractionDigits: 2,
																			}
																		)}
																		type="string"
																		className={
																			'w-12 h-6'
																		}
																	/>
																	<p className="text-xs w-4">
																		A
																	</p>
																</>
															)}
														</div>
														<div className="flex justify-center items-center text-sm text-secondary drop-shadow-1xl gap-1 h-full">
															{vt
																.leituraVentilador
																?.frequencia && (
																<>
																	<InputGeneric
																		value={vt.leituraVentilador.frequencia.toLocaleString(
																			'pt-br',
																			{
																				maximumFractionDigits: 2,
																			}
																		)}
																		type="string"
																		className={
																			'w-12 h-6'
																		}
																	/>
																	<p className="text-xs w-4">
																		Hz
																	</p>
																</>
															)}
														</div>
													</div>
												) : null}
											</div>
										)
									)}
								</>
							) : (
								<div className="flex w-full h-full justify-center items-center">
									<NoData />
								</div>
							)}
						</div>
					</div>
				))}
			</div>
		</CardLayout>
	);
}

const NoData = () => {
	return (
		<>
			<div className="px-4 text-secondary">-</div>
		</>
	);
};
