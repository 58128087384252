import React from 'react';

const TitleCard = ({ children }) => {
	return (
		<div className="flex justify-center mx-auto items-center text-lg mb-4 font-extrabold leading-none  md:text-3xl lg:text-4xl text-white hover:text-accent drop-shadow-1xl">
			{children}
		</div>
	);
};

export default TitleCard;
