import { useCallback, useEffect, useState } from "react"

export default function JanelasForm({
    numJanelaId,
    deletarJanela,
    quantidade,
    altura,
    largura,
    handleJanelas
    }) {
    const [quantidadeInput, setQuantidadeInput] = useState(quantidade);
    const [alturaInput, setAlturaInput] = useState(altura);
    const [larguraInput, setLarguraInput] = useState(largura);
    const atualizarJanela = useCallback(() => {
        handleJanelas({
            id:parseFloat(numJanelaId),
            quantidade:parseFloat(quantidadeInput),
            altura:parseFloat(alturaInput),
            largura:parseFloat(larguraInput)
        })
    },[alturaInput, handleJanelas, larguraInput, numJanelaId, quantidadeInput]);

    useEffect(() => {
        atualizarJanela();
    },[quantidadeInput, atualizarJanela]);

    useEffect(() => {
        atualizarJanela();
    },[alturaInput, atualizarJanela]);

    useEffect(() => {
        atualizarJanela();
    },[larguraInput, atualizarJanela]);

    

    return (
    <div id={"janela"+numJanelaId} className="flex w-full flex-wrap justify-between items-center px-2">
        <div className="w-[100px] flex content-end flex-col h-[70px]">
            <label htmlFor={"quantidade" + numJanelaId} className="flex flex-col w-[400px] m-t-[10px]">Quantidade</label>
            <input id={"quantidade" + numJanelaId} name={"quantidade" + numJanelaId} type="number" className="bg-transparent border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[80px] h-[40px] mt-1" required value={quantidadeInput} onChange={(e) => setQuantidadeInput(e.target.value)}/>
        </div>

        <div className="w-[100px] flex content-end flex-col h-[70px]">
            <label htmlFor={"altura" + numJanelaId} className="flex flex-col w-[400px] m-t-[10px]">Altura (m)</label>
            <input id={"altura" + numJanelaId} name={"altura" + numJanelaId} type="number" className="bg-transparent border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[80px] h-[40px] mt-1"  required value={alturaInput} onChange={(e) => 
                setAlturaInput(e.target.value)} />
        </div>

        <div className="w-[100px] flex content-end flex-col h-[70px]">
            <label htmlFor={"largura" + numJanelaId} className="flex flex-col w-[400px] m-t-[10px]">Largura (m)</label>
            <input id={"largura" + numJanelaId} name={"largura" + numJanelaId} type="number" className="bg-transparent border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[80px] h-[40px] mt-1"  required value={larguraInput} onChange={(e) => 
                setLarguraInput(e.target.value)} />
        </div>



        <div className="flex items-end h-[70px]">
            <button className="w-[40px] h-[40px] rounded-[5px] border-none bg-[#d20303] align-middle" title="Excluir janela" id={`deletar-janela${numJanelaId}`} type="button"  onClick={() => deletarJanela(numJanelaId)}><i className="fa-solid fa-trash" title="Deletar"></i></button>
        </div>
    </div>
    )
}