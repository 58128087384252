import React, { useState } from 'react';

const SwitchOnOff = ({ status, handleSwitch = () => {} }) => {
	const [isOnline, setOnline] = useState(status === 'on');

	const handleCheckboxChange = () => {
		setOnline(!isOnline);
		if (handleSwitch?.handleSwitch)
			handleSwitch?.handleSwitch(handleSwitch);
	};

	return (
		<>
			<label className="relative inline-flex cursor-pointer select-none items-center">
				<input
					type="checkbox"
					checked={isOnline}
					onChange={handleCheckboxChange}
					className="sr-only"
				/>
				<div className="shadow-card flex h-[32px] w-[64px] items-center justify-center rounded-full bg-primary overflow-hidden border-2 border-darkaccent shadow-lg">
					<div
						className={`flex h-8 w-8 items-center justify-center rounded-full absolute transition-all duration-200 transform border-[1px] border-gray-700 ${
							isOnline
								? '-translate-x-4'
								: 'translate-x-4'
						} bg-darkaccent`}
					></div>
					<div
						className={`z-10 flex h-8 w-8 items-center justify-center rounded-full font-bold drop-shadow-1xl ${
							isOnline
								? 'text-[#00FF00]'
								: 'text-secondary'
						}`}
					>
						<span>ON</span>
					</div>
					<div
						className={`z-10 flex h-8 w-8 items-center justify-center rounded-full font-bold drop-shadow-1xl ${
							isOnline
								? 'text-secondary'
								: 'text-[#FF0000]'
						}`}
					>
						<span>OFF</span>
					</div>
				</div>
			</label>
		</>
	);
};

export default SwitchOnOff;
