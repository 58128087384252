import Chart from 'react-apexcharts';
import NoDataAlert from '../../../common/returns/NoDataAlert';

const ChartAreaViewDefault = ({ chartDataProps, typeChart, nameSerie }) => {
	let data = [];
	let color = '';
	if (typeChart === 'demandaAtiva') {
		data = chartDataProps ? chartDataProps.DemandaAtiva : [];
		color = '#0CABF5';
	} else if (typeChart === 'demandaReativa') {
		data = chartDataProps ? chartDataProps.DemandaReativa : [];
		color = '#FFFF00';
	} else if (typeChart === 'salaMaquinas') {
		data = chartDataProps ? chartDataProps.SalaMaquinas : [];
		color = '#00FF00';
	}

	const series = [
		{
			name: nameSerie ? nameSerie : '',
			data: data,
			color: color,
		},
	];
	const options = {
		chart: {
			id: 'area-chart',
			toolbar: {
				show: false,
			},
			zoom: {
				enabled: false,
			},
		},
		stroke: {
			curve: 'smooth',
			lineCap: 'round',
			width: 4,
		},

		grid: {
			show: false,
			borderColor: '#40475D',
			strokeDashArray: 0,
		},
		fill: {
			type: 'gradient',
			gradient: {
				type: 'vertical',
				opacityFrom: 1,
				opacityTo: 0,
				stops: [0, 100],
				colorStops: [
					{
						offset: 0,
						opacity: 0.4,
						color: color,
					},
					{
						offset: 100,
						opacity: 0,
						color: color,
					},
				],
			},
		},
		dataLabels: {
			enabled: false,
			textAnchor: 'middle',
			style: {
				colors: ['#FFF'],
				fontSize: '12px',
			},
			formatter: function (val, index) {
				return val?.toLocaleString('pt-BR', {
					maximumFractionDigits: 2,
				});
			},
			background: {
				enabled: false,
				foreColor: '',
				padding: 4,
				borderRadius: 2,
				borderWidth: 1,
				borderColor: '#fff',
				opacity: 0.9,
				dropShadow: {
					enabled: false,
					top: 1,
					left: 1,
					blur: 1,
					color: '#000',
					opacity: 0.45,
				},
			},
			offsetY: 0,
			offsetX: 0,

			dropShadow: {
				enabled: true,
				left: 2,
				top: 2,
				opacity: 0.5,
				blur: 1,
			},
		},
		xaxis: {
			categories: chartDataProps ? chartDataProps.date : {},
			position: 'bottom',
			axisBorder: {
				show: false,
			},
			axisTicks: {
				show: false,
				color: '#40475D',
			},
			labels: {
				show: true,
				style: {
					colors: '#40475D',
					fontSize: '12px',
				},
				datetimeFormatter: {
					hour: 'HH:mm',
				},
			},
		},
		yaxis: {
			reversed: false,
			opposite: false,
			floating: false,
			axisBorder: {
				show: false,
			},
			axisTicks: {
				show: false,
				color: '#40475D',
			},
			labels: {
				show: true,
				style: {
					colors: '#40475D',
					fontSize: '12px',
					fontStyle: 'bold',
				},
				formatter: function (val, index) {
					return val?.toLocaleString('pt-BR', {
						maximumFractionDigits: 2,
					});
				},
			},
		},
		tooltip: {
			theme: 'dark',
			x: {
				show: false,
				format: 'dd/MM/yy HH:mm',
				formatter: undefined,
			},
		},
		legend: {
			show: true,
			showForSingleSeries: true,
			showForNullSeries: true,
			showForZeroSeries: true,
			position: 'top',
			horizontalAlign: 'left',
			fontStyle: 'bold',
			fontColor: 'white',
			fontSize: '16px',
			labels: {
				colors: 'white',
				useSeriesColors: false,
			},
			onItemClick: {
				toggleDataSeries: true,
			},
			onItemHover: {
				highlightDataSeries: true,
			},
		},
	};

	if (!chartDataProps) return <NoDataAlert />;

	return (
		<div className="bg-transparent w-full">
			<Chart
				options={options}
				series={series}
				type="area"
				width="100%"
				height={320}
			/>
		</div>
	);
};

export default ChartAreaViewDefault;
