import Chart from 'react-apexcharts';
import NoDataAlert from '../../../common/returns/NoDataAlert';

const ChartViewDefault = ({
	chartDataProps,
	typeChart,
	horizontal,
	offsetX,
	offsetY,
}) => {
	let data,
		data2 = 0;
	let isHorizontal = horizontal ? true : false;
	let offset = { x: offsetX ? offsetX : 0, y: offsetY ? offsetY : 0 };

	if (!chartDataProps || chartDataProps === undefined)
		return (
			<>
				<div className="">
					<NoDataAlert />
				</div>
			</>
		);

	if (typeChart === 'cargasTermicasCondensacao') {
		data = chartDataProps ? chartDataProps.condensadoresDisponivel : 0;
		data2 = chartDataProps ? chartDataProps.condensadoresNecessario : 0;
	} else if (typeChart === 'cargasTermicasCompressores') {
		data = chartDataProps ? chartDataProps.compressoresDisponivel : 0;
		data2 = chartDataProps ? chartDataProps.compressoresNecessario : 0;
	}

	const series = [
		{
			name: 'Disponível ( Kcal/ h )',
			data: data,
			color: '#02FFA9',
		},
		{
			name: 'Necessario ( Kcal/ h )',
			data: data2,
			color: '#0CABF5',
		},
	];

	const options = {
		chart: {
			stacked: false,
			toolbar: {
				show: false,
			},
		},
		grid: {
			show: false,
			borderColor: '#40475D',
			strokeDashArray: 0,
		},
		plotOptions: {
			bar: {
				borderRadius: 5,
				/* borderRadiusApplication: 'end',
				borderRadiusWhenStacked: 'last', */
				columnWidth: '100%',
				horizontal: isHorizontal,
				dataLabels: {
					position: 'top',
				},
			},
		},
		dataLabels: {
			enabled: true,
			textAnchor: 'middle', //
			style: {
				colors: ['#FFF'],
				fontSize: '14px',
			},
			background: {
				enabled: false,
				dropShadow: {
					enabled: true,
					top: 3,
					left: 3,
					blur: 3,
				},
			},
			offsetY: offset.y,
			offsetX: offset.x,
			dropShadow: {
				enabled: true,
				left: 2,
				top: 2,
				opacity: 0.5,
			},
			formatter: function (val, opts) {
				let value = val?.toLocaleString('pt-BR', {
					maximumFractionDigits: 1,
				});
				return ` ${value} Kcal/ h`;
			},
		},
		stroke: {
			width: 1,
			colors: ['#33333380'],
		},
		xaxis: {
			categories: ['Carga Térmica ( Kcal/ h )'],
			position: 'top',
			axisBorder: {
				show: false,
			},
			axisTicks: {
				show: false,
				color: '#40475D',
			},
			labels: {
				show: false,
				style: {
					colors: 'white',
					fontSize: '16px',
				},
				formatter: function (val, index) {
					return val?.toLocaleString('pt-BR', {
						maximumFractionDigits: 2,
					});
				},
				offsetY: -20,
			},
		},
		yaxis: {
			reversed: false,
			opposite: false,
			floating: false,
			axisBorder: {
				show: false,
			},
			axisTicks: {
				show: false,
				color: '#40475D',
			},
			labels: {
				show: false,
				style: {
					colors: '#40475D',
					fontSize: '12px',
					fontStyle: 'bold',
				},
				formatter: function (val, index) {
					return val?.toLocaleString('pt-BR', {
						maximumFractionDigits: 2,
					});
				},
			},
		},
		tooltip: {
			theme: 'dark',
			shared: true,
			intersect: false,
			y: {
				show: true,
			},
		},

		fill: {
			type: 'gradient',
			gradient: {
				type: 'radial',
				shadeIntensity: 1,
				gradientToColors: ['#02FFA980', '#6078ea'],
				inverseColors: false,
				opacityFrom: 1,
				opacityTo: 1,
				stops: [],
				colorStops: [],
			},
		},
		legend: {
			show: true,
			showForSingleSeries: true,
			showForNullSeries: true,
			showForZeroSeries: true,
			floating: false,
			position: 'top',
			horizontalAlign: 'center',
			fontStyle: 'bold',
			fontColor: 'white',
			fontSize: '14px',
			offsetX: 0,
			offsetY: 0,
			labels: {
				colors: 'white',
				useSeriesColors: false,
			},
			onItemClick: {
				toggleDataSeries: true,
			},
			onItemHover: {
				highlightDataSeries: true,
			},
		},
	};

	return (
		<div className="bg-transparent w-full h-full px-2">
			<Chart
				options={options}
				series={series}
				type="bar"
				width="100%"
				height="100%"
			/>
		</div>
	);
};

export default ChartViewDefault;
