// import BaseEscolhaUnidade from '../../components/base/BaseEscolhaUnidade';
import { Link, useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { AuthContext } from '../.././contexts/AuthContext';
import apiEmpresa from '../.././services/apis/apiEmpresa';
import apiUnidade from '../.././services/apis/apiUnidade';
import MainButton from '../../components/common/MainButton';
import CardLayout from '../../components/common/CardLayout';
import SgemAnimate from '../../components/common/SgemAnimate';
import { ChevronDown } from 'lucide-react';
function EscolhaUnidade() {
	const [autorizado, setAutorizado] = useState(false);

	const [listaGrupos, setListaGrupos] = useState([]);
	const [listaEmpresas, setListaEmpresas] = useState([]);
	const [listaUnidades, setListaUnidades] = useState([]);
	const [grupoId, setGrupoId] = useState(0);
	const [empresaId, setEmpresaId] = useState(0);
	const [unidadeId, setUnidadeId] = useState(0);

	//VERIFICAR SE TEM TOKEN NO SESSION STORAGE

	const { verificarCargo, pegaGrupoAutorizado } = useContext(AuthContext);
	const navigate = useNavigate();

	useEffect(() => {
		verificarCargo('SUPER_USER', 'ADMIN').then((res) => {
			setAutorizado(res);
		});
	}, [verificarCargo]);

	useEffect(() => {
		pegaGrupoAutorizado()
			.then((res) => {
				if (res.data !== undefined) {
					setListaGrupos(res.data);
				}
			})
			.catch((error) => {
				console.error(error);
			});
	}, [pegaGrupoAutorizado]);

	useEffect(() => {
		if (
			listaGrupos.length !== 0 &&
			typeof listaGrupos[0] === 'string' &&
			listaGrupos[0].includes('DIRETOR')
		) {
			setGrupoId(listaGrupos[1]);
			const grupoIdBlock = document.getElementById('grupoIdBlock');
			const grupoIdSelect = document.getElementById('grupoId');
			grupoIdBlock.classList.add('hidden');
			grupoIdSelect.removeAttribute('required');

			if (listaGrupos[0].includes('EMPRESA')) {
				const empresaIdBlock =
					document.getElementById('empresaIdBlock');
				const empresaIdSelect =
					document.getElementById('empresaId');
				empresaIdBlock.classList.add('hidden');
				empresaIdSelect.removeAttribute('required');
				setEmpresaId(listaGrupos[1]);
			}
		}
	}, [listaGrupos]);
	useEffect(() => {
		if (grupoId > 0) {
			apiEmpresa
				.getByGrupoApi(grupoId)
				.then((res) => {
					setListaEmpresas(res.data);
				})
				.catch((error) => {
					console.error(error);
				});
		} else {
			setListaEmpresas([]);
		}
		setEmpresaId(0);
	}, [grupoId]);

	useEffect(() => {
		if (empresaId > 0) {
			apiUnidade
				.getByEmpresaApi(empresaId)
				.then((res) => {
					setListaUnidades(res.data);
				})
				.catch((error) => {
					console.error(error);
				});
		} else {
			setListaUnidades([]);
		}
	}, [empresaId]);

	function selecionarUnidade() {
		if (unidadeId > 0) {
			localStorage.setItem('unId', unidadeId);
			window.dispatchEvent(new Event('storage'));
			navigate('/monitoramento/geral');
		}
	}

	return (
		<>
			<div className="w-full max-w-4xl h-full flex justify-center items-center mx-auto mt-8 lg:pr-16 lg:pt-8">
				<CardLayout>
					<div className="w-full p-4 2xl:p-8 4xl:p-16">
						<SgemAnimate />
						<form
							className="pt-2"
							onSubmit={(e) => selecionarUnidade()}
						>
							<div className="flex flex-col mt-[5px]">
								<div
									className="mb-[10px] relative"
									id="grupoIdBlock"
								>
									<label
										htmlFor="grupos"
										className="text-lg px-4"
									>
										Grupo
									</label>
									<div className="w-full absolute left-[87%] lg:left-[95%] top-9 -z-10 text-white/80">
										<ChevronDown />
									</div>
									<select
										name="grupoId"
										id="grupoId"
										required
										onChange={(e) =>
											setGrupoId(
												parseInt(
													e
														.target
														.value
												)
											)
										}
										className="cursor-pointer appearance-none bg-transparent border border-accent h-full rounded-3xl w-full py-1.5 px-4 lg:px-6 2xl:px-10 text-lg"
										defaultValue={''}
									>
										<option
											className="bg-darkaccent"
											value=""
											disabled
											defaultValue
										>
											Escolha um
											grupo
										</option>
										{listaGrupos.map(
											(
												grupo,
												i
											) => (
												<option
													className="bg-darkaccent"
													value={
														grupo.id
													}
													key={
														i
													}
												>
													{
														grupo.nome
													}
												</option>
											)
										)}
									</select>
								</div>
								<div
									className="mb-[10px] relative"
									id="empresaIdBlock"
								>
									<label
										htmlFor="empresas"
										className="text-lg px-4"
									>
										Empresa
									</label>
									<div className="w-full absolute left-[87%] lg:left-[95%] top-9 -z-10 text-white/80">
										<ChevronDown />
									</div>
									<select
										name="empresaId"
										id="empresaId"
										required
										onChange={(e) =>
											setEmpresaId(
												parseInt(
													e
														.target
														.value
												)
											)
										}
										className="cursor-pointer appearance-none bg-transparent border border-accent h-full rounded-3xl w-full py-1.5 px-4 lg:px-6 2xl:px-10 text-lg"
										defaultValue={''}
									>
										<option
											className="bg-darkaccent"
											value=""
											disabled
											defaultValue
										>
											Escolha uma
											empresa
										</option>
										{listaEmpresas.map(
											(
												empresa,
												i
											) => (
												<option
													className="bg-darkaccent"
													value={
														empresa.id
													}
													key={
														i
													}
												>
													{
														empresa.nome
													}
												</option>
											)
										)}
									</select>
								</div>
								<div className="mb-[10px] relative">
									<label
										htmlFor="unidadeId"
										className="text-lg px-4"
									>
										Unidade
									</label>
									<div className="w-full absolute left-[87%] lg:left-[95%] top-9 -z-10 text-white/80">
										<ChevronDown />
									</div>
									<select
										name="unidadeId"
										id="unidadeId"
										required
										onChange={(e) =>
											setUnidadeId(
												parseInt(
													e
														.target
														.value
												)
											)
										}
										className="cursor-pointer appearance-none bg-transparent border border-accent h-full rounded-3xl w-full py-1.5 px-4 lg:px-6 2xl:px-10 text-lg"
										defaultValue={''}
									>
										<option
											className="bg-darkaccent my-2"
											value=""
											disabled
											defaultValue
										>
											Escolha uma
											unidade{' '}
										</option>
										{listaUnidades.map(
											(
												unidade,
												i
											) => (
												<option
													className="bg-darkaccent"
													value={
														unidade.id
													}
													key={
														i
													}
												>
													{
														unidade.nome
													}
												</option>
											)
										)}
									</select>
								</div>
							</div>

							<div className="flex flex-col justify-center items-center py-6 gap-y-2">
								<div className="py-2 w-full">
									<MainButton>
										<p className="font-bold py-2 drop-shadow-1xl">
											ENTRAR
										</p>
									</MainButton>
								</div>
								{autorizado && (
									<div className="py-2 w-full">
										<Link
											to={
												'/admin/grupo/1'
											}
										>
											<MainButton
												styles={{
													style: 'bg-gradient-to-t from-amber-500 to-orange-700 hover:from-amber-600 hover:to-orange-800',
												}}
											>
												<p className="font-bold py-2 drop-shadow-1xl">
													{' '}
													PÁGINA
													ADMIN{' '}
												</p>
											</MainButton>
										</Link>
									</div>
								)}
							</div>
						</form>
					</div>
				</CardLayout>
			</div>
		</>
	);
}

export default EscolhaUnidade;
