import React, { useEffect, useState, useCallback } from 'react';
import CardLayout from '../../common/CardLayout';
import NoDataAlert from '../../common/returns/NoDataAlert';
import ChartViewLineVazoes from './charts/ChartLineVazoesVolAr';
import ChartViewDefault from './charts/ChartBarDefault';
import ChartCapacidades from './charts/ChartCapacidades';
import ChartEECondensadores2 from './charts/ChartEECondensadores';
import StatusCondensadores from './ListCondensadores/StatusCondensadores';
import apiCondensadores from '../../../services/apis/apiCondensadores';
import SignalService from '../../../services/SignalService';
import { apiLink } from '../../../constants/env';
import apiUltimaLeituraCondensador from '../../../services/apis/Leituras/apiUltimaLeituraCondensador';
import StatusVentiladores from './ListCondensadores/StatusVentiladores';

export default function ListCondesadores({ condensacao }) {
	const condensacaoId = condensacao?.id;
	const [hubConnection, setHubConnection] = useState();
	const [condensadores, setCondensadores] = useState([]);
	const [leituras, setLeituras] = useState([]);
	const [dadosGraficos, setDadosGraficos] = useState([]);
	const [leituraAtual, setLeituraAtual] = useState([]);
	const [wsDadosGraficos, setWsDadosGraficos] = useState([]);

	const fetchData = useCallback(async () => {
		try {
			if (!condensacaoId) return;
			const res = await apiCondensadores.getByRegimeId(
				condensacaoId
			);
			setCondensadores(
				res.data.map(function (condensador) {
					return {
						id: condensador.id,
						nome: condensador.nome,
					};
				})
			);

			if (res.data.length > 0) {
				const leituras = await Promise.all(
					res.data.map((condensador) => {
						return fetchUltimaLeitura({
							cdID: condensador.id,
						});
					})
				);
				setLeituras(leituras);
			}
		} catch (error) {
			console.error(
				`Erro fetching condensadores [SobreUnidade - Condensadores]: ${error}`
			);
		}
	}, [condensacaoId]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	useEffect(() => {
		if (condensadores.length > 0) {
			const update = condensadores.map((condensador) => {
				const read = leituras.find(
					(leitura) =>
						leitura &&
						leitura.condensadorId === condensador.id
				);
				const vazoes = [];
				read &&
					vazoes.push({
						timestamp: new Date(
							read.dataHora
						).getTime(),
						valor: read.vazaoVolumetricaAr,
					});

				if (read) {
					return {
						...condensador,
						leitura: read,
						vazoes: vazoes,
					};
				} else {
					return condensador;
				}
			});
			setDadosGraficos(update);
		}
	}, [condensadores, leituras]);

	useEffect(() => {
		setHubConnection(
			SignalService.startConnection(
				`${apiLink}LeituraCondensadorHub`
			)
		);
	}, []);

	useEffect(() => {
		if (hubConnection) {
			hubConnection
				.start()
				.then(() => {
					hubConnection.on(
						'AtualizarDadosCondensador',
						(message) => {
							setLeituraAtual(message);
						}
					);
				})
				.catch((err) =>
					console.log(
						'Error while establishing connection [SobreUnidade - Condensadores]:',
						err
					)
				);
		}
	}, [hubConnection]);

	useEffect(() => {
		if (leituraAtual) {
			const index = dadosGraficos.findIndex(
				(condensador) =>
					condensador.id === leituraAtual.condensadorId
			);
			if (index !== -1) {
				const updatedData =
					wsDadosGraficos.length > 0
						? [...wsDadosGraficos]
						: [...dadosGraficos];

				const updatedVazoes = Array.isArray(
					updatedData[index].vazoes
				)
					? [...updatedData[index].vazoes]
					: [];
				console.log(updatedVazoes);

				updatedVazoes.push({
					timestamp: new Date(
						leituraAtual.dataHora
					).getTime(),
					valor: leituraAtual.vazaoVolumetricaAr,
				});

				updatedData[index] = {
					...updatedData[index],
					leitura: leituraAtual,
					vazoes: updatedVazoes,
				};
				setWsDadosGraficos(updatedData);
			} else {
				setWsDadosGraficos([...dadosGraficos]);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [leituraAtual, dadosGraficos]);

	return (
		<>
			<StatusCondensadores
				leituraCondensadores={wsDadosGraficos}
				condensacao={condensacao}
			/>
			<div className="flex w-full flex-wrap lg:flex-nowrap gap-4">
				<CardLayout>
					<div className="flex flex-col w-full justify-center items-center gap-y-4 py-2">
						<h2 className="text-lg 2xl:text-2xl font-semibold text-accent drop-shadow-1xl">
							Capacidade Condensadores
						</h2>
						<div className="flex w-full items-center">
							{dadosGraficos.length > 0 ? (
								<ChartCapacidades
									chartDataProps={wsDadosGraficos.filter(
										(d) => d.leitura
									)}
								/>
							) : (
								<div className="flex justify-center items-center mx-auto">
									<NoDataAlert />
								</div>
							)}
						</div>
					</div>
				</CardLayout>
				<CardLayout>
					<div className="flex flex-col w-full justify-center items-center gap-y-4 py-2">
						<h2 className="text-lg 2xl:text-2xl font-semibold text-accent drop-shadow-1xl">
							Indice Eficiência Condensadores
						</h2>
						<div className="flex w-full items-center">
							{dadosGraficos.length > 0 ? (
								<ChartEECondensadores2
									chartDataProps={wsDadosGraficos.filter(
										(d) => d.leitura
									)}
								/>
							) : (
								<div className="flex justify-center items-center mx-auto">
									<NoDataAlert />
								</div>
							)}
						</div>
					</div>
				</CardLayout>
			</div>
			<div className="flex w-full flex-wrap lg:flex-nowrap gap-4">
				<CardLayout>
					<div className="flex flex-col w-full justify-center items-center gap-y-4 py-2">
						<h2 className="text-lg 2xl:text-2xl font-semibold text-accent drop-shadow-1xl">
							TBU Admissão do Ar
						</h2>
						<div className="flex w-full items-center">
							{dadosGraficos.length > 0 ? (
								<ChartViewDefault
									chartDataProps={wsDadosGraficos.filter(
										(d) => d.leitura
									)}
									typeChart={'tbuAdmissao'}
									nameSerie={' TBU ( °C )'}
								/>
							) : (
								<div className="flex justify-center items-center mx-auto">
									<NoDataAlert />
								</div>
							)}
						</div>
					</div>
				</CardLayout>
				<CardLayout>
					<div className="flex flex-col w-full justify-center items-center gap-y-4 py-2">
						<h2 className="text-lg 2xl:text-2xl font-semibold text-accent drop-shadow-1xl">
							Ganho energético do Ar
						</h2>
						<div className="flex w-full items-center">
							{dadosGraficos.length > 0 ? (
								<ChartViewDefault
									chartDataProps={wsDadosGraficos.filter(
										(d) => d.leitura
									)}
									typeChart={
										'ganhoEnergetico'
									}
									nameSerie={
										'Ganho Energético  ( kJ/Kg )'
									}
								/>
							) : (
								<div className="flex justify-center items-center mx-auto">
									<NoDataAlert />
								</div>
							)}
						</div>
					</div>
				</CardLayout>
				<CardLayout>
					<div className="flex flex-col w-full justify-center items-center gap-y-4 py-2">
						<h2 className="text-lg 2xl:text-2xl font-semibold text-accent drop-shadow-1xl">
							Umidade Relativa
						</h2>
						<div className="flex w-full items-center">
							{dadosGraficos.length > 0 ? (
								<ChartViewDefault
									chartDataProps={wsDadosGraficos.filter(
										(d) => d.leitura
									)}
									typeChart={
										'umidadeRelativaSaida'
									}
									nameSerie={
										'Umidade Relativa  ( % )'
									}
								/>
							) : (
								<div className="flex justify-center items-center mx-auto">
									<NoDataAlert />
								</div>
							)}
						</div>
					</div>
				</CardLayout>
			</div>
			<div className="flex w-full h-full">
				<StatusVentiladores
					leituraCondensadores={wsDadosGraficos}
					condensacao={condensacao}
				/>
			</div>
			<div className="flex w-full flex-wrap lg:flex-nowrap gap-4">
				<CardLayout>
					<div className="flex w-full flex-col justify-center items-center gap-y-4 py-2">
						<h2 className="text-lg 2xl:text-2xl font-semibold text-accent drop-shadow-1xl">
							Vazao Volumetrica do Ar
						</h2>
						<p className="text-sm font-semibold text-white drop-shadow-1xl">
							Vazao ( m³/s )
						</p>
						<div className="flex w-full items-center px-0 md:px-8">
							{dadosGraficos.length > 0 ? (
								<ChartViewLineVazoes
									chartDataProps={wsDadosGraficos.filter(
										(d) => d.leitura
									)}
								/>
							) : (
								<div className="flex justify-center items-center mx-auto">
									<NoDataAlert />
								</div>
							)}
						</div>
					</div>
				</CardLayout>
			</div>
		</>
	);
}

const fetchUltimaLeitura = async ({ cdID }) => {
	try {
		const res = await apiUltimaLeituraCondensador.fetchApi(cdID);
		return res.data;
	} catch (error) {
		console.error(
			`Erro fetching ultima leitura Condensador [SobreUnidade - Condensadores]: ${error}`
		);
	}
};
