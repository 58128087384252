import CardLayout from '../../common/CardLayout';
import TitleCardSimple from '../../common/TitleCardSimple';
import { TbSum } from 'react-icons/tb';
import CardPeriodoMedido from './CardPeriodoMedido';

import ChartAreaDefault from './charts/ChartAreaDefault';


const DemandaReativaDiario = ({ dados, dadosDetalhado }) => {
	const valores = dados.map((elemento) => {
		return elemento.demandaReativa;
	});

	const horas = dados.map((elemento) => {
		const horaFormatada = new Date(0, 0, 0, elemento.hora, 0, 0); // hora, minuto, segundo
		const horaString = horaFormatada.toLocaleTimeString([], {
			hour: '2-digit',
			hour12: false,
			minute: '2-digit',
		});
		return `${horaString}`;
	});

	const DemandaReativaData = {
		date: horas,
		DemandaReativa: valores,
	};
	return (
		<>
			<CardLayout>
				<div className="flex justify-center h-full flex-col items-center px-4">
					<TitleCardSimple>
						Demanda reativa{' '}
						<span className="text-accent">(</span>
						diário
						<span className="text-accent">)</span>
					</TitleCardSimple>
					<div className="flex justify-center items-center flex-coltext-white text-sm lg:text-lg">
						<p className="py-2 flex items-center px-2 lg:text-lg">
							Acumulado total
							<TbSum className="h-5 w-5 pl-1" />
						</p>
						<p className="drop-shadow-1xl text-lg lg:text-2xl font-bold bg-gradient-to-r from-yellow-200 via-[#FFFF00] to-yellow-200 inline-block text-transparent bg-clip-text">
							{dadosDetalhado?.total.toLocaleString(
								'pt-BR',
								{
									maximumFractionDigits: 2,
								}
							)}{' '}
							{'kVAR'}
						</p>
					</div>
					<div className="flex justify-center items-center">
						<CardPeriodoMedido
							ponta={dadosDetalhado?.ponta.toLocaleString(
								'pt-BR',
								{
									maximumFractionDigits: 2,
								}
							)}
							FPCapacitivo={dadosDetalhado?.foraPontaCapacitivo.toLocaleString(
								'pt-BR',
								{
									maximumFractionDigits: 2,
								}
							)}
							FPIndutivo={dadosDetalhado?.foraPontaIndutivo.toLocaleString(
								'pt-BR'
							)}
							dateFPCapacitivo={
								dadosDetalhado?.data
							}
							dateFPIndutivo={dadosDetalhado?.data}
							datePonta={dadosDetalhado?.data}
							unidade={'kVAR'}
						/>
					</div>
					<ChartAreaDefault
						typeChart="demandaReativa"
						nameSerie="Demanda Reativa ( kVAR )"
						chartDataProps={DemandaReativaData}
					/>
				</div>
			</CardLayout>
		</>
	);
};

export default DemandaReativaDiario;
