import { apiLink } from '../../constants/env';
import axiosInstance from '../AxiosInstance';

const apiAmbientes = {
    fetchApi: async (page) => {
        const URL = `${apiLink}api/ambiente?page=` + page;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();
            })
    },
    getById: async (id) => {
        const URL = `${apiLink}api/ambiente/` + id;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();
            })
    },
    postApi: async (ambiente) => {
        const URL = `${apiLink}api/ambiente`;
        return await axiosInstance.post(URL, ambiente)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();
            })
    },
    deleteApi: async (id) => {
        const URL = `${apiLink}api/ambiente/status/${id}`;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();

            })
    },
    putApi: async (id, ambiente) => {
        const URL = `${apiLink}api/ambiente/${id}`;
        return await axiosInstance.put(URL, ambiente)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();

            })
    },
    getByRegimeId: async (id) => {
        const URL = `${apiLink}api/ambiente/regime/${id}`;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao pegar dados do Ambiente:', error);
                throw new Error();
            })
    },

}

export default apiAmbientes; 