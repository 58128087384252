import { Link, useNavigate } from 'react-router-dom';
import { useContext, useState } from 'react';
import { ToastContext } from '../../contexts/ToastContext';
import apiAtuth from '../../services/apis/apiAuth';
import { AuthContext } from '../../contexts/AuthContext';
import SgemAnimate from '../../components/common/SgemAnimate';
import MainButton from '../../components/common/MainButton';

function Login() {
	const navigate = useNavigate();
	const [userName, setUserName] = useState('');
	const [password, setPassword] = useState('');
	const { erro } = useContext(ToastContext);
	const { deslogar } = useContext(AuthContext);

	const handleChangeEmail = (e) => {
		setUserName(e.target.value);
	};

	const handleChangeSenha = (e) => {
		setPassword(e.target.value);
	};

	function aoSubmeterFormulario(e) {
		e.preventDefault(e);
		setUserName(e.target.nomeUsuario.value);
		setPassword(e.target.password.value);

		const usuario = {
			userName: userName,
			password: password,
		};

		apiAtuth
			.login(usuario)
			.then((res) => {
				deslogar();
				localStorage.setItem('token', res.data.token);
				localStorage.setItem(
					'refreshToken',
					res.data.refreshToken
				);
				navigate('/escolha-unidade');
			})
			.catch((error) => {
				if (error?.response?.data?.message) {
					alert(error.response.data.message);
				} else {
					deslogar();
					erro('Usuário ou senha incorreto');
					setUserName('');
					setPassword('');
					console.error(error);
				}
			});
	}
	return (
		<div className="bg-transparent text-white rounded-md md:shadow-2xl w-full max-w-7xl">
			<div className="flex flex-col md:flex-row">
				{/* <!-- PARTE DA ESQUERDA --> */}
				<div className="p-6 md:p-8 2xl:p-20 w-full md:w-1/2 bg-transparent md:bg-gradient-to-r md:bg-primary md:from-darkaccent md:to-darkaccent md:via-primary">
					<div className="flex flex-col justify-center md:py-4 md:gap-2">
						<h1 className="text-lg md:text-2xl font-bold drop-shadow-1xl text-center">
							Sistema de Gerenciamento e
							Monitoramento Industrial
						</h1>
						<div className="mt-[30px]">
							<SgemAnimate />
						</div>
					</div>
				</div>
				{/* <!-- PARTE DE DIREITA --> */}
				<div className="w-full md:w-1/2 md:bg-darkaccent bg-transparent px-4">
					<div className="flex flex-col justify-center h-full">
						<p className="md:my-4 py-1 text-lg md:text-2xl text-center">
							AUTENTICAÇÃO DE USUÁRIO
						</p>
						<form
							onSubmit={(e) => {
								aoSubmeterFormulario(e);
							}}
						>
							<div className="flex flex-col p-4 gap-y-2">
								<div className="flex flex-col">
									<label
										htmlFor="usuario"
										className="text-lg md:text-2xl"
									>
										Usuário
									</label>
									<input
										className="bg-transparent border border-lightBlue rounded-3xl px-[10px] py-[6px] text-[18px] max-sm:h-[45px] max-sm:text-[22px]"
										type="text"
										name="nomeUsuario"
										id="usuario"
										placeholder="Insira o seu usuário"
										required
										value={userName}
										onChange={
											handleChangeEmail
										}
									/>
								</div>
								<div className="flex flex-col">
									<label
										htmlFor="password"
										className="text-lg md:text-2xl"
									>
										Senha
									</label>
									<input
										className="bg-transparent border border-lightBlue rounded-3xl px-[10px] py-[6px] text-[18px] max-sm:h-[45px] max-sm:text-[22px]"
										type="password"
										name="password"
										id="password"
										placeholder="Insira a sua senha"
										required
										value={password}
										onChange={
											handleChangeSenha
										}
									/>
								</div>
							</div>
							{/* Button submit and forgot password */}
							<div className="flex flex-col px-4 gap-y-2 my-2">
								<div className="flex justify-center items-center">
									<Link to={"/recuperar-senha"}>
										<p className='hover:underline'>Esqueci a senha</p>
									</Link>
								</div>
								<div className="flex justify-center items-center">
									<MainButton>
										<p className="font-bold py-2">
											ENTRAR
										</p>
									</MainButton>
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	);
}
export default Login;
