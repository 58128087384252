import { apiLink } from '../../constants/env';
import axiosInstance from '../AxiosInstance';

const apiVentilador = {
    fetchApi: async (page) => {
        const URL = `${apiLink}api/ventilador?page=` + page;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
            })
    },
    postApi: async (ventilador) => {
        const URL = `${apiLink}api/ventilador`;
        return await axiosInstance.post(URL, ventilador)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao fazer o post dos dados:', error);
                throw new Error(error);
            })
    },
    putApi: async (id, ventilador) => {
        const URL = `${apiLink}api/ventilador/${id}`;
        return await axiosInstance.put(URL, ventilador)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao fazer o post dos dados:', error);
                throw new Error();

            })
    },
    deleteApi: async (id) => {
        const URL = `${apiLink}api/ventilador/status/${id}`;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao deletar o Ventilador:', error);
                throw new Error();

            })
    },
    getByUnidadeId: async (id) => {
        const URL = `${apiLink}api/ventilador/unidade/${id}`;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao pegar Ventilador:', error);
                throw new Error();

            })
    },

}

export default apiVentilador; 