import React, { useEffect, useState } from 'react';
import SmallCardLayout from '../../common/SmallCardLayout';
import { LiaTemperatureLowSolid } from 'react-icons/lia';
import { colorsPallete } from '../../../constants';
import apiAmbientes from '../../../services/apis/apiAmbientes';
import apiUltimaLeituraAmbiente from '../../../services/apis/Leituras/apiUltimaLeituraAmbiente';
import SignalService from '../../../services/SignalService';
import { apiLink } from '../../../constants/env';

const ListAmbientes = ({ listaRegimes }) => {
	const [listaAmbientes, setListaAmbientes] = useState([]);
	const [listaTemperaturas, setListaTemperaturas] = useState([]);
	const [hubConnection, setHubConnection] = useState();
	const [dadoAtualizado, setDadoAtualizado] = useState();

	//GET AMBIENTES
	useEffect(() => {
		let colorIndex = 0;
		setListaAmbientes([]);
		listaRegimes.length > 0 &&
			listaRegimes.forEach((elemento) => {
				if (elemento.tipoRegime !== 'Condensação') {
					elemento['RegimeColor'] =
						colorsPallete[colorIndex].borderClass;
					colorIndex += 1;

					apiAmbientes
						.getByRegimeId(elemento.id)
						.then((res) => {
							let ambientes = res.data;
							if (ambientes.length > 0) {
								ambientes.forEach(
									(ambiente) => {
										ambiente[
											'borderClass'
										] =
											elemento[
												'RegimeColor'
											];
									}
								);
								setListaAmbientes((current) => [
									...current,
									ambientes,
								]);
							}
						})
						.catch((error) => {
							console.log(
								`Error fetching ambientes - [ ListAmbientes ]:`,
								error
							);
						});
				}
			});
	}, [listaRegimes]);

	useEffect(() => {
		setListaTemperaturas([]);
		listaAmbientes.forEach((listaTemperatura) => {
			listaTemperatura.forEach((elemento) => {
				apiUltimaLeituraAmbiente
					.fetchApi(elemento.id)
					.then((res) => {
						if (res === undefined) {
							setListaTemperaturas((current) => [
								...current,
								{
									temperaturaAtual: '-',
									ambienteId: elemento.id,
								},
							]);
						} else {
							setListaTemperaturas((current) => [
								...current,
								res.data,
							]);
						}
					})
					.catch((error) => {
						console.error(
							`Error fetching ultima leitura ambiente [ListAmbientes]:, ${error}`,
							error
						);
					});
			});
		});
	}, [listaAmbientes]);

	//ATUALIZAÇÃO EM TEMPO REAL
	useEffect(() => {
		setHubConnection(
			SignalService.startConnection(`${apiLink}LeituraAmbienteHub`)
		);
	}, []);

	useEffect(() => {
		if (hubConnection) {
			hubConnection
				.start()
				.then((result) => {
					hubConnection.on(
						'AtualizarDadosAmbiente',
						(message) => {
							setDadoAtualizado(message);
						}
					);
				})
				.catch((e) => console.log('Conexão falhou. ', e));
		}
	}, [hubConnection]);

	//ATUALIZA DADOS
	useEffect(() => {
		if (dadoAtualizado) {
				const index = listaTemperaturas.findIndex(
					(elemento) => {
						return (
							elemento.ambienteId ===
							dadoAtualizado.ambienteId
						);
					}
				);

				let arrayAux = [...listaTemperaturas];
				if(index === -1) {
					arrayAux.push(dadoAtualizado);
				} else {
					arrayAux[index] = dadoAtualizado;	
				}
				setListaTemperaturas([...arrayAux]);
				setDadoAtualizado(null);
		}
	}, [dadoAtualizado, listaTemperaturas]);

	function getTemperaturaByAmbienteId(id) {
		const temperatura = listaTemperaturas.find(
			(elemento) => elemento.ambienteId === id
		);
		return temperatura?.temperaturaAtual;
	}

	function getReference(id) {
		const ref = listaAmbientes.flat().find((obj) => obj.id === id);
		return ref?.temperaturaReferencia;
	}

	function formatTemperatura(id) {
		const temp = getTemperaturaByAmbienteId(id);
		const ref = getReference(id);

		let value = temp.toLocaleString('pt-BR', {
			maximumFractionDigits: 2,
		});

		let classTemp = '';

		if (temp > ref) {
			classTemp = 'text-red-500 animate-pulse';
		} else if (temp < ref - 5) {
			classTemp = 'text-yellow-300 animate-pulse';
		} else {
			classTemp = 'text-white';
		}

		return { value, classTemp };
	}

	const allAmbientes = listaAmbientes.flatMap((regime, index) => {
		return regime.map((ambiente) => ({
			...ambiente,
		}));
	});


	return (
		<>
			{allAmbientes.length > 0 ? (
				<div className="flex flex-row w-full mt-2">
					<div className="flex flex-col h-full text-white">
						<div className="flex flex-wrap w-9 text-center h-full mr-1">
							<SmallCardLayout className="flex flex-col items-center border-glassColor h-full justify-center">
								<>
									<p>A</p>
									<p>M</p>
									<p>B</p>
									<p>I</p>
									<p>E</p>
									<p>N</p>
									<p>T</p>
									<p>E</p>
									<p>S</p>
								</>
							</SmallCardLayout>
						</div>
					</div>
					<div className="flex flex-wrap w-full h-full gap-y-2">
						{allAmbientes.map((ambiente, i) => (
							<div
								className={
									allAmbientes.length <= 3
										? 'w-full'
										: 'w-full min-w-[22rem] flex-1'
								}
								key={i}
							>
								<SmallCardLayout
									className={`${ambiente.borderClass} h-full ml-2`}
								>
									<div className="flex flex-col flex-grow items-center w-full h-full justify-center">
										<div className="flex items-center h-full text-secondary drop-shadow-1xl text-lg text-center">
											{
												ambiente.nome
											}{' '}
										</div>
										<div className="flex items-center h-full">
											<LiaTemperatureLowSolid className="text-accent/40 w-6 h-6 mr-3" />
											{getTemperaturaByAmbienteId(
												ambiente.id
											) ? (
												<span
													className={`font-bold drop-shadow-1xl text-lg 2xl:text-3xl ${
														formatTemperatura(
															ambiente.id
														)
															.classTemp
													}`}
												>
													{
														formatTemperatura(
															ambiente.id
														)
															.value
													}
													<p className="text-secondary inline-flex px-3 text-sm lg:text-lg">
														°C
													</p>
												</span>
											) : (
												<span className="animate-pulse text-alarm/60 font-semibold drop-shadow-1xl text-lg">
													OFF
												</span>
											)}
										</div>
									</div>
								</SmallCardLayout>
							</div>
						))}
					</div>
				</div>
			) : (
				<div className="-top-4"></div>
			)}
		</>
	);
};

export default ListAmbientes;
