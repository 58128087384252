import React from 'react';

const SgemAnimate = () => {
	return (
		<>
			<div className="relative flex items-center justify-center py-16">
				<div className="semi_arc e5 c_ease absolute">
					<div className="semi_arc_3 e5_1">
						<div className="semi_arc_3 e5_2">
							<div className="semi_arc_3 e5_3">
								<div className="semi_arc_3 e5_4"></div>
							</div>
						</div>
					</div>
				</div>
				<div className="text-4xl text-center text-accent font-bold">
					<span className="inline-block">SGEM</span>
				</div>
			</div>
		</>
	);
};

export default SgemAnimate;
