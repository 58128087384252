function Resistor({ fill = '#000' }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width="36"
			height="36"
			fill={fill}
			version="1.1"
			viewBox="0 0 462.782 462.782"
			xmlSpace="preserve"
		>
			<g>
				<g>
					<path d="M350.272 323.014a14.999 14.999 0 01-13.783-9.091l-50.422-117.65-40.505 116.662a15.002 15.002 0 01-14.038 10.08h-.133a15 15 0 01-14.081-9.831l-41.979-114.358-40.798 114.234a15 15 0 01-13.827 9.952 14.983 14.983 0 01-14.213-9.394l-31.482-78.172H0v-30h85.141a15 15 0 0113.914 9.396l20.511 50.93 41.446-116.048a15 15 0 0114.06-9.955h.066a15 15 0 0114.081 9.831l41.779 113.814 39.431-113.565a15.002 15.002 0 0113.64-10.071 14.99 14.99 0 0114.317 9.082l49.683 115.927 15.254-48.815a15 15 0 0114.317-10.526h85.141v30h-74.113l-24.076 77.043a14.999 14.999 0 01-14.32 10.525z"></path>
				</g>
			</g>
		</svg>
	);
}

export default Resistor;
