import React, { forwardRef } from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva } from 'class-variance-authority';
import { cn } from '../../lib/utils/utils';
import SmallLoader from '../common/svgs/smallLoader';

const buttonVariants = cva(
	'inline-flex items-center justify-center whitespace-nowrap rounded-md text-sm font-medium ring-offset-darkaccent transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-darkaccent focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
	{
		variants: {
			variant: {
				default: 'shadow-2xl bg-darkaccent/80 text-accent hover:bg-darkaccent ring-accent ring-1 hover:ring-accent hover:bg-accent text-white bg-accent/40 hover:text-darkaccent',
				destructive:
					'bg-red-500 text-slate-50 hover:bg-red-500/90 shadow-2xl',
				label: 'border-dark hover:border-accent bg-darkaccent/70 hover:bg-darkaccent hover:secondary',
				secondary:
					'bg-slate-100 text-slate-900 hover:bg-slate-100/80',
				ghost: 'hover:bg-glassColor hover:text-accent',
				link: 'text-slate-900 underline-offset-4 hover:underline',
			},
			size: {
				default: 'h-10 px-4 py-2',
				sm: 'h-9 rounded-md px-3',
				lg: 'h-11 rounded-md px-8',
				icon: 'h-10 w-10',
			},
		},
		defaultVariants: {
			variant: 'default',
			size: 'default',
		},
	}
);

const Button = forwardRef(
	(
		{ className, variant, size, asChild = false, loading, ...props },
		ref
	) => {
		const Comp = asChild ? Slot : 'button';
		return (
			<Comp
				className={cn(
					buttonVariants({ variant, size, className })
				)}
				ref={ref}
				disabled={loading}
				{...props}
			>
				{loading ? (
					<span className="flex items-center space-x-2 text-secondary">
						<SmallLoader />
						Loading...
					</span>
				) : (
					props.children
				)}
			</Comp>
		);
	}
);
Button.displayName = 'Button';

export { Button, buttonVariants };
