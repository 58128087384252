import { useContext, useState } from "react";
import CardLayout from "../../components/common/CardLayout";
import MainButton from "../../components/common/MainButton";
import apiAtuth from "../../services/apis/apiAuth";
import { ToastContext } from "../../contexts/ToastContext";
import LoadingScreen from "../LoadingScreen";
import SgemAnimate from "../../components/common/SgemAnimate";
import { Link } from "react-router-dom";

export default function RecuperarSenha() {
    const [userName, setUserName] = useState("");
    const {erro, sucesso} = useContext(ToastContext);
    const [waitingReponse, setWaitingResponse] = useState(false); 

    async function enviar(e) {
        e.preventDefault();
        setWaitingResponse(true);
        const username = {
            userName: userName
        } 
        apiAtuth.resetPasswordRequest(username).then(res => {
            sucesso("Verifique seu email!");
        }).catch(error => {
            erro("Usuário inválido!");
            console.error(error);
        }).finally(() => {
            setWaitingResponse(false);
            setUserName('');
        })
    }

    return <div className="h-full w-full flex items-center justify-center">
        {
            waitingReponse && 
            <LoadingScreen/>
        }
        <div className="w-[700px]">
            <CardLayout>
                <div className="min-h-[370px] px-5 py-7 flex  flex-col items-center">
                    <SgemAnimate/>
                    <h1 className="text-[40px] font-bold text-center mt-7">Recuperar Senha</h1>
                    <h2 className="text-[20px] text-center">Digite o username da sua conta.</h2>
                    <form onSubmit={(e) => enviar(e)} className="flex flex-col items-center">
                    <div className="flex flex-col justify-start pt-10">
                        <label className="text-[20px] mb-2 pl-1" htmlFor="username"> Username </label>
                        <input
                            id="username"
                            name="username"
                            type="text"
                            className="border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[250px]"
                            value={userName}
                            onChange={(e) =>
                                setUserName(e.target.value)
                            }
                            required
                        />
                    </div>
                    <div className="flex w-full justify-center">
                            <Link to={'/login'}>
                                <div className="mt-10 h-[40px] w-[180px] mx-5">
                                    <MainButton
                                        styles={{
                                            style: 'bg-gradient-to-t from-red-500 to-red-700 hover:from-red-600 hover:to-red-800',
                                        }}
                                        type="button"
                                    >

                                        <p className="text-[20px]">Cancelar</p>
                                    </MainButton>
                                </div>
                            </Link>
                            <div className="mt-10 h-[40px] w-[180px] mx-5">
                                <MainButton>
                                    <p className="text-[20px]">Enviar</p>
                                </MainButton>
                            </div>
                        </div>
                    </form>
                </div>
            </CardLayout>
        </div>
    </div>
}