import React, { useCallback, useEffect, useRef, useState } from 'react';
import CardLayout from '../common/CardLayout';
import Table from '../common/table';
import Fake from '../common/Fake';
import InputGeneric from '../common/InputGeneric';
import SwitchOnOff from '../common/SwitchOnOff';
import TitleCard from './TitleCard';
import NoEquipAlert from '../common/returns/NoEquipAlert';
import NaoSeAplica from '../common/returns/NaoSeAplica';
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from '../../components/ui/accordion';

import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '../ui/select';
import apiAmbientes from '../../services/apis/apiAmbientes';
import apiSimulacaoUnidade from '../../services/apis/apiSimulacaoUnidade';
import { delayTime } from '../../constants';

export default function ListAmbientes({ regimes, setCapacidadesRegimes }) {
	const [ambientes, setAmbientes] = useState([]);
	const debounceTimeout = useRef(null);

	useEffect(() => {
		if (ambientes.length === 0) {
			regimes.forEach((regime) => {
				apiAmbientes.getByRegimeId(regime.id).then((res) => {
					res.data.forEach((ambiente) => {
						setAmbientes((current) => [
							...current,
							{ ...ambiente, status: true },
						]);
					});
				});
			});
		}
	}, [regimes, ambientes]);

	const trocarRegimeEquipamento = useCallback(
		(ambiente, novoRegimeId) => {
			const index = ambientes.findIndex(
				(a) => a.id === ambiente.id
			);
			const newAmbientes = [...ambientes];
			newAmbientes[index].regimeId = novoRegimeId;
			setAmbientes(newAmbientes);
		},
		[ambientes]
	);

	useEffect(() => {
		setCapacidadesRegimes((current) => {
			const aux = [...current];
			aux.map((capacidade) => {
				return (capacidade.ambientes = ambientes.filter(
					(ambiente) =>
						ambiente.regimeId === capacidade.regimeId
				));
			});
			return aux;
		});
	}, [ambientes, setCapacidadesRegimes]);

	const handleTempoPorecesso = useCallback(
		(ambiente, novoTempoProcesso) => {
			const index = ambientes.findIndex(
				(a) => a.id === ambiente.id
			);
			const newAmbientes = [...ambientes];
			newAmbientes[index].tempoProcesso =
				novoTempoProcesso === ''
					? 1
					: parseFloat(novoTempoProcesso);
			setAmbientes(newAmbientes);
		},
		[ambientes]
	);

	const handleProdutoMassa = useCallback(
		(ambiente, novaMassaProduto) => {
			const index = ambientes.findIndex(
				(a) => a.id === ambiente.id
			);
			const newAmbientes = [...ambientes];
			newAmbientes[index].massaProduto =
				novaMassaProduto === ''
					? 0
					: parseFloat(novaMassaProduto);
			if (ambiente.tipoAmbiente === 'Câmara_resfriamento') {
				newAmbientes[index].tempoProcesso = 1;
			}
			setAmbientes(newAmbientes);
		},
		[ambientes]
	);

	const handleStatus = (handleSwitch) => {
		const { equipamento } = handleSwitch;
		const status = !equipamento.status;
		setAmbientes((prevAmbiente) => {
			const novoAmbiente = [
				...prevAmbiente.map((amb) => {
					if (amb.id === equipamento.id) {
						return { ...amb, status: status };
					}
					return amb;
				}),
			];
			return novoAmbiente;
		});
	};

	useEffect(() => {
		if (debounceTimeout.current) {
			clearTimeout(debounceTimeout.current);
		}

		debounceTimeout.current = setTimeout(() => {
			ambientes.forEach((ambiente) => {
				const aux = [...ambientes];

				const { id, tempoProcesso, massaProduto } = ambiente;
				apiSimulacaoUnidade
					.getCalculoCargaTermicaAmbiente(
						id,
						tempoProcesso,
						massaProduto
					)
					.then((res) => {
						const index = aux.findIndex(
							(a) => a.id === ambiente.id
						);
						const novaCargaTermica = res.data;
						if (
							aux[index].cargaTermica !==
							novaCargaTermica
						) {
							aux[index].cargaTermica = parseFloat(
								res.data
							);
							setAmbientes(aux);
						}
					})
					.catch((error) => console.error(error.message));
			});
		}, delayTime);
	}, [ambientes]);

	return (
		<CardLayout>
			<Accordion
				type="single"
				defaultValue="item-1"
				collapsible="true"
			>
				<AccordionItem value="item-1">
					<AccordionTrigger>
						<TitleCard>Lista de Ambientes</TitleCard>
					</AccordionTrigger>
					<AccordionContent>
						<div className="w-full">
							<Table.Root>
								<Table.Head>
									<Table.Header>
										Nome
									</Table.Header>
									<Table.Header>
										Status
									</Table.Header>
									<Table.Header>
										Regime
									</Table.Header>
									<Table.Header>
										Setor
									</Table.Header>
									<Table.Header>
										AREA
										<p className="text-xs px-[2px] uppercase inline">
											(L*C*A)
										</p>
									</Table.Header>
									<Table.Header>
										Referência
									</Table.Header>
									<Table.Header>
										Processo
										<span className="text-xs lowercase inline px-[1px]">
											(h)
										</span>
									</Table.Header>
									<Table.Header>
										Produto
										<span className="text-xs capitalize inline px-[1px]">
											(kg/un)
										</span>
									</Table.Header>
									{ambientes[0]
										?.cargaTermicaEvaporadores && (
										<Table.Header>
											Capacidade
											Evaporadores
											<span className="text-xs inline px-[1px]">
												(Kcal/
												h)
											</span>
										</Table.Header>
									)}
									<Table.Header>
										Carga Termica
									</Table.Header>
								</Table.Head>
								<Table.Body>
									{ambientes.map(
										(ambiente, i) => (
											<Table.Row
												key={i}
											>
												<Table.Data>
													{
														ambiente.nome
													}
												</Table.Data>
												<Table.Data>
													<SwitchOnOff
														status="on"
														handleSwitch={{
															handleSwitch:
																handleStatus,
															equipamento:
																ambiente,
														}}
													/>
												</Table.Data>
												<Table.Data>
													<Select
														id="regimeAmbienteSelect"
														defaultValue={
															ambiente.regimeId
														}
														onValueChange={(
															id
														) =>
															trocarRegimeEquipamento(
																ambiente,
																id
															)
														}
													>
														<SelectTrigger className="w-full">
															<SelectValue placeholder="Regime" />
														</SelectTrigger>
														<SelectContent>
															{regimes.map(
																(
																	regime,
																	i
																) => (
																	<SelectItem
																		key={
																			regime.id
																		}
																		value={
																			regime.id
																		}
																	>
																		{
																			regime.nome
																		}
																	</SelectItem>
																)
															)}
														</SelectContent>
													</Select>
												</Table.Data>
												<Table.Data>
													{ambiente.tipoAmbiente.replaceAll(
														'_',
														' '
													)}
												</Table.Data>
												<Table.Data>
													{ambiente.tipoAmbiente ===
														'Túnel_congelamento' ||
													ambiente.tipoAmbiente ===
														'Câmara_resfriamento' ? (
														<NaoSeAplica />
													) : (
														<>
															{ambiente?.largura +
																' * ' ||
																'- *'}
															{ambiente?.comprimento +
																' * ' ||
																'- *'}
															{ambiente?.altura ||
																'-'}{' '}
														</>
													)}
												</Table.Data>
												<Table.Data>
													{ambiente.temperaturaReferencia?.toLocaleString(
														'pt-BR',
														{
															maximumFractionDigits: 2,
														}
													) +
														' °C'}
												</Table.Data>
												<Table.Data className="">
													{ambiente.tipoAmbiente ===
													'Túnel_congelamento' ? (
														<InputGeneric
															className="max-w-[88px] mx-auto"
															defaultValue={
																ambiente.tempoProcesso
															}
															onChange={(
																e
															) =>
																handleTempoPorecesso(
																	ambiente,
																	e
																		.target
																		.value
																)
															}
														/>
													) : (
														<NaoSeAplica />
													)}
												</Table.Data>
												<Table.Data>
													{ambiente.tipoAmbiente ===
														'Túnel_congelamento' ||
													ambiente.tipoAmbiente ===
														'Câmara_resfriamento' ? (
														<InputGeneric
															className="max-w-[88px] mx-auto"
															defaultValue={
																ambiente.massaProduto
															}
															onChange={(
																e
															) =>
																handleProdutoMassa(
																	ambiente,
																	e
																		.target
																		.value
																)
															}
														/>
													) : (
														<NaoSeAplica />
													)}
												</Table.Data>
												{ambiente?.cargaTermicaEvaporadores && (
													<Table.Data>
														{ambiente?.cargaTermicaEvaporadores || (
															<Fake />
														)}
													</Table.Data>
												)}
												<Table.Data>
													{ambiente.cargaTermica.toLocaleString(
														'pt-BR',
														{
															maximumFractionDigits: 2,
														}
													) +
														' Kcal/ h'}
												</Table.Data>
											</Table.Row>
										)
									)}
									{ambientes.length ===
										0 && (
										<Table.Row className="text-center ">
											<th
												scope="col"
												colSpan="10"
												className="text-center"
											>
												<NoEquipAlert equipments="ambientes" />
											</th>
										</Table.Row>
									)}
								</Table.Body>
							</Table.Root>
						</div>
					</AccordionContent>
				</AccordionItem>
			</Accordion>
		</CardLayout>
	);
}
