import { apiLink } from '../../constants/env';
import axiosInstance from '../AxiosInstance';

const apiModelo = {
    fetchApi: async (marcaId, tipoEquipamento) => {
        const URL = `${apiLink}api/modelo/marca/${marcaId}/tipo-equipamento/${tipoEquipamento}`;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();

            })
    },

    pagination: async (page) => {
        const URL = `${apiLink}api/modelo?page=` + page;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();

            })
    },
    getById: async (id) => {
        const URL = `${apiLink}api/modelo/` + id;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();

            })
    },
    postApi: async (modelo) => {
        const URL = `${apiLink}api/modelo`;
        return await axiosInstance.post(URL, modelo)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();

            })
    },
    deleteApi: async (id) => {
        const URL = `${apiLink}api/modelo/status/${id}`;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();

            })
    },
    putApi: async (id, modelo) => {
        const URL = `${apiLink}api/modelo/${id}`;
        return await axiosInstance.put(URL, modelo)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();

            })
    }

}

export default apiModelo;

