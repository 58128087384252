import { useContext, useEffect, useState } from 'react';
import apiTipoOperacaoCompressores from '../../../../services/apis/apiTipoOperacaoCompressores';
import apiCalculosCompressores from '../../../../services/apis/apiCalculosCompressores';
import { ToastContext } from '../../../../contexts/ToastContext';
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from '../../../../components/ui/dialog';
import {
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
	AlertDialogTrigger,
} from '../../../../components/ui/alert-dialog';
import MainButton from '../../../../components/common/MainButton';
import Simulacao from './Simulacao';
import { useParams } from 'react-router-dom';
import PaginationBar from '../../../../components/common/PaginationBar';
import apiMarca from '../../../../services/apis/apiMarca';
import apiModeloCompressor from '../../../../services/apis/apiModeloCompressor';
import { getParentMap } from '../../../../funcs/getParent';
import apiEnum from '../../../../services/apis/apiEnum';

export default function ListaTipoOperacaoCompressor({
	atualizarLista,
	setQuantidade,
	pesquisar,
	funcaoModalController,
}) {
	const [listaTipoOperacaoCompressores, setListaTipoOperacaoCompressores] = useState([]);
	const [modeloCompressor, setModeloCompressor] = useState(new Map());

	const [idEditar, setIdEditar] = useState();
	const [itemRemovido, setItemRemovido] = useState('');
	const [idRemover, setIdRemover] = useState();
	const [nome, setNome] = useState('');
	const [tiposOperacaoCompressor, setTiposOperacaoCompressor] = useState([]);
	const {page} = useParams();
	const [pagination, setPagination] = useState();

	const [equacaoCapacidade, setEquacaoCapacidade] = useState([]);
	const [equacaoPotencia, setEquacaoPotencia] = useState([]);

	const [listaModelos, setListaModelos] = useState([]);
    const [listaMarcas, setListaMarcas] = useState([]);

	const [fatorAjustePotencia, setFatorAjustePotencia] = useState(0);
	const [fatorAjusteCapacidade, setFatorAjusteCapacidade] = useState(0);

	const [temperaturaDescarga, setTemperaturaDescarga] = useState(0);
	const [temperaturaSuccao, setTemperaturaSuccao] = useState(0);

	const [marcaId, setMarcaId] = useState(0);
	const [modeloId, setModeloId] = useState(0);
	const [simulacao, setSimulacao] = useState(
		<div className="w-[300px]"></div>
	);
	const funcaoModal = funcaoModalController.funcaoModal;

	const { erro, sucesso } = useContext(ToastContext);

	function limparInputs() {
		setEquacaoCapacidade('');
		setEquacaoPotencia('');
		setFatorAjustePotencia(0);
		setFatorAjusteCapacidade(0);
		setTemperaturaDescarga(0);
		setTemperaturaSuccao(0);
		setMarcaId(0);
		setModeloId(0);
		document.getElementById('nome').value = 0;
	}

	useEffect(() => {
		apiTipoOperacaoCompressores.fetchApi(page).then((res) => {
			setListaTipoOperacaoCompressores(res.data.items);
			setPagination(res.data);
		});
		apiMarca.fetchApi().then(res => {
			setListaMarcas(res.data);
		});
		apiEnum.getTiposOperacaoCompressor().then(res => {
			setTiposOperacaoCompressor(res.data);
		});
	}, [page]);

	useEffect(() => {
		if(listaTipoOperacaoCompressores.length > 0) {
			listaTipoOperacaoCompressores.forEach(elemento => {
				apiModeloCompressor.getById(elemento.modeloCompressorId).then(res => {
					const modeloCompressor = res.data;
					setModeloCompressor(map => new Map(map.set(modeloCompressor.id, modeloCompressor)));
				})
			})
		}
	},[listaTipoOperacaoCompressores]);


	useEffect(() => {
		marcaId !== undefined &&
		!isNaN(marcaId) &&
		apiModeloCompressor.getByMarcaId(marcaId).then(res => {
			setListaModelos(res.data);
		});
	},[marcaId])

	function deletar(id) {
		apiTipoOperacaoCompressores
			.deleteApi(id)
			.then((res) => {
				atualizarLista();
				sucesso(
					'Tipo operação compressor deletado com sucesso!'
				);
			})
			.catch((error) => {
				console.error(error.message);
				erro('Erro ao deletar tipo operação compressor');
			});
	}

	function enviar(e) {
		e.preventDefault();
		const tipoOperacaoCompressor = {
			nome: e.target.nome.value,
			equacaoCapacidade: equacaoCapacidade,
			equacaoPotencia: equacaoPotencia,
			modeloCompressorId: parseInt(e.target.modeloId.value),
			fatorAjustePotencia: parseFloat(fatorAjustePotencia),
			fatorAjusteCapacidade: parseFloat(fatorAjusteCapacidade),
			temperaturaDescarga: parseFloat(temperaturaDescarga),
			temperaturaSuccao:  parseFloat(temperaturaSuccao),
		};

		if (funcaoModal === 'Adicionar') {
			apiTipoOperacaoCompressores
				.postApi(tipoOperacaoCompressor)
				.then((res) => {
					atualizarLista();
					limparInputs();
					sucesso(
						'Tipo operação compressor adicionado com sucesso!'
					);
				})
				.catch((error) => {
					console.error(error.message);
					erro(
						'Erro ao adicionar tipo operação compressor'
					);
				});
		} else if (funcaoModal === 'Editar') {
			apiTipoOperacaoCompressores
				.putApi(idEditar, tipoOperacaoCompressor)
				.then((res) => {
					atualizarLista();
					limparInputs();
					sucesso(
						'Tipo operação compressor editado com sucesso!'
					);
				})
				.catch((error) => {
					console.error(error.message);
					erro('Erro ao editar tipo operação compressor');
				});
		}
	}

	function simular() {
		const valores = {
			temperaturaDescarga: parseFloat(temperaturaDescarga),
			temperaturaSuccao: parseFloat(temperaturaSuccao),
			ajusteCapacidade: parseFloat(fatorAjusteCapacidade),
			ajustePotencia: parseFloat(fatorAjustePotencia),
			equacaoCapacidade: equacaoCapacidade,
			equacaoPotencia: equacaoPotencia,
		};
		
		apiCalculosCompressores
			.calculoCompressor(valores)
			.then((res) => {
				const dado = res.data;
				setSimulacao(
					<Simulacao
						capacidadeRealCalculada={
							dado.capacidadeRealCalculada
						}
						potenciaRealCalculada={
							dado.potenciaRealCalculada
						}
					/>
				);
			})
			.catch((error) => {
				erro('Falha ao simular, dados inválidos!');
				console.error(error.message);
			});
	}

	return (
		<>
			<Dialog>
				<div className="w-full flex flex-col items-center mt-[40px] md:px-[200px]">
					<table className="w-full">
						<thead className="border-b w-full">
							<tr>
								<th className="text-[25px] pl-[10px] text-start">
									Nome
								</th>
								<th className="text-[25px] pl-[10px] text-start">
									Modelo
								</th>
							</tr>
						</thead>
						<tbody>
							{listaTipoOperacaoCompressores.map(
								(tipoOperacaoCompressor, i) =>
									getParentMap(modeloCompressor, tipoOperacaoCompressor.modeloCompressorId)?.nome
										.toUpperCase()
										.startsWith(
											pesquisar.toUpperCase()
										) && (
										<tr
											className="border-b hover:bg-gray-200 hover:bg-opacity-5"
											key={i}
										>
											<td className="pl-[10px] text-[20px]">
												{
													tipoOperacaoCompressor.nome.replaceAll("_", " ")
												}
											</td>
											<td className="flex justify-between py-1">
												<span className="text-[20px] pl-[10px]">
													{getParentMap(modeloCompressor, tipoOperacaoCompressor.modeloCompressorId)?.nome}
												</span>
												<span className="flex">
													<AlertDialogTrigger>
														<div
															onClick={() => {
																apiModeloCompressor.getById(tipoOperacaoCompressor.modeloCompressorId).then(res => {
																	setMarcaId(res.data.marcaId);
																	setModeloId(res.data.id);
																});

																setIdEditar(
																	tipoOperacaoCompressor.id
																);

																setNome(
																	tipoOperacaoCompressor.nome.replaceAll("_", " ")
																);
																setModeloId(
																	tipoOperacaoCompressor.modeloCompressorId
																);
																setFatorAjusteCapacidade(
																	tipoOperacaoCompressor.fatorAjusteCapacidade
																);
																setFatorAjustePotencia(
																	tipoOperacaoCompressor.fatorAjustePotencia
																);
																setTemperaturaDescarga(
																	tipoOperacaoCompressor.temperaturaDescarga
																);
																setTemperaturaSuccao(
																	tipoOperacaoCompressor.temperaturaSuccao
																);
																setEquacaoCapacidade(
																	tipoOperacaoCompressor.equacaoCapacidade
																);
																setEquacaoPotencia(
																	tipoOperacaoCompressor.equacaoPotencia
																);

																funcaoModalController.setFuncaoModal(
																	'Editar'
																);
															}}
														>
															<i
																className="fa-solid fa-pen-to-square text-[18px] hover:text-lightBlue"
																title="Editar"
															></i>
														</div>
													</AlertDialogTrigger>
													<DialogTrigger>
														<div
															className="mx-2.5"
															onClick={() => {
																setItemRemovido(
																	tipoOperacaoCompressor.nome
																);
																setIdRemover(
																	tipoOperacaoCompressor.id
																);
															}}
														>
															<i
																className="fa-solid fa-trash text-[18px] hover:text-lightBlue"
																title="Deletar"
															></i>
														</div>
													</DialogTrigger>
												</span>
											</td>
										</tr>
									)
							)}
						</tbody>
					</table>
					{
						pagination !== undefined &&
						<PaginationBar pagination={pagination}/>
					}
				</div>

				<DialogContent>
					<DialogHeader>
						<DialogTitle>
							Excluir {itemRemovido}?
						</DialogTitle>
					</DialogHeader>
					<DialogDescription>
						Todos os dados associados com {itemRemovido}{' '}
						serão removidos.
					</DialogDescription>
					<DialogFooter>
						<div
							className="bg-red-600/70 py-2 px-4 rounded-md hover:bg-red-600 hover:cursor-pointer border-slate-800 border-2 transition-all duration-200 ease-in-out"
							onClick={() => deletar(idRemover)}
						>
							<DialogClose>Excluir</DialogClose>
						</div>
					</DialogFooter>
				</DialogContent>
			</Dialog>

			<AlertDialogContent>
				<form onSubmit={(e) => enviar(e)}>
					<AlertDialogHeader>
						<AlertDialogTitle>
							{funcaoModal} Tipo Operação Compressor
						</AlertDialogTitle>
					</AlertDialogHeader>

					<div className="w-full max-h-[500px] flex justify-around my-5 px-5 flex-wrap gap-5 overflow-x-hidden overflow-y-auto scrollbar-thin scrollbar-track-primary scrollbar-thumb-darkaccent">
						{/* NOME */}
						<div className="flex flex-col justify-end">
							<label
								htmlFor="nome"
								className="text-[18px] mb-2 pl-1"
							>
								Nome{' '}
							</label>
							<select
								className="border-b text-[20px] h-[42.8px] bg-inputColor rounded-t-md w-[180px]"
								name="nome"
								id="nome"
								value={nome}
								required
								onChange={(e) =>
									setNome(e.target.value)
								}
							>
								<option label="  "></option>
								{
									tiposOperacaoCompressor.map((tipo, i) => (
										<option key={i} value={tipo}>{tipo}</option>	
									))
								}
							</select>
						</div>

						{/* MARCA COMPRESSOR ID */}
						<div className="flex flex-col justify-end">
							<label
								htmlFor="marcaId"
								className="text-[18px] mb-2 pl-1"
							>
								Marca{' '}
							</label>
							<select
								className="border-b text-[20px] h-[42.8px] bg-inputColor rounded-t-md w-[180px]"
								name="marcaId"
								id="marcaId"
								required
								value={marcaId}
								onChange={(e) =>
									setMarcaId(
										parseInt(
											e.target.value
										)
									)
								}
							>
								<option label="  "></option>
								{listaMarcas.map(
									(modelo, i) => (
										<option
											value={
												modelo.id
											}
											key={i}
										>
											{modelo.nome}
										</option>
									)
								)}
							</select>
						</div>

						{/* MODELO COMPRESSOR ID */}
						<div className="flex flex-col justify-end">
							<label
								htmlFor="modeloId"
								className="text-[18px] mb-2 pl-1"
							>
								Modelo{' '}
							</label>
							<select
								className="border-b text-[20px] h-[42.8px] bg-inputColor rounded-t-md w-[180px]"
								name="modeloId"
								id="modeloId"
								required
								value={modeloId}
								onChange={(e) =>
									setModeloId(
										parseInt(
											e.target.value
										)
									)
								}
							>
								<option label="  "></option>
								{listaModelos
									.filter(
										(elemento) =>
											elemento.marcaId ===
											marcaId
									)
									.map((modelo, i) => (
										<option
											value={
												modelo.id
											}
											key={i}
										>
											{modelo.nome}
										</option>
									))}
							</select>
						</div>

						{/* FATOR AJUSTE POTENCIA */}
						<div className="flex flex-col justify-end">
							<label
								htmlFor="fatorAjustePotencia"
								className="text-[16px] mb-2 pl-1"
							>
								Fator Ajuste Potência
							</label>
							<input
								id="fatorAjustePotencia"
								name="fatorAjustePotencia"
								type="number"
								className="border-b text-[20px] p-1.5 bg-inputColor rounded-t-md w-[180px]"
								value={fatorAjustePotencia}
								onChange={(e) =>
									setFatorAjustePotencia(
										e.target.value
									)
								}
								required
							/>
						</div>

						{/* FATOR AJUSTE CAPACIDADE */}
						<div className="flex flex-col justify-end">
							<label
								htmlFor="fatorAjusteCapacidade"
								className="text-[16px] mb-2 pl-1"
							>
								Fator Ajuste Capacidade
							</label>
							<input
								id="fatorAjusteCapacidade"
								name="fatorAjusteCapacidade"
								type="number"
								className="border-b text-[20px] p-1.5 bg-inputColor rounded-t-md w-[180px]"
								value={fatorAjusteCapacidade}
								onChange={(e) =>
									setFatorAjusteCapacidade(
										e.target.value
									)
								}
								required
							/>
						</div>
						{/* TEMPERATURA SUCCAO */}
						<div className="flex flex-col justify-end">
							<label
								htmlFor="temperaturaSuccao"
								className="text-[18px] mb-2 pl-1"
							>
								Temperatura Sucção
							</label>
							<input
								id="temperaturaSuccao"
								name="temperaturaSuccao"
								type="number"
								placeholder="temperatura sucção"
								className="border-b text-[20px] p-1.5 bg-inputColor rounded-t-md w-[180px]"
								value={temperaturaSuccao}
								onChange={(e) =>
									setTemperaturaSuccao(
										e.target.value
									)
								}
								required
							/>
						</div>

						{/* TEMPERATURA DESCARGA */}
						<div className="flex flex-col justify-end">
							<label
								htmlFor="temperaturaDescarga"
								className="text-[18px] mb-2 pl-1"
							>
								Temperatura Descarga
							</label>
							<input
								id="temperaturaDescarga"
								name="temperaturaDescarga"
								type="number"
								placeholder="temperatura descarga"
								className="border-b text-[20px] p-1.5 bg-inputColor rounded-t-md w-[180px]"
								value={temperaturaDescarga}
								onChange={(e) =>
									setTemperaturaDescarga(
										e.target.value
									)
								}
								required
							/>
						</div>

						{/* EQUACAO CAPACIDADE */}
						<div className="flex flex-col justify-end">
							<label
								htmlFor="equacaoCapacidade"
								className="text-[18px] mb-2 pl-1"
							>
								Equação Capacidade
							</label>
							<textarea
								id="equacaoCapacidade"
								name="equacaoCapacidade"
								type="number"
								placeholder="equação capacidade"
								className="border-b text-[15px] min-h-[150px] bg-inputColor rounded-t-md w-[420px] p-1"
								value={equacaoCapacidade}
								onChange={(e) =>
									setEquacaoCapacidade(
										e.target.value
									)
								}
								required
							/>
						</div>

						{/* EQUACAO POTENCIA */}
						<div className="flex flex-col justify-end">
							<label
								htmlFor="equacaoPotencia"
								className="text-[18px] mb-2 pl-1"
							>
								Equação Potência
							</label>
							<textarea
								id="equacaoPotencia"
								name="equacaoPotencia"
								type="text"
								placeholder="equação potência"
								className="border-b text-[15px] min-h-[150px] bg-inputColor rounded-t-md w-[420px] p-1"
								value={equacaoPotencia}
								onChange={(e) =>
									setEquacaoPotencia(
										e.target.value
									)
								}
								required
							/>
						</div>

						<div
							className="w-[200px] h-[30px]"
							onClick={() => simular()}
						>
							<MainButton type={'button'}>
								Simular
							</MainButton>
						</div>

						{simulacao}
					</div>
					<AlertDialogFooter>
						<div className="flex w-full justify-end gap-x-4 p-4">
							<AlertDialogAction className="w-32">
								Confirmar
							</AlertDialogAction>
							<AlertDialogCancel className="w-32">
								Cancelar
							</AlertDialogCancel>
						</div>
					</AlertDialogFooter>
				</form>
			</AlertDialogContent>
		</>
	);
}
