import React from 'react';
import { cn } from '../../../lib/utils/utils';

export const TableHead = ({ children, className }) => {
	return (
		<thead
			className={cn(
				'py-2 text-sm xl:text-lg bg-accent/20 whitespace-nowrap',
				className
			)}
		>
			<tr className="">{children}</tr>
		</thead>
	);
};
