import { apiLink } from '../../../constants/env';
import axiosInstance from '../../AxiosInstance';

const apiLeituraBomba = {
    fetchApi: async (bbID) => {
        const URL = `${apiLink}api/leitura-bomba/ultima-leitura?chillerId=${bbID}`;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error(`Erro ao buscar a última leitura da Bomba: [AXIOS]: ${error}`);

            })
    }
}

export default apiLeituraBomba;