import React, { useCallback, useEffect, useState } from 'react';
import CardLayout from '../common/CardLayout';
import RegimeData from './RegimeData';
import ChartGroupBarDefault from './charts/ChartGroupBarDefault';
import TableCompressor from './TableCompressor';
import NoEquipAlert from '../common/returns/NoEquipAlert';

import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from '../../components/ui/accordion';
import pressaoParaTemperatura from '../../calc/pressaoParaTemperatura';
import apiCompressores from '../../services/apis/apiCompressores';
import apiTipoOperacaoCompressores from '../../services/apis/apiTipoOperacaoCompressores';
import apiModeloCompressor from '../../services/apis/apiModeloCompressor';
import apiCalculosCompressores from '../../services/apis/apiCalculosCompressores';
import temperaturaParaPressao from '../../calc/temperaturaParaPressao';
import removeDuplicados from '../../funcs/removeDuplicados';

const ListRegimes = ({
	Regimes,
	ListaRegimes,
	capacidadesRegimes,
	setCapacidadesRegimes,
	allRegimes,
	pressaoReferenciaRegime,
	setPressaoReferenciaRegime,

}) => {
	const getRegimeCapacidade = (regimeId) => {
		const regime = capacidadesRegimes.find(
			(regime) => regime.regimeId === regimeId
		);
		return regime;
	};

	const [compressores, setCompressores] = useState([]);
	const [tiposOperacao, setTiposOperacao] = useState([]);
	const [calculosCompressor, setCalculosCompressor] = useState(new Map());


	const getPressaoRegimeById = useCallback(
		(regimeId) => {
			const regime = pressaoReferenciaRegime.find(
				(regime) => regime.id === regimeId
			);
			return regime;
		},
		[pressaoReferenciaRegime]
	);
	const fetchData = useCallback(async () => {
		try {
			const promises = Regimes.map(async (regime) => {
				return await apiCompressores.getByRegimeId(regime.id);
			});
			const result = await Promise.all(promises);
			//juntar res.data dos promises
			let cps = result.reduce((res, obj) => {
				return res.concat(obj.data);
			}, []);

			cps = await Promise.all(
				cps.map(async (cp) => {
					const modelo = await apiModeloCompressor.getById(
						cp.modeloCompressorId
					);
					return {
						...cp,
						capacidade: 100,
						status: true,
						modelo: modelo.data,
					};
				})
			);

			const tiposOperacaoPromise = removeDuplicados(
				cps,
				'tipoOperacaoCompressorId'
			).map(async (element) => {
				return await apiTipoOperacaoCompressores.getById(
					element.tipoOperacaoCompressorId
				);
			});

			const tipoOperacaoResult = await Promise.all(
				tiposOperacaoPromise
			);
			const tiposOperacao = tipoOperacaoResult.reduce(
				(res, obj) => {
					return res.concat(obj.data);
				},
				[]
			);
			cps = cps.map((cp) => {
				return {
					...cp,
					temperaturaDescarga: pressaoParaTemperatura(
						Regimes.find(
							(regime) =>
								regime.id ===
								cp.regimeDescargaId
						)?.pressaoReferencia
					),
					temperaturaSuccao: pressaoParaTemperatura(
						Regimes.find(
							(regime) =>
								regime.id === cp.regimeSuccaoId
						)?.pressaoReferencia
					),
					tipoOperacao: tiposOperacao.find(
						(operacao) =>
							operacao.id ===
							cp.tipoOperacaoCompressorId
					),
				};
			});
			const regimesPressaoReferencia = allRegimes.map((regime) => {
				return {
					id: regime.id,
					pressaoReferencia: regime.pressaoReferencia,
				};
			});
			setPressaoReferenciaRegime(regimesPressaoReferencia);
			setTiposOperacao(tiposOperacao);
			setCompressores(cps);
		} catch (error) {
			console.error(error);
		}
	}, [allRegimes, Regimes, setPressaoReferenciaRegime]);

	const atualizarRegime = useCallback(async () => {
		setCapacidadesRegimes((current) => {
			const aux = [...current];
			aux.map((regime) => {
				regime.compressores.clear();
				return regime;
			});
			return aux;
		});
		compressores.forEach(async (compressor) => {
			if (compressor.tipoOperacao) {
				const tipoOperacao = compressor.tipoOperacao;
				const valores = {
					temperaturaSuccao: pressaoParaTemperatura(
						pressaoReferenciaRegime.find(
							(regime) =>
								regime.id ===
								compressor.regimeSuccaoId
						)?.pressaoReferencia
					),
					temperaturaDescarga: pressaoParaTemperatura(
						pressaoReferenciaRegime.find(
							(regime) =>
								regime.id ===
								compressor.regimeDescargaId
						)?.pressaoReferencia
					),
					ajusteCapacidade:
						tipoOperacao?.fatorAjusteCapacidade,
					ajustePotencia: tipoOperacao?.fatorAjustePotencia,
					equacaoPotencia: tipoOperacao?.equacaoPotencia,
					equacaoCapacidade:
						tipoOperacao?.equacaoCapacidade,
				};

				try {
					const res =
						await apiCalculosCompressores.calculoCompressor(
							valores
						);
					setCapacidadesRegimes((current) => {
						const aux = [...current];
						const index = aux.findIndex(
							(capacidade) =>
								capacidade.regimeId ===
								compressor.regimeSuccaoId
						);
						const capacidade = aux[index];
						capacidade.compressores.set(compressor.id, {
							...compressor,
							...res.data,
						});
						return aux;
					});
					setCalculosCompressor(
						(map) =>
							new Map(
								map.set(compressor.id, res.data)
							)
					);
				} catch (error) {
					console.error(
						'Erro ao calcular valores do compressor: ' +
							error.message
					);
				}
			}
			return {};
		});
	}, [compressores, setCapacidadesRegimes, pressaoReferenciaRegime]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	useEffect(() => {
		atualizarRegime();
	}, [atualizarRegime]);

	const handleTemperaturaDescargaById = useCallback(
		(compressor, regimeId) => {
			const index = compressores.findIndex(
				(cp) => cp.id === compressor.id
			);
			const novoCompressor = [...compressores];
			novoCompressor[index].regimeDescargaId = regimeId;
			novoCompressor[index].temperaturaDescarga =
				pressaoParaTemperatura(
					Regimes.find((regime) => regime.id === regimeId)
						?.pressaoReferencia
				);
			setCompressores(novoCompressor);
		},
		[compressores, Regimes]
	);

	const handleTemperaturaSuccaoByRegimeId = useCallback(
		(compressor, regimeId) => {
			const index = compressores.findIndex(
				(cp) => cp.id === compressor.id
			);
			const novoCompressor = [...compressores];
			novoCompressor[index].regimeSuccaoId = regimeId;
			novoCompressor[index].temperaturaSuccao =
				pressaoParaTemperatura(
					Regimes.find((regime) => regime.id === regimeId)
						?.pressaoReferencia
				);
			setCompressores(novoCompressor);
		},
		[compressores, Regimes]
	);

	const handleTemperatura = useCallback(
		(regimeId, temperatura) => {
			setPressaoReferenciaRegime((current) => {
				const aux = [...current];
				const temp = parseFloat(
					temperatura.toString().replace(',', '.')
				);
				const index = aux.findIndex(
					(regime) => regime.id === regimeId
				);
				aux[index].pressaoReferencia =
					temperaturaParaPressao(temp);
				return aux;
			});
		},
		[setPressaoReferenciaRegime]
	);

	const handlePressao = useCallback(
		(regimeId, pressao) => {
			setPressaoReferenciaRegime((current) => {
				const aux = [...current];
				const press = parseFloat(
					pressao.toString().replace(',', '.')
				);
				const index = aux.findIndex(
					(regime) => regime.id === regimeId
				);
				aux[index].pressaoReferencia = press;
				return aux;
			});
		},
		[setPressaoReferenciaRegime]
	);

	const handleOperacao = useCallback(
		(compressor, tipoOperacaoId) => {
			const index = compressores.findIndex(
				(cp) => cp.id === compressor.id
			);
			const novoCompressor = [...compressores];
			const tipoOperacaoCompressor = tiposOperacao.find(
				(to) => to.id === tipoOperacaoId
			);
			novoCompressor[index].tipoOperacao = tipoOperacaoCompressor;
			novoCompressor[index].tipoOperacaoCompressorId =
				tipoOperacaoCompressor.id;
			setCompressores(novoCompressor);
		},
		[compressores, tiposOperacao]
	);

	const handleCapacidade = useCallback(
		(compressor, capacidade) => {
			const index = compressores.findIndex(
				(cp) => cp.id === compressor.id
			);
			const novoCompressor = [...compressores];
			novoCompressor[index].capacidade = capacidade;
			setCompressores(novoCompressor);
		},
		[compressores]
	);

	const handleStatus = (handleSwitch) => {
		const { equipamento } = handleSwitch;
		const status = !equipamento.status;
		setCompressores((prevCompressores) => {
			const novoCompressor = [
				...prevCompressores.map((comp) => {
					if (comp.id === equipamento.id) {
						return { ...comp, status: status };
					}
					return comp;
				}),
			];
			return novoCompressor;
		});
	};

	return (
		<>
			{Regimes.length > 0 ? (
				Regimes?.map((regime) => (
					<CardLayout key={regime.id}>
						<Accordion
							type="single"
							defaultValue="item-1"
							collapsible="true"
						>
							<AccordionItem value="item-1">
								<div className="font-semibold">
									<AccordionTrigger>
										<div className="w-full flex items-center justify-center text-sm lg:text-2xl">
											<p
												className={`uppercase drop-shadow-1xl ${regime.regimeColor.class}`}
											>
												Regime:
												<span className="px-2 capitalize drop-shadow-1xl">
													{
														regime.nome
													}{' '}
												</span>
											</p>
										</div>
									</AccordionTrigger>
								</div>
								<AccordionContent>
									<div className="flex flex-col lg:flex-row gap-4 px-4">
										<div className="w-full lg:w-3/4 2xl:w-2/3 flex flex-col ">
											<TableCompressor
												regimeId={
													regime.id
												}
												AllRegimes={
													ListaRegimes
												}
												calculosCompressor={
													calculosCompressor
												}
												compressoresData={compressores.filter(
													(
														cp
													) =>
														cp.regimeSuccaoId ===
														regime.id
												)}
												tiposOperacao={
													tiposOperacao
												}
												handleTemperaturaDescarga={
													handleTemperaturaDescargaById
												}
												handleTemperaturaSuccaoByRegimeId={
													handleTemperaturaSuccaoByRegimeId
												}
												handleOperacao={
													handleOperacao
												}
												handleCapacidade={
													handleCapacidade
												}
												handleStatus={
													handleStatus
												}
											/>
										</div>
										<div className="w-full lg:w-1/4 2xl:w-1/3 flex flex-col justify-center">
											<div className="flex w-full flex-col gap-2 px-4">
												<RegimeData
													regime={
														regime
													}
													capacidadeDisponivel={
														getRegimeCapacidade(
															regime.id
														)
															?.capacidadeDisponivel |
														0
													}
													capacidadeNecessaria={
														getRegimeCapacidade(
															regime.id
														)
															?.capacidadeNecessaria |
														0
													}
													regimePressao={getPressaoRegimeById(
														regime.id
													)}
													temperatura={
														getPressaoRegimeById(
															regime.id
														) |
														0
													}
													handleTemperaturaSuccao={
														handleTemperatura
													}
													handlePressao={
														handlePressao
													}
												/>
												<div className="flex w-full px-4 justify-center align-center">
													<ChartGroupBarDefault
														disponivel={
															getRegimeCapacidade(
																regime.id
															)
																?.capacidadeDisponivel ||
															0.0
														}
														necessario={
															getRegimeCapacidade(
																regime.id
															)
																?.capacidadeNecessaria ||
															'0'
														}
													/>
												</div>
											</div>
										</div>
									</div>
								</AccordionContent>
							</AccordionItem>
						</Accordion>
					</CardLayout>
				))
			) : (
				<CardLayout>
					<NoEquipAlert equipments="regimes" />
				</CardLayout>
			)}
		</>
	);
};

export default ListRegimes;
