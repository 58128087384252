import React, { useCallback, useRef, useState } from 'react';
import CardLayout from '../common/CardLayout';
import CondensacaoData from './CondensacaoData';
import TableCondensador from './TableCondensador';

import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from '../../components/ui/accordion';
import temperaturaParaPressao from '../../calc/temperaturaParaPressao';
import { delayTime } from '../../constants';

const  Condensacao = ({ 
	Condensacao, 
	bombas, 
	ventiladores, 
	setCapacidadesRegimes, 
	capacidadesRegimes,
	setPressaoReferenciaRegime,
}) => {
	const [tbu, setTbu] = useState(30);
	const [temperatura, setTemperatura] = useState(35);
	const debounceTimeoutTbu = useRef(null);
	const debounceTimeoutTemperatura = useRef(null);


	const handleTbu = (value) => {
		if (debounceTimeoutTbu.current) {
			clearTimeout(debounceTimeoutTbu.current);
		}
		debounceTimeoutTbu.current = setTimeout(() => {
			setTbu(current => {
				return parseFloat(value.toString().replace(',', '.'));
			});
		}, delayTime);
	};

	const handleTemperatura = useCallback((value, regimeId) => {
		if (debounceTimeoutTemperatura.current) {
			clearTimeout(debounceTimeoutTemperatura.current);
		}
		debounceTimeoutTemperatura.current = setTimeout(() => {
			setTemperatura(current => {
				return parseFloat(value.toString().replace(',', '.'));
			});	
			setPressaoReferenciaRegime((current) => {
				const aux = [...current];
				const temp = parseFloat(
					value.toString().replace(',', '.')
				);
				const index = aux.findIndex(
					(regime) => regime.id === regimeId
				);
				aux[index].pressaoReferencia =
					temperaturaParaPressao(temp);
				return aux;
			});
		}, delayTime);
	}, [setPressaoReferenciaRegime]);


	return (
		<>
			<CardLayout>
				<Accordion
					type="single"
					defaultValue="item-1"
					collapsible="true"
				>
					<AccordionItem value="item-1">
						<div className="font-semibold">
							<AccordionTrigger>
								<div className="w-full flex items-center justify-center text-sm lg:text-2xl">
									<p className="text-accent drop-shadow-1xl uppercase">
										{Condensacao?.nome}
									</p>
								</div>
							</AccordionTrigger>
						</div>
						<AccordionContent>
							<div className="flex flex-col lg:flex-row gap-2 px-4">
								<div className="w-full lg:w-1/4 2xl:w-1/3 flex flex-col justify-center">
									<CondensacaoData
										capacidadesRegimes={
											capacidadesRegimes
										}
										pressao={
											temperaturaParaPressao(temperatura)
										}
										condensacao={
											Condensacao
										}
										tbu={
											tbu
										}
										temperatura={
											temperatura
										}
										handleTbu={
											handleTbu
										}
										handleTemperatura={
											handleTemperatura
										}
									/>
								</div>
								<div className="w-full lg:w-3/4 2xl:w-2/3 flex flex-col ">
									<TableCondensador
										condensacaoId={
											Condensacao.id
										}
										bombas={
											bombas
										}
										ventiladores={
											ventiladores
										}
										tbu={
											tbu
										}
										temperatura={
											temperatura
										}
										setCapacidadesRegimes={
											setCapacidadesRegimes
										}
									/>
								</div>
							</div>
						</AccordionContent>
					</AccordionItem>
				</Accordion>
			</CardLayout>
		</>
	);
};

export default Condensacao;
