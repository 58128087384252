import React from 'react';
import { cn } from '../../lib/utils/utils';

const SmallCardLayout = ({ children, className, props }) => {
	return (
		<div
			className={cn(
				className,
				'bg-darkaccent/60 shadow-2xl p-2 border-l-4  rounded-md'
			)}
			{...props}
		>
			{children}
		</div>
	);
};

export default SmallCardLayout;
