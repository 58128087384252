import { apiLink } from '../../constants/env';
import axiosInstance from '../AxiosInstance';

const apiSistemaAguaGelada = {
    fetchApi: async (page) => {
        const URL = `${apiLink}api/sistema-agua?page=` + page;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();
            })
    },
    getById: async (id) => {
        const URL = `${apiLink}api/sistema-agua/` + id;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();
            })
    },
    postApi: async (sistemaAguaGelada) => {
        const URL = `${apiLink}api/sistema-agua`;
        return await axiosInstance.post(URL, sistemaAguaGelada)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();
            })
    },
    deleteApi: async (id) => {
        const URL = `${apiLink}api/sistema-agua/status/${id}`;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();

            })
    },
    putApi: async (id, sistemaAguaGelada) => {
        const URL = `${apiLink}api/sistema-agua/${id}`;
        return await axiosInstance.put(URL, sistemaAguaGelada)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();

            })
    },
    getByRegimeId: async (id) => {
        const URL = `${apiLink}api/sistema-agua/regime/${id}`;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao pegar dados do sistema de água gelada:', error);
                throw new Error();
            })
    },

}

export default apiSistemaAguaGelada; 