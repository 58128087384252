import { TableHeader } from './TableHeader';
import { TableHead } from './TableHead';
import { TableBody } from './TableBody';
import { TableRow } from './TableRow';
import { TableData } from './TableData';
import { TableRoot } from './TableRoot';

const Table = {
	Head: TableHead,
	Body: TableBody,
	Row: TableRow,
	Header: TableHeader,
	Root: TableRoot,
	Data: TableData,
};

export default Table;
