import { useState, useEffect, useContext } from 'react';
import { ToastContext } from '../../../../contexts/ToastContext';
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from '../../../../components/ui/dialog';
import {
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
	AlertDialogTrigger,
} from '../../../../components/ui/alert-dialog';
import apiAmbientes from '../../../../services/apis/apiAmbientes';
import { useParams } from 'react-router-dom';
import apiGrupo from '../../../../services/apis/apiGrupo';
import apiEmpresa from '../../../../services/apis/apiEmpresa';
import apiUnidade from '../../../../services/apis/apiUnidade';
import apiRegimes from '../../../../services/apis/apiRegimes';
import PaginationBar from '../../../../components/common/PaginationBar';
import { getParentMap } from '../../../../funcs/getParent';
import apiEnum from '../../../../services/apis/apiEnum';

export default function ListaAmbientes({
	atualizarLista,
	pesquisar,
	funcaoModalController,
}) {
	const [nome, setNome] = useState('');
	const [comprimento, setComprimento] = useState(0);
	const [largura, setLargura] = useState(0);
	const [altura, setAltura] = useState(0);
	const [fatorAmbiente, setFatorAmbiente] = useState(0);
	const [massaProduto, setMassaProduto] = useState(0);
	const [tiposAmbiente, setTiposAmbiente] = useState([]);
	const [tempoProcesso, setTempoProcesso] = useState(0);
	const [temperaturaReferencia, setTemperaturaReferencia] = useState('0');
	const [idEditar, setIdEditar] = useState();
	const [itemRemovido, setItemRemovido] = useState();
	const [idRemover, setIdRemover] = useState();
	const [regimeId, setRegimeId] = useState(0);
	const [listaRegimesTabela, setListaRegimesTabela] = useState(new Map());
	const [listaUnidadesTabela, setListaUnidadesTabela] = useState(new Map());
	const [listaEmpresasTabela, setListaEmpresaTabela] = useState(new Map());
	const [listaRegimes, setListaRegimes] = useState([]);
	const [listaUnidades, setListaUnidades] = useState([]);
	const [listaEmpresas, setListaEmpresas] = useState([]);
	const [listaGrupos, setListaGrupos] = useState([]);
	const { page } = useParams();
	const [pagination, setPagination] = useState();
	const [listaAmbientes, setListaAmbientes] = useState([]);
	const [grupoId, setGrupoId] = useState(0);
	const [empresaId, setEmpresaId] = useState(0);
	const [unidadeId, setUnidadeId] = useState(0);
	const [tipoAmbiente, setTipoAmbiente] = useState("");
	const funcaoModal = funcaoModalController.funcaoModal;

	const { erro, sucesso } = useContext(ToastContext);

	function limparInputs() {
		setNome('');
		setTemperaturaReferencia(0);
		setEmpresaId(0);
		setUnidadeId(0);
		setRegimeId(0);
	}
	useEffect(() => {
		apiAmbientes.fetchApi(page).then((res) => {
			setListaAmbientes(res.data.items);
			setPagination(res.data);
		});
		apiGrupo.fetchApi().then(res => {
			setListaGrupos(res.data);
		});
		apiEnum.getTiposAmbiente().then(res => {
			setTiposAmbiente(res.data);
		});
	}, [page]);

	useEffect(() => {
		grupoId !== undefined &&
			!isNaN(grupoId) &&
			apiEmpresa.getByGrupoApi(grupoId).then(res => {
				setListaEmpresas(res.data);
			})
	}, [grupoId]);

	useEffect(() => {
		empresaId !== undefined &&
			!isNaN(empresaId) &&
			apiUnidade.getByEmpresaApi(empresaId).then(res => {
				setListaUnidades(res.data);
			})
	}, [empresaId])

	useEffect(() => {
		unidadeId !== undefined &&
			!isNaN(unidadeId) &&
			apiRegimes.getByUnidadeId(unidadeId).then(res => {
				setListaRegimes(res.data);
			})
	}, [unidadeId])


	useEffect(() => {
		listaAmbientes.length > 0 &&
		listaAmbientes.forEach(elemento => {
			apiRegimes.getById(elemento.regimeId).then(res => {
				const regime = res.data;
				setListaRegimesTabela(map => new Map(map.set(regime.id, regime)));
			});
		});

	}, [listaAmbientes]);

	useEffect(() => {
		listaRegimesTabela.size > 0 &&
		listaRegimesTabela.forEach(elemento => {
			apiUnidade.getById(elemento.unidadeId).then(res => {
				const unidade = res.data;
				setListaUnidadesTabela(map => new Map(map.set(unidade.id, unidade)));
			});
		});
	}, [listaRegimesTabela]);

	useEffect(() => {

		listaUnidadesTabela.size > 0 &&
		listaUnidadesTabela.forEach(elemento => {
			apiEmpresa.getById(elemento.empresaId).then(res => {
				const empresa = res.data;
				setListaEmpresaTabela(map => new Map(map.set(empresa.id, empresa)));
			});

		});
	}, [listaUnidadesTabela]);

	function getParentsObjects(id) {
		const regime = getParentMap(listaRegimesTabela, id);
		const unidade = getParentMap(listaUnidadesTabela, regime?.unidadeId);
		const empresa = getParentMap(listaEmpresasTabela, unidade?.empresaId);
		return {
			regime: regime,
			unidade: unidade,
			empresa: empresa,
		}

	}

	//DELETE AMBIENTE
	function deletar(id) {
		apiAmbientes
			.deleteApi(id)
			.then((res) => {
				atualizarLista();
				sucesso('Ambiente deletado com sucesso!');
			})
			.catch((error) => {
				console.error(error.message);
				erro('Erro ao deletar ambiente!');
			});
	}

	function enviar(e) {
		e.preventDefault();

		const ambiente = {
			nome: nome,
			regimeId: parseInt(e.target.regimeId.value),
			temperaturaReferencia: parseFloat(temperaturaReferencia),
			tipoAmbiente: tipoAmbiente,
			comprimento:parseFloat(comprimento),
			largura:parseFloat(largura),
			altura:parseFloat(altura),
			fatorAmbiente:parseFloat(fatorAmbiente),
			massaProduto:parseFloat(massaProduto),
			tempoProcesso:parseFloat(tempoProcesso),
		};

		if (funcaoModal === 'Adicionar') {
			apiAmbientes
				.postApi(ambiente)
				.then((res) => {
					limparInputs();
					sucesso('Ambiente adicionado com sucesso!');
					atualizarLista();
				})
				.catch((error) => {
					erro('Erro ao adicionar ambiente!');
					console.error(error.message);
				});
		} else if (funcaoModal === 'Editar') {
			apiAmbientes
				.putApi(idEditar, ambiente)
				.then((res) => {
					limparInputs();
					atualizarLista();
					sucesso('Ambiente editado com sucesso!');
				})
				.catch((error) => {
					console.error(error.message);
					erro('Erro ao editar ambiente!');
				});
		}
	}



	return (
		<>
			<Dialog>
				<div className="w-full flex flex-col items-center mt-[40px] md:px-[200px]">
					<table className="w-full">
						<thead className="border-b w-full">
							<tr>
								<th className="text-[25px] pl-[10px] text-start">
									Nome
								</th>
								<th className="text-[25px] pl-[10px] text-start">
									Regime
								</th>
								<th className="text-[25px] pl-[10px] text-start">
									Unidade
								</th>
							</tr>
						</thead>
						<tbody>
							{listaAmbientes.map(
								(ambiente, i) =>
									ambiente.nome
										.toUpperCase()
										.startsWith(
											pesquisar.toUpperCase()
										) && (
										<tr
											className="border-b hover:bg-gray-200 hover:bg-opacity-5"
											key={i}
										>
											<td className="pl-[10px] text-[20px]">
												{
													ambiente.nome
												}
											</td>
											<td className="pl-[10px] text-[20px]">
												{getParentMap(listaRegimesTabela, ambiente.regimeId)?.nome}
											</td>
											<td className="flex justify-between py-1">
												<span className="text-[20px] pl-[10px]">
													{getParentsObjects(ambiente.regimeId)?.unidade?.nome}
												</span>
												<span className="flex">
													<AlertDialogTrigger>
														<div
															onClick={() => {
																const regimeId = ambiente.regimeId;
																const { empresa, unidade } = getParentsObjects(regimeId)
																setIdEditar(
																	ambiente.id
																);
																setNome(
																	ambiente.nome
																);
																setGrupoId(
																	empresa.grupoId
																);
																setTipoAmbiente(
																	ambiente.tipoAmbiente.replaceAll("_", " ")

																);
																setTemperaturaReferencia(
																	ambiente.temperaturaReferencia
																);
																setEmpresaId(
																	empresa.id
																);
																setUnidadeId(
																	unidade.id
																);
																setRegimeId(
																	regimeId
																);
																setFatorAmbiente(
																	ambiente.fatorAmbiente
																);
																setMassaProduto(
																	ambiente.massaProduto
																);
																setAltura(
																	ambiente.altura
																);
																setTempoProcesso(
																	ambiente.tempoProcesso
																);
																setLargura(
																	ambiente.largura
																);
																setComprimento(
																	ambiente.comprimento
																);
																
																funcaoModalController.setFuncaoModal(
																	'Editar'
																);
															}}
														>
															<i
																className="fa-solid fa-pen-to-square text-[18px] hover:text-lightBlue"
																title="Editar"
															></i>
														</div>
													</AlertDialogTrigger>
													<DialogTrigger>
														<div
															className="mx-2.5"
															onClick={() => {
																setItemRemovido(
																	ambiente.nome
																);
																setIdRemover(
																	ambiente.id
																);
															}}
														>
															<i
																className="fa-solid fa-trash text-[18px] hover:text-lightBlue"
																title="Deletar"
															></i>
														</div>
													</DialogTrigger>
												</span>
											</td>
										</tr>
									)
							)}
						</tbody>
					</table>
					{
						pagination !== undefined &&
						<PaginationBar pagination={pagination} />
					}
				</div>

				<DialogContent>
					<DialogHeader>
						<DialogTitle>
							Excluir {itemRemovido}?
						</DialogTitle>
					</DialogHeader>
					<DialogDescription>
						Todos os dados associados com {itemRemovido}{' '}
						serão removidos.
					</DialogDescription>
					<DialogFooter>
						<div
							className="bg-red-600/70 py-2 px-4 rounded-md hover:bg-red-600 hover:cursor-pointer border-slate-800 border-2 transition-all duration-200 ease-in-out"
							onClick={() => deletar(idRemover)}
						>
							<DialogClose>Excluir</DialogClose>
						</div>
					</DialogFooter>
				</DialogContent>
			</Dialog>

			<AlertDialogContent>
				<form onSubmit={(e) => enviar(e)}>
					<AlertDialogHeader>
						<AlertDialogTitle>
							{funcaoModal} Ambiente
						</AlertDialogTitle>
					</AlertDialogHeader>
					<div className="w-full max-h-[500px] flex justify-around my-5 px-5 flex-wrap gap-5 overflow-x-hidden overflow-y-auto scrollbar-thin scrollbar-track-primary scrollbar-thumb-darkaccent">
						{/* NOME */}
						<div className="flex flex-col justify-end">
							<label
								className="text-[18px] mb-2 pl-1"
								htmlFor="nome"
							>
								Nome
							</label>
							<input
								id="nome"
								name="nome"
								type="text"
								placeholder="nome do ambiente"
								className="border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[180px]"
								value={nome}
								onChange={(e) =>
									setNome(e.target.value)
								}
								required
							/>
						</div>
						{/* TEMPERATURA REFERENCIA */}
						<div className="flex flex-col justify-end">
							<label
								className="text-[16px] mb-2 pl-1"
								htmlFor="temperaturaReferencia"
							>
								Temperatura referência
							</label>
							<input
								id="temperaturaReferencia"
								name="temperaturaReferencia"
								type="number"
								className="border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[180px]"
								value={temperaturaReferencia}
								onChange={(e) =>
									setTemperaturaReferencia(
										e.target.value
									)
								}
								required
							/>
						</div>

						{/* TIPO AMBIENTE */}
						<div className="flex flex-col justify-end">
							<label
								className="text-[18px] mb-2 pl-1"
								htmlFor="tipoAmbiente"
							>
								Tipo de ambiente
							</label>
							<select
								className="border-b text-[20px] h-[42.8px] bg-[#0b101d] rounded-t-md w-[180px]"
								name="tipoAmbiente"
								id="tipoAmbiente"
								required
								value={tipoAmbiente}
								onChange={(e) =>
									setTipoAmbiente(
										e.target.value
									)
								}
							>
								<option label="   "></option>
								{
									tiposAmbiente.map((tipo, i) => (
										<option key={i} value={tipo}>{tipo}</option>	
									))
								}
							</select>
						</div>
						{/* COMPRIMENTO */}
						<div className="flex flex-col justify-end">
							<label
								className="text-[16px] mb-2 pl-1"
								htmlFor="comprimento"
							>
								Comprimento
							</label>
							<input
								id="comprimento"
								name="comprimento"
								type="number"
								className="border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[180px]"
								value={comprimento}
								onChange={(e) =>
									setComprimento(
										e.target.value
									)
								}
								required
							/>
						</div>
						{/* LARGURA */}
						<div className="flex flex-col justify-end">
							<label
								className="text-[16px] mb-2 pl-1"
								htmlFor="largura"
							>
								Largura
							</label>
							<input
								id="largura"
								name="largura"
								type="number"
								className="border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[180px]"
								value={largura}
								onChange={(e) =>
									setLargura(
										e.target.value
									)
								}
								required
							/>
						</div>
						{/* ALTURA */}
						<div className="flex flex-col justify-end">
							<label
								className="text-[16px] mb-2 pl-1"
								htmlFor="altura"
							>
								Altura
							</label>
							<input
								id="altura"
								name="altura"
								type="number"
								className="border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[180px]"
								value={altura}
								onChange={(e) =>
									setAltura(
										e.target.value
									)
								}
								required
							/>
						</div>
						{/* FATOR AMBUENTE */}
						<div className="flex flex-col justify-end">
							<label
								className="text-[16px] mb-2 pl-1"
								htmlFor="largura"
							>
								Fator de ambiente
							</label>
							<input
								id="fatorAmbiente"
								name="fatorAmbiente"
								type="number"
								className="border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[180px]"
								value={fatorAmbiente}
								onChange={(e) =>
									setFatorAmbiente(
										e.target.value
									)
								}
								required
							/>
						</div>
						{/* MASSA DO PRODUTO */}
						<div className="flex flex-col justify-end">
							<label
								className="text-[16px] mb-2 pl-1"
								htmlFor="massaProduto"
							>
								Massa de produto
							</label>
							<input
								id="massaProduto"
								name="massaProduto"
								type="number"
								className="border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[180px]"
								value={massaProduto}
								onChange={(e) =>
									setMassaProduto(
										e.target.value
									)
								}
								required
							/>
						</div>
						{/* LARGURA */}
						<div className="flex flex-col justify-end">
							<label
								className="text-[16px] mb-2 pl-1"
								htmlFor="tempoProcesso"
							>
								Tempo de processo
							</label>
							<input
								id="tempoProcesso"
								name="tempoProcesso"
								type="number"
								className="border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[180px]"
								value={tempoProcesso}
								onChange={(e) =>
									setTempoProcesso(
										e.target.value
									)
								}
								required
							/>
						</div>
						{/* GRUPOS */}
						<div className="flex flex-col justify-end">
							<label
								className="text-[18px] mb-2 pl-1"
								htmlFor="grupoId"
							>
								Grupo
							</label>
							<select
								className="border-b text-[20px] h-[42.8px] bg-[#0b101d] rounded-t-md w-[180px]"
								name="grupoId"
								id="grupoId"
								required
								value={grupoId}
								onChange={(e) =>
									setGrupoId(
										parseInt(
											e.target.value
										)
									)
								}
							>
								<option label="   "></option>
								{listaGrupos.map(
									(empresa, i) => (
										<option
											value={
												empresa.id
											}
											key={i}
										>
											{empresa.nome}
										</option>
									)
								)}
							</select>
						</div>
						{/* EMPRESAS */}
						<div className="flex flex-col justify-end">
							<label
								className="text-[18px] mb-2 pl-1"
								htmlFor="empresaId"
							>
								Empresa
							</label>
							<select
								className="border-b text-[20px] h-[42.8px] bg-[#0b101d] rounded-t-md w-[180px]"
								name="empresaId"
								id="empresaId"
								required
								value={empresaId}
								onChange={(e) =>
									setEmpresaId(
										parseInt(
											e.target.value
										)
									)
								}
							>
								<option label="   "></option>
								{listaEmpresas.map(
									(empresa, i) => (
										<option
											value={
												empresa.id
											}
											key={i}
										>
											{empresa.nome}
										</option>
									)
								)}
							</select>
						</div>
						{/* UNIDADES */}
						<div className="flex flex-col justify-end">
							<label
								className="text-[18px] mb-2 pl-1"
								htmlFor="unidadeId"
							>
								Unidade
							</label>
							<select
								className="border-b text-[20px] h-[42.8px] bg-[#0b101d] rounded-t-md w-[180px]"
								name="unidadeId"
								id="unidadeId"
								required
								value={unidadeId}
								onChange={(e) =>
									setUnidadeId(
										parseInt(
											e.target.value
										)
									)
								}
							>
								<option label="   "></option>
								{listaUnidades
									.filter(
										(elemento) =>
											elemento.empresaId ===
											empresaId
									)
									.map((unidade, i) => (
										<option
											value={
												unidade.id
											}
											key={i}
										>
											{unidade.nome}
										</option>
									))}
							</select>
						</div>
						{/* REGIME ID */}
						<div className="flex flex-col justify-end">
							<label
								className="text-[18px] mb-2 pl-1"
								htmlFor="regimeId"
							>
								Regime
							</label>
							<select
								className="border-b text-[20px] h-[42.8px] bg-[#0b101d] rounded-t-md w-[180px]"
								name="regimeId"
								id="regimeId"
								required
								value={regimeId}
								onChange={(e) =>
									setRegimeId(
										parseInt(
											e.target.value
										)
									)
								}
							>
								<option label="   "></option>
								{listaRegimes
									.filter(
										(elemento) =>
											elemento.unidadeId ===
											unidadeId
									)
									.map((regime, i) => (
										<option
											value={
												regime.id
											}
											key={i}
										>
											{regime.nome}
										</option>
									))}
							</select>
						</div>
					</div>
					<AlertDialogFooter>
						<div className="flex w-full justify-end gap-x-4 p-4">
							<AlertDialogAction className="w-32">
								Confirmar
							</AlertDialogAction>
							<AlertDialogCancel className="w-32">
								Cancelar
							</AlertDialogCancel>
						</div>
					</AlertDialogFooter>
				</form>
			</AlertDialogContent>
		</>
	);
}
