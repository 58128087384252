import React, { useEffect, useState } from 'react';

import { FaArrowUpLong, FaArrowDownLong } from 'react-icons/fa6';
import { GaugeChart } from './charts/GaugeChart';
import { colorsPallete } from '../../../constants';
import CardLayout from '../../common/CardLayout';
import NoDataAlert from '../../common/returns/NoDataAlert';
import ReactHowler from 'react-howler';
import alarmSound from '../../../assets/audio/som-de-alarme.wav';

const ListRegimes = ({ listaRegimes, leituraAtualListaRegime }) => {

	const [key, setKey] = useState(0);
	const [playingAudio, setPlayingAudio] = useState(false);
	


	const regimesWithColors = listaRegimes.map((regime, index) => {
		return {
			...regime,
			color: colorsPallete[index].class,
		};
	});

	function isAlarmed(regime) {
		const pressaoAtual = getLeituraByRegime(regime.id)?.pressaoAtual;
		if (
			pressaoAtual < regime.rangeMin ||
			pressaoAtual > regime.rangeMax
		) {
			return true;
		}
		return false;
	}

	function getLeituraByRegime(id) {
		return leituraAtualListaRegime.find(
			(elemento) => elemento.regimeId === id
		);
	}

	useEffect(() => {
		setKey(crypto.randomUUID());

		/* Está dando erro nesse setkey:
        Warning: Maximum update depth exceeded. This can happen when a component calls setState inside useEffect, but useEffect either doesn't have a dependency array, or one of the dependencies changes on every render.%s

        Corrigir assim que tiver tempo @ViniciusMarchandCESS @andersonlimacrv
        */
		const alarmeStatus = listaRegimes.some((regime) => {
			const pressaoAtual = leituraAtualListaRegime.find(
				(elemento) => elemento.regimeId === regime.id
			)?.pressaoAtual;

			if (
				pressaoAtual < regime.rangeMin ||
				pressaoAtual > regime.rangeMax
			) {
				return true;
			}
			return false;
		});
		setPlayingAudio(alarmeStatus);
	}, [leituraAtualListaRegime, listaRegimes]);

	if(listaRegimes.length === 0) {
		return <NoDataAlert />;
	}
	
	return (
		<>
			<div className="hidden">
				<ReactHowler
					src={alarmSound}
					playing={playingAudio}
					loop={true}
				/>
			</div>
			{regimesWithColors.map((regime) => (
				<CardLayout key={regime.id} alarmed={isAlarmed(regime)}>
					<div
						className={`flex flex-col justify-center items-center ${regime.color}`}
					>
						<div className="py-2 lg:pt-2 lg:pb-1 text-lg 3xl:text-3xl font-semibold drop-shadow-1xl uppercase">
							Regime {regime.nome}
						</div>
					</div>
					<div className="flex flex-row w-full flex-wrap justify-center gap-x-[15%]">
						<div className="flex flex-col justify-center items-center">
							<div className="drop-shadow-2xl">
								<p className="text-md font-normal text-secondary">
									Referência:{' '}
									<span className="font-bold drop-shadow-1xl text-white text-lg">
										{
											regime.pressaoReferencia?.toLocaleString(
												'pt-BR',
												{
													maximumFractionDigits: 2,
												}
											)
										}
										{' Bar'}
									</span>
								</p>
							</div>
							<div className="flex justify-center items-center" key={key}>
								{getLeituraByRegime(regime.id)
									?.pressaoAtual !==
								undefined ? (
									<GaugeChart
										regime={regime}
										pressaoAtual={
											getLeituraByRegime(
												regime.id
											)
												?.pressaoAtual
										}
									/>
								) : (
									<div className="pt-8">
										<NoDataAlert />
									</div>
								)}
							</div>
							<div className="flex flex-row justify-around w-full max-w-2xl text-secondary text-xs px-4 py-2">
								<p className="text-secondary text-lg">
									{regime.rangeMin.toLocaleString(
												'pt-BR',
												{
													maximumFractionDigits: 2,
												}
											)} {' Bar'}
								</p>
								<p className="text-secondary text-lg">
									{regime.rangeMax.toLocaleString(
												'pt-BR',
												{
													maximumFractionDigits: 2,
												}
											)} {' Bar'}
								</p>
							</div>
						</div>
						<div className="flex justify-center items-center flex-col text-secondary font-normal text-left py-2">
							<div className="text-secondary inline-flex text-sm 3xl:text-lg p-1">
								<FaArrowUpLong className="w-4 h-4 text-accent 3xl:w-5 3xl:h-5" />
								Max. registrada:{' '}
								<span className="font-semibold drop-shadow-1xl text-white px-1">
									{getLeituraByRegime(
										regime.id
									)?.pressaoMaxima.toLocaleString(
										'pt-BR',
										{
											maximumFractionDigits: 2,
										}
									)}
								</span>
							</div>
							<div className="text-secondary inline-flex text-sm 3xl:text-lg p-1">
								<FaArrowDownLong className="w-4 h-4 text-accent 3xl:w-5 3xl:h-5" />
								Min. registrada:{' '}
								<span className="font-semibold drop-shadow-1xl text-white px-1">
									{getLeituraByRegime(
										regime.id
									)?.pressaoMinima.toLocaleString(
										'pt-BR',
										{
											maximumFractionDigits: 2,
										}
									)}
								</span>
							</div>
							<div className="flex text-center justify-center border-2 items-center border-accent rounded-full h-8 w-full max-w-[20rem] 3xl:px-4 my-2">
								<div className="text-secondary font-normal">
									<div className="flex text-center justify-center text-secondary items-center">
										<p
											className={`text-sm 3xl:text-lg font-bold px-4 ${regime.color}`}
										>
											Temperatura:
										</p>
										<span className="font-bold drop-shadow-1xl text-white px-4 md:px-2 text-sm 3xl:text-lg">
											{getLeituraByRegime(
												regime.id
											)?.temperaturaAtual?.toLocaleString(
												'pt-BR',
												{
													maximumFractionDigits: 1,
												}
											)}
											<span>
												°C
											</span>
										</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</CardLayout>
			))}
		</>
	);
};

export default ListRegimes;
