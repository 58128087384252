import { useContext, useState } from "react";
import CardLayout from "../../components/common/CardLayout";
import MainButton from "../../components/common/MainButton";
import SgemAnimate from "../../components/common/SgemAnimate";
import apiSolicitacaoSuporte from "../../services/apis/SolicitacaoSuporte";
import { ToastContext } from "../../contexts/ToastContext";
import LoadingScreen from "../LoadingScreen";
import { Link } from "react-router-dom";

export default function Suporte() {

    const [assunto, setAssunto] = useState('');
    const [setor, setSetor] = useState('');
    const [nome, setNome] = useState('');
    const [sobrenome, setSobrenome] = useState('');
    const [mensagem, setMensagem] = useState('');
    const [waitingResponse, setWaitingResponse] = useState(false);

    const { sucesso, erro } = useContext(ToastContext);
    function enviar(e) {
        e.preventDefault();
        setWaitingResponse(true);
        const form = new FormData();
        form.append("Assunto", assunto);
        form.append("SetorSuporte", setor);
        form.append("Mensagem", mensagem);
        form.append("DataHora", new Date().toISOString().replace("Z", ""));
        form.append("NomeUsuario", nome);
        form.append("SobrenomeUsuario", sobrenome);

        const inputFile = document.getElementById("arquivo");
        for (let i = 0; i < inputFile.files.length; i++) {
            form.append("Arquivos", inputFile.files[i]);
        }

        apiSolicitacaoSuporte.send(form).then(res => {
            sucesso(res.data.message);
            setNome("");
            setAssunto("");
            setMensagem("");
            setSetor("");
            setSobrenome("");
            inputFile.value = "";
        }).catch(error => {
            erro(error.message);
        }).finally(() => setWaitingResponse(false));
    }


    return <div className="w-full min-h-full flex items-center justify-center flex-col my-16">
        <div className="z-50 w-screen relative">
            {
                waitingResponse &&
                <LoadingScreen />
            }
        </div>
        <div className="w-[700px] mt-16">
            <CardLayout>
                <div className="min-h-[370px] px-5 py-10 flex flex-col items-center">
                    <div className="">
                        <SgemAnimate />
                    </div>
                    <h1 className="text-[40px] font-bold text-center mt-10">Solicitação de Suporte</h1>
                    <form onSubmit={(e) => enviar(e)} className="w-full flex flex-col items-center">
                        <div className="w-[450px] flex flex-wrap justify-between px-5">
                            <div className="flex flex-col justify-start pt-5">
                                <label className="text-[20px] mb-2 pl-1" htmlFor="nome"> Nome </label>
                                <input
                                    id="nome"
                                    name="nome"
                                    type="text"
                                    className="border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[180px]"
                                    value={nome}
                                    onChange={(e) =>
                                        setNome(e.target.value)
                                    }
                                    required
                                />
                            </div>
                            <div className="flex flex-col justify-start pt-5">
                                <label className="text-[20px] mb-2 pl-1" htmlFor="sobrenome"> Sobrenome </label>
                                <input
                                    id="sobrenome"
                                    name="sobrenome"
                                    type="text"
                                    className="border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[180px]"
                                    value={sobrenome}
                                    onChange={(e) =>
                                        setSobrenome(e.target.value)
                                    }
                                    required
                                />
                            </div>
                            <div className="flex flex-col justify-start pt-5">
                                <label className="text-[20px] mb-2 pl-1" htmlFor="assunto"> Assunto </label>
                                <input
                                    id="assunto"
                                    name="assunto"
                                    type="text"
                                    className="border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[180px]"
                                    value={assunto}
                                    onChange={(e) =>
                                        setAssunto(e.target.value)
                                    }
                                    required
                                />
                            </div>
                            <div className="flex flex-col justify-start pt-5">
                                <label className="text-[20px] mb-2 pl-1" htmlFor="setor"> Setor </label>
                                <select
                                    id="setor"
                                    name="setor"
                                    type="text"
                                    className="border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[180px]"
                                    value={setor}
                                    onChange={(e) =>
                                        setSetor(e.target.value)
                                    }
                                    required
                                >
                                    <option value={""} disabled></option>
                                    <option value={"0"}>TI</option>
                                    <option value={"1"}>Financeiro</option>
                                </select>
                            </div>
                            <div className="flex flex-col justify-start pt-5 w-full">
                                <label className="text-[20px] mb-2 pl-1 w-full" htmlFor="mensagem"> Mensagem </label>
                                <textarea
                                    id="mensagem"
                                    name="mensagem"
                                    type="text"
                                    className="border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-full min-h-[100px] scrollbar-thin scrollbar-track-primary scrollbar-thumb-darkaccent"
                                    value={mensagem}
                                    onChange={(e) =>
                                        setMensagem(e.target.value)
                                    }
                                    required
                                />
                            </div>
                            <div className="w-full">
                                <div className="flex flex-col justify-start pt-5">
                                    <label className="text-[20px] mb-2 pl-1" htmlFor="arquivo"> Arquivo(s) </label>
                                    <input
                                        id="arquivo"
                                        name="arquivo"
                                        type="file"
                                        multiple={true}
                                        className="block w-full text-lg text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50 dark:text-gray-400 focus:outline-none dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400"></input>
                                </div>
                            </div>
                        </div>
                        <div className="flex w-full justify-center">
                            <Link to={'/monitoramento/geral'}>
                                <div className="mt-10 h-[40px] w-[180px] mx-5">
                                    <MainButton
                                        styles={{
                                            style: 'bg-gradient-to-t from-red-500 to-red-700 hover:from-red-600 hover:to-red-800',
                                        }}
                                        type="button"
                                    >

                                        <p className="text-[20px]">Cancelar</p>
                                    </MainButton>
                                </div>
                            </Link>
                            <div className="mt-10 h-[40px] w-[180px] mx-5">
                                <MainButton>
                                    <p className="text-[20px]">Enviar</p>
                                </MainButton>
                            </div>
                        </div>
                    </form>
                </div>
            </CardLayout>
        </div>
    </div>
}