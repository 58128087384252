import { apiLink } from '../../constants/env';
import axiosInstance from '../AxiosInstance';

const apiSimulacaoUnidade = {
    getCalculoCargaTermicaAmbiente: async (ambienteId, tempoProcesso, massaProduto) => {
        const URL = `${apiLink}api/simulacao-unidade/carga-termica/ambiente?ambienteId=${ambienteId}&tempoProcesso=${isNaN(tempoProcesso) ? 1 : tempoProcesso}&massaProduto=${isNaN(massaProduto) ? 0 : massaProduto}`
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();

            })
    },
    getCalculoCargaTermicaSistemaAgua: async (sistemaAguaId, temperaturaEntrada, temperaturaSaida) => {
        const URL = `${apiLink}api/simulacao-unidade/carga-termica/sistema-agua?sistemaAguaId=${sistemaAguaId}&temperaturaEntrada=${isNaN(temperaturaEntrada) ? 0 : temperaturaEntrada}&temperaturaSaida=${isNaN(temperaturaSaida) ? 0 : temperaturaSaida}`
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();

            })
    },
    getCalculoCargaTermicaMaquinaGelo: async (maquinaGeloId, temperaturaEntrada) => {
        const URL = `${apiLink}api/simulacao-unidade/carga-termica/maquina-gelo?maquinaGeloId=${maquinaGeloId}&capacidadeProducao=${isNaN(temperaturaEntrada) ? 1 : temperaturaEntrada}`
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();

            })
    },
    getCalculosCondensador: async (tbu, capacidadeNominal, temperaturaCondensacao) => {
        const URL = `${apiLink}api/simulacao-unidade/capacidade-condensador?tbu=${tbu}&capacidadeNominal=${capacidadeNominal}&temperaturaCondensacao=${temperaturaCondensacao}`
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();

            })
    },
}

export default apiSimulacaoUnidade;
