import React from 'react';
import { cn } from '../../lib/utils/utils';

const InputGeneric = ({ unidade, value, id, name, className, ...props }) => {
	const inputClasses = cn(
		'block',
		'w-full',
		'rounded',
		'border-0',
		'py-[2px]',
		'my-[2px]',
		'text-white',
		'ring-1',
		'bg-darkaccent',
		'ring-accent',
		'placeholder:text-secondary',
		'focus:ring-2',
		'sm:text-sm',
		'2xl:text-lg',
		'sm:leading-6',
		className,
		{
			'pl-4': unidade,
			'text-center': !unidade,
		}
	);

	return (
		<>
			<input
				type="number"
				name={name}
				id={id}
				className={inputClasses}
				placeholder={0.0}
				value={value}
				{...props}
			/>
			{unidade && (
				<span className="absolute top-[6px] 2xl:top-[8px] right-2 2xl:right-6  pl-1 text-xs font-medium text-gray-500 sm:text-sm">
					<span className="text-gray-500 sm:text-sm inline-flex ">
						{unidade}
					</span>
				</span>
			)}
		</>
	);
};

export default InputGeneric;
