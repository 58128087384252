import Chart from 'react-apexcharts';
import NoDataAlert from '../../../common/returns/NoDataAlert';

const ChartBarViewDefault = ({ chartDataProps, typeChart, nameSerie }) => {
	const options = {
		chart: {
			stacked: true,
			toolbar: {
				show: false,
			},
		},
		grid: {
			show: false,
			borderColor: '#40475D',
			strokeDashArray: 0,
		},
		plotOptions: {
			bar: {
				borderRadius: 3,
				borderRadiusApplication: 'end',
				borderRadiusWhenStacked: 'last',
				columnWidth: '85%',
				horizontal: false,
				dataLabels: {
					position: 'top',
				},
			},
		},
		dataLabels: {
			enabled: false,
			textAnchor: 'middle', //
			style: {
				colors: ['#FFF'],
				fontSize: '14px',
			},
			offsetY: 0,
			offsetX: 0,
			dropShadow: {
				enabled: true,
				left: 2,
				top: 2,
				opacity: 0.5,
			},
		},
		stroke: {
			width: 1,
			colors: ['#33333380'],
		},
		xaxis: {
			categories: chartDataProps ? chartDataProps.date : {},
			position: 'bottom',
			axisBorder: {
				show: false,
			},
			axisTicks: {
				show: false,
				color: '#40475D',
			},
			labels: {
				show: true,
				style: {
					colors: '#40475D',
					fontSize: '12px',
				},
				datetimeFormatter: {
					hour: 'HH:mm',
				},
			},
		},
		yaxis: {
			reversed: false,
			opposite: false,
			floating: false,
			axisBorder: {
				show: false,
			},
			axisTicks: {
				show: false,
				color: '#40475D',
			},
			labels: {
				show: true,
				style: {
					colors: '#40475D',
					fontSize: '12px',
					fontStyle: 'bold',
				},
				formatter: function (value) {
					var formattedValue = new Intl.NumberFormat(
						'pt-BR'
					).format(value.toFixed(2));
					return formattedValue;
				},
			},
		},
		tooltip: {
			theme: 'dark',
			y: {
				show: true,
				formatter: function (value) {
					var formattedValue = new Intl.NumberFormat(
						'pt-BR'
					).format(value.toFixed(2));
					return formattedValue;
				},
			},
		},

		fill: {
			type: 'gradient',
			gradient: {
				type: 'radial',
				shadeIntensity: 1,
				gradientToColors: ['#6078ea'], // optional, if not defined - uses the shades of same color in series
				inverseColors: false,
				opacityFrom: 1,
				opacityTo: 1,
				stops: [],
				colorStops: [],
			},
		},
		legend: {
			show: true,
			showForSingleSeries: true,
			showForNullSeries: true,
			showForZeroSeries: true,
			position: 'top',
			horizontalAlign: 'left',
			fontStyle: 'bold',
			fontColor: 'white',
			fontSize: '16px',
			labels: {
				colors: 'white',
				useSeriesColors: false,
			},
			onItemClick: {
				toggleDataSeries: true,
			},
			onItemHover: {
				highlightDataSeries: true,
			},
		},
	};

	let data = [];
	if (typeChart === 'consumoTotalMensal') {
		data = chartDataProps ? chartDataProps.consumoTotalDiario : [];
	} else if (typeChart === 'consumoTotalDiario') {
		data = chartDataProps ? chartDataProps.ConsumoPorHora : [];
	}

	const series = [
		{
			name: nameSerie ? nameSerie : '',
			data: data,
			color: '#0CABF5',
		},
	];

	if (!chartDataProps) return <NoDataAlert />;

	return (
		<div className="bg-transparent w-full h-full px-2 text-center">
			<Chart
				options={options}
				series={series}
				type="bar"
				width="100%"
				height={320}
			/>
		</div>
	);
};

export default ChartBarViewDefault;
