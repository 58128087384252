import { Link } from 'react-router-dom';
import '../globals.css';
export default function NotFound() {
	return (
		<>
			<section className="text-white py-20">
				<div className="flex items-center justify-center">
					<div className="max-w-md text-center h-full">
						<h2 className="mb-8 font-extrabold text-9xl dark:text-gray-600">
							<span className="sr-only">Error</span>
							404
						</h2>
						<p className="text-2xl font-semibold md:text-3xl">
							Desculpe, mas a página que você
							procura não foi encontrada.
						</p>
						<p className="mt-4 mb-8 dark:text-gray-400">
							Mas não se preocupe, você pode
							retornar para a página inicial.
						</p>
						<Link to={'/login'}>
							<button className="px-8 py-3 font-semibold rounded bg-accent/80 hover:bg-accent dark:text-gray-900">
								Voltar a página inicial
							</button>
						</Link>
					</div>
				</div>
			</section>
		</>
	);
}
