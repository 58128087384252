import React, { useState, useEffect, useCallback } from 'react';
import Table from '../../common/table';
import NoEquipAlert from '../../common/returns/NoEquipAlert';
import StatusOnOff from '../../common/StatusOnOff';
import InputGeneric from '../../common/InputGeneric';

import apiBomba from '../../../services/apis/apiBomba';
import apiModelo from '../../../services/apis/apiModelo';
import apiMarca from '../../../services/apis/apiMarca';
import apiCondensador from '../../../services/apis/apiCondensadores';
import apiLeituraBomba from '../../../services/apis/Leituras/apiUltimaLeituraBomba';

const fetchCondensador = async (condensadorId) => {
	try {
		const res = await apiCondensador.getById(condensadorId);
		return res.data;
	} catch (error) {
		console.error(
			`Erro fetching condensador [SobreUnidade - Bombas]: ${error}`
		);
		return null;
	}
};

const fetchModelo = async (modeloID) => {
	try {
		const res = await apiModelo.getById(modeloID);
		return res.data;
	} catch (error) {
		console.error(
			`Erro fetching modelo [SobreUnidade - Bombas]: ${error}`
		);
		return null;
	}
};

const fetchMarca = async (marcaId) => {
	try {
		const res = await apiMarca.getById(marcaId);
		return res.data;
	} catch (error) {
		console.error(
			`Erro fetching marca [SobreUnidade - Bombas]: ${error}`
		);
		return null;
	}
};

const fetchUltimaLeitura = async (bbID) => {
	try {
		const res = await apiLeituraBomba.fetchApi(bbID);
		return res.data;
	} catch (error) {
		console.error(
			`Erro fetching ultima leitura [SobreUnidade - Bombas]: ${error}`
		);
	}
};

const TableBombas = ({ unidadeId, regimes, condensacao }) => {
	const [bombas, setBombas] = useState([]);
	const [bombasData, setBombasData] = useState([]);
	const [modelos, setModelos] = useState([]);
	const [marcas, setMarcas] = useState([]);
	const [bbCondensador, setBbCondensador] = useState([]);
	const [leituras, setLeituras] = useState([]);

	const fetchData = useCallback(async () => {
		if (!unidadeId && !condensacao && !regimes) return;
		try {
			const res = await apiBomba.getByUnidadeId(unidadeId);
			setBombas(res.data);

			if (res.data.length > 0) {
				const leituras = await Promise.all(
					res.data.map((bomba) => {
						return fetchUltimaLeitura(bomba.id);
					})
				);
				setLeituras(leituras);

				const condensadorBombas = res.data.filter(
					(bomba) => bomba.tipoEquipamento === 'Condensador'
				);
				const condensadores = await Promise.all(
					condensadorBombas.map((bomba) =>
						fetchCondensador(bomba.condensadorId)
					)
				);

				const condensadoresData = condensadores.map(
					(condensador, index) => {
						const bomba = condensadorBombas[index];
						return {
							...bomba,
							condensadorNome: condensador
								? condensador.nome
								: '-',
							regimeId: condensador
								? condensador.regimeId
								: null,
						};
					}
				);
				setBbCondensador(condensadoresData);

				const modelos = await Promise.all(
					res.data.map((bomba) =>
						fetchModelo(bomba.modeloId)
					)
				);
				setModelos(modelos);

				const marcas = await Promise.all(
					modelos.map((modelo) =>
						fetchMarca(modelo.marcaId)
					)
				);
				setMarcas(marcas);
			}
		} catch (error) {
			console.error(`Error fetching bombas: ${error}`);
		}
	}, [unidadeId, condensacao, regimes]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	useEffect(() => {
		if (bombas.length > 0 && modelos.length > 0 && marcas.length > 0) {
			const updatedBombasData = bombas.map((bomba) => {
				const modelo = modelos.find(
					(model) => model && model.id === bomba.modeloId
				);
				const marca = marcas.find(
					(brand) => brand && brand.id === modelo?.marcaId
				);
				const equipamento = bbCondensador.find(
					(bbsCondensador) =>
						bbsCondensador &&
						bbsCondensador.id === bomba.id
				);
				const regimeId = bbCondensador.find(
					(bbsCondensador) =>
						bbsCondensador &&
						bbsCondensador.id === bomba.id
				);
				const regimeName = regimes.find(
					(regime) =>
						regime && regime.id === regimeId?.regimeId
				);
				const condesacaoName = condensacao.find(
					(condensacao) =>
						condensacao &&
						condensacao.id === regimeId?.regimeId
				);
				const leitura = leituras.find(
					(leitura) =>
						leitura && leitura.bombaId === bomba.id
				);
				return {
					...bomba,
					modelo: modelo ? modelo.nome : '-',
					marca: marca ? marca.nome : '-',
					regimeId: regimeId ? regimeId.regimeId : '-',
					equipamento: equipamento
						? equipamento.condensadorNome
						: '-',
					regime: regimeName
						? regimeName.nome
						: condesacaoName.nome,
					leitura: leitura ? leitura : '-',
				};
			});
			setBombasData(updatedBombasData);
		}
	}, [
		bombas,
		modelos,
		marcas,
		bbCondensador,
		regimes,
		condensacao,
		leituras,
	]);

	return (
		<div className="w-full">
			{bombasData.length > 0 ? (
				<Table.Root>
					<Table.Head>
						<Table.Header>Nome</Table.Header>
						<Table.Header>Status</Table.Header>
						<Table.Header>Regime</Table.Header>
						<Table.Header>Equipamento</Table.Header>
						<Table.Header>Marca</Table.Header>
						<Table.Header>Modelo</Table.Header>
						<Table.Header>Corrente</Table.Header>
						<Table.Header>Frequencia</Table.Header>
						<Table.Header>Potência</Table.Header>
					</Table.Head>

					<Table.Body>
						{bombasData.map((bomba) => (
							<Table.Row key={bomba.id}>
								<Table.Data>
									{bomba.nome}
								</Table.Data>
								<Table.Data>
									{bomba.leitura.ligado ? (
										<StatusOnOff
											status={
												bomba
													.leitura
													.ligado
											}
										/>
									) : (
										<StatusOnOff
											status={false}
										/>
									)}
								</Table.Data>
								<Table.Data>
									{bomba.regime}
								</Table.Data>
								<Table.Data>
									{bomba.equipamento}
								</Table.Data>
								<Table.Data>
									{bomba.marca}
								</Table.Data>
								<Table.Data>
									{bomba.modelo}
								</Table.Data>
								<Table.Data>
									{bomba.leitura
										.corrente ? (
										<>
											<InputGeneric
												className="max-w-[88px] mx-auto"
												value={bomba.leitura.corrente.toLocaleString(
													'pt-BR',
													{
														maximumFractionDigits: 2,
													}
												)}
												type="string"
												id={
													'leituraBBcorrente' +
													bomba.id
												}
												name={
													'leituraBBcorrente' +
													bomba.id
												}
												readOnly
											/>
										</>
									) : (
										'-'
									)}
								</Table.Data>
								<Table.Data>
									{bomba.leitura
										.frequencia ? (
										<>
											<InputGeneric
												className="max-w-[88px] mx-auto"
												value={
													bomba.leitura.frequencia.toLocaleString(
														'pt-BR',
														{
															maximumFractionDigits: 2,
														}
													) +
													' Hz'
												}
												type="string"
												id={
													'leituraBBfrequencia' +
													bomba.id
												}
												name={
													'leituraBBfrequencia' +
													bomba.id
												}
												readOnly
											/>
										</>
									) : (
										'-'
									)}
								</Table.Data>
								<Table.Data>
									{bomba.potenciaNominal +
										' CV'}
								</Table.Data>
							</Table.Row>
						))}
					</Table.Body>
				</Table.Root>
			) : (
				<NoEquipAlert equipments="bombas" />
			)}
		</div>
	);
};

export default TableBombas;
