import { useContext, useState } from "react";
import CardLayout from "../../components/common/CardLayout";
import MainButton from "../../components/common/MainButton";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import apiAtuth from "../../services/apis/apiAuth";
import { ToastContext } from "../../contexts/ToastContext";
import SgemAnimate from "../../components/common/SgemAnimate";

export default function ResetPassword() {
    const [userName, setUserName] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [searchParams,] = useSearchParams();
    const { erro, sucesso } = useContext(ToastContext);
    const navigate = useNavigate();
    async function enviar(e) {
        e.preventDefault();
        const token = searchParams.get('token').replaceAll(" ", "+");
        const form = {
            token: token,
            userName: userName,
            password: newPassword
        }
        try {
            if (newPassword !== confirmPassword) {
                throw new Error("As senhas precisam ser iguais!");
            }

            await apiAtuth.resetPassword(form);
            sucesso("Senha alterada!");
            navigate("/login");
        } catch (error) {
            const message = error.message;
            erro(error.message)
            console.error(message);
        }
    }


    return <div className="h-full w-full flex items-center justify-center">
        <div className="w-[700px]">
            <CardLayout>
                <div className="min-h-[370px] px-5 py-7 flex  flex-col items-center">
                    <SgemAnimate />
                    <h1 className="text-[40px] font-bold text-center mt-7">Redefinir Senha</h1>
                    <form onSubmit={(e) => enviar(e)} className="flex justify-center flex-col items-center">
                        <div className="flex flex-col justify-start pt-10">
                            <label className="text-[20px] mb-4 pl-1" htmlFor="username"> Username </label>
                            <input
                                id="username"
                                name="username"
                                type="text"
                                className="border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[250px]"
                                value={userName}
                                onChange={(e) =>
                                    setUserName(e.target.value)
                                }
                                required
                                placeholder="digite seu username"
                            />
                        </div>
                        <div className="flex flex-col justify-start pt-2">
                            <label className="text-[20px] mb-4 pl-1" htmlFor="new-password"> Senha </label>
                            <input
                                id="new-passowrd"
                                name="new-passowrd"
                                type="password"
                                className="border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[250px]"
                                value={newPassword}
                                onChange={(e) =>
                                    setNewPassword(e.target.value)
                                }
                                placeholder="nova senha"
                                required
                            />
                        </div>
                        <div className="flex flex-col justify-start pt-2">
                            <label className="text-[20px] mb-4 pl-1" htmlFor="confirm-password">Confirmação da Senha </label>
                            <input
                                id="confirm-password"
                                name="confirm-password"
                                type="password"
                                className="border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[250px]"
                                value={confirmPassword}
                                onChange={(e) =>
                                    setConfirmPassword(e.target.value)
                                }
                                placeholder="confirme a senha"
                                required
                            />
                        </div>
                        <div className="flex w-full justify-center">
                            <Link to={'/login'}>
                                <div className="mt-10 h-[40px] w-[180px] mx-5">
                                    <MainButton
                                        styles={{
                                            style: 'bg-gradient-to-t from-red-500 to-red-700 hover:from-red-600 hover:to-red-800',
                                        }}
                                        type="button"
                                    >

                                        <p className="text-[20px]">Cancelar</p>
                                    </MainButton>
                                </div>
                            </Link>
                            <div className="mt-10 h-[40px] w-[180px] mx-5">
                                <MainButton>
                                    <p className="text-[20px]">Enviar</p>
                                </MainButton>
                            </div>
                        </div>
                    </form>
                </div>
            </CardLayout>
        </div>
    </div>
}