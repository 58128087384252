import React, { useState, useEffect, useCallback } from 'react';
import Table from '../../common/table';
import NoEquipAlert from '../../common/returns/NoEquipAlert';

import apiMaquinaGelo from '../../../services/apis/apiMaquinaGelo';
import apiModelo from '../../../services/apis/apiModelo';
import apiMarca from '../../../services/apis/apiMarca';

const TableMaquinasGelo = ({ regimes }) => {
	const [maquinasGelo, setMaquinasGelo] = useState([]);
	const [maquinasGeloData, setMaquinasGeloData] = useState([]);
	const [modelos, setModelos] = useState([]);
	const [marcas, setMarcas] = useState([]);

	const fetchData = useCallback(async () => {
		try {
			if (!regimes) return;
			const requests = regimes.map((regime) =>
				apiMaquinaGelo.getByRegimeId(regime.id)
			);
			const responses = await Promise.all(requests);
			const maquinasGeloData = responses
				.map((res) => res.data)
				.flat();
			setMaquinasGelo(maquinasGeloData);

			if (maquinasGeloData.length > 0) {
				const models = await Promise.all(
					maquinasGeloData.map((maquinaGelo) => {
						return fetchModelo({
							modeloID: maquinaGelo.modeloId,
						});
					})
				);
				setModelos(models);
				const brands = await Promise.all(
					models.map((model) => {
						return fetchMarca({
							marcaId: model.marcaId,
						});
					})
				);
				setMarcas(brands);
			}
		} catch (error) {
			console.error(
				`Error fetching maquinas de gelo [SobreUnidade]: ${error}`
			);
		}
	}, [regimes]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	useEffect(() => {
		if (
			maquinasGelo.length > 0 &&
			modelos.length > 0 &&
			marcas.length > 0
		) {
			const updatedMaquinasGeloData = maquinasGelo.map(
				(maquinaGelo) => {
					const modelo = modelos.find(
						(model) => model.id === maquinaGelo.modeloId
					);
					const marca = marcas.find(
						(brand) => brand.id === modelo.marcaId
					);
					const regimeName = regimes.find(
						(regime) =>
							regime.id === maquinaGelo.regimeId
					).nome;

					return {
						...maquinaGelo,
						modelo: modelo.nome,
						marca: marca.nome,
						regime: regimeName,
					};
				}
			);
			setMaquinasGeloData(updatedMaquinasGeloData);
		}
	}, [maquinasGelo, modelos, marcas, regimes]);

	return (
		<div className="w-full">
			{maquinasGeloData.length > 0 ? (
				<Table.Root>
					<Table.Head>
						<Table.Header>Nome</Table.Header>
						<Table.Header>Regime</Table.Header>
						<Table.Header>Marca</Table.Header>
						<Table.Header>Modelo</Table.Header>
						<Table.Header>
							Capacidade
							{/* <p className="lowercase px-[1px] text-xs inline">
								(ton/dia)
							</p> */}
						</Table.Header>
						<Table.Header>MASSA DE GELO</Table.Header>
						<Table.Header>
							DEGELO
							{/* <p className="lowercase px-[1px] text-xs inline">
								(min)
							</p> */}
						</Table.Header>
						<Table.Header>
							PRODUÇÃO
							{/* <p className="lowercase px-[1px] text-xs inline">
								(min)
							</p> */}
						</Table.Header>
						<Table.Header>Potencia</Table.Header>
						<Table.Header>Carga Termica</Table.Header>
					</Table.Head>

					<Table.Body>
						{maquinasGeloData.map((maquinaGelo) => (
							<Table.Row key={maquinaGelo.id}>
								<Table.Data>
									{maquinaGelo.nome}
								</Table.Data>
								<Table.Data>
									{maquinaGelo.regime}
								</Table.Data>
								<Table.Data>
									{maquinaGelo.marca}
								</Table.Data>
								<Table.Data>
									{maquinaGelo.modelo}
								</Table.Data>
								<Table.Data>
									{maquinaGelo.capacidadeProducao.toLocaleString(
										'pt-BR',
										{
											maximumFractionDigits: 2,
										}
									) + ' Ton/Dia'}
								</Table.Data>
								<Table.Data>
									{maquinaGelo.massaGeloHora.toLocaleString(
										'pt-BR',
										{
											maximumFractionDigits: 2,
										}
									) + ' Ton/ h'}
								</Table.Data>
								<Table.Data>
									{
										maquinaGelo.tempoProducao
									}
									{' min'}
								</Table.Data>
								<Table.Data>
									{maquinaGelo.tempoDegelo +
										' min'}
								</Table.Data>
								<Table.Data>
									{maquinaGelo.potencia.toLocaleString(
										'pt-BR',
										{
											maximumFractionDigits: 2,
										}
									) + ' CV'}
								</Table.Data>
								<Table.Data>
									{maquinaGelo.cargaTermica.toLocaleString(
										'pt-BR',
										{
											maximumFractionDigits: 2,
										}
									) + ' Kcal/ h'}
								</Table.Data>
							</Table.Row>
						))}
					</Table.Body>
				</Table.Root>
			) : (
				<NoEquipAlert equipments="maquinas de gelo" />
			)}
		</div>
	);
};

const fetchModelo = async ({ modeloID }) => {
	try {
		const res = await apiModelo.getById(modeloID);
		return res.data;
	} catch (error) {
		console.error(`Erro fetching modelo [SobreUnidade]: ${error}`);
	}
};

const fetchMarca = async ({ marcaId }) => {
	try {
		const res = await apiMarca.getById(marcaId);
		return res.data;
	} catch (error) {
		console.error(`Erro fetching marca [SobreUnidade]: ${error}`);
	}
};

export default TableMaquinasGelo;
