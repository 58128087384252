import React, { useEffect, useState, useCallback } from 'react';
import CardLayout from '../../../common/CardLayout';
import StatusOnOff from '../../../common/StatusOnOff';
import InputGeneric from '../../../common/InputGeneric';
import apiLeituraBomba from '../../../../services/apis/Leituras/apiUltimaLeituraBomba';
import apiBomba from '../../../../services/apis/apiBomba';
import SignalService from '../../../../services/SignalService';
import { apiLink } from '../../../../constants/env';

const fetchUltimaLeitura = async (bbID) => {
	try {
		const res = await apiLeituraBomba.fetchApi(bbID);
		return res.data;
	} catch (error) {
		console.error(
			`Erro fetching ultima leitura [Monitoramento Geral - Bombas]: ${error}`
		);
	}
};

export default function StatusCondensadores({
	leituraCondensadores,
	condensacao,
}) {
	const [hubConnection, setHubConnection] = useState();
	const [leituraBB, setLeituraBB] = useState([]);
	const [condensadores, setCondensadores] = useState([]);
	const [bombas, setBombas] = useState([]);
	const [condensadoresData, setCondensadoresData] = useState([]);
	const [bombasData, setBombasData] = useState([]);
	const [leituraAtualBB, setLeituraAtualBB] = useState([]);
	const unidadeId = condensacao?.unidadeId;

	const fetchData = useCallback(async () => {
		if (!unidadeId) return;
		const res = await apiBomba.getByUnidadeId(unidadeId);
		setBombas(res.data);

		if (res.data.length > 0) {
			const leituras = await Promise.all(
				res.data.map((bomba) => {
					return fetchUltimaLeitura(bomba.id);
				})
			);
			setLeituraBB(leituras);
		}
	}, [unidadeId]);

	useEffect(() => {
		setCondensadores(leituraCondensadores);
		fetchData();
	}, [fetchData, leituraCondensadores]);

	useEffect(() => {
		if (condensadores.length > 0) {
			const up = condensadores.map((cd) => {
				const bb = bombas.filter(
					(b) => b.condensadorId === cd.id
				);
				const bbLeitura = bb.map((bomba) => {
					const read = leituraBB.find(
						(l) => l && l.bombaId === bomba.id
					);
					return {
						...bomba,
						leituraBomba: read || {},
					};
				});
				return {
					...cd,
					bombas: bbLeitura,
				};
			});
			setCondensadoresData(up);
		}
	}, [condensadores, leituraBB, bombas]);
	useEffect(() => {
		setHubConnection(
			SignalService.startConnection(`${apiLink}LeituraBombaHub`)
		);
	}, []);

	useEffect(() => {
		if (hubConnection) {
			hubConnection
				.start()
				.then(() => {
					hubConnection.on(
						'AtualizarDadosBomba',
						(message) => {
							console.log(message);
							setLeituraAtualBB(message);
						}
					);
				})
				.catch((err) =>
					console.log(
						'Error while establishing connection [Monitoramento Geral - Bombas]: ',
						err
					)
				);
		}
	}, [hubConnection]);

	useEffect(() => {
		if (leituraAtualBB) {
			const index = condensadoresData.findIndex((condensador) =>
				condensador.bombas.some(
					(bomba) => bomba.id === leituraAtualBB.bombaId
				)
			);
			if (index !== -1) {
				const updatedData = condensadoresData.map(
					(condensador) => {
						if (
							condensador.bombas.some(
								(bomba) =>
									bomba.id ===
									leituraAtualBB.bombaId
							)
						) {
							return {
								...condensador,
								bombas: condensador.bombas.map(
									(bomba) =>
										bomba.id ===
										leituraAtualBB.bombaId
											? {
													...bomba,
													leituraBomba:
														leituraAtualBB,
											  }
											: bomba
								),
							};
						}
						return condensador;
					}
				);
				setBombasData(updatedData);
			} else {
				setBombasData([...condensadoresData]);
			}
		}
	}, [condensadoresData, leituraAtualBB]);

	return (
		<CardLayout>
			<div className="flex flex-wrap w-full gap-1">
				{bombasData.map((condensador) => (
					<div
						key={condensador.id}
						className="flex w-full flex-1 gap-x-1 rounded-md border-[1px] border-gray-700"
					>
						<div className="flex flex-col justify-center items-center bg-accent/20 rounded-bl-md rounded-tl-md pb-[1px]">
							<h2 className="text-lg 2xl:text-2xl font-semibold text-accent drop-shadow-1xl min-w-20 text-center w-full whitespace-nowrap">
								{condensador.nome}
							</h2>
							<StatusOnOff
								status={
									condensador.leitura
										?.ligado
										? condensador
												.leitura
												.ligado
										: false
								}
							/>
						</div>
						{/* divider */}
						<div className="w-px h-full bg-gray-700 -ml-1"></div>
						<div className="flex  justify-center items-center gap-[1px] w-full mr-1 pr-[2px]">
							{condensador.bombas?.length > 0 ? (
								<>
									{condensador.bombas.map(
										(bb) => (
											<div
												key={
													bb.id
												}
												className="flex w-full gap-x-1 mx-auto justify-center items-center"
											>
												<div className="flex flex-col jutify-center items-center w-full px-auto mx-[1px]">
													<p className="text-gray-400 drop-shadow-1xl font-semibold text-center w-full whitespace-nowrap">
														{
															bb.nome
														}
													</p>
													{bb
														.leituraBomba
														?.ligado ? (
														<StatusOnOff
															status={
																bb
																	.leituraBomba
																	.ligado
															}
														/>
													) : (
														<StatusOnOff
															status={
																false
															}
														/>
													)}
												</div>
												{bb
													.leituraBomba
													?.corrente ||
												bb
													.leituraBomba
													?.frequencia ? (
													<div className="flex flex-col w-full px-auto px-2 py-1">
														<div className="flex justify-center items-center text-sm text-secondary drop-shadow-1xl gap-1 h-full">
															{bb
																.leituraBomba
																?.corrente && (
																<>
																	<InputGeneric
																		value={bb.leituraBomba.corrente.toLocaleString(
																			'pt-br',
																			{
																				maximumFractionDigits: 2,
																			}
																		)}
																		type="string"
																		className={
																			'w-12 h-6'
																		}
																	/>
																	<p className="text-xs w-4">
																		A
																	</p>
																</>
															)}
														</div>
														<div className="flex justify-center items-center text-sm text-secondary drop-shadow-1xl gap-1 h-full">
															{bb
																.leituraBomba
																?.frequencia && (
																<>
																	<InputGeneric
																		value={bb.leituraBomba.frequencia.toLocaleString(
																			'pt-br',
																			{
																				maximumFractionDigits: 2,
																			}
																		)}
																		type="string"
																		className={
																			'w-12 h-6'
																		}
																	/>
																	<p className="text-xs w-4">
																		Hz
																	</p>
																</>
															)}
														</div>
													</div>
												) : null}
											</div>
										)
									)}
								</>
							) : (
								<div className="flex w-full h-full justify-center items-center">
									<NoData />
								</div>
							)}
						</div>
					</div>
				))}
			</div>
		</CardLayout>
	);
}

const NoData = () => {
	return (
		<>
			<div className="px-4 text-secondary">-</div>
		</>
	);
};
