import React, { useState, useEffect, useCallback } from 'react';
import StatusOnOff from '../../common/StatusOnOff';
import Table from '../../common/table';
import InputGeneric from '../../common/InputGeneric';

import apiCondensadores from '../../../services/apis/apiCondensadores';
import apiUltimaLeituraCondensador from '../../../services/apis/Leituras/apiUltimaLeituraCondensador';
import apiModelo from '../../../services/apis/apiModelo';
import apiMarca from '../../../services/apis/apiMarca';
import NoEquipAlert from '../../common/returns/NoEquipAlert';

const TableCondensadores = ({ condensacao }) => {
	let condensacaoId = condensacao[0]?.id;
	const [condensadores, setCondensadores] = useState();
	const [modelos, setModelos] = useState([]);
	const [marcas, setMarcas] = useState([]);
	const [condensadoresIds, setCondensadoresIds] = useState([]);
	const [cdLeitura, setCdLeitura] = useState([]);
	const [condensadoresData, setCondensadoresData] = useState([]);

	const fetchData = useCallback(async () => {
		try {
			if (!condensacaoId) return;
			const res = await apiCondensadores.getByRegimeId(
				condensacaoId
			);
			setCondensadores(res.data);
			setCondensadoresIds(
				res.data.map((condensador) => condensador.id)
			);

			if (res.data.length > 0) {
				const leituras = await Promise.all(
					res.data.map((condensador) => {
						return fetchUltimaLeitura({
							cdID: condensador.id,
						});
					})
				);
				setCdLeitura(leituras);

				const models = await Promise.all(
					res.data.map((condensador) => {
						return fetchModelo({
							modeloID: condensador.modeloId,
						});
					})
				);
				setModelos(models);

				const brands = await Promise.all(
					models.map((model) => {
						return fetchMarca({
							marcaId: model.marcaId,
						});
					})
				);
				setMarcas(brands);
			}
		} catch (error) {
			console.error(
				`Erro fetching condensadores [SobreUnidade - Condensadores]: ${error}`
			);
		}
	}, [condensacaoId]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	useEffect(() => {
		if (condensadores) {
			const updatedCondensadorData = condensadores.map(
				(condensador) => {
					const quantidadeTotalJanelas =
						condensador.janelas.reduce(
							(total, janela) =>
								total + janela.quantidade,
							0
						);

					const areaTotalJanelas =
						condensador.janelas.reduce(
							(totalArea, janela) =>
								totalArea +
								janela.altura *
									janela.largura *
									janela.quantidade,
							0
						);

					return {
						...condensador,
						leitura: cdLeitura[
							condensadoresIds.indexOf(
								condensador.id
							)
						],
						modelo: modelos[
							condensadoresIds.indexOf(
								condensador.id
							)
						],
						marca: marcas[
							condensadoresIds.indexOf(
								condensador.id
							)
						],
						quantidadeTotalJanelas,
						areaTotalJanelas,
					};
				}
			);

			setCondensadoresData(updatedCondensadorData);
		}
	}, [condensadores, cdLeitura, condensadoresIds, modelos, marcas]);

	const fetchUltimaLeitura = async ({ cdID }) => {
		try {
			const res = await apiUltimaLeituraCondensador.fetchApi(cdID);
			return res.data;
		} catch (error) {
			console.error(
				`Erro fetching ultima leitura Condensador [SobreUnidade - Condensadores]: ${error}`
			);
		}
	};

	const fetchModelo = async ({ modeloID }) => {
		try {
			const res = await apiModelo.getById(modeloID);
			return res.data;
		} catch (error) {
			console.error(
				`Erro fetching modelo [SobreUnidade - Condensadores]: ${error}`
			);
		}
	};

	const fetchMarca = async ({ marcaId }) => {
		try {
			const res = await apiMarca.getById(marcaId);
			return res.data;
		} catch (error) {
			console.error(
				`Erro fetching marca [SobreUnidade - Condensadores]: ${error}`
			);
		}
	};

	return (
		<>
			<div className="w-full">
				{condensadoresData.length > 0 ? (
					<Table.Root>
						<Table.Head>
							<Table.Header>Nome</Table.Header>
							<Table.Header>Status</Table.Header>
							<Table.Header>Marca</Table.Header>
							<Table.Header>Modelo</Table.Header>
							<Table.Header>
								Janelas
								<span className="text-xs px-[1px] inline lowercase">
									(qtd)
								</span>
							</Table.Header>
							<Table.Header>
								Area{' '}
								<p className="text-xs px-[1px] inline lowercase">
									(Arestas)
								</p>
							</Table.Header>
							<Table.Header>
								Capacidade Nominal
							</Table.Header>
							<Table.Header>
								Capacidade
								<p className="text-xs px-[1px] inline lowercase">
									(Kcal/ h)
								</p>
							</Table.Header>

							<Table.Header>
								TBU admissão
							</Table.Header>
						</Table.Head>
						<Table.Body>
							{condensadoresData.map(
								(condensador) => (
									<Table.Row
										key={condensador.id}
									>
										<Table.Data className="font-bold text-accent">
											{
												condensador.nome
											}
										</Table.Data>
										<Table.Data>
											{condensador.leitura &&
											condensador
												.leitura
												.ligado ? (
												<StatusOnOff
													status={
														condensador
															.leitura
															.ligado
													}
												/>
											) : (
												<StatusOnOff
													status={
														false
													}
												/>
											)}
										</Table.Data>
										<Table.Data>
											{condensador.marca &&
											condensador.marca
												? condensador
														.marca
														.nome
												: '-'}
										</Table.Data>
										<Table.Data>
											{condensador.modelo &&
											condensador.modelo
												? condensador
														.modelo
														.nome
												: '-'}
										</Table.Data>
										<Table.Data>
											{condensador
												.janelas
												.length >
											0
												? condensador.quantidadeTotalJanelas
												: '-'}
										</Table.Data>
										<Table.Data>
											{condensador
												.janelas
												.length >
											0
												? condensador.areaTotalJanelas.toLocaleString(
														'pt-BR',
														{
															maximumFractionDigits: 2,
														}
												  ) +
												  ' m²'
												: '-'}
										</Table.Data>
										<Table.Data>
											{condensador.capacidadeNominal.toLocaleString(
												'pt-BR',
												{
													maximumFractionDigits: 2,
												}
											) +
												' Kcal/ h'}
										</Table.Data>

										<Table.Data>
											{condensador.leitura &&
											condensador
												.leitura
												.capacidadeNominalCorrigida ? (
												<>
													<InputGeneric
														className="max-w-[97px] mx-auto"
														value={condensador.leitura.capacidadeNominalCorrigida.toLocaleString(
															'pt-BR',
															{
																maximumFractionDigits: 2,
															}
														)}
														type="string"
														id={
															'leituraCapCd' +
															condensador.id
														}
														readOnly
													/>
												</>
											) : (
												'-'
											)}
										</Table.Data>
										<Table.Data>
											{condensador.leitura &&
											condensador
												.leitura
												.tbuAdmissao ? (
												<>
													<InputGeneric
														className="max-w-[88px] mx-auto"
														value={
															condensador.leitura.tbuAdmissao.toLocaleString(
																'pt-BR',
																{
																	maximumFractionDigits: 2,
																}
															) +
															' °C'
														}
														type="string"
														id={
															'tbuAdmissao' +
															condensador.id
														}
														readOnly
													/>
												</>
											) : (
												'-'
											)}
										</Table.Data>
									</Table.Row>
								)
							)}
						</Table.Body>
					</Table.Root>
				) : (
					<NoEquipAlert equipments="condensadores" />
				)}
			</div>
		</>
	);
};

export default TableCondensadores;
