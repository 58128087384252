export default function getCurrentUnidadeId() {
    const unidadeId = localStorage.getItem('unId');

    if (unidadeId === null) {
        window.location.href = '/escolha-unidade';
        return null;
    }

    return unidadeId;
}
