import { useState, useEffect, useContext } from 'react';
import { ToastContext } from '../../../../contexts/ToastContext';
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from '../../../../components/ui/dialog';
import {
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
	AlertDialogTrigger,
} from '../../../../components/ui/alert-dialog';
import apiAmbientes from '../../../../services/apis/apiAmbientes';
import { useParams } from 'react-router-dom';
import apiGrupo from '../../../../services/apis/apiGrupo';
import apiEmpresa from '../../../../services/apis/apiEmpresa';
import apiUnidade from '../../../../services/apis/apiUnidade';
import apiRegimes from '../../../../services/apis/apiRegimes';
import PaginationBar from '../../../../components/common/PaginationBar';
import { getParentMap } from '../../../../funcs/getParent';
import apiEnum from '../../../../services/apis/apiEnum';
import apiEvaporador from '../../../../services/apis/apiEvaporador';
import apiMarca from '../../../../services/apis/apiMarca';
import apiModelo from '../../../../services/apis/apiModelo';

export default function ListaEvaporadores({
	atualizarLista,
	pesquisar,
	funcaoModalController,
}) {
	const [nome, setNome] = useState('');
	const [areaDeTroca, setAreaDeTroca] = useState(0);
	const [acionamento, setAcionamento] = useState('');
	const [marcaId, setMarcaId] = useState(0);
	const [modeloId, setModeloId] = useState(0)
	const [grupoId, setGrupoId] = useState(0);
	const [empresaId, setEmpresaId] = useState(0);
	const [unidadeId, setUnidadeId] = useState(0);
	const [ambienteId, setAmbienteId] = useState(0);
	const [tiposAcionamento, setTiposAcionamento] = useState([]);
	const [listaMarcas, setListaMarcas] = useState([]);
	const [listaModelos, setListaModelos] = useState([]);
	const [listaAmbientes, setListaAmbientes] = useState([]);
	const [idEditar, setIdEditar] = useState();
	const [itemRemovido, setItemRemovido] = useState();
	const [idRemover, setIdRemover] = useState();
	const [regimeId, setRegimeId] = useState(0);
	const [listaRegimesTabela, setListaRegimesTabela] = useState(new Map());
	const [listaAmbientesTabela, setListaAmbientesTabela] = useState(new Map());
	const [listaUnidadesTabela, setListaUnidadesTabela] = useState(new Map());
	const [listaEmpresasTabela, setListaEmpresaTabela] = useState(new Map());
	const [listaRegimes, setListaRegimes] = useState([]);
	const [listaUnidades, setListaUnidades] = useState([]);
	const [listaEmpresas, setListaEmpresas] = useState([]);
	const [listaGrupos, setListaGrupos] = useState([]);
	const { page } = useParams();
	const [pagination, setPagination] = useState();
	const [listaEvaporadores, setListaEvaporadores] = useState([]);
	const funcaoModal = funcaoModalController.funcaoModal;

	const { erro, sucesso } = useContext(ToastContext);

	function limparInputs() {
		setNome('');
		setEmpresaId(0);
		setUnidadeId(0);
		setRegimeId(0);
	}
	useEffect(() => {
		apiEvaporador.fetchApi(page).then((res) => {
			setListaEvaporadores(res.data.items);
			setPagination(res.data);
		});
		apiGrupo.fetchApi().then(res => {
			setListaGrupos(res.data);
		});
		apiEnum.getAcionamentos().then(res => {
			setTiposAcionamento(res.data);
		});
		apiMarca.fetchApi().then(res => {
			setListaMarcas(res.data);
		});
	}, [page]);

	useEffect(() => {
		marcaId !== undefined &&
			!isNaN(marcaId) &&
			apiModelo.fetchApi(marcaId, 'Evaporador').then(res => {
				setListaModelos(res.data);
			});
	}, [marcaId]);

	useEffect(() => {
		grupoId !== undefined &&
			!isNaN(grupoId) &&
			apiEmpresa.getByGrupoApi(grupoId).then(res => {
				setListaEmpresas(res.data);
			})
	}, [grupoId]);

	useEffect(() => {
		empresaId !== undefined &&
			!isNaN(empresaId) &&
			apiUnidade.getByEmpresaApi(empresaId).then(res => {
				setListaUnidades(res.data);
			})
	}, [empresaId]);

	useEffect(() => {
		unidadeId !== undefined &&
			!isNaN(unidadeId) &&
			apiRegimes.getByUnidadeId(unidadeId).then(res => {
				setListaRegimes(res.data);
			});
	}, [unidadeId]);

	useEffect(() => {
		regimeId !== undefined &&
			!isNaN(regimeId) &&
			apiAmbientes.getByRegimeId(regimeId).then(res => {
				setListaAmbientes(res.data);
			});
	}, [regimeId]);

	useEffect(() => {
		listaEvaporadores.length > 0 &&
		listaEvaporadores.forEach(elemento => {
			apiAmbientes.getById(elemento.ambienteId).then(res => {
				const ambiente = res.data;
				setListaAmbientesTabela(map => new Map(map.set(ambiente.id, ambiente)));
			});
		});

	}, [listaEvaporadores]);

	useEffect(() => {
		listaAmbientesTabela.size > 0 &&
		listaAmbientesTabela.forEach(elemento => {
			apiRegimes.getById(elemento.regimeId).then(res => {
				const regime = res.data;
				setListaRegimesTabela(map => new Map(map.set(regime.id, regime)));
			});
		});

	}, [listaAmbientesTabela]);

	useEffect(() => {
		listaRegimesTabela.size > 0 &&
		listaRegimesTabela.forEach(elemento => {
			apiUnidade.getById(elemento.unidadeId).then(res => {
				const unidade = res.data;
				setListaUnidadesTabela(map => new Map(map.set(unidade.id, unidade)));
			});
		});
	}, [listaRegimesTabela]);

	useEffect(() => {
		listaUnidadesTabela.size > 0 &&
		listaUnidadesTabela.forEach(elemento => {
			apiEmpresa.getById(elemento.empresaId).then(res => {
				const empresa = res.data;
				setListaEmpresaTabela(map => new Map(map.set(empresa.id, empresa)));
			});
		});
	}, [listaUnidadesTabela]);

	function getParentMapsObjects(id) {
		const ambiente = getParentMap(listaAmbientesTabela, id);
		const regime = getParentMap(listaRegimesTabela, ambiente?.regimeId);
		const unidade = getParentMap(listaUnidadesTabela, regime?.unidadeId);
		const empresa = getParentMap(listaEmpresasTabela, unidade?.empresaId);
		return {
			ambiente: ambiente,
			regime: regime,
			unidade: unidade,
			empresa: empresa,
		}
	}

	//DELETE AMBIENTE
	function deletar(id) {
		apiEvaporador
			.deleteApi(id)
			.then((res) => {
				atualizarLista();
				sucesso('Evaporador deletado com sucesso!');
			})
			.catch((error) => {
				console.error(error.message);
				erro('Erro ao deletar evaporador!');
			});
	}

	function enviar(e) {
		e.preventDefault();

		const evaporador = {
			nome: nome,
			acionamento: acionamento,
			regimeId: parseInt(e.target.regimeId.value),
			areaDeTroca: parseFloat(areaDeTroca),
			modeloId: parseInt(modeloId),
			ambienteId: parseInt(ambienteId)
		};

		if (funcaoModal === 'Adicionar') {
			apiEvaporador
				.postApi(evaporador)
				.then((res) => {
					limparInputs();
					sucesso('Evaporador adicionado com sucesso!');
					atualizarLista();
				})
				.catch((error) => {
					erro('Erro ao adicionar evaporador!');
					console.error(error.message);
				});
		} else if (funcaoModal === 'Editar') {
			apiEvaporador
				.putApi(idEditar, evaporador)
				.then((res) => {
					limparInputs();
					atualizarLista();
					sucesso('Evaporador editado com sucesso!');
				})
				.catch((error) => {
					console.error(error.message);
					erro('Erro ao editar evaporador!');
				});
		}
	}

	return (
		<>
			<Dialog>
				<div className="w-full flex flex-col items-center mt-[40px] md:px-[200px]">
					<table className="w-full">
						<thead className="border-b w-full">
							<tr>
								<th className="text-[25px] pl-[10px] text-start">
									Nome
								</th>
								<th className="text-[25px] pl-[10px] text-start">
									Ambiente
								</th>
								<th className="text-[25px] pl-[10px] text-start">
									Unidade
								</th>
							</tr>
						</thead>
						<tbody>
							{listaEvaporadores.map(
								(evaporador, i) =>
									evaporador.nome
										.toUpperCase()
										.startsWith(
											pesquisar.toUpperCase()
										) && (
										<tr
											className="border-b hover:bg-gray-200 hover:bg-opacity-5"
											key={i}
										>
											<td className="pl-[10px] text-[20px]">
												{
													evaporador.nome
												}
											</td>
											<td className="pl-[10px] text-[20px]">
												{getParentMapsObjects(evaporador.ambienteId)?.ambiente?.nome}
											</td>
											<td className="flex justify-between py-1">
												<span className="text-[20px] pl-[10px]">
													{getParentMapsObjects(evaporador.ambienteId)?.unidade?.nome}
												</span>
												<span className="flex">
													<AlertDialogTrigger>
														<div
															onClick={() => {
																const ambienteId = evaporador.ambienteId;
																const { empresa, unidade, ambiente, regime } = getParentMapsObjects(ambienteId);
																setIdEditar(
																	evaporador.id
																);
																apiModelo.getById(evaporador.modeloId).then(res => {
																	setMarcaId(
																		res.data.marcaId
																	);
																	setModeloId(
																		res.data.id
																	);
																});
																setNome(
																	evaporador.nome
																);
																
																setAreaDeTroca(
																	evaporador.areaDeTroca
																);
																setGrupoId(
																	empresa.grupoId
																);
																setEmpresaId(
																	empresa.id
																);
																setUnidadeId(
																	unidade.id
																);
																setRegimeId(
																	regime.id
																);
																setAmbienteId(
																	ambiente.id
																);
																setAcionamento(
																	evaporador.acionamento.replaceAll("_", " ")
																);
																funcaoModalController.setFuncaoModal(
																	'Editar'
																);
															}}
														>
															<i
																className="fa-solid fa-pen-to-square text-[18px] hover:text-lightBlue"
																title="Editar"
															></i>
														</div>
													</AlertDialogTrigger>
													<DialogTrigger>
														<div
															className="mx-2.5"
															onClick={() => {
																setItemRemovido(
																	evaporador.nome
																);
																setIdRemover(
																	evaporador.id
																);
															}}
														>
															<i
																className="fa-solid fa-trash text-[18px] hover:text-lightBlue"
																title="Deletar"
															></i>
														</div>
													</DialogTrigger>
												</span>
											</td>
										</tr>
									)
							)}
						</tbody>
					</table>
					{
						pagination !== undefined &&
						<PaginationBar pagination={pagination} />
					}
				</div>

				<DialogContent>
					<DialogHeader>
						<DialogTitle>
							Excluir {itemRemovido}?
						</DialogTitle>
					</DialogHeader>
					<DialogDescription>
						Todos os dados associados com {itemRemovido}{' '}
						serão removidos.
					</DialogDescription>
					<DialogFooter>
						<div
							className="bg-red-600/70 py-2 px-4 rounded-md hover:bg-red-600 hover:cursor-pointer border-slate-800 border-2 transition-all duration-200 ease-in-out"
							onClick={() => deletar(idRemover)}
						>
							<DialogClose>Excluir</DialogClose>
						</div>
					</DialogFooter>
				</DialogContent>
			</Dialog>

			<AlertDialogContent>
				<form onSubmit={(e) => enviar(e)}>
					<AlertDialogHeader>
						<AlertDialogTitle>
							{funcaoModal} Evaporador
						</AlertDialogTitle>
					</AlertDialogHeader>
					<div className="w-full max-h-[500px] flex justify-around my-5 px-5 flex-wrap gap-5 overflow-x-hidden overflow-y-auto scrollbar-thin scrollbar-track-primary scrollbar-thumb-darkaccent">
						{/* NOME */}
						<div className="flex flex-col justify-end">
							<label
								className="text-[18px] mb-2 pl-1"
								htmlFor="nome"
							>
								Nome
							</label>
							<input
								id="nome"
								name="nome"
								type="text"
								placeholder="nome do evaporador"
								className="border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[180px]"
								value={nome}
								onChange={(e) =>
									setNome(e.target.value)
								}
								required
							/>
						</div>
						{/* AREA DE TROCA */}
						<div className="flex flex-col justify-end">
							<label
								className="text-[16px] mb-2 pl-1"
								htmlFor="areaDeTroca"
							>
								Área de troca
							</label>
							<input
								id="areaDeTroca"
								name="areaDeTroca"
								type="number"
								className="border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[180px]"
								value={areaDeTroca}
								onChange={(e) =>
									setAreaDeTroca(
										e.target.value
									)
								}
								required
							/>
						</div>

						{/* ACIONAMENTO */}
						<div className="flex flex-col justify-end">
							<label
								className="text-[18px] mb-2 pl-1"
								htmlFor="acionamento"
							>
								Acionamento
							</label>
							<select
								className="border-b text-[20px] h-[42.8px] bg-[#0b101d] rounded-t-md w-[180px]"
								name="acionamento"
								id="acionamento"
								required
								value={acionamento}
								onChange={(e) =>
									setAcionamento(
										e.target.value
									)
								}
							>
								<option label="   "></option>
								{
									tiposAcionamento.map((tipo, i) => (
										<option key={i} value={tipo}>{tipo}</option>
									))
								}
							</select>
						</div>
						{/* MARCA */}
						<div className="flex flex-col justify-end">
							<label
								className="text-[18px] mb-2 pl-1"
								htmlFor="marcaId"
							>
								Marca
							</label>
							<select
								className="border-b text-[20px] h-[42.8px] bg-[#0b101d] rounded-t-md w-[180px]"
								name="marcaId"
								id="marcaId"
								required
								value={marcaId}
								onChange={(e) =>
									setMarcaId(
										parseInt(
											e.target.value
										)
									)
								}
							>
								<option label="   "></option>
								{listaMarcas.map(
									(marca, i) => (
										<option
											value={
												marca.id
											}
											key={i}
										>
											{marca.nome}
										</option>
									)
								)}
							</select>
						</div>
						{/* MODELO */}
						<div className="flex flex-col justify-end">
							<label
								className="text-[18px] mb-2 pl-1"
								htmlFor="modeloId"
							>
								Modelo
							</label>
							<select
								className="border-b text-[20px] h-[42.8px] bg-[#0b101d] rounded-t-md w-[180px]"
								name="modeloId"
								id="modeloId"
								required
								value={modeloId}
								onChange={(e) =>
									setModeloId(
										parseInt(
											e.target.value
										)
									)
								}
							>
								<option label="   "></option>
								{listaModelos.map(
									(modelo, i) => (
										<option
											value={
												modelo.id
											}
											key={i}
										>
											{modelo.nome}
										</option>
									)
								)}
							</select>
						</div>
						{/* GRUPOS */}
						<div className="flex flex-col justify-end">
							<label
								className="text-[18px] mb-2 pl-1"
								htmlFor="grupoId"
							>
								Grupo
							</label>
							<select
								className="border-b text-[20px] h-[42.8px] bg-[#0b101d] rounded-t-md w-[180px]"
								name="grupoId"
								id="grupoId"
								required
								value={grupoId}
								onChange={(e) =>
									setGrupoId(
										parseInt(
											e.target.value
										)
									)
								}
							>
								<option label="   "></option>
								{listaGrupos.map(
									(grupo, i) => (
										<option
											value={
												grupo.id
											}
											key={i}
										>
											{grupo.nome}
										</option>
									)
								)}
							</select>
						</div>
						{/* EMPRESAS */}
						<div className="flex flex-col justify-end">
							<label
								className="text-[18px] mb-2 pl-1"
								htmlFor="empresaId"
							>
								Empresa
							</label>
							<select
								className="border-b text-[20px] h-[42.8px] bg-[#0b101d] rounded-t-md w-[180px]"
								name="empresaId"
								id="empresaId"
								required
								value={empresaId}
								onChange={(e) =>
									setEmpresaId(
										parseInt(
											e.target.value
										)
									)
								}
							>
								<option label="   "></option>
								{listaEmpresas.map(
									(empresa, i) => (
										<option
											value={
												empresa.id
											}
											key={i}
										>
											{empresa.nome}
										</option>
									)
								)}
							</select>
						</div>
						{/* UNIDADES */}
						<div className="flex flex-col justify-end">
							<label
								className="text-[18px] mb-2 pl-1"
								htmlFor="unidadeId"
							>
								Unidade
							</label>
							<select
								className="border-b text-[20px] h-[42.8px] bg-[#0b101d] rounded-t-md w-[180px]"
								name="unidadeId"
								id="unidadeId"
								required
								value={unidadeId}
								onChange={(e) =>
									setUnidadeId(
										parseInt(
											e.target.value
										)
									)
								}
							>
								<option label="   "></option>
								{listaUnidades
									.filter(
										(elemento) =>
											elemento.empresaId ===
											empresaId
									)
									.map((unidade, i) => (
										<option
											value={
												unidade.id
											}
											key={i}
										>
											{unidade.nome}
										</option>
									))}
							</select>
						</div>
						{/* REGIME ID */}
						<div className="flex flex-col justify-end">
							<label
								className="text-[18px] mb-2 pl-1"
								htmlFor="regimeId"
							>
								Regime
							</label>
							<select
								className="border-b text-[20px] h-[42.8px] bg-[#0b101d] rounded-t-md w-[180px]"
								name="regimeId"
								id="regimeId"
								required
								value={regimeId}
								onChange={(e) =>
									setRegimeId(
										parseInt(
											e.target.value
										)
									)
								}
							>
								<option label="   "></option>
								{listaRegimes
									.map((regime, i) => (
										<option
											value={
												regime.id
											}
											key={i}
										>
											{regime.nome}
										</option>
									))}
							</select>
						</div>
						{/* AMBIENTE */}
						<div className="flex flex-col justify-end">
							<label
								className="text-[18px] mb-2 pl-1"
								htmlFor="ambienteId"
							>
								Ambiente
							</label>
							<select
								className="border-b text-[20px] h-[42.8px] bg-[#0b101d] rounded-t-md w-[180px]"
								name="ambienteId"
								id="ambienteId"
								required
								value={ambienteId}
								onChange={(e) =>
									setAmbienteId(
										parseInt(
											e.target.value
										)
									)
								}
							>
								<option label="   "></option>
								{listaAmbientes
									.map((ambiente, i) => (
										<option
											value={
												ambiente.id
											}
											key={i}
										>
											{ambiente.nome}
										</option>
									))}
							</select>
						</div>
					</div>
					<AlertDialogFooter>
						<div className="flex w-full justify-end gap-x-4 p-4">
							<AlertDialogAction className="w-32">
								Confirmar
							</AlertDialogAction>
							<AlertDialogCancel className="w-32">
								Cancelar
							</AlertDialogCancel>
						</div>
					</AlertDialogFooter>
				</form>
			</AlertDialogContent>
		</>
	);
}
