import React, { useState } from 'react';
import { FaEdit } from 'react-icons/fa';
import { IoIosLock } from 'react-icons/io';

const EditIcon = ({ status, side = 'right' }) => {
	const [showDescription, setShowDescription] = useState(false);
	const parentClass = side === 'left' ? 'left-16' : 'right-16';
	const childClass =
		side === 'left'
			? '-left-[11px] rotate-180 top-[50%] -translate-y-[50%]'
			: '-right-[11px] top-[40%] -translate-y-[60%]';

	const statusData = [
		{
			type: 'unlock',
			description: 'Editar',
			icon: (
				<FaEdit className="text-accent h-6 w-6 cursor-pointer" />
			),
		},
		{
			type: 'lock',
			description:
				'Para editar, você precisa ser um admin do sistema.',
			icon: (
				<IoIosLock className="text-yellow-400/20 h-6 w-6 cursor-pointer" />
			),
		},
	];

	const currentStatus = statusData.find((item) => item.type === status);

	if (!currentStatus) return null;

	const { description, icon } = currentStatus;
	return (
		<div className="z-100">
			<div
				className="relative flex justify-center items-center"
				onMouseEnter={() => setShowDescription(true)}
				onMouseLeave={() => setShowDescription(false)}
			>
				{icon}
				{showDescription && (
					<div
						className={`${parentClass} absolute z-100 inline-block p-2.5 text-center text-sm font-semibold drop-shadow-1xl text-white transition-opacity duration-300 bg-gray-600 rounded-md shadow min-w-40`}
					>
						<span>{description}</span>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 24 24"
							className={`${childClass} absolute drop-shadow h-6 w-auto text-gray-600`}
							fill="currentColor"
						>
							<path d="M11.303 8l11.394 7.997L11.303 24z" />
						</svg>
					</div>
				)}
			</div>
		</div>
	);
};

export default EditIcon;
