function Indutor({ fill = '#000' }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 36 36"
			fill={fill}
		>
			<g>
				<path
					d="M24.31 25.81c-1.75 0-3-2.49-3-6a12.79 12.79 0 011.72-6.7 2.57 2.57 0 00-3.79 0A12.79 12.79 0 0121 19.76c0 3.56-1.23 6-3 6s-3-2.49-3-6a12.79 12.79 0 011.72-6.7 2.57 2.57 0 00-3.79 0 12.79 12.79 0 011.72 6.7c0 3.56-1.23 6-3 6s-3-2.49-3-6a12.88 12.88 0 011.71-6.7 2.7 2.7 0 00-1.89-.87C7.1 12.19 5.69 13.7 5 16l-.23.7H2a1 1 0 010-2h1.29c1.1-2.83 3.06-4.55 5.24-4.55a4.67 4.67 0 013.16 1.32 4.62 4.62 0 013.15-1.32A4.65 4.65 0 0118 11.51a4.43 4.43 0 016.31 0 4.67 4.67 0 013.16-1.32c2.18 0 4.14 1.72 5.24 4.55H34a1 1 0 010 2h-2.72l-.23-.7c-.74-2.34-2.15-3.85-3.58-3.85a2.7 2.7 0 00-1.89.87 12.88 12.88 0 011.71 6.7c0 3.56-1.22 6.05-2.98 6.05zM18 14.93a11.71 11.71 0 00-1 4.83c0 2.54.66 3.75 1 4 .32-.27 1-1.48 1-4a11.71 11.71 0 00-1-4.83zm6.31 0a11.71 11.71 0 00-1 4.83c0 2.54.66 3.75 1 4 .32-.27 1-1.48 1-4a11.71 11.71 0 00-1-4.83zm-12.62 0a11.71 11.71 0 00-1 4.83c0 2.54.66 3.75 1 4 .32-.27 1-1.48 1-4a11.71 11.71 0 00-1-4.83z"
					className="clr-i-outline clr-i-outline-path-1"
				></path>
				<path fillOpacity="0" d="M0 0H36V36H0z"></path>
			</g>
		</svg>
	);
}

export default Indutor;
