import { useContext, useEffect, useState } from 'react';
import apiGrupo from '../../../../services/apis/apiGrupo';
import { ToastContext } from '../../../../contexts/ToastContext';
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from '../../../../components/ui/dialog';

import {
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
	AlertDialogTrigger,
} from '../../../../components/ui/alert-dialog';
import PaginationBar from '../../../../components/common/PaginationBar';
import { useParams } from 'react-router-dom';

export default function ListaGrupos({
	atualizarLista,
	pesquisar,
	funcaoModalController,
}) {
	const [listaGrupos, setListaGrupos] = useState([]);
	const [idEditar, setIdEditar] = useState();
	const [nome, setNome] = useState('');
	const [itemRemovido, setItemRemovido] = useState('');
	const [idRemover, setIdRemover] = useState();
	const { erro, sucesso } = useContext(ToastContext);
	const {page} = useParams();
	const [pagination, setPagination] = useState();
	const funcaoModal = funcaoModalController.funcaoModal;

	//GET GRUPO
	useEffect(() => {
		apiGrupo.pagination(page).then((res) => {
			setListaGrupos(res.data.items);
			setPagination(res.data);
		});
	}, [page]);

	//DELETE GRUPO
	function deletar(id) {
		apiGrupo
			.deleteApi(id)
			.then((res) => {
				sucesso('Grupo deletado com sucesso!');
				atualizarLista();
			})
			.catch((error) => {
				console.error(error.message);
				erro('Erro ao deletar grupo!');
			});
	}

	function enviar(e) {
		e.preventDefault();

		const grupo = {
			nome: e.target.nome.value,
		};

		if (funcaoModal === 'Adicionar') {
			apiGrupo
				.postApi(grupo)
				.then((res) => {
					atualizarLista();
					setNome('');
					sucesso('Grupo adicionado com sucesso!');
				})
				.catch((error) => {
					erro('Erro ao adicionar grupo!');
					console.error(error.message);
				});
		} else if (funcaoModal === 'Editar') {
			apiGrupo
				.putApi(idEditar, grupo)
				.then((res) => {
					sucesso('Grupo editado com sucesso!');
					atualizarLista();
				})
				.catch((error) => {
					console.error(error.message);
					erro('Erro ao editar grupo!');
				});
		}
	}

	return (
		<>
			<Dialog>
				{/* LISTA */}
				<div className="w-full flex flex-col items-center mt-[40px] md:px-[200px]">
					<table className="table-auto w-full">
						<thead className="flex border-b">
							<tr>
								<th className="text-[25px] pl-[10px]">
									Nome
								</th>
							</tr>
						</thead>
						<tbody>
							{listaGrupos.map(
								(grupo, i) =>
									grupo.nome
										.toUpperCase()
										.startsWith(
											pesquisar.toUpperCase()
										) && (
										<tr
											className="border-b"
											key={i}
										>
											<td className="flex justify-between py-1 hover:bg-gray-200 hover:bg-opacity-5">
												<span className="text-[20px] pl-[10px]">
													{
														grupo.nome
													}
												</span>
												<span className="flex">
													<AlertDialogTrigger>
														<div
															onClick={() => {
																setIdEditar(
																	grupo.id
																);
																setNome(
																	grupo.nome
																);
																funcaoModalController.setFuncaoModal(
																	'Editar'
																);
															}}
														>
															<i
																className="fa-solid fa-pen-to-square text-[18px] hover:text-lightBlue"
																title="Editar"
															></i>
														</div>
													</AlertDialogTrigger>
													<DialogTrigger>
														<div
															className="mx-2.5"
															onClick={() => {
																setItemRemovido(
																	grupo.nome
																);
																setIdRemover(
																	grupo.id
																);
															}}
														>
															<i
																className="fa-solid fa-trash text-[18px] hover:text-lightBlue"
																title="Deletar"
															></i>
														</div>
													</DialogTrigger>
												</span>
											</td>
										</tr>
									)
							)}
						</tbody>
					</table>
				{
				pagination !== undefined &&
				<PaginationBar pagination={pagination}/>
				}
				</div>
				<DialogContent>
					<DialogHeader>
						<DialogTitle>
							Excluir {itemRemovido} ?
						</DialogTitle>
					</DialogHeader>
					<DialogDescription>
						Todos os dados associados com {itemRemovido}{' '}
						serão removidos.
					</DialogDescription>
					<DialogFooter>
						<div
							className="bg-red-600/70 py-2 px-4 rounded-md hover:bg-red-600 hover:cursor-pointer border-slate-800 border-2 transition-all duration-200 ease-in-out"
							onClick={() => deletar(idRemover)}
						>
							<DialogClose>Excluir</DialogClose>
						</div>
					</DialogFooter>
				</DialogContent>
			</Dialog>

			<AlertDialogContent>
				<form onSubmit={(e) => enviar(e)}>
					<AlertDialogHeader>
						<AlertDialogTitle>
							{funcaoModal} Grupo
						</AlertDialogTitle>
					</AlertDialogHeader>
					<div className="w-full flex justify-center my-5">
						<div className="flex flex-col">
							<label
								htmlFor="nome"
								className="text-[18px] mb-2 pl-1"
							>
								Nome
							</label>
							<input
								id="nome"
								className="border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[200px]"
								type="text"
								placeholder="nome do grupo"
								value={nome}
								onChange={(e) =>
									setNome(e.target.value)
								}
								required
							/>
						</div>
					</div>
					<AlertDialogFooter>
						<div className="flex w-full justify-end gap-x-4 p-4">
							<AlertDialogAction className="w-32">
								Confirmar
							</AlertDialogAction>
							<AlertDialogCancel className="w-32">
								Cancelar
							</AlertDialogCancel>
						</div>
					</AlertDialogFooter>
				</form>
			</AlertDialogContent>
		</>
	);
}
