import { apiLink } from '../../constants/env';
import axiosInstance from '../AxiosInstance';

const apiUnidade = {
    fetchApi: async (page) => {
        const URL = `${apiLink}api/unidade?page=` + page;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();

            })
    },

    getById: async (id) => {
        const URL = `${apiLink}api/unidade/` + id;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();

            })
    },
    postApi: async (unidade) => {
        const URL = `${apiLink}api/Unidade`;
        return await axiosInstance.post(URL, unidade)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();
            })
    },
    deleteApi: async (id) => {
        const URL = `${apiLink}api/unidade/status/${id}`;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();

            })
    },
    putApi: async (id, unidade) => {
        const URL = `${apiLink}api/unidade/${id}`;
        return await axiosInstance.put(URL, unidade)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();

            })
    },
    getByEmpresaApi: async (id) => {
        const URL = `${apiLink}api/unidade/empresa/${id}`;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();

            })
    }

}

export default apiUnidade;

