import React from 'react';

const CardLayout = ({ children, alarmed }) => {
	return (
		<div
			className={`bg-darkaccent/60 text-white  rounded-md border-[1px] border-gray-800/40 shadow-2xl p-2 w-full ${
				alarmed ? ' animate-warning ' : ''
			}`}
		>
			{children}
		</div>
	);
};

export default CardLayout;
