import { useState, useRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FaSquareArrowUpRight } from 'react-icons/fa6';
import { SidebarData, SidebarDataAdmin } from '../../constants';

const Sidebar = () => {
	const [sidebarData, setSidebarData] = useState(SidebarData);
	const [isAdminPage, setIsAdminPage] = useState(false);
	const pathname = useLocation().pathname;

	useEffect(() => {
		if (pathname.includes('/admin')) {
			setSidebarData(SidebarDataAdmin);

			setIsAdminPage(true);
		} else {
			setSidebarData(SidebarData);
			setIsAdminPage(false);
		}
	}, [pathname]);

	const isActive = (href) => {
		return pathname === href;
	};
	const [isOpen, setIsOpen] = useState(false);
	const sidebarRef = useRef(null);
	const [isMobile, setIsMobile] = useState(
		typeof window !== 'undefined' ? window.innerWidth < 768 : false
	);

	const handleClickOutside = (event) => {
		if (isAdminPage === false) {
			if (
				sidebarRef.current &&
				!sidebarRef.current.contains(event.target)
			) {
				setIsOpen(false);
			}
		}
	};

	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	});

	useEffect(() => {
		const updateIsOpen = () => {
			setIsOpen(isMobile ? false : true);
		};

		updateIsOpen();

		return () => {
			window.removeEventListener('resize', updateIsOpen);
		};
	}, [isMobile]);

	useEffect(() => {
		const initialCheck = () => {
			setIsMobile(window.innerWidth < 768);
		};

		window.addEventListener('resize', initialCheck);

		initialCheck();

		window.removeEventListener('resize', initialCheck);
	}, []);

	return (
		<nav
			className={` flex flex-col items-center space-y-4 pt-3 fixed top-[60px] left-0 rounded-sm transition-all duration-300 z-40 ${
				isOpen
					? 'w-[11rem] h-full pb-16 overflow-y-auto overflow-x-hidden scrollbar-thin scrollbar-track-primary scrollbar-thumb-darkaccent shadow-1xl bg-darkaccent'
					: 'w-12 h-[0px] bg-transparent'
			}`}
			aria-label="Sidebar"
			ref={sidebarRef}
		>
			{/* Navbar */}
			<div className="flex text-accent/20 hover:text-accent relative text-center py-8 pr-2">
				<FaSquareArrowUpRight
					className={`w-6 h-6 lg:h-8 lg:w-8 cursor-pointer absolute mx-auto transition-all duration-200 ${
						!isOpen ? 'rotate-45' : 'rotate-[225deg]'
					}`}
					onClick={() => setIsOpen(!isOpen)}
				/>
			</div>
			{/* Nav items */}
			<ul
				className={`w-full pt-5 flex flex-col  gap-y-0 lg:gap-y-1 text-whitetwo text-sm md:text-md transition-all duration-200 ${
					isOpen ? '' : 'pr-3 mr-1'
				}`}
			>
				{sidebarData.map((category) => (
					<div key={category._categoryId}>
						<li
							className={`pl-4 text-white/20 text-center font-semi uppercase tracking-widest ${
								isOpen ? '' : 'hidden'
							}`}
						>
							{category.category}
						</li>
						{category.items.map((item, index) => (
							<li
								key={index}
								className={`w-full flex items-center gap-x-1.5 group py-1 select-none ${
									isOpen ? 'py-0' : 'py-1'
								}`}
							>
								<div
									className={`pr-1 w-1 rounded-xl h-8 bg-transparent transition-all duration-200 relative overflow-hidden ${
										isOpen ? '' : 'px-1'
									}`}
								>
									<div
										className={`absolute top-0 left-0 w-full h-[110%] translate-y-0 group-hover:translate-y-0 bg-accent transition-all duration-300 ${
											isActive(
												item.href
											)
												? 'translate-y-0'
												: 'translate-y-full'
										}`}
									/>
								</div>
								<Link to={item.href}>
									<div
										className={` group-active:scale-95 self-stretch px-3 py-1 rounded flex items-center space-x-3 transition-all duration-200 dark:group-hover:text-white dark:hover:text-white ${
											isOpen
												? 'w-40 group-hover:bg-primary'
												: 'lg:w-12 w-11 bg-accent/10'
										}`}
									>
										<div className="text-accent/20 group-hover:text-accent transition-colors duration-200">
											{item.icon}
										</div>

										<span
											className={`capitalize pl-0 sm:pl-2 text-sm lg:text-base transition-all duration-200 ${
												isOpen
													? 'inline-block translate-x-0 group-hover:translate-x-1'
													: 'hidden'
											}`}
										>
											{item.title}
										</span>
									</div>
								</Link>
							</li>
						))}
					</div>
				))}
			</ul>
		</nav>
	);
};

export default Sidebar;
