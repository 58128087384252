import { apiLink } from '../../constants/env';
import axiosInstance from '../AxiosInstance';

const apiAlarme = {
    fetchApi: async (id) => {
        const URL = `${apiLink}api/alarme/alarmes-ativos/` + id;
        return await axiosInstance.get(URL)
        .then((res) => res)
        .catch(error => {
            console.error('Erro ao buscar os dados:', error);
            throw new Error(error);

        })
    },
    
}

export default apiAlarme;

