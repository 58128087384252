import { apiLink } from '../../../constants/env';
import axiosInstance from '../../AxiosInstance';

const apiUltimaLeituraCompressor = {
    fetchApi: async (compressorId) => {
        const URL = `${apiLink}api/leitura-compressor/ultima-leitura?compressorId=${compressorId}`;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar a última leitura compressor');

            })
    }
}

export default apiUltimaLeituraCompressor;
