import { apiLink } from '../../constants/env';
import axiosInstance from '../AxiosInstance';

const apiEmpresa = {
    fetchApi: async (page) => {
        const URL = `${apiLink}api/empresa?page=` + page;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();

            })
    },
    getById: async (id) => {
        const URL = `${apiLink}api/empresa/` + id;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();

            })
    },
    postApi: async (empresa) => {
        const URL = `${apiLink}api/empresa`;
        return await axiosInstance.post(URL, empresa)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();

            })
    },
    deleteApi: async (id) => {
        const URL = `${apiLink}api/empresa/status/${id}`;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();

            })
    },
    putApi: async (id, empresa) => {
        const URL = `${apiLink}api/empresa/${id}`;
        return await axiosInstance.put(URL, empresa)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();

            })
    },
    getByGrupoApi: async (id) => {
        const URL = `${apiLink}api/empresa/grupo/${id}`;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();

            })
    }

}

export default apiEmpresa;

