import { useCallback, useContext, useEffect, useState } from 'react';
import apiUsuario from '../../../../services/apis/apiUsuarios';
import { ToastContext } from '../../../../contexts/ToastContext';
import { AuthContext } from '../../../../contexts/AuthContext';
import {
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
	AlertDialogTrigger,
} from '../../../../components/ui/alert-dialog';
import apiAtuth from '../../../../services/apis/apiAuth';
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from '../../../../components/ui/dialog';
import { useParams } from 'react-router-dom';
import PaginationBar from '../../../../components/common/PaginationBar';
import apiGrupo from '../../../../services/apis/apiGrupo';
import apiEmpresa from '../../../../services/apis/apiEmpresa';
import apiUnidade from '../../../../services/apis/apiUnidade';

export default function ListaUsuarios({
	atualizarLista,
	pesquisar,
	funcaoModalController,
}) {
	const [idEditar, setIdEditar] = useState('');
	const [itemRemovido, setItemRemovido] = useState('');
	const [idRemover, setIdRemover] = useState();
	const [listaUsuarios, setListaUsuarios] = useState([]);
	const [grupoIdRequired, setGrupoIdRequired] = useState(true);
	const [empresaIdRequired, setEmpresaIdRequired] = useState(true);
	const [unidadeIdRequired, setUnidadeIdRequired] = useState(true);
	const [listaGrupos, setListaGrupos] = useState([]);
	const [listaEmpresas, setListaEmpresas] = useState([]);
	const [listaUnidades, setListaUnidades] = useState([]);
	const [isLoading, setIsLoading] = useState(true);
	
	const [nome, setNome] = useState('');
	const [sobrenome, setSobrenome] = useState('');
	const [cargo, setCargo] = useState('');
	const [grupoId, setGrupoId] = useState(0);
	const [empresaId, setEmpresaId] = useState(0);
	const [unidadeId, setUnidadeId] = useState(0);
	const [userName, setUserName] = useState('');
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [confirmPassword, setConfirmPassword] = useState('');
	const { page } = useParams();
	const [pagination, setPagination] = useState();
	const funcaoModal = funcaoModalController.funcaoModal;
	
	const [cargoUsuario, setCargoUsuario] = useState(false);
	
	const { sucesso, erro } = useContext(ToastContext);
	const { verificarCargo } = useContext(AuthContext);
	
	//GET USUARIOS
	const fetchGetUsuarios = useCallback(() => {
		if(!isLoading) {
			apiUsuario.fetchApi(page).then((res) => {
				let usuarioFiltrado;
				setPagination(res.data);
				if (!cargoUsuario) {
					usuarioFiltrado = res.data.items.filter(
						(usuario) =>
							usuario.cargo === 'BASIC_USER' ||
							usuario.cargo === 'ADMIN_UNIDADE'
					);
				} else {
					usuarioFiltrado = res.data.items;
				}
				setListaUsuarios(usuarioFiltrado);
			});
		}
	}, [cargoUsuario, page, isLoading]);

	useEffect(() => {
		verificarCargo('SUPER_USER').then((res) => {
			setCargoUsuario(res);
			setIsLoading(false);
		}).finally(() => {
			fetchGetUsuarios();
		});
		
	}, [verificarCargo, fetchGetUsuarios]);
	
	useEffect(() => {
		apiGrupo.fetchApi().then(res => setListaGrupos(res.data));
	}, []);
	useEffect(() => {
		apiEmpresa.getByGrupoApi(grupoId).then(res => setListaEmpresas(res.data));
	}, [grupoId])
	useEffect(() => {
		apiUnidade.getByEmpresaApi(empresaId).then(res => setListaUnidades(res.data));
	}, [empresaId])
	
	function apagarInputs() {
		setNome('');
		setSobrenome('');
		setUserName('');
		setEmail('');
		setPassword('');
		setConfirmPassword('');
	}

	useEffect(() => {
		const inputGrupo = document.getElementById('input-grupo');
		const inputEmpresa = document.getElementById('input-empresa');
		const inputUnidade = document.getElementById('input-unidade');

		if (
			cargo === 'CESS_USER' ||
			cargo === 'SUPER_USER' ||
			cargo === 'ADMIN' ||
			cargo === ''
		) {
			setGrupoIdRequired(false);
			inputGrupo?.classList.add('hidden');
			setEmpresaIdRequired(false);
			inputEmpresa?.classList.add('hidden');
			setUnidadeIdRequired(false);
			inputUnidade?.classList.add('hidden');
		} else if (cargo === 'BASIC_USER' || cargo === 'ADMIN_UNIDADE') {
			inputGrupo?.classList.remove('hidden');
			inputEmpresa?.classList.remove('hidden');
			inputUnidade?.classList.remove('hidden');
			setGrupoIdRequired(true);
			setEmpresaIdRequired(true);
			setUnidadeIdRequired(true);
		} else if (cargo === 'DIRETOR') {
			inputGrupo?.classList.remove('hidden');
			inputEmpresa?.classList.remove('hidden');
			inputUnidade?.classList.add('hidden');
			setGrupoIdRequired(true);
			setEmpresaIdRequired(true);
			setUnidadeIdRequired(false);
		}
	}, [cargo]);


	//DELETE USUARIO
	function deletar(id) {
		apiUsuario
			.deleteApi(id)
			.then((res) => {
				atualizarLista();
				sucesso('Usuário deletado com sucesso!');
			})
			.catch((error) => {
				erro('Erro ao deletar usuário!');
			});
	}

	function enviar(e) {
		e.preventDefault();

		let usuario;
		if (
			cargo === 'CESS_USER' ||
			cargo === 'SUPER_USER' ||
			cargo === 'ADMIN' ||
			cargo === ''
		) {
			usuario = {
				nome: e.target.nome.value,
				sobrenome: e.target.sobrenome.value,
				userName: e.target?.userName?.value,
				email: e.target?.email?.value,
				password: e.target?.password?.value,
				cargo: e.target.cargo.value,
			};
		} else if (cargo === 'BASIC_USER' || cargo === 'ADMIN_UNIDADE') {
			usuario = {
				nome: e.target.nome.value,
				sobrenome: e.target.sobrenome.value,
				userName: e.target?.userName?.value,
				email: e.target?.email?.value,
				password: e.target?.password?.value,
				cargo: e.target.cargo.value,
				unidadeId: parseInt(e.target.unidadeId.value),
			};
		} else if (cargo === 'DIRETOR') {
			if (empresaId === 0) {
				usuario = {
					nome: e.target.nome.value,
					sobrenome: e.target.sobrenome.value,
					userName: e.target?.userName?.value,
					email: e.target?.email?.value,
					password: e.target?.password?.value,
					cargo: e.target.cargo.value,
					grupoId: grupoId,
				};
			} else {
				usuario = {
					nome: e.target.nome.value,
					sobrenome: e.target.sobrenome.value,
					userName: e.target?.userName?.value,
					email: e.target?.email?.value,
					password: e.target?.password?.value,
					cargo: e.target.cargo.value,
					empresaId: empresaId,
				};
			}
		}
		if (funcaoModal === 'Adicionar') {
			apiAtuth
				.register(usuario)
				.then((res) => {
					apagarInputs();
					atualizarLista();
					sucesso('Usuário cadastrado com sucesso!');
				})
				.catch((error) => {
					erro('Erro ao cadastrar usuário!');
				});
		} else if (funcaoModal === 'Editar') {
			apiUsuario
				.putApi(idEditar, usuario)
				.then((res) => {
					atualizarLista();
					sucesso('Usuário editado com sucesso!');
				})
				.catch((error) => {
					console.error(error.message);
					erro('Erro ao editar usuário!');
				});
		}
	}

	function pegarIdGrupo(id) {
		try {
			const index = listaEmpresas.findIndex(
				(elemento) => elemento.id === id
			);
			return listaEmpresas[index].grupoId;
		} catch (error) {
			return 0;
		}
	}

	function pegarIdEmpresa(id) {
		try {
			const index = listaUnidades.findIndex(
				(elemento) => elemento.id === id
			);
			return listaUnidades[index].empresaId;
		} catch (error) {
			return 0;
		}
	}

	if(isLoading) {
		return null;
	}

	return (

		<>
			<Dialog>
				<div className="w-full flex flex-col items-center mt-[40px] md:px-[200px]">
					<table className="w-full">
						<thead className="border-b w-full">
							<tr>
								<th className="text-[25px] pl-[10px] text-start">
									Nome
								</th>
							</tr>
						</thead>
						<tbody>
							{listaUsuarios.map(
								(usuario, i) =>
									usuario.nome
										.toUpperCase()
										.startsWith(
											pesquisar.toUpperCase()
										) && (
										<tr
											className="border-b hover:bg-gray-200 hover:bg-opacity-5"
											key={i}
										>
											<td className="flex justify-between py-1">
												<span className="text-[20px] pl-[10px]">
													{usuario.nome +
														' ' +
														usuario.sobrenome}
												</span>
												<span className="flex">
													<AlertDialogTrigger>
														<div
															onClick={() => {
																funcaoModalController.setFuncaoModal(
																	'Editar'
																);
																setIdEditar(
																	usuario.id
																);
																setNome(
																	usuario.nome
																);
																setSobrenome(
																	usuario.sobrenome
																);
																setCargo(
																	usuario.cargo
																);

																const grupoIdUsuario =
																	usuario.grupoId;
																const empresaIdUsuario =
																	usuario.empresaId;
																const unidadeIdUsuario =
																	usuario.unidadeId;

																if (
																	typeof grupoIdUsuario ===
																	'number'
																) {
																	setGrupoId(
																		grupoIdUsuario
																	);
																} else if (
																	typeof empresaIdUsuario ==
																	'number'
																) {
																	setGrupoId(
																		pegarIdGrupo(
																			empresaIdUsuario
																		)
																	);
																	setEmpresaId(
																		empresaIdUsuario
																	);
																} else if (
																	typeof unidadeIdUsuario ===
																	'number'
																) {
																	setGrupoId(
																		pegarIdGrupo(
																			pegarIdEmpresa(
																				unidadeIdUsuario
																			)
																		)
																	);
																	setEmpresaId(
																		pegarIdEmpresa(
																			unidadeIdUsuario
																		)
																	);
																	setUnidadeId(
																		unidadeIdUsuario
																	);
																}
															}}
														>
															<i
																className="fa-solid fa-pen-to-square text-[18px] hover:text-lightBlue"
																title="Editar"
															></i>
														</div>
													</AlertDialogTrigger>
													<DialogTrigger>
														<div
															className="mx-2.5"
															onClick={() => {
																setItemRemovido(
																	usuario.nome
																);
																setIdRemover(
																	usuario.id
																);
															}}
														>
															<i
																className="fa-solid fa-trash text-[18px] hover:text-lightBlue"
																title="Deletar"
															></i>
														</div>
													</DialogTrigger>
												</span>
											</td>
										</tr>
									)
							)}
						</tbody>
					</table>
					{
						pagination !== undefined &&
						<PaginationBar pagination={pagination} />
					}
				</div>
				<DialogContent>
					<DialogHeader>
						<DialogTitle>
							Excluir {itemRemovido}?
						</DialogTitle>
					</DialogHeader>
					<DialogDescription>
						Todos os dados associados com {itemRemovido}{' '}
						serão removidos.
					</DialogDescription>
					<DialogFooter>
						<div
							className="bg-red-600/70 py-2 px-4 rounded-md hover:bg-red-600 hover:cursor-pointer border-slate-800 border-2 transition-all duration-200 ease-in-out"
							onClick={() => deletar(idRemover)}
						>
							<DialogClose>Excluir</DialogClose>
						</div>
					</DialogFooter>
				</DialogContent>
			</Dialog>

			<AlertDialogContent>
				<form onSubmit={(e) => enviar(e)}>
					<AlertDialogHeader>
						<AlertDialogTitle>
							{funcaoModal} Usuário
						</AlertDialogTitle>
					</AlertDialogHeader>
					<div className="w-full flex justify-around my-5 px-5 flex-wrap gap-5">
						{/* NOME */}
						<div className="flex flex-col">
							<label
								className="text-[18px] mb-2 pl-1"
								htmlFor="nome"
							>
								Nome
							</label>
							<input
								id="nome"
								nome="nome"
								type="text"
								placeholder="nome do usuário"
								className="border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[180px]"
								value={nome}
								onChange={(e) =>
									setNome(e.target.value)
								}
								required
							/>
						</div>
						{/* SOBRENOME */}
						<div className="flex flex-col">
							<label
								className="text-[18px] mb-2 pl-1"
								htmlFor="sobrenome"
							>
								Sobrenome
							</label>
							<input
								id="sobrenome"
								name="sobrenome"
								type="text"
								placeholder="sobrenome do usuário"
								className="border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[180px]"
								value={sobrenome}
								onChange={(e) =>
									setSobrenome(
										e.target.value
									)
								}
								required
							/>
						</div>
						{funcaoModal === 'Adicionar' && (
							<>
								{/* USERNAME */}
								<div className="flex flex-col">
									<label
										className="text-[18px] mb-2 pl-1"
										htmlFor="userName"
									>
										Username
									</label>
									<input
										id="userName"
										name="userName"
										type="text"
										placeholder="username do usuário"
										className="border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[180px]"
										value={userName}
										onChange={(e) =>
											setUserName(
												e.target
													.value
											)
										}
										required
									/>
								</div>
								{/* EMAIL */}
								<div className="flex flex-col">
									<label
										className="text-[18px] mb-2 pl-1"
										htmlFor="email"
									>
										Email
									</label>
									<input
										id="email"
										name="email"
										type="text"
										placeholder="email do usuário"
										className="border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[180px]"
										value={email}
										onChange={(e) =>
											setEmail(
												e.target
													.value
											)
										}
										required
									/>
								</div>
								{/* PASSWORD */}
								<div className="flex flex-col">
									<label
										className="text-[18px] mb-2 pl-1"
										htmlFor="password"
									>
										Senha
									</label>
									<input
										id="password"
										name="password"
										type="password"
										placeholder="senha do usuário"
										className="border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[180px]"
										value={password}
										onChange={(e) =>
											setPassword(
												e.target
													.value
											)
										}
										required
									/>
								</div>
								{/*CONFIRMAR PASSWORD */}
								<div className="flex flex-col">
									<label
										className="text-[18px] mb-2 pl-1"
										htmlFor="confirmPassword"
									>
										Confirmar senha
									</label>
									<input
										id="confirmPassword"
										name="confirmPassword"
										type="password"
										placeholder="confirme senha do usuário"
										className="border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[180px]"
										value={
											confirmPassword
										}
										onChange={(e) =>
											setConfirmPassword(
												e.target
													.value
											)
										}
										required
									/>
								</div>
							</>
						)}
						{/* CARGO */}
						<div className="flex flex-col">
							<label
								className="text-[18px] mb-2 pl-1"
								htmlFor="cargo"
							>
								Cargo
							</label>
							<select
								className="border-b text-[20px] h-[42.8px] bg-[#0b101d] rounded-t-md w-[180px]"
								name="cargo"
								id="cargo"
								value={cargo}
								required
								onChange={(e) =>
									setCargo(e.target.value)
								}
							>
								<option label="  "></option>
								{cargoUsuario && (
									<>
										<option
											value={
												'SUPER_USER'
											}
										>
											Super Usuário
										</option>
										<option
											value={
												'ADMIN'
											}
										>
											Administrador
										</option>
										<option
											value={
												'DIRETOR'
											}
										>
											Diretor
										</option>
										<option
											value={
												'CESS_USER'
											}
										>
											Usuário da
											CESS
										</option>
									</>
								)}
								<option value={'ADMIN_UNIDADE'}>
									Administrador da Unidade
								</option>
								<option value={'BASIC_USER'}>
									Usuário
								</option>
							</select>
						</div>
						{/* GRUPO ID */}
						<div
							id="input-grupo"
							className={`flex flex-col  ${!grupoIdRequired && 'hidden'
								}`}
						>
							<label
								className="text-[18px] mb-2 pl-1"
								htmlFor="grupoId"
							>
								Grupo{' '}
							</label>
							<select
								className="border-b text-[20px] h-[42.8px] bg-[#0b101d] rounded-t-md w-[180px]"
								name="grupoId"
								id="grupoId"
								value={grupoId}
								required={grupoIdRequired}
								onChange={(e) =>
									setGrupoId(
										parseInt(
											e.target.value
										)
									)
								}
							>
								<option label="  "></option>
								{listaGrupos.map((grupo, i) => (
									<option
										value={grupo.id}
										key={i}
									>
										{grupo.nome}
									</option>
								))}
							</select>
						</div>
						{/* EMPRESA ID */}
						<div
							id="input-empresa"
							className={`flex flex-col ${!empresaIdRequired && 'hidden'
								}`}
						>
							<label
								className="text-[18px] mb-2 pl-1"
								htmlFor="empresaId"
							>
								Empresa{' '}
							</label>
							<select
								className="border-b text-[20px] h-[42.8px] bg-[#0b101d] rounded-t-md w-[180px]"
								name="empresaId"
								id="empresaId"
								value={empresaId}
								required={empresaIdRequired}
								onChange={(e) =>
									setEmpresaId(
										parseInt(
											e.target.value
										)
									)
								}
							>
								<option label="  "></option>
								{cargo === 'DIRETOR' && (
									<option value={'0'}>
										É Diretor de grupo
									</option>
								)}
								{listaEmpresas
									.map((empresa, i) => (
										<option
											value={
												empresa.id
											}
											key={i}
										>
											{empresa.nome}
										</option>
									))}
							</select>
						</div>

						{/* UNIDADE ID */}
						<div
							id="input-unidade"
							className={`flex flex-col ${!unidadeIdRequired && 'hidden'
								}`}
						>
							<label
								className="text-[18px] mb-2 pl-1"
								htmlFor="unidadeId"
							>
								Unidade{' '}
							</label>
							<select
								className="border-b text-[20px] h-[42.8px] bg-[#0b101d] rounded-t-md w-[180px]"
								name="unidadeId"
								id="unidadeId"
								value={unidadeId}
								required={unidadeIdRequired}
								onChange={(e) =>
									setUnidadeId(
										parseInt(
											e.target.value
										)
									)
								}
							>
								<option label="  "></option>
								{listaUnidades
									.map((unidade, i) => (
										<option
											value={
												unidade.id
											}
											key={i}
										>
											{unidade.nome}
										</option>
									))}
							</select>
						</div>
					</div>
					<AlertDialogFooter>
						<div className="flex w-full justify-end gap-x-4 p-4">
							<AlertDialogAction className="w-32">
								Confirmar
							</AlertDialogAction>
							<AlertDialogCancel className="w-32">
								Cancelar
							</AlertDialogCancel>
						</div>
					</AlertDialogFooter>
				</form>
			</AlertDialogContent>
		</>
	);
}
