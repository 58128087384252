import { apiLink } from '../../../constants/env';
import axiosInstance from '../../AxiosInstance';

const apiUltimaLeituraChiller = {
    fetchApi: async (chillerId) => {
        const URL = `${apiLink}api/leitura-chiller/ultima-leitura?chillerId=${chillerId}`;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar a última leitura do chiller');

            })
    }
}

export default apiUltimaLeituraChiller;
