import { HubConnectionBuilder } from '@microsoft/signalr';

class SignalRService {
  constructor() {
    this.connection = null;
  }

  startConnection(hubUrl) {
    this.connection = new HubConnectionBuilder()
      .withUrl(hubUrl)
      .withAutomaticReconnect()
      .build();

    return this.connection;
  }

  on(eventName, callback) {
    if (this.connection) {
      this.connection.on(eventName, callback);
    }
  }

  async sendMessage(eventName, message) {
    if (this.connection) {
      await this.connection.invoke(eventName, message);
    }
  }

  async stopConnection() {
    if (this.connection && this.connection.state === 'Connected') {
      await this.connection.stop();
    }
  }
}
const signalRService = new SignalRService();
export default signalRService;
