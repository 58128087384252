import Chart from 'react-apexcharts';
import NoDataAlert from '../../common/returns/NoDataAlert';

import { colorsPallete } from '../../../constants';

const colorsArray = colorsPallete.map((item) => item.color);

const ChartDonutDefault = ({ chartDataProps, typeChart }) => {
	if (!chartDataProps) {
		return <NoDataAlert />;
	}
	let data = [];

	if (typeChart === 'capacidadeDisponivel') {
		data = chartDataProps.reduce(
			(valorInicial, regime) =>
				valorInicial.concat(regime.capacidadeDisponivel),
			[]
		);
	} else if (typeChart === 'capacidadeNecessaria') {
		data = chartDataProps.reduce(
			(valorInicial, regime) =>
				valorInicial.concat(regime.capacidadeNecessaria),
			[]
		);
	}

	const nomes = chartDataProps.reduce(
		(valorInicial, regime) => valorInicial.concat(regime.nome),
		[]
	);

	const options = {
		chart: {
			id: 'basic-donut',
			type: 'donut',
			width: '100%',
			height: 380,
			stacked: true,
		},
		formatter: function (val) {
			return val.toLocaleString(
				'pt-BR',
				{
					maximumFractionDigits: 2,
				}
			);
		},
		colors: colorsArray,
		dataLabels: {
			formatter: function (val) {
				return val.toLocaleString(
					'pt-BR',
					{
						maximumFractionDigits: 2,
					}
				) + '%';
			},
		},
		plotOptions: {
			formatter: function (val) {
				return val.toLocaleString(
					'pt-BR',
					{
						maximumFractionDigits: 2,
					}
				);
			},
			pie: {
				formatter: function (val) {
					return val.toLocaleString(
						'pt-BR',
						{
							maximumFractionDigits: 2,
						}
					);
				},
				dataLabels: {
					offset: 0,
					minAngleToShowLabel: 10,
					fontSize: '20px',
					formatter: function (val) {
						return val.toLocaleString(
							'pt-BR',
							{
								maximumFractionDigits: 2,
							}
						);
					},

				},
				donut: {
					formatter: function (val) {
						return val.toLocaleString(
							'pt-BR',
							{
								maximumFractionDigits: 2,
							}
						);
					},
					size: '60%',
					background: 'transparent',
					labels: {
						show: false,
						name: {
							show: true,
							fontSize: '16px',
							fontWeight: 'bold',
							color: undefined,
							offsetY: -10,
							formatter: function (val) {
								return val.toLocaleString(
									'pt-BR',
									{
										maximumFractionDigits: 2,
									}
								);
							},
						},
						formatter: function (val) {
							return val.toLocaleString(
								'pt-BR',
								{
									maximumFractionDigits: 2,
								}
							);
						},
						value: {
							show: true,
							fontSize: '18px',
							color: 'white',
							offsetY: 0,
							formatter: function (val) {
								return val.toLocaleString(
									'pt-BR',
									{
										maximumFractionDigits: 2,
									}
								);
							},

						},
						total: {
							show: false,
							showAlways: false,
							color: 'white',
							fontSize: '16px',
							fontWeight: 'bold',
							formatter: function (w) {
								const series = w.globals.series;
								const totalValue =
									series.reduce(
										(
											acc,
											currentValue
										) =>
											acc +
											currentValue,
										0
									);
								const formattedTotal =
									totalValue.toLocaleString(
										'pt-BR',
										{
											maximumFractionDigits: 1,
										}
									) + ' Kcal/ h';

								return formattedTotal;
							},
						},
					},
				},
				startAngle: -90,
				endAngle: 270,
			},
		},
		tooltip: {
			theme: 'dark',
			shared: true,
			intersect: false,
			y: {
				show: true,
				formatter: function (value) {
					var formattedValue = new Intl.NumberFormat(
						'pt-BR'
					).format(value.toFixed(2));
					return formattedValue;
				},
			},
		},
		labels: nomes,
		fill: {
			type: 'gradient',
			gradient: {
				shade: 'light',
				type: '',
				shadeIntensity: 0.1,
				gradientToColors: undefined,
				inverseColors: true,
			},
		},
		stroke: {
			show: true,
			width: 4,
			colors: '#0F162A',
		},
		legend: {
			show: false,
			position: 'bottom',
			horizontalAlign: 'center',
			fontSize: '14px',
			fontWeight: 'bold',
			labels: {
				colors: 'white',
			},
			itemMargin: {
				horizontal: 5,
				vertical: 0,
			},
			onItemClick: {
				toggleDataSeries: false,
				formatter: function (val) {
					return val.toLocaleString(
						'pt-BR',
						{
							maximumFractionDigits: 2,
						}
					);
				},
			},
			onItemHover: {
				highlightDataSeries: false,
			},
	
			floating: false,
		},
	};

	return (
		<div className="bg-transparent w-full h-full m-auto py-6">
			<Chart
				options={options}
				type="donut"
				series={data}
				width="100%"
				height={300}
			/>
		</div>
	);
};

export default ChartDonutDefault;
