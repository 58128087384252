import React from 'react';

const PulseWarning = ({ classSize = 'h-2 w-2', active = false }) => {
	if (!active) return <></>;
	const size = classSize;
	return (
		<span
			className={`animate-ping inline-block rounded-full ring-2 ring-alarm/40 bg-alarm mx-2 ${size}`}
		></span>
	);
};

export default PulseWarning;
