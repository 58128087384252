import { apiLink } from '../../constants/env';
import axiosInstance from '../AxiosInstance';

const apiModeloCompressor = {
    fetchApi: async (page) => {
        const URL = `${apiLink}api/modelo-compressor?page=` + page;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();
            })
    },
    getById: async (id) => {
        const URL = `${apiLink}api/modelo-compressor/` + id;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();
            })
    },
    getByMarcaId: async (id) => {
        const URL = `${apiLink}api/modelo-compressor/marca/` + id;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();
            })
    },
    postApi: async (modeloCompressor) => {
        const URL = `${apiLink}api/modelo-compressor`;
        return await axiosInstance.post(URL, modeloCompressor)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();

            })
    },
    deleteApi: async (id) => {
        const URL = `${apiLink}api/modelo-compressor/status/${id}`;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();

            })
    },
    putApi: async (id, modeloCompressor) => {
        const URL = `${apiLink}api/modelo-compressor/${id}`;
        return await axiosInstance.put(URL, modeloCompressor)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();
            })
    }

}

export default apiModeloCompressor;

