import React, { useCallback, useEffect, useState } from 'react';
import CardLayout from '../common/CardLayout';
import NoEquipAlert from '../common/returns/NoEquipAlert';
import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from '../../components/ui/accordion';

import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '../ui/select';

import Table from '../common/table';
import SwitchOnOff from '../common/SwitchOnOff';
import TitleCard from './TitleCard';
import apiModelo from '../../services/apis/apiModelo';
import apiCondensadores from '../../services/apis/apiCondensadores';

export default function ListBombas({ regimes, setBombas, bombas }) {
	const [modelosBomba, setModelosBomba] = useState(new Map());
	const [condensadores, setCondensadores] = useState(new Map());

	const handleStatus = (handleSwitch) => {
		const { equipamento } = handleSwitch;
		const status = !equipamento.status;
		setBombas((prevBombas) => {
			const novoBombas = [
				...prevBombas.map((bm) => {
					if (bm.id === equipamento.id) {
						return { ...bm, status: status };
					}
					return bm;
				}),
			];
			return novoBombas;
		});
	};

	const trocarRegimeEquipamento = useCallback(
		(bomba, novoRegimeId) => {
			const index = bombas.findIndex((a) => a.id === bomba.id);
			const newBombas = [...bombas];
			newBombas[index].regimeId = novoRegimeId;
			setBombas(newBombas);
		},
		[bombas, setBombas]
	);

	useEffect(() => {
		bombas.length > 0 &&
			bombas.forEach((bomba) => {
				apiModelo.getById(bomba.modeloId).then((res) => {
					const modelo = res.data;
					setModelosBomba(
						(map) => new Map(map.set(modelo.id, modelo))
					);
				});
			});
	}, [bombas]);

	useEffect(() => {
		bombas.length > 0 &&
			bombas.forEach((bomba) => {
				apiCondensadores
					.getById(bomba.condensadorId)
					.then((res) => {
						const condensador = res.data;
						setCondensadores(
							(map) =>
								new Map(
									map.set(
										condensador.id,
										condensador
									)
								)
						);
					});
			});
	}, [bombas]);

	const getCondensadorById = useCallback(
		(id) => {
			return condensadores.get(id);
		},
		[condensadores]
	);

	function getModeloById(id) {
		return modelosBomba.get(id);
	}

	return (
		<CardLayout>
			<Accordion
				type="single"
				defaultValue="item-1"
				collapsible="true"
			>
				<AccordionItem value="item-1">
					<AccordionTrigger>
						<TitleCard>Lista de Bombas</TitleCard>
					</AccordionTrigger>
					<AccordionContent>
						<div className="w-full">
							<Table.Root>
								<Table.Head>
									<Table.Header>
										Nome
									</Table.Header>
									<Table.Header>
										Status
									</Table.Header>
									{/* <Table.Header>
										Regime
									</Table.Header> */}
									<Table.Header>
										Modelo
									</Table.Header>
									<Table.Header>
										Equipamento
									</Table.Header>
									<Table.Header>
										Potência
									</Table.Header>
								</Table.Head>
								<Table.Body>
									{bombas.map((bomba) => (
										<Table.Row
											key={bomba.id}
										>
											<Table.Data>
												{
													bomba.nome
												}
											</Table.Data>
											<Table.Data>
												<SwitchOnOff
													status="on"
													handleSwitch={{
														handleSwitch:
															handleStatus,
														equipamento:
															bomba,
													}}
												/>
											</Table.Data>
											{/* <Table.Data>
												{
													condensadores.size > 0 &&
													<Select
														id="regimeSAGSelect"
														defaultValue={
															getCondensadorById(bomba.condensadorId)?.regimeId
														}
														onValueChange={(value) => trocarRegimeEquipamento(bomba, value)}
													>
														<SelectTrigger className="w-full">
															<SelectValue placeholder="Regime" />
														</SelectTrigger>
														<SelectContent>
														{
															regimes.map((regime, i) => (
															<SelectItem key={regime.id}
																value={
																	regime.id
																}
															>
																{regime.nome}
															</SelectItem>
															))
														}
														</SelectContent>
													</Select>
												}
												</Table.Data> */}
											<Table.Data className="px-auto">
												{
													getModeloById(
														bomba.modeloId
													)
														?.nome
												}
											</Table.Data>
											<Table.Data>
												{
													getCondensadorById(
														bomba.condensadorId
													)
														?.nome
												}
											</Table.Data>
											<Table.Data>
												{bomba.potenciaNominal +
													' CV'}
											</Table.Data>
										</Table.Row>
									))}
									{bombas.length === 0 && (
										<Table.Row className="text-center ">
											<th
												scope="col"
												colSpan="5"
												className="text-center"
											>
												<NoEquipAlert equipments="bombas" />
											</th>
										</Table.Row>
									)}
								</Table.Body>
							</Table.Root>
						</div>
					</AccordionContent>
				</AccordionItem>
			</Accordion>
		</CardLayout>
	);
}
