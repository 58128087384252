import React, { useEffect, useState, useCallback } from 'react';
import CardLayout from '../../components/common/CardLayout';
import ChartBarDefault from '../../components/simulacao-unidade/charts/ChartBarDefault';
import ChartDonutDefault from '../../components/simulacao-unidade/charts/ChartDonutDefault';
import TableCapacidades from '../../components/simulacao-unidade/TableCapacidades';
import ListRegimes from '../../components/simulacao-unidade/ListRegimes';
import ListAmbientes from '../../components/simulacao-unidade/ListAmbientes';
import ListMaquinasGelo from '../../components/simulacao-unidade/ListMaquinasGelo';
import ListSistemasAgua from '../../components/simulacao-unidade/ListSistemasAgua';
import ListBombas from '../../components/simulacao-unidade/ListBombas';

import { colorsPallete } from '../../constants';
import Condensacao from '../../components/simulacao-unidade/Condensacao';
import getCurrentUnidadeId from '../../funcs/getCurrentUnidadeId';

import apiRegimes from '../../services/apis/apiRegimes';
import apiUltimaLeituraRegime from '../../services/apis/Leituras/apiUltimaLeituraRegime';
import { getPctg } from '../../funcs';
import apiBombas from '../../services/apis/apiBomba';
import apiVentilador from '../../services/apis/apiVentilador';
import apiUnidade from '../../services/apis/apiUnidade';

export default function SimulacaoUnidade() {
	const UNIDADE = getCurrentUnidadeId();
	const [unidade, setUnidade] = useState({});
	const [regimes, setRegimes] = useState();
	const [condensacao, setCondensacao] = useState();
	const [allRegimes, setAllRegimes] = useState([]);
	const [regimesLeituras, setRegimesLeituras] = useState([]);
	const [regimeData, setRegimeData] = useState([]);
	const [condensacaoData, setCondensacaoData] = useState([]);
	const [ListaRegimes, SetListaRegimes] = useState([]);
	const [capacidadesRegimes, setCapacidadesRegimes] = useState([]);
	const [bombas, setBombas] = useState([]); 
	const [ventiladores, setVentiladores] = useState([]);
	const [pressaoReferenciaRegime, setPressaoReferenciaRegime] = useState([
		{ id: 0, pressaoReferencia: 0 },
	]);

	const fetchData = useCallback(async () => {
		try {
			const res = await apiRegimes.getByUnidadeId(UNIDADE);
			const tempCondensacao = res.data.filter(
				(regime) => regime.tipoRegime === 'Condensação'
			);
			tempCondensacao[0].pressaoReferencia = 12.5;
			setCondensacao(tempCondensacao?.length && tempCondensacao);
			
			res.data.length &&
				setRegimes(
					res.data.filter(
						(regime) => regime.tipoRegime !== 'Condensação'
					)
				);

			res.data.length && 
			setCapacidadesRegimes(
				res.data.map(regime => {
					return {
						nome:regime.nome,
						tipoRegime:regime.tipoRegime,
						regimeId:regime.id,
						capacidadeNecessaria:0,
						capacidadeDisponivel:0,
						potencia:0,
						compressores:new Map(),
						ambientes:[],
						sistemasAgua:[],
						maquinasGelo:[],
						condensadores:[],
						pressaoReferencia:regime.pressaoReferencia,
					}
				}
			));
			setAllRegimes(res.data.map(regime => {
				return {
					...regime,
				}
			}));

			const leituras =
				res.data.length &&
				(await Promise.all(
					res.data.map((regime) => {
						return fetchUltimaLeituraRegime({
							regimeId: regime.id,
						});
					})
				));

			setRegimesLeituras(leituras);

			const resUnidade = await apiUnidade.getById(UNIDADE);
			setUnidade(resUnidade.data);
		} catch (error) {
			console.error(error.message);
		}
	}, [UNIDADE]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	useEffect(() => {
		let indexColor = 0;
		if (regimes) {
			const updateRegimeData = regimes.map((regime) => {
				return {
					...regime,
					leitura: regimesLeituras.find(
						(leitura) => leitura.regimeId === regime.id
					),
					regimeColor: colorsPallete[indexColor++],
				};
			});

			setRegimeData(updateRegimeData);
		}
		if (condensacao) {
			const updateCondensacao = condensacao.map((regime) => {
				return {
					...regime,
					tbu: 26,
					temperatura: 30,
				};
			});
			setCondensacaoData(updateCondensacao);
		}
	}, [regimes, regimesLeituras, condensacao]);

	const fetchUltimaLeituraRegime = async ({ regimeId }) => {
		try {
			const res = await apiUltimaLeituraRegime.fetchApi(regimeId);
			return res.data;
		} catch (error) {
			console.error(error.message);
		}
	};
	
	useEffect(() => {
		setBombas([]);
		apiBombas
		.getByUnidadeId(UNIDADE)
		.then( res => {
			const regimeCondensacao = allRegimes.find(regime => regime.tipoRegime === 'Condensação')?.id;
			setBombas(res.data.map(bomba => {
				return {...bomba, status:true, regimeId: regimeCondensacao}
			}));
		});
		setVentiladores([]);
		apiVentilador
		.getByUnidadeId(UNIDADE)
		.then( res => {
			setVentiladores(res.data);
		});
	},[UNIDADE, allRegimes]);


	useEffect(() => {
		SetListaRegimes(allRegimes);
	}, [allRegimes]);

	useEffect(() => {
		
		setCapacidadesRegimes(current => {
			let aux = [...current];
			aux = aux.map(capacidade => {
				let cargaNecessaria = 0;
				let cargaDisponivel = 0;
				let potencia = 0;
				
				const compressoresArray = Array.from(capacidade.compressores.values());
				const todosCompressores = aux.reduce((prevValue, regime) => prevValue.concat(Array.from(regime.compressores.values())), []);
				potencia += Object.values(compressoresArray).filter(compressor => compressor.status).reduce((t, {potenciaRealCalculada, capacidade}) => t + getPctg(potenciaRealCalculada, capacidade), 0);

				if(capacidade.tipoRegime === 'RI') {
					const compressoresQueDescarregamNesseRI = todosCompressores.filter(cp => cp.status && cp.regimeDescargaId === capacidade.regimeId);
					const somaCapacidadeCompressoresRiId = compressoresQueDescarregamNesseRI.reduce((t, {capacidadeRealCalculada, capacidade}) => t + getPctg(capacidadeRealCalculada, capacidade), 0);
					const somaPotenciasCompressores = compressoresQueDescarregamNesseRI.reduce((t, {potenciaRealCalculada, capacidade}) => t + getPctg(potenciaRealCalculada, capacidade), 0);
					cargaNecessaria += (somaPotenciasCompressores * 860) + somaCapacidadeCompressoresRiId;
				} 
				
				cargaNecessaria += Object.values(capacidade.ambientes.filter(amb => amb.status)).reduce((t, {cargaTermica}) => t + cargaTermica, 0);
				cargaNecessaria += Object.values(capacidade.sistemasAgua).filter(sa => sa.status).reduce((t, {cargaTermica}) => t + cargaTermica, 0);
				cargaNecessaria += Object.values(capacidade.maquinasGelo).filter(mg => mg.status).reduce((t, {cargaTermica}) => t + cargaTermica, 0);
				cargaDisponivel += Object.values(capacidade.condensadores).filter(cd => cd.status).reduce((t, {cargaTermica, capacidade}) => t + getPctg(cargaTermica, capacidade), 0);
				cargaDisponivel +=  Object.values(compressoresArray.filter(cp => cp.status)).reduce((t, cp) => t + getPctg(cp.capacidadeRealCalculada, cp.capacidade), 0);
				
				
				if(capacidade.tipoRegime === 'Condensação') {
					const somaPotenciasCompressores = todosCompressores.filter(cp => cp.status).reduce((prevValue, compressor) => prevValue + getPctg(compressor.potenciaRealCalculada, compressor.capacidade), 0);
					const somaCapacidadeRegimeDescargaCondensacao = todosCompressores.filter(cp => cp.status && cp.regimeDescargaId === capacidade.regimeId).reduce((prevValue, compressor) => prevValue + getPctg(compressor.capacidadeRealCalculada, compressor.capacidade), 0);
					cargaNecessaria += (somaPotenciasCompressores * 860) + somaCapacidadeRegimeDescargaCondensacao;

					potencia += Object.values(bombas).filter(bomba => bomba.status).reduce((t, {potenciaNominal}) => t + potenciaNominal, 0);
					const todosVentiladores = aux.reduce((prevValue, regime) => prevValue.concat(regime.condensadores.reduce((prevValue, condensador) => prevValue.concat(condensador.ventiladores), [])), []);
					potencia += Object.values(todosVentiladores).reduce((t, {potenciaNominal}) => t + potenciaNominal, 0);
					
				}
				
				return {
					...capacidade,
					capacidadeNecessaria: cargaNecessaria,
					capacidadeDisponivel: cargaDisponivel,
					potencia:potencia,
				}                
			});
			if(JSON.stringify(aux) !== JSON.stringify(current)) {
				return aux;
			}
			return current;
		});
	},[capacidadesRegimes, bombas]);

	return (
		<>
			<div className="flex flex-col pb-16">
				{/* Gráficos de Consumo e Potência */}
				<div className="flex flex-col lg:flex-row gap-4 py-2">
					<CardLayout>
						<ChartBarDefault
							typeChart="potenciaskW"
							nameSerie="Potencias por Regime ( kW )"
							chartDataProps={capacidadesRegimes}
						/>
					</CardLayout>
					<CardLayout>
						<ChartBarDefault
							typeChart="consumokWh"
							nameSerie="Consumo por Regime ( kWh )"
							chartDataProps={capacidadesRegimes}
							unidade={unidade}
						/>
					</CardLayout>
				</div>
				{/* Tabela e Gráficos comparativo de capacidade necessária x disponível */}
				<div className="flex flex-col lg:flex-row gap-4 py-2">
					<CardLayout>
						<TableCapacidades 
						tableContents={capacidadesRegimes}
						/>
					</CardLayout>
					<CardLayout>
						<div className="flex flex-col justify-center items-center">
							<h1 className="py-2 px-auto md:text-2xl text-lg font-semibold text-accent drop-shadow-1xl text-center">
								Capacidade Necessaria
							</h1>
							<ChartDonutDefault 
							typeChart="capacidadeNecessaria" 
							chartDataProps={capacidadesRegimes}
							/>
						</div>
					</CardLayout>
					<CardLayout>
						<div className="flex flex-col justify-center items-center">
							<h1 className="py-2 px-auto md:text-2xl text-lg font-semibold text-accent drop-shadow-1xl text-center">
								Capacidade Disponível
							</h1>
							<ChartDonutDefault typeChart="capacidadeDisponivel" 
							chartDataProps={capacidadesRegimes}
							/>
						</div>
					</CardLayout>
				</div>
				{/* Lista de Regimes */}
				<div className="flex flex-col gap-4 py-2">
					<ListRegimes
						Regimes={regimeData}
						allRegimes={allRegimes}
						ListaRegimes={ListaRegimes}
						capacidadesRegimes={capacidadesRegimes}
						setCapacidadesRegimes={setCapacidadesRegimes}
						pressaoReferenciaRegime={pressaoReferenciaRegime}
						setPressaoReferenciaRegime={setPressaoReferenciaRegime}
					/>
				</div>
				{/* Dados de Condensação e Condensadores */}
				<div className="flex flex-col gap-4 py-2">
					<Condensacao
						Condensacao={
							condensacaoData.length > 0 &&
							condensacaoData[0]
						}
						bombas={
							bombas.filter(bomba => bomba.tipoEquipamento === "Condensador")
						}
						ventiladores={
							ventiladores.filter(ventilador => ventilador.tipoEquipamento === "Condensador")
						}
						setCapacidadesRegimes={
							setCapacidadesRegimes
						}
						capacidadesRegimes={
							capacidadesRegimes
						}
						pressaoReferenciaRegime={
							pressaoReferenciaRegime
						}
						setPressaoReferenciaRegime={
							setPressaoReferenciaRegime
						}
						
					/>
				</div>
				{/* Lista de Ambientes */}
				<div className="flex flex-col gap-4 py-2">
					<ListAmbientes 
						regimes={allRegimes}
						setCapacidadesRegimes={setCapacidadesRegimes}
					/>
				</div>
				{/* Lista de Sistemas de Agua */}
				<div className="flex flex-col gap-4 py-2">
					<ListSistemasAgua regimes={allRegimes} setCapacidadesRegimes={setCapacidadesRegimes} />
				</div>
				<div className="flex flex-col gap-4 py-2">
					<ListMaquinasGelo regimes={allRegimes} setCapacidadesRegimes={setCapacidadesRegimes} />
				</div>
				{/* <div className="flex flex-col gap-4 py-2">

                @andersonlimacrv verificar se precisa realmente do chiller, ou Máquinas de gelo e Sistemas de Agua já compreendem a carga do chiller.
					<ListChillers />
				</div> */}
				{/* Sistemas de Agua */}
				{/* Lista de Bombas */}
				<div className="flex flex-col gap-4 py-2">
					<ListBombas 
					regimes={allRegimes} 
					UNIDADE={UNIDADE} 
					setBombas={
						setBombas
					}
					bombas={
						bombas
					}
						/>
				</div>
				{/* Lista de Maquinas de Gelo */}
			</div>
		</>
	);
}
