import React, { useEffect, useState } from 'react';
import CardLayout from '../../common/CardLayout';

import { TiEqualsOutline } from 'react-icons/ti';
import RoundProgress from '../../common/RoundProgress';
import apiLeituraEnergia from '../../../services/apis/Leituras/apiLeituraEnergia';
import getCurrentUnidadeId from '../../../funcs/getCurrentUnidadeId';

const Energia = () => {
	const [leituraEnergia, setLeituraEnergia] = useState({});
	useEffect(() => {
		apiLeituraEnergia
			.getEficiencia(getCurrentUnidadeId())
			.then((res) => {
				setLeituraEnergia(res.data);
			})
			.catch((error) => {
				console.error(error.message);
			});
	}, []);
	return (
		<>
			<CardLayout>
				<div className="flex flex-col w-full gap-y-2 text-center 3xl:px-8">
					<div className="flex flex-col items-center">
						<div className="text-center pt-2 text-lg lg:text-2xl  font-semibold drop-shadow-1xl text-accent uppercase">
							Eficiência Energética
						</div>
						<div className="py-2 font-2xl">
							{leituraEnergia?.eficienciaPorcentagem !=
							null ? (
								<RoundProgress
									value={leituraEnergia.eficienciaPorcentagem.toLocaleString(
										'pt-BR',
										{
											maximumFractionDigits: 2,
										}
									)}
								/>
							) : (
								<RoundProgress value={0} />
							)}
						</div>
						<div className="text-sm md:text-lg py-1 md:py-2">
							<p className="font-bold drop-shadow-1xl text-accent">
								I.E.E.{' '}
								<TiEqualsOutline className="inline text-accent" />
								<span className="text-lg md:text-2xl font-bold drop-shadow-1xl bg-gradient-to-r from-[#008000] via-[#00FF00] to-[#008000] inline-block text-transparent bg-clip-text md:px-10 px-4">
									{leituraEnergia?.iee !=
									null
										? leituraEnergia.iee?.toLocaleString(
												'pt-BR',
												{
													maximumFractionDigits: 2,
												}
										  )
										: 0}

									<span className="px-1">
										Kcal / kWh
									</span>
								</span>
							</p>
						</div>
					</div>
					<div className="flex flex-row justify-between items-center px-4 mx-4">
						<div className="text-sm md:text-lg font-bold drop-shadow-1xl">
							Demanda Ativa
						</div>
						<div className="text-lg md:text-2xl font-bold">
							<p className=" bg-gradient-to-r from-accent via-indigo-400 to-accent inline-block text-transparent bg-clip-text">
								{leituraEnergia?.demandaAtiva !=
								null
									? leituraEnergia.demandaAtiva?.toLocaleString(
											'pt-BR',
											{
												maximumFractionDigits: 2,
											}
									  )
									: 0}
								<span className="px-1">kW</span>
							</p>
						</div>
					</div>
					<div className="px-2 w-full">
						{/* <HorizontalProgress
							value={50}
							variant="one"
						/> */}
					</div>
					<div className="flex flex-row justify-between items-center px-4 mx-4">
						<div className="text-sm md:text-lg font-bold drop-shadow-1xl">
							Sala de Máquinas
						</div>
						<div className="text-lg md:text-2xl font-bold">
							<p className="drop-shadow-1xl bg-gradient-to-r from-yellow-300 via-rose-300 to-yellow-300 inline-block text-transparent bg-clip-text">
								{leituraEnergia?.potenciaSalaMaquinasKw !=
								null
									? leituraEnergia?.potenciaSalaMaquinasKw?.toLocaleString(
											'pt-BR',
											{
												maximumFractionDigits: 2,
											}
									  )
									: 0}
								<span className="px-1">kW</span>
							</p>
						</div>
					</div>
					<div className="px-2 w-full">
						{/* <HorizontalProgress
							value={50}
							variant="three"
						/> */}
					</div>
					<div className="flex flex-col md:flex-row justify-center items-center p-2 gap-2 font-semibold">
						<div className="flex justify-between items-center md:w-1/2 w-full border-2 border-accent rounded-full px-4 py-[2px] 2xl:px-10">
							<p className="2xl:text-lg text-sm text-secondary">
								Fator de Potência:
							</p>
							<span className="drop-shadow-1xl text-white 2xl:text-lg text-sm">
								{leituraEnergia?.fatorPotencia !=
								null
									? leituraEnergia.fatorPotencia?.toLocaleString(
											'pt-BR',
											{
												maximumFractionDigits: 2,
											}
									  )
									: '0.00'}
							</span>
						</div>
						<div className="flex justify-between items-center md:w-1/2 w-full border-2 border-accent rounded-full px-2 py-[2px] 2xl:px-8">
							<p className="2xl:text-lg text-sm text-secondary">
								Consumo Diário:
							</p>
							<span className="drop-shadow-1xl text-white 2xl:text-lg text-sm whitespace-nowrap">
								{leituraEnergia.consumoDiarioKwh !=
								null
									? leituraEnergia?.consumoDiarioKwh?.toLocaleString(
											'pt-BR',
											{
												maximumFractionDigits: 2,
											}
									  )
									: '0.00'}
								{' kwh'}
							</span>
						</div>
					</div>
				</div>
			</CardLayout>
		</>
	);
};

export default Energia;
