import { apiLink } from '../../constants/env';
import axiosInstance from '../AxiosInstance';

const apiTipoOperacaoCompressores = {
    fetchApi: async (page) => {
        const URL = `${apiLink}api/tipo-operacao-compressor?page=` + page;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();

            })
    },
    getByModeloId: async (id) => {
        const URL = `${apiLink}api/tipo-operacao-compressor/modelo/` + id;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();

            })
    },
    getById: async (id) => {
        const URL = `${apiLink}api/tipo-operacao-compressor/${id}`;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();
            })
    },
    postApi: async (tipoOperacaoCompressor) => {
        const URL = `${apiLink}api/tipo-operacao-compressor`;
        return await axiosInstance.post(URL, tipoOperacaoCompressor)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao fazer o post dos dados:', error);
                throw new Error();

            })
    },
    putApi: async (id, tipoOperacaoCompressor) => {
        const URL = `${apiLink}api/tipo-operacao-compressor/${id}`;
        return await axiosInstance.put(URL, tipoOperacaoCompressor)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao fazer o post dos dados:', error);
                throw new Error();

            })
    },
    deleteApi: async (id) => {
        const URL = `${apiLink}api/tipo-operacao-compressor/status/${id}`;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao deletar o tipo-operacao-compressor:', error);
                throw new Error();

            })
    }

}

export default apiTipoOperacaoCompressores; 