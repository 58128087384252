import React, { useEffect, useState } from 'react';
import CardLayout from '../../../components/common/CardLayout';
import NoDataAlert from '../../../components/common/returns/NoDataAlert';
import Condensacao from '../../../components/monitoramento/cargas-termicas/Condensacao';
import ListAmbientes from '../../../components/monitoramento/cargas-termicas/ListAmbientes';
import getCurrentUnidadeId from '../../../funcs/getCurrentUnidadeId';

import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from '../../../components/ui/accordion';

import apiRegimes from '../../../services/apis/apiRegimes';
import { colorsPallete } from '../../../constants';
import apiCompressores from '../../../services/apis/apiCompressores';
import apiCalculosCompressores from '../../../services/apis/apiCalculosCompressores';

export default function CargasTermicas() {
	const unidadeId = getCurrentUnidadeId();
	const regimeColors = colorsPallete;
	const [allRegimes, setAllRegimes] = useState([]);
	const [condensacao, setCondensacao] = useState([]);
	const [calculosCompressores, setCalculoCompressores] = useState([]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const response = await apiRegimes.getByUnidadeId(
					unidadeId
				);

				let colorIndex = 0;
				const regimesWithColors = response.data.reduce(
					(acc, regime) => {
						if (regime.tipoRegime !== 'Condensação') {
							acc.allRegimes.push({
								...regime,
								...regimeColors[
									colorIndex %
										regimeColors.length
								],
							});
							colorIndex++;
						} else if (
							regime.tipoRegime === 'Condensação'
						) {
							acc.condensacao.push({
								...regime,
							});
						}
						return acc;
					},
					{ allRegimes: [], condensacao: [] }
				);
				const resCps = response.data.map(async (regime) => {
					const compressoresResponse =
						await apiCompressores.getByRegimeId(
							regime.id
						);
					return compressoresResponse.data;
				});

				const cps = (await Promise.all(resCps)).reduce(
					(valorInicial, cp) => valorInicial.concat(cp),
					[]
				);
				setCalculoCompressores(cps);

				const resCalculos = cps.map(async (cp) => {
					return {
						id: cp.id,
						regimeSuccaoId: cp.regimeSuccaoId,
						regimeDescargaId: cp.regimeDescargaId,
						...(
							await apiCalculosCompressores.calculoCompressorByCompressorId(
								cp.id
							)
						).data,
					};
				});

				setCalculoCompressores(await Promise.all(resCalculos));

				setAllRegimes(regimesWithColors.allRegimes || []);
				setCondensacao(regimesWithColors.condensacao || []);
			} catch (error) {
				console.log(
					'Error CargasTermicas fetching REGIMES:',
					error
				);
				setAllRegimes([]);
				setCondensacao([]);
			}
		};

		fetchData();
	}, [regimeColors, unidadeId]);

	return (
		<>
			<section className="flex flex-col pb-16">
				<div className="flex flex-col gap-4 py-2">
					{condensacao && condensacao.length > 0 ? (
						<div className="flex flex-col">
							<Condensacao
								ListContent={condensacao}
								calculosCompressores={
									calculosCompressores
								}
							/>
						</div>
					) : (
						<CardLayout>
							<div className="flex flex-col justify-center items-center w-ful h-full">
								<h1 className="py-8 lg:text-2xl md:text-lg text-sm font-semibold text-secondary drop-shadow-1xl italic tracking-wide">
									É necessário cadastrar um
									regime de condensação.
								</h1>
								<div className="flex justify-center">
									<NoDataAlert />
								</div>
							</div>
						</CardLayout>
					)}
					{allRegimes && allRegimes.length > 0 ? (
						allRegimes.map((ListContent) => (
							<CardLayout key={ListContent.id}>
								<Accordion
									type="single"
									defaultValue={`item-${ListContent.id}`}
									collapsible={true}
								>
									<AccordionItem
										value={`item-${ListContent.id}`}
									>
										<div className="font-semibold">
											<AccordionTrigger>
												<div className="w-full flex items-center justify-center text-sm lg:text-2xl drop-shadow-1xl">
													{ListContent &&
														ListContent.color && (
															<div
																className={`${ListContent.class} uppercase`}
															>
																<p>
																	Regime:{' '}
																	<span className="capitalize">
																		{
																			ListContent.nome
																		}
																	</span>
																</p>
															</div>
														)}
												</div>
											</AccordionTrigger>
										</div>
										<AccordionContent>
											<div className="flex flex-col lg:flex-row gap-4 px-4">
												<ListAmbientes
													Regime={
														ListContent
													}
													calculosCompressores={
														calculosCompressores
													}
												/>
											</div>
										</AccordionContent>
									</AccordionItem>
								</Accordion>
							</CardLayout>
						))
					) : (
						<CardLayout>
							<div className="flex flex-col justify-center items-center w-ful h-full">
								<h1 className="py-8 lg:text-2xl md:text-lg text-sm font-semibold text-secondary drop-shadow-1xl italic tracking-wide">
									É necessário cadastrar ao
									menos um regime de
									operação.
								</h1>
								<div className="flex justify-center">
									<NoDataAlert />
								</div>
							</div>
						</CardLayout>
					)}
				</div>
			</section>
		</>
	);
}
