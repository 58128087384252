import React from 'react';

const TitleCardSimple = ({ children }) => {
	return (
		<>
			<h1 className="py-2 lg:text-2xl md:text-lg text-sm font-semibold text-secondary drop-shadow-1xl">
				{children}
			</h1>
		</>
	);
};

export default TitleCardSimple;
