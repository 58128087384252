import React from 'react';
import InputGeneric from '../common/InputGeneric';

const CondensacaoData = ({
	pressao,
	capacidadesRegimes,
	condensacao,
	tbu,
	temperatura,
	handleTemperatura,
	handleTbu,
}) => {
	const capacidade = capacidadesRegimes?.find(
		(capacidade) => capacidade.regimeId === condensacao.id
	);
	
	const capacidadeDisponivel = capacidade?.capacidadeDisponivel;
	const capacidadeNecessaria = capacidade?.capacidadeNecessaria;

	const formattedCapacidadeDisponivel =
		capacidadeDisponivel !== undefined && capacidadeDisponivel !== null
			? capacidadeDisponivel.toLocaleString('pt-BR', {
					maximumFractionDigits: 2,
			  })
			: '0.00';
	const formattedCapacidadeNecessaria =
		capacidadeNecessaria !== undefined && capacidadeNecessaria !== null
			? capacidadeNecessaria.toLocaleString('pt-BR', {})
			: '0.00';
	const formattedTemperatura =
		temperatura !== undefined && temperatura !== null
			? temperatura.toFixed(2)
			: '0.00';
	const formattedPressao =
		pressao !== undefined && pressao !== null
			? pressao.toFixed(2)
			: '0.00';
	const formattedTbu =
		tbu !== undefined && tbu !== null ? tbu.toFixed(2) : '333.00';
	return (
		<div className="flex flex-col gap-y-1">
			{/* Temperatura */}
			<div className="flex flex-col 2xl:flex-row justify-center items-center w-full px-1 3xl:px-8">
				<label
					htmlFor="temperatura"
					className="block text-sm 3xl:text-lg font-medium leading-6 text-secondary px-2 drop-shadow-1xl"
				>
					Temperatura
				</label>
				<div className="relative rounded-md shadow-sm flex-1">
					<InputGeneric
						unidade="°C"
						id="temperatura"
						name="temperatura"
						defaultValue={formattedTemperatura}
						onChange={(e) =>
							handleTemperatura(e.target.value, condensacao.id)
						}
					/>
				</div>
			</div>
			<div className="flex flex-col 2xl:flex-row justify-center items-center px-1 3xl:px-8 3xl:gap-x-2 4xl:gap-x-8">
				{/* Tbu */}
				<div className="flex flex-col 2xl:flex-row justify-end items-center w-full 2xl:w-[47%]">
					<label
						htmlFor="tbu"
						className="block text-sm 3xl:text-lg font-medium leading-6 text-secondary px-2 3xl:pr-4 drop-shadow-1xl"
					>
						Tbu
					</label>
					<div className="relative rounded-md shadow-sm flex-1">
						<InputGeneric
							unidade="°C"
							id="tbu"
							name="tbu"
							defaultValue={formattedTbu}
							onChange={(e) =>
								handleTbu(e.target.value)
							}
						/>
					</div>
				</div>
				{/* Pressão */}
				<div className="flex flex-col 2xl:flex-row justify-center items-center w-full 2xl:w-[53%]">
					<label
						htmlFor="pressao"
						className="block text-sm 3xl:text-lg font-medium leading-6 text-secondary px-2 3xl:pr-4 drop-shadow-1xl"
					>
						Pressão
					</label>
					<div className="relative rounded-md shadow-sm flex-1">
						<InputGeneric
							id={'pressao'}
							name={'pressao'}
							unidade={'bar'}
							value={formattedPressao}
							className={
								'ring-gray-400 cursor-auto'
							}
							readOnly={true}
						/>
					</div>
				</div>
			</div>
			<div className="flex flex-col gap-y-1 px-1 justify-center 3xl:px-8">
				{/* Capacidade Necessária */}
				<div className="flex justify-center items-center flex-col 2xl:flex-row">
					<label
						htmlFor="capacidade_necessaria"
						className="block text-sm 3xl:text-lg font-medium leading-6 text-secondary px-2 3xl:pr-4  drop-shadow-1xl"
					>
						Capacidade Necessária
					</label>
					<div className="relative rounded-md shadow-sm flex-1">
						<InputGeneric
							id="capacidade_necessaria"
							name="capacidade_necessaria"
							unidade="Kcal/ h"
							readOnly={true}
							className={
								'ring-gray-400 cursor-auto'
							}
							type={'String'}
							value={formattedCapacidadeNecessaria}
						/>
					</div>
				</div>
				{/* Capacidade Disponível */}
				<div className="flex justify-center items-center flex-col 2xl:flex-row">
					<label
						htmlFor="capacidade_disponivel"
						className="block text-sm 3xl:text-lg font-medium leading-6 text-secondary px-2 3xl:pr-4 drop-shadow-1xl"
					>
						Capacidade Disponível
					</label>
					<div className="relative rounded-md shadow-sm flex-1">
						<InputGeneric
							id="capacidade_disponivel"
							name="capacidade_disponivel"
							unidade="Kcal/ h "
							readOnly={true}
							type={'String'}
							className={
								'ring-gray-400 cursor-auto'
							}
							value={formattedCapacidadeDisponivel}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CondensacaoData;
