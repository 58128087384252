import ListAmbientes from '../../components/resumo-unidade/ListAmbientes';
import ListChillers from '../../components/resumo-unidade/ListChillers';
import ListEquipamentos from '../../components/resumo-unidade/ListEquipamentos';
import SobreUnidade from '../../components/resumo-unidade/SobreUnidade';
import getCurrentUnidadeId from '../../funcs/getCurrentUnidadeId';
export default function ResumoUnidade() {
	const UNIDADE = getCurrentUnidadeId();
	if (UNIDADE === null) {
		window.location.href = '/escolha-unidade';
	}

	return (
		<>
			<div className="flex flex-col gap-2 p-2 text-white mb-4 lg:mb-16">
				{/* Sobre a unidade */}
				<SobreUnidade unidadeID={UNIDADE} />
				{/* Sobre os Equipamentos */}
				<ListEquipamentos unidadeID={UNIDADE} />
				{/* Sobre os Ambientes */}
				<ListAmbientes unidadeID={UNIDADE} />
				{/* Sobre os Chillers*/}
				<ListChillers unidadeID={UNIDADE} />
			</div>
		</>
	);
}
