import { apiLink } from '../../../constants/env';
import axiosInstance from '../../AxiosInstance';

const apiUltimaLeituraCondensacao = {
    fetchApi: async (reigmeId) => {
        const URL = `${apiLink}api/leitura-condensacao/ultima-leitura?regimeId=${reigmeId}`;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar a última leitura de condensação');

            })
    }
}

export default apiUltimaLeituraCondensacao;
