import { useContext, useEffect, useState } from 'react';
import apiModeloCompressor from '../../../../services/apis/apiModeloCompressor';
import { ToastContext } from '../../../../contexts/ToastContext';
import {
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
	AlertDialogTrigger,
} from '../../../../components/ui/alert-dialog';
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from '../../../../components/ui/dialog';
import { useParams } from 'react-router-dom';
import PaginationBar from '../../../../components/common/PaginationBar';
import apiMarca from '../../../../services/apis/apiMarca';

export default function ListaModeloCompressores({
	atualizarLista,
	pesquisar,
	funcaoModalController,
}) {
	const [idEditar, setIdEditar] = useState();
	const [nome, setNome] = useState('');
	const [marcaId, setMarcaId] = useState(0);
	const { page } = useParams();
	const [pagination, setPagination] = useState();
	const [itemRemovido, setItemRemovido] = useState('');
	const [idRemover, setIdRemover] = useState();
	const [listaModeloCompressores, setListaModeloCompressores] = useState([]);
	const [listaMarcas, setListaMarcas] = useState([]);
	const { erro, sucesso } = useContext(ToastContext);
	const funcaoModal = funcaoModalController.funcaoModal;

	//GET MODELO
	useEffect(() => {
		apiModeloCompressor.fetchApi(page).then((res) => {
			setListaModeloCompressores(res.data.items);
			setPagination(res.data);
		});
		apiMarca.fetchApi().then(res => setListaMarcas(res.data));

	}, [page]);

	//DELETE MODELO
	function deletar(id) {
		apiModeloCompressor
			.deleteApi(id)
			.then((res) => {
				atualizarLista();
				sucesso('Modelo deletado com sucesso!');
			})
			.catch((error) => {
				console.error(error.message);
				erro('Erro ao deletar modelo');
			});
	}

	function enviar(e) {
		e.preventDefault();

		const modeloCompressor = {
			nome: e.target.nome.value,
			marcaId: parseInt(e.target.marcaId.value),
		};

		if (funcaoModal === 'Adicionar') {
			apiModeloCompressor
				.postApi(modeloCompressor)
				.then((res) => {
					atualizarLista();
					limparInputs();
					sucesso('Modelo adicionado com sucesso!');
				})
				.catch((error) => {
					console.error(error.message);
					erro('Erro ao adicionar modelo!');
				});
		} else if (funcaoModal === 'Editar') {
			apiModeloCompressor
				.putApi(idEditar, modeloCompressor)
				.then((res) => {
					atualizarLista();
					limparInputs();
					sucesso('Modelo editado com sucesso!');
				})
				.catch((error) => {
					console.error(error.message);
					erro('Erro ao editar modelo!');
				});
		}
	}

	function pegarNomeMarca(id) {
		try {
			const index = listaMarcas.findIndex(
				(elemento) => elemento.id === id
			);
			return listaMarcas[index].nome;
		} catch (error) {
			return '';
		}
	}

	function limparInputs() {
		setNome('');
		document.getElementById('marcaId').value = 0;
	}

	return (
		<>
			<Dialog>
				<div className="w-full flex flex-col items-center mt-[40px] md:px-[200px]">
					<table className="w-full">
						<thead className="border-b w-full">
							<tr>
								<th className="text-[25px] pl-[10px] text-start">
									Nome
								</th>
								<th className="text-[25px] pl-[10px] text-start">
									Marca
								</th>
							</tr>
						</thead>
						<tbody>
							{listaModeloCompressores.map(
								(modeloCompressor, i) =>
									modeloCompressor.nome
										.toUpperCase()
										.startsWith(
											pesquisar.toUpperCase()
										) && (
										<tr
											className="border-b hover:bg-gray-200 hover:bg-opacity-5"
											key={i}
										>
											<td className="pl-[10px] text-[20px]">
												{
													modeloCompressor.nome
												}
											</td>
											<td className="flex justify-between py-1">
												<span className="text-[20px] pl-[10px]">
													{pegarNomeMarca(
														modeloCompressor.marcaId
													)}
												</span>
												<span className="flex">
													<AlertDialogTrigger>
														<div
															onClick={() => {
																setIdEditar(
																	modeloCompressor.id
																);
																setNome(
																	modeloCompressor.nome
																);
																setMarcaId(
																	modeloCompressor.marcaId
																);
																funcaoModalController.setFuncaoModal(
																	'Editar'
																);
															}}
														>
															<i
																className="fa-solid fa-pen-to-square text-[18px] hover:text-lightBlue"
																title="Editar"
															></i>
														</div>
													</AlertDialogTrigger>
													<DialogTrigger>
														<div
															className="mx-2.5"
															onClick={() => {
																setItemRemovido(
																	modeloCompressor.nome
																);
																setIdRemover(
																	modeloCompressor.id
																);
															}}
														>
															<i
																className="fa-solid fa-trash text-[18px] hover:text-lightBlue"
																title="Deletar"
															></i>
														</div>
													</DialogTrigger>
												</span>
											</td>
										</tr>
									)
							)}
						</tbody>
					</table>
					{
						pagination !== undefined &&
						<PaginationBar pagination={pagination} />
					}
				</div>

				<DialogContent>
					<DialogHeader>
						<DialogTitle>
							Excluir {itemRemovido}?
						</DialogTitle>
					</DialogHeader>
					<DialogDescription>
						Todos os dados associados com {itemRemovido}{' '}
						serão removidos.
					</DialogDescription>
					<DialogFooter>
						<div
							className="bg-red-600/70 py-2 px-4 rounded-md hover:bg-red-600 hover:cursor-pointer border-slate-800 border-2 transition-all duration-200 ease-in-out"
							onClick={() => deletar(idRemover)}
						>
							<DialogClose>Excluir</DialogClose>
						</div>
					</DialogFooter>
				</DialogContent>
			</Dialog>

			<AlertDialogContent>
				<form onSubmit={(e) => enviar(e)}>
					<AlertDialogHeader>
						<AlertDialogTitle>
							{funcaoModal} Modelo Compressor
						</AlertDialogTitle>
					</AlertDialogHeader>
					<div className="w-full flex justify-between my-5 px-10">
						<div className="flex flex-col">
							<label
								htmlFor="nome"
								className="text-[18px] mb-2 pl-1"
							>
								Nome
							</label>
							<input
								id="nome"
								className="bg-transparent border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[180px] h-[42px]"
								type="text"
								placeholder="nome do modelo"
								value={nome}
								onChange={(e) =>
									setNome(e.target.value)
								}
								required
							/>
						</div>

						<div className="flex flex-col">
							<label
								htmlFor="nome"
								className="text-[18px] mb-2 pl-1"
							>
								Marca{' '}
							</label>
							<select
								id="marcaId"
								className="bg-transparent border-b text-[20px] h-[42px] bg-[#0b101d] rounded-t-md w-[180px]"
								name="marcaId"
								value={marcaId}
								required
								onChange={(e) =>
									setMarcaId(
										parseInt(
											e.target.value
										)
									)
								}
							>
								<option label=" "> </option>
								{listaMarcas.map(
									(elemento, i) => (
										<option
											value={
												elemento.id
											}
											key={i}
										>
											{
												elemento.nome
											}
										</option>
									)
								)}
							</select>
						</div>
					</div>
					<AlertDialogFooter>
						<div className="flex w-full justify-end gap-x-4 p-4">
							<AlertDialogAction className="w-32">
								Confirmar
							</AlertDialogAction>
							<AlertDialogCancel className="w-32">
								Cancelar
							</AlertDialogCancel>
						</div>
					</AlertDialogFooter>
				</form>
			</AlertDialogContent>
		</>
	);
}
