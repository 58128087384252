import React from 'react';
import { cn } from '../../lib/utils/utils';

const Fake = () => {
	return (
		<span
			className={cn(
				'bg-red-100 text-red-800 me-2 px-2.5 py-0.5 rounded dark:bg-gray-700 dark:text-red-400 border border-red-500'
			)}
		>
			<span className="drop-shadow-1xl text-xs font-medium">
				Fake data
			</span>
		</span>
	);
};

export default Fake;
