import CardLayout from '../../common/CardLayout';
import TitleCardSimple from '../../common/TitleCardSimple';
import { TbSum } from 'react-icons/tb';
import CardPeriodoMedido from './CardPeriodoMedido';

import ChartBarDefault from './charts/ChartBarDefault';

const horasDia = [];
for (let i = 0; i < 24; i++) {
	const horaFormatada = new Date(0, 0, 0, i, 0, 0); // hora, minuto, segundo
	const horaString = horaFormatada.toLocaleTimeString([], {
		hour: '2-digit',
		hour12: false,
		minute: '2-digit',
	});
	horasDia.push(`${horaString}`);
}

const ConsumoEnergiaDiario = ({ dados, dadosDetalhado }) => {
	const horas = dados.map((elemento) => {
		const horaFormatada = new Date(0, 0, 0, elemento.hora, 0, 0); // hora, minuto, segundo
		const horaString = horaFormatada.toLocaleTimeString([], {
			hour: '2-digit',
			hour12: false,
			minute: '2-digit',
		});
		return `${horaString}`;
	});

	const valores = dados.map((elemento) => {
		return elemento.consumoAtivoTotal;
	});

	const ConsumoEnergiaDiarioData = {
		date: horas,
		ConsumoPorHora: valores,
	};

	return (
		<>
			<CardLayout>
				<div className="flex flex-col justify-center h-full">
					<div className="flex justify-center flex-col items-center">
						<TitleCardSimple>
							Consumo de Energia{' '}
							<span className="text-accent">(</span>
							diário
							<span className="text-accent">)</span>
						</TitleCardSimple>
						<div className="flex justify-center items-center flex-coltext-white text-sm lg:text-lg">
							<p className="py-2 flex items-center px-2 lg:text-lg">
								Acumulado total
								<TbSum className="h-5 w-5 pl-1" />
							</p>
							<p className="drop-shadow-1xl text-lg lg:text-2xl font-bold bg-gradient-to-r from-accent via-indigo-500 to-accent inline-block text-transparent bg-clip-text">
								{dadosDetalhado?.consumoAtivoTotal?.toLocaleString(
									'pt-BR',
									{
										maximumFractionDigits: 2,
									}
								)}{' '}
								kWh
							</p>
						</div>
						<div className="flex justify-center items-center">
							<CardPeriodoMedido
								ponta={dadosDetalhado?.consumoAtivoPonta?.toLocaleString(
									'pt-BR',
									{
										maximumFractionDigits: 2,
									}
								)}
								FPCapacitivo={dadosDetalhado?.consumoAtivoForaDePontaCapacitivo?.toLocaleString(
									'pt-BR',
									{
										maximumFractionDigits: 2,
									}
								)}
								FPIndutivo={dadosDetalhado?.consumoAtivoForaDePontaIndutivo?.toLocaleString(
									'pt-BR',
									{
										maximumFractionDigits: 2,
									}
								)}
								dateFPCapacitivo={
									dadosDetalhado?.data
								}
								dateFPIndutivo={
									dadosDetalhado?.data
								}
								datePonta={dadosDetalhado?.data}
								unidade={'kWh'}
							/>
						</div>
						<ChartBarDefault
							chartDataProps={
								ConsumoEnergiaDiarioData
							}
							nameSerie={
								'Consumo Acumulado ( kWh )'
							}
							typeChart="consumoTotalDiario"
						/>
					</div>
				</div>
			</CardLayout>
		</>
	);
};

export default ConsumoEnergiaDiario;
