import { Outlet, useLocation } from 'react-router-dom';
import '../../globals.css';
import Header from '../header';
import Sidebar from '../common/Sidebar';
import { publicPages } from '../../constants';
import Breadcrumb from '../common/Breadcrumb';
import ResumoUnidade from '../../pages/resumo_unidade';

const Layout = ({ children }) => {
	const path = useLocation().pathname;

	if (publicPages.some((pathName) => path.includes(pathName))) {
		return (
			<div className="bg-gradient-to-r bg-primary from-darkaccent to-darkaccent via-primary scrollbar-thin scrollbar-track-primary scrollbar-thumb-darkaccent overflow-x-hidden min-h-full h-screen">
				<main className="flex flex-col flex-wrap md:p-8 md:px-auto w-full md:justify-center max-sm:full overflow-x-hidden justify-center min-h-full items-center">
					<Outlet />
				</main>
			</div>
		);
	} else if (path === '/escolha-unidade' || path === '/suporte') {
		return (
			<div className="bg-gradient-to-r bg-primary from-darkaccent to-darkaccent via-primary scrollbar-thin scrollbar-track-primary scrollbar-thumb-darkaccent overflow-x-hidden min-h-full h-screen">
				<Header />
				<main className="flex flex-col flex-wrap md:px-auto  w-full justify-center content-center min-h-full">
					<Outlet />
				</main>
			</div>
		);
	} else {
		return (
			<div className="bg-gradient-to-r bg-primary from-darkaccent to-darkaccent via-primary scrollbar-thin scrollbar-track-primary scrollbar-thumb-darkaccent overflow-x-hidden z-10 h-screen fixed w-full">
				<Header />
				<main className="flex flex-col mb-24 md:p-8 md:px-auto md:mx-6 mx-4">
					<section className="flex flex-col absolute top-20 w-screen mx-8 pl-[1rem] lg:pl-[6rem] xl:pl-[8rem] pr-[4.5rem] md:pr-[8.5rem] 2xl:pr-[14rem]">
						<Breadcrumb />
						{path === '/' && <ResumoUnidade />}
						{path !== '/' && <Outlet />}
					</section>
				</main>
				<Sidebar className="" />
			</div>
		);
	}
};

export default Layout;
