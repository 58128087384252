import { apiLink } from '../../constants/env';
import axiosInstance from '../AxiosInstance';


const apiGrupo = {
    fetchApi: async () => {
        const URL = `${apiLink}api/grupo`;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error(error);
            })
    },
    getById: async (id) => {
        const URL = `${apiLink}api/Grupo` + id;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();

            })
    },
    pagination: async (id) => {
        const URL = `${apiLink}api/grupo/page/` + id;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();

            })
    },
    postApi: async (grupo) => {
        const URL = `${apiLink}api/grupo`;
        return await axiosInstance.post(URL, grupo)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();

            })
    },
    deleteApi: async (id) => {
        const URL = `${apiLink}api/grupo/status/${id}`;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();

            })
    },
    putApi: async (id, grupo) => {
        const URL = `${apiLink}api/grupo/${id}`;
        return await axiosInstance.put(URL, grupo)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();

            })
    }

}

export default apiGrupo;

