import React from 'react';
import { Link } from 'react-router-dom';

const HeaderLinks = () => {
	return (
		<div className="flex gap-x-6 text-lg font-semibold">
			<Link to="/" className="group relative text-white">
				<span className="hover:text-accent transition-color duration-150">
					Home
				</span>
				<div className="absolute -bottom-1 left-0 w-full h-[4px] bg-accent scale-x-0 group-hover:scale-x-100 transition-transform duration-500 origin-left rounded-full"></div>
			</Link>
			<Link
				to="/escolha-unidade"
				className="group relative text-white"
			>
				<span className="hover:text-accent transition-color duration-150">
					Alterar Empresa
				</span>
				<div className="absolute -bottom-1 left-0 w-full h-[4px] bg-accent scale-x-0 group-hover:scale-x-100 transition-transform duration-500 origin-left rounded-full"></div>
			</Link>
			<Link to="/suporte" className="group relative text-white">
				<span className="hover:text-accent transition-color duration-150">
					Suporte
				</span>
				<div className="absolute -bottom-1 left-0 w-full h-[4px] bg-accent scale-x-0 group-hover:scale-x-100 transition-transform duration-500 origin-left rounded-full"></div>
			</Link>
		</div>
	);
};

export default HeaderLinks;
