import React from 'react';

const PercentProgress = ({ value }) => {
	const progressLength = value > 100 ? 100 : value < 0 ? 0 : value;

	return (
		<div className="flex justify-center items-center text-center">
			{/* Circular Progress */}
			<div className="relative md:h-12 md:w-12 w-9 h-9  text-center">
				<svg
					className="h-full w-full"
					width={16}
					height={16}
					viewBox="0 0 36 36"
					xmlns="http://www.w3.org/2000/svg"
				>
					{/* Background Circle */}
					<circle
						cx={18}
						cy={18}
						r={16}
						fill="none"
						className="stroke-current text-gray-200 dark:text-gray-700"
						strokeWidth={4}
					/>
					{/* Progress Circle inside a group with rotation */}
					<g className="origin-center -rotate-90 transform">
						<circle
							cx={18}
							cy={18}
							r={16}
							fill="none"
							className="stroke-current text-accent"
							strokeLinecap="round"
							strokeWidth={4}
							strokeDasharray={100}
							strokeDashoffset={
								100 - progressLength
							}
						/>
					</g>
				</svg>
				{/* Percentage Text */}
				<div className="absolute top-1/2 start-1/2 transform -translate-y-1/2 -translate-x-1/2">
					<span className="text-center text-normal font-semibold text-white">
						{value}%
					</span>
				</div>
			</div>
		</div>
	);
};

export default PercentProgress;
