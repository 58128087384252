function Capacitor({ fill = '#000' }) {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			fill={fill}
		>
			<g>
				<path d="M22 11h-7.4c.1-1.6.5-3.1 1.2-4.6L14 5.5c-2 4-2 8.9 0 12.9l1.8-.9c-.7-1.4-1.1-3-1.2-4.6H22V11zM9 11H2v2h7v6h2V5H9v6z"></path>
			</g>
		</svg>
	);
}

export default Capacitor;
