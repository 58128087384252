import React, { useState, useEffect, useCallback } from 'react';
import CardLayout from '../common/CardLayout';
import TitleCard from '../common/TitleCard';
import Table from '../common/table';
import NoEquipAlert from '../common/returns/NoEquipAlert';
import apiRegimes from '../../services/apis/apiRegimes';
import apiUltimaLeituraChiller from '../../services/apis/Leituras/apiUltimaLeituraChiller';
import apiChillers from '../../services/apis/apiChiller';
import { GiWaterDrop } from 'react-icons/gi';
import NaoSeAplica from '../common/returns/NaoSeAplica';

import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from '../../components/ui/accordion';

const fetchChiller = async ({ regimeId }) => {
	try {
		const res = await apiChillers.getByRegimeId(regimeId);
		return res.data;
	} catch (error) {
		console.error(error.message);
	}
};

const fetchUltimaLeitura = async ({ chillerId }) => {
	try {
		const res = await apiUltimaLeituraChiller.fetchApi(chillerId);
		return res.data;
	} catch (error) {
		console.error(error.message);
	}
};

const ListChillers = ({ unidadeID }) => {
	const [chillers, setChillers] = useState([]);
	const [chillersData, setChillersData] = useState([]);
	const [leituras, setLeituras] = useState([]);
	const [regimes, setRegimes] = useState();

	const fetchData = useCallback(async () => {
		try {
			if (!unidadeID) return;
			const res = await apiRegimes.getByUnidadeId(unidadeID);
			setRegimes(
				res.data.filter(
					(regime) => regime.tipoRegime !== 'Condensação'
				)
			);

			const requests = res.data.map((regime) =>
				fetchChiller({ regimeId: regime.id })
			);
			const responses = await Promise.all(requests);
			const chillersData = responses.map((res) => res).flat();
			setChillers(chillersData);

			if (chillersData.length > 0) {
				const leituras = await Promise.all(
					chillersData.map((chiller) => {
						return fetchUltimaLeitura({
							chillerId: chiller.id,
						});
					})
				);
				setLeituras(leituras);
			}
		} catch (error) {
			console.error(
				`Erro fetch chillers [Sobre Unidade - ListChillers]: ${error.message}`
			);
		}
	}, [unidadeID]);

	useEffect(() => {
		fetchData();
	}, [unidadeID, fetchData]);

	useEffect(() => {
		const getNameById = (id) => {
			const regime = regimes.find((regime) => regime.id === id);
			if (regime) {
				return regime.nome;
			}

			return '';
		};
		if (chillers) {
			const updateChillerData = chillers.map((chiller) => {
				return {
					...chiller,
					regime: getNameById(chiller.regimeId),
					leitura: leituras.find(
						(leitura) =>
							leitura.chillerId === chiller.id
					),
				};
			});
			setChillersData(updateChillerData);
		}
	}, [chillers, regimes, leituras]);
	return (
		<CardLayout>
			<Accordion
				type="single"
				defaultValue="item-1"
				collapsible="true"
			>
				<AccordionItem value="item-1">
					<div className="font-semibold">
						<AccordionTrigger>
							<TitleCard>
								Chillers
								<p className="text-lg lg:text-3xl px-1">
									<GiWaterDrop />
								</p>
							</TitleCard>
						</AccordionTrigger>
					</div>
					<AccordionContent>
						<div className="w-full">
							{chillersData.length > 0 && (
								<Table.Root>
									<Table.Head>
										<Table.Header>
											Nome
										</Table.Header>
										<Table.Header>
											Produto
										</Table.Header>
										<Table.Header>
											Regime
										</Table.Header>
										<Table.Header>
											<div className="top-2 relative">
												Produto
												<p className="lowercase text-xs relative -top-1">
													(Entrada/Saída)
												</p>
											</div>
										</Table.Header>
										<Table.Header>
											<div className="top-2 relative">
												Água
												<p className="lowercase text-xs relative -top-1 py-1">
													(Entrada/Saída)
												</p>
											</div>
										</Table.Header>
										<Table.Header>
											Massa Gelo
										</Table.Header>
										<Table.Header>
											VOL. Água
										</Table.Header>
										<Table.Header>
											Carga Térmica
										</Table.Header>
									</Table.Head>
									<Table.Body>
										{chillersData.map(
											(chiller) => (
												<Table.Row
													key={
														chiller.id
													}
												>
													<Table.Data className="text-accent font-semibold">
														{
															chiller.nome
														}
													</Table.Data>
													<Table.Data>
														{chiller.tipoProduto ===
														'Miúdos' ? (
															<>
																{chiller.tipoMiudo.replaceAll(
																	'_',
																	' '
																)}
															</>
														) : (
															<>
																{chiller.tipoProduto.replace(
																	'_',
																	' '
																)}
															</>
														)}
													</Table.Data>
													<Table.Data>
														{
															chiller.regime
														}
													</Table.Data>
													<Table.Data>
														{chiller.temperaturaEntradaProduto?.toLocaleString(
															'pt-BR',
															{
																maximumFractionDigits: 2,
															}
														)}{' '}
														°C{' '}
														<p className=" text-accent inline">
															/
														</p>{' '}
														{chiller.temperaturaSaidaProduto?.toLocaleString(
															'pt-BR',
															{
																maximumFractionDigits: 2,
															}
														)}{' '}
														°C
													</Table.Data>
													<Table.Data className="">
														{chiller.temperaturaEntradaAgua ||
														chiller.temperaturaSaidaAgua !==
															0 ? (
															<>
																{chiller.temperaturaEntradaAgua?.toLocaleString(
																	'pt-BR',
																	{
																		maximumFractionDigits: 2,
																	}
																)}{' '}
																°C{' '}
																<p className=" text-accent inline">
																	/
																</p>{' '}
																{chiller.temperaturaSaidaAgua?.toLocaleString(
																	'pt-BR',
																	{
																		maximumFractionDigits: 2,
																	}
																)}{' '}
																°C
															</>
														) : (
															<NaoSeAplica />
														)}
													</Table.Data>
													<Table.Data>
														{chiller.massaGelo?.toLocaleString(
															'pt-BR',
															{
																maximumFractionDigits: 2,
															}
														) +
															' kg/ h'}
													</Table.Data>
													<Table.Data>
														{chiller.volumeAguaPorKg !==
														0 ? (
															<>
																{chiller.volumeAguaPorKg?.toLocaleString(
																	'pt-BR',
																	{
																		maximumFractionDigits: 2,
																	}
																)}
																{
																	' m³/ h'
																}
															</>
														) : (
															<NaoSeAplica />
														)}
													</Table.Data>
													<Table.Data>
														{chiller.cargaTermicaTotal?.toLocaleString(
															'pt-BR',
															{
																maximumFractionDigits: 2,
															}
														)}
														{
															' Kcal/ h'
														}
													</Table.Data>
												</Table.Row>
											)
										)}
									</Table.Body>
								</Table.Root>
							)}
							{chillersData.length === 0 && (
								<NoEquipAlert equipments="chillers" />
							)}
						</div>
					</AccordionContent>
				</AccordionItem>
			</Accordion>
		</CardLayout>
	);
};

export default ListChillers;
