export default function removeDuplicados(arr, key ='id') {
    const idsVistos = new Set();
    return arr.filter(obj => {
      if (idsVistos.has(obj[key])) {
        return false;
      } else {
        idsVistos.add(obj[key]);
        return true;
      }
    });
  }