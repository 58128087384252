import React, { useCallback, useState } from 'react';
import InputGeneric from '../common/InputGeneric';
import pressaoParaTemperatura from '../../calc/pressaoParaTemperatura';

const RegimeData = ({
	regime,
	capacidadeDisponivel,
	capacidadeNecessaria,
	regimePressao = { id: 0, pressaoReferencia: 0 },
	handleTemperaturaSuccao,
	handlePressao,
}) => {
	const { pressaoReferencia } = regimePressao;
	const temperatura = pressaoParaTemperatura(pressaoReferencia);

	const [keyTemperatura, setKeyTemperatura] = useState(0);
	const [keyPressao, setKeyPressao] = useState(0);

	const updateKeyTemperatura = useCallback(() => {
		setKeyTemperatura(Math.random());
	}, []);

	const updateKeyPressao = useCallback(() => {
		setKeyPressao(Math.random());
	}, []);

	return (
		regimePressao.id !== 0 && (
			<>
				<div className="flex flex-col 2xl:flex-row justify-center pt-1 items-center px-4">
					{/* Temperatura */}
					<div className="flex flex-col 2xl:flex-row justify-center items-center w-full">
						<label
							htmlFor="temperatura"
							className="block text-sm font-medium leading-6 text-secondary px-2 drop-shadow-1xl"
						>
							Temperatura
						</label>
						<div className="relative rounded-md shadow-sm flex-1">
							<InputGeneric
								id="temperatura"
								key={keyTemperatura}
								unidade="°C"
								name="temperatura"
								type={'String'}
								onChange={(e) => {
									handleTemperaturaSuccao(
										regime.id,
										e.target.value
									);
									updateKeyPressao();
								}}
								defaultValue={temperatura?.toLocaleString(
									'pt-BR',
									{
										maximumFractionDigits: 2,
									}
								)}
							/>
						</div>
					</div>
					{/* Pressão */}
					<div className="flex flex-col 2xl:flex-row justify-center items-center w-full">
						<label
							htmlFor="pressao"
							className="block text-sm font-medium leading-6 text-secondary px-2 drop-shadow-1xl"
						>
							Pressão
						</label>
						<div className="relative rounded-md shadow-sm flex-1">
							<InputGeneric
								id={'pressao'}
								key={keyPressao}
								name={'pressao'}
								unidade={'bar'}
								type={'String'}
								onChange={(e) => {
									handlePressao(
										regime.id,
										e.target.value
									);
									updateKeyTemperatura();
								}}
								defaultValue={pressaoReferencia?.toLocaleString(
									'pt-BR',
									{
										maximumFractionDigits: 2,
									}
								)}
							/>
						</div>
					</div>
				</div>
				<div className="flex flex-col px-4 gap-y-2 justify-center">
					{/* Capacidade Necessária */}
					<div className="flex justify-center items-center flex-col 2xl:flex-row">
						<label
							htmlFor="capacidade_necessaria"
							className="block text-sm font-medium leading-6 text-secondary 2xl:px-2  drop-shadow-1xl"
						>
							Capacidade Necessária
						</label>
						<div className="relative rounded-md shadow-sm flex-1">
							<InputGeneric
								id="capacidade_necessaria"
								name="capacidade_necessaria"
								unidade="Kcal/ h"
								readOnly={true}
								type={'String'}
								className="cursor-auto flex ring-gray-400"
								/* Confirmar o calculo de capacidade necessária @andersonlimacrv */
								value={capacidadeNecessaria.toLocaleString(
									'pt-BR',
									{
										maximumFractionDigits: 2,
									}
								)}
							/>
						</div>
					</div>
					{/* Capacidade Disponível */}
					<div className="flex justify-center items-center flex-col 2xl:flex-row">
						<label
							htmlFor="capacidade_disponivel"
							className="block text-sm font-medium leading-6 text-secondary 2xl:px-2 drop-shadow-1xl"
						>
							Capacidade Disponível
						</label>
						<div className="relative  rounded-md shadow-sm flex-1">
							<InputGeneric
								id="capacidade_disponivel"
								name="capacidade_disponivel"
								unidade="Kcal/ h"
								readOnly={true}
								type={'String'}
								className="cursor-auto ring-gray-400"
								value={capacidadeDisponivel.toLocaleString(
									'pt-BR',
									{
										maximumFractionDigits: 2,
									}
								)}
							/>
						</div>
					</div>
				</div>
			</>
		)
	);
};

export default RegimeData;
