import React from 'react';
import { colorsPallete } from '../../constants';

const TableCapacidades = ({ tableContents }) => {
	return (
		<>
			<div className="flex flex-col h-full justify-center item-center">
				<div className="inline-block min-w-full align-middle">
					<div className="overflow-hidden ">
						<table className="min-w-full divide-y divide-glassColor text-white md:text-[0.8rem] text-sm">
							<thead className="py-4 text-sm lg:text-lg">
								<tr className="text-secondary">
									<th
										scope="col"
										className="py-3 font-semibold tracking-wider text-center uppercase"
									>
										Regime
										<p className="capitalize text-secondary/20">
											(Nome)
										</p>
									</th>
									<th
										scope="col"
										className="py-3 font-semibold tracking-wider text-center uppercase "
									>
										Necessário
										<p className=" capitalize text-secondary/20">
											(Kcal/ h){' '}
										</p>
									</th>
									<th
										scope="col"
										className="py-3 font-semibold tracking-wider text-center uppercase"
									>
										Disponível
										<p className="capitalize text-secondary/20">
											(Kcal/ h)
										</p>
									</th>
								</tr>
							</thead>
							<tbody className="divide-y divide-glassColor">
								{tableContents.map(
									(regime, index) => (
										<tr
											key={index}
											className={
												'hover:bg-glassColor/20 font-bold ' +
												colorsPallete[
													index
												].class
											}
										>
											<th className="py-3 text-ldg text-center font-bold whitespace-nowrap regime_color">
												{
													regime.nome
												}
											</th>
											<td className="py-3 text-center whitespace-nowrap">
												{regime.capacidadeNecessaria.toLocaleString(
													'pt-BR',
													{
														maximumFractionDigits: 2,
													}
												)}
											</td>
											<td className="py-3 text-center whitespace-nowrap">
												{regime.capacidadeDisponivel.toLocaleString(
													'pt-BR',
													{
														maximumFractionDigits: 2,
													}
												)}
											</td>
										</tr>
									)
								)}
							</tbody>
						</table>
					</div>
				</div>
			</div>
		</>
	);
};

export default TableCapacidades;
