import React, { useState, useEffect, useCallback } from 'react';
import StatusOnOff from '../../common/StatusOnOff';
import Table from '../../common/table';
import NoEquipAlert from '../../common/returns/NoEquipAlert';
import InputGeneric from '../../common/InputGeneric';

import apiCompressores from '../../../services/apis/apiCompressores';
import apiUltimaLeituraCompressor from '../../../services/apis/Leituras/apiUltimaLeituraCompressor';
import apiTipoOperacaoCompressores from '../../../services/apis/apiTipoOperacaoCompressores';
import apiModeloCompressores from '../../../services/apis/apiModeloCompressor';
import apiMarca from '../../../services/apis/apiMarca';

const TableCompressores = ({ regimes, condensacao }) => {
	const [compressores, setCompressores] = useState([]);
	const [compressoresData, setCompressoresData] = useState([]);
	const [modelos, setModelos] = useState([]);
	const [marcas, setMarcas] = useState([]);
	const [tipoOperacao, setTiposOperacao] = useState([]);
	const [compressoresIds, setCompressoresIds] = useState([]);
	const [cpLeituras, setCpLeituras] = useState([]);

	const fetchData = useCallback(async () => {
		try {
			if (!regimes) return;
			const requests = regimes.map((regime) =>
				apiCompressores.getByRegimeId(regime.id)
			);
			const responses = await Promise.all(requests);
			const compressoresData = responses
				.map((res) => res.data)
				.flat();
			setCompressores(compressoresData);

			setCompressoresIds(
				compressoresData.map((compressor) => compressor.id)
			);
			if (compressoresData.length > 0) {
				const leituras = await Promise.all(
					compressoresData.map((compressor) => {
						return fetchUltimaLeitura({
							cpID: compressor.id,
						});
					})
				);
				setCpLeituras(leituras);
				const models = await Promise.all(
					compressoresData.map((compressor) => {
						return fetchModelo({
							modeloCompressorId:
								compressor.modeloCompressorId,
						});
					})
				);
				setModelos(models);

				const brands = await Promise.all(
					models.map((model) => {
						return fetchMarca({
							marcaId: model.marcaId,
						});
					})
				);
				setMarcas(brands);

				const operacoes = await Promise.all(
					compressoresData.map((compressor) => {
						return fetchTipoOperacao({
							tipoOperacaoCompressorId:
								compressor.tipoOperacaoCompressorId,
						});
					})
				);
				setTiposOperacao(operacoes);
			}
		} catch (error) {
			console.error(
				`Error fetching compressores [SobreUnidade - Compressores]: ${error}`
			);
		}
	}, [regimes]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	useEffect(() => {
		const getNameById = (id) => {
			const regime = regimes.find((regime) => regime.id === id);
			if (regime) {
				return regime.nome;
			} else {
				const condensador = condensacao.find(
					(condensador) => condensador.id === id
				);
				if (condensador) {
					return condensador.nome;
				}
			}
			return '';
		};
		if (compressores) {
			const updateCompressorData = compressores.map(
				(compressor) => {
					return {
						...compressor,
						regimeSuccao: getNameById(
							compressor.regimeSuccaoId
						),
						regimeDescarga: getNameById(
							compressor.regimeDescargaId
						),
						leitura: cpLeituras[
							compressoresIds.indexOf(compressor.id)
						],
						modelo: modelos[
							compressoresIds.indexOf(compressor.id)
						],
						marca: marcas[
							compressoresIds.indexOf(compressor.id)
						],
						tipoOperacao:
							tipoOperacao[
								compressoresIds.indexOf(
									compressor.id
								)
							],
					};
				}
			);
			setCompressoresData(updateCompressorData);
		}
	}, [
		compressores,
		cpLeituras,
		compressoresIds,
		modelos,
		marcas,
		tipoOperacao,
		condensacao,
		regimes,
	]);

	const fetchUltimaLeitura = async ({ cpID }) => {
		try {
			const res = await apiUltimaLeituraCompressor.fetchApi(cpID);
			return res.data;
		} catch (error) {
			console.error(
				`Error fetching ultima leitura [SobreUnidade - Compressor]: ${error}`
			);
		}
	};

	const fetchModelo = async ({ modeloCompressorId }) => {
		try {
			const res = await apiModeloCompressores.getById(
				modeloCompressorId
			);
			return res.data;
		} catch (error) {
			console.error(
				`Error fetching modelo [SobreUnidade - Compressor]: ${error}`
			);
		}
	};

	const fetchTipoOperacao = async ({ tipoOperacaoCompressorId }) => {
		try {
			const res = await apiTipoOperacaoCompressores.getById(
				tipoOperacaoCompressorId
			);
			return res.data;
		} catch (error) {
			console.error(
				`Error fetching tipo operação [SobreUnidade - Compressor]: ${error}`
			);
		}
	};

	const fetchMarca = async ({ marcaId }) => {
		try {
			const res = await apiMarca.getById(marcaId);
			return res.data;
		} catch (error) {
			console.error(
				`Error fetching marca [SobreUnidade - Compressor]: ${error}`
			);
		}
	};

	return (
		<>
			<div className="w-full">
				{compressoresData.length > 0 ? (
					<Table.Root>
						<Table.Head>
							<Table.Header>Nome</Table.Header>
							<Table.Header>Status</Table.Header>
							<Table.Header>Marca</Table.Header>
							<Table.Header>Modelo</Table.Header>
							<Table.Header>
								Regime Sucção
							</Table.Header>
							<Table.Header>
								Regime Descarga
							</Table.Header>
							<Table.Header>Operação</Table.Header>
							<Table.Header>
								Capacidade
								<p className="text-xs px-[1px] inline lowercase">
									(Kcal/ h){' '}
								</p>
							</Table.Header>
							<Table.Header>Potência</Table.Header>
						</Table.Head>
						<Table.Body>
							{compressoresData.map(
								(compressor) => (
									<Table.Row
										key={compressor.id}
									>
										<Table.Data className="font-bold text-accent">
											{
												compressor.nome
											}
										</Table.Data>
										<Table.Data>
											<StatusOnOff
												status={
													compressor
														.leitura
														?.ligado ||
													false
												}
											/>
										</Table.Data>
										<Table.Data>
											{compressor.marca &&
												compressor
													.marca
													.nome}
										</Table.Data>
										<Table.Data>
											{compressor.modelo &&
												compressor
													.modelo
													.nome}
										</Table.Data>
										<Table.Data>
											{compressor.regimeSuccao &&
												compressor.regimeSuccao}
										</Table.Data>
										<Table.Data>
											{compressor.regimeDescarga &&
												compressor.regimeDescarga}
										</Table.Data>
										<Table.Data>
											{compressor.tipoOperacao &&
												compressor
													.tipoOperacao
													.nome}
										</Table.Data>
										<Table.Data>
											{compressor
												.capacidadeNominal? (
												<>
													<InputGeneric
														className="max-w-[97px] mx-auto"
														value={compressor.capacidadeNominal.toLocaleString(
															'pt-BR',
															{
																maximumFractionDigits: 2,
															}
														)}
														type="string"
														id={
															'leituraCapacidade' +
															compressor.id
														}
														readOnly
													/>
												</>
											) : (
												'-'
											)}
										</Table.Data>
										<Table.Data>
											{ compressor
												.potenciaNominal ? (
												<>
													<InputGeneric
														className="max-w-[88px] mx-auto"
														value={
															compressor.potenciaNominal.toLocaleString(
																'pt-BR',
																{
																	maximumFractionDigits: 2,
																}
															) +
															' kW'
														}
														type="string"
														id={
															'leituraPotenciaKw' +
															compressor.id
														}
														readOnly
													/>
												</>
											) : (
												'-'
											)}
										</Table.Data>
									</Table.Row>
								)
							)}
						</Table.Body>
					</Table.Root>
				) : (
					<NoEquipAlert equipments="compressores" />
				)}
			</div>
		</>
	);
};

export default TableCompressores;
