import { apiLink } from '../../../constants/env';
import axiosInstance from '../../AxiosInstance';

const apiLeituraVentilador = {
    fetchApi: async (ventiladorId) => {
        const URL = `${apiLink}api/leitura-ventilador/ultima-leitura?chillerId=${ventiladorId}`;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error(`Erro ao buscar a último leitura do Ventilador [AXIOS]: ${error}`);
            })
    }
}

export default apiLeituraVentilador;