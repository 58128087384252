import { useState, useEffect, useCallback } from 'react';
import ChartAmbientes from './charts/ChartAmbientes';
import ChartBarDefault from './charts/ChartBarDefault';
import TitleCardSimple from '../../common/TitleCardSimple';
import apiAmbientes from '../../../services/apis/apiAmbientes';
import NoDataAlert from '../../common/returns/NoDataAlert';
import NoEquipAlert from '../../common/returns/NoEquipAlert';

export default function ListAmbientes({ Regime, calculosCompressores }) {
	const regimeId = Regime?.id || 0;
	// eslint-disable-next-line no-unused-vars
	const [chartData, setChartData] = useState({
		ambientesNome: [],
		ambientesCargaTermica: [],
		compressoresNecessario: [],
		compressoresDisponivel: [],
	});

	const fetchData = useCallback(async () => {
		if (regimeId === 0) return;

		try {
			const [ambientesResponse] = await Promise.all([
				apiAmbientes.getByRegimeId(regimeId),
			]);

			const somaCargaTermicaAmbientes =
				ambientesResponse.data.reduce(
					(valorInicial, { cargaTermica }) =>
						valorInicial + cargaTermica,
					0
				);
			const capacidadeNecessaria =
				somaCargaTermicaAmbientes +
				Regime.cargaTermicaMaquinasGelo +
				Regime.cargaTermicaSistemasAgua;
			const capacidadeDisponivel = calculosCompressores
				.filter(
					(calculo) => calculo.regimeSuccaoId === regimeId
				)
				.reduce(
					(valorInicial, { capacidadeRealCalculada }) =>
						valorInicial + capacidadeRealCalculada,
					0
				);

			const newData = {
				ambientesNome: ambientesResponse.data.map(
					(item) => item.nome
				),
				ambientesCargaTermica: ambientesResponse.data.map(
					(item) => item.cargaTermica
				),
				compressoresNecessario: capacidadeNecessaria,

				compressoresDisponivel: capacidadeDisponivel,
			};

			setChartData(newData);
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	}, [regimeId, Regime, setChartData, calculosCompressores]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	const chartDataProps = {
		ambientes: chartData.ambientesNome,
		cargasTermicas: chartData.ambientesCargaTermica,
	};

	const CargaTermicaCompressores = {
		compressoresNecessario: [chartData.compressoresNecessario],
		compressoresDisponivel: [chartData.compressoresDisponivel],
	};

	return (
		<>
			<div className="flex flex-col lg:flex-row justify-center items-center w-full px-2">
				<div className="flex flex-col items-center w-full h-full lg:w-7/12 2xl:3/4">
					<TitleCardSimple children="Cargas Termicas dos Ambientes" />
					{chartData.ambientesCargaTermica.length > 0 ? (
						<ChartAmbientes
							chartDataProps={chartDataProps}
							typeChart="cargasTermicasAmbientes"
						/>
					) : (
						<div className="flex flex-col justify-center items-center h-full">
							<NoEquipAlert
								equipments="ambientes"
								regime={true}
							/>
						</div>
					)}
				</div>
				<div className="flex flex-col justify-center items-center w-full h-full lg:w-5/12 2xl:w-1/4">
					<TitleCardSimple children="Cargas Termicas dos Compressores" />
					{CargaTermicaCompressores ? (
						<ChartBarDefault
							chartDataProps={
								CargaTermicaCompressores
							}
							typeChart="cargasTermicasCompressores"
							offsetY={-30}
						/>
					) : (
						<div className="mx-auto p-2">
							<NoDataAlert />
						</div>
					)}
				</div>
			</div>
		</>
	);
}
