import React, { useState, useEffect, useCallback } from 'react';
import Table from '../../common/table';
import NoEquipAlert from '../../common/returns/NoEquipAlert';
import StatusOnOff from '../../common/StatusOnOff';
import InputGeneric from '../../common/InputGeneric';

import apiVentiladores from '../../../services/apis/apiVentilador';
import apiModelo from '../../../services/apis/apiModelo';
import apiMarca from '../../../services/apis/apiMarca';
import apiCondensador from '../../../services/apis/apiCondensadores';
import apiLeituraVentilador from '../../../services/apis/Leituras/apiUltimaLeituraVentilador';

const fetchCondensador = async (condensadorId) => {
	try {
		const res = await apiCondensador.getById(condensadorId);
		return res.data;
	} catch (error) {
		console.error(
			`Erro fetching condensador [SobreUnidade - Ventiladores]: ${error}`
		);
		return null;
	}
};

const fetchModelo = async (modeloID) => {
	try {
		const res = await apiModelo.getById(modeloID);
		return res.data;
	} catch (error) {
		console.error(`Erro fetching modelo [SobreUnidade]: ${error}`);
	}
};

const fetchMarca = async (marcaId) => {
	try {
		const res = await apiMarca.getById(marcaId);
		return res.data;
	} catch (error) {
		console.error(`Erro fetching marca [SobreUnidade]: ${error}`);
	}
};

const fetchUltimaLeitura = async (ventiladorId) => {
	try {
		const res = await apiLeituraVentilador.fetchApi(ventiladorId);
		return res.data;
	} catch (error) {
		console.error(
			`Erro fetching ultima leitura [SobreUnidade - Ventiladores]: ${error}`
		);
	}
};

const TableVentiladores = ({ unidadeId, regimes, condensacao }) => {
	const [ventiladores, setVentiladores] = useState([]);
	const [ventiladoresData, setVentiladoresData] = useState([]);
	const [modelos, setModelos] = useState([]);
	const [marcas, setMarcas] = useState([]);
	const [ventiladorCondensador, setVentiladorCondensador] = useState([]);
	const [leituras, setLeituras] = useState([]);

	const fetchData = useCallback(async () => {
		if (!unidadeId && !condensacao && !regimes) return;
		try {
			const res = await apiVentiladores.getByUnidadeId(unidadeId);
			setVentiladores(res.data);

			if (res.data.length > 0) {
				const leituras = await Promise.all(
					res.data.map((ventilador) => {
						return fetchUltimaLeitura(ventilador.id);
					})
				);
				setLeituras(leituras);

				const condensadorVentiladores = res.data.filter(
					(ventilador) =>
						ventilador.tipoEquipamento === 'Condensador'
				);
				const condensadores = await Promise.all(
					condensadorVentiladores.map((ventilador) => {
						return fetchCondensador(
							ventilador.condensadorId
						);
					})
				);

				const condensadoresData = condensadores.map(
					(condensador, index) => {
						const ventilador =
							condensadorVentiladores[index];
						return {
							...ventilador,
							condensadorNome: condensador
								? condensador.nome
								: '-',
							regimeId: condensador
								? condensador.regimeId
								: '-',
						};
					}
				);
				setVentiladorCondensador(condensadoresData);
				const modelos = await Promise.all(
					res.data.map((ventilador) =>
						fetchModelo(ventilador.modeloId)
					)
				);
				setModelos(modelos);

				const marcas = await Promise.all(
					modelos.map((modelo) =>
						fetchMarca(modelo.marcaId)
					)
				);
				setMarcas(marcas);
			}
		} catch (error) {
			console.log(
				`Erro fetching ventiladores [SobreUnidade - Ventiladores]: ${error}`
			);
		}
	}, [unidadeId, condensacao, regimes]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	useEffect(() => {
		if (
			ventiladores.length > 0 &&
			modelos.length > 0 &&
			marcas.length > 0
		) {
			const updatedVentiladoresData = ventiladores.map(
				(ventilador) => {
					const modelo = modelos.find(
						(mo) => mo && mo.id === ventilador.modeloId
					);
					const marca = marcas.find(
						(ma) => ma && ma.id === modelo?.marcaId
					);
					const equipamento = ventiladorCondensador.find(
						(vc) => vc && vc.id === ventilador.id
					);
					const regimeId = ventiladorCondensador.find(
						(vc) => vc && vc.id === ventilador.id
					);
					const regimeName = regimes.find(
						(regime) =>
							regime &&
							regime.id === regimeId?.regimeId
					);
					const condesacaoName = condensacao.find(
						(condensacao) =>
							condensacao &&
							condensacao.id === regimeId?.regimeId
					);
					const leitura = leituras.find(
						(leitura) =>
							leitura &&
							leitura.ventiladorId === ventilador.id
					);

					return {
						...ventilador,
						modelo: modelo ? modelo.nome : '-',
						marca: marca ? marca.nome : '-',
						regimeId: regimeId
							? regimeId.regimeId
							: '-',
						regime: regimeName
							? regimeName.nome
							: condesacaoName.nome,
						equipamento: equipamento
							? equipamento.condensadorNome
							: '-',
						leitura: leitura ? leitura : '-',
					};
				}
			);
			setVentiladoresData(updatedVentiladoresData);
		}
	}, [
		ventiladores,
		modelos,
		marcas,
		ventiladorCondensador,
		leituras,
		regimes,
		condensacao,
	]);

	return (
		<div className="w-full">
			{ventiladoresData.length > 0 ? (
				<Table.Root>
					<Table.Head>
						<Table.Header>Nome</Table.Header>
						<Table.Header>Status</Table.Header>
						<Table.Header>Regime</Table.Header>
						<Table.Header>Equipamento</Table.Header>
						<Table.Header>Marca</Table.Header>
						<Table.Header>Modelo</Table.Header>
						<Table.Header>Corrente</Table.Header>
						<Table.Header>Frequencia</Table.Header>
						<Table.Header>Potência</Table.Header>
					</Table.Head>

					<Table.Body>
						{ventiladoresData.map((ventilador) => (
							<Table.Row key={ventilador.id}>
								<Table.Data>
									{ventilador.nome}
								</Table.Data>
								<Table.Data>
									{ventilador.leitura
										.ligado ? (
										<StatusOnOff
											status={
												ventilador
													.leitura
													.ligado
											}
										/>
									) : (
										<StatusOnOff
											status={false}
										/>
									)}
								</Table.Data>
								<Table.Data>
									{ventilador.regime}
								</Table.Data>
								<Table.Data>
									{ventilador.equipamento}
								</Table.Data>
								<Table.Data>
									{ventilador.marca}
								</Table.Data>
								<Table.Data>
									{ventilador.modelo}
								</Table.Data>
								<Table.Data>
									{ventilador.leitura
										.corrente ? (
										<>
											<InputGeneric
												className="max-w-[88px] mx-auto"
												value={ventilador.leitura.corrente.toLocaleString(
													'pt-BR',
													{
														maximumFractionDigits: 2,
													}
												)}
												type="string"
												id={
													'leituraBBcorrente' +
													ventilador.id
												}
												name={
													'leituraBBcorrente' +
													ventilador.id
												}
												readOnly
											/>
										</>
									) : (
										'-'
									)}
								</Table.Data>
								<Table.Data>
									{ventilador.leitura
										.frequencia ? (
										<>
											<InputGeneric
												className="max-w-[88px] mx-auto"
												value={
													ventilador.leitura.frequencia.toLocaleString(
														'pt-BR',
														{
															maximumFractionDigits: 2,
														}
													) +
													' Hz'
												}
												type="string"
												id={
													'leituraBBfrequencia' +
													ventilador.id
												}
												name={
													'leituraBBfrequencia' +
													ventilador.id
												}
												readOnly
											/>
										</>
									) : (
										'-'
									)}
								</Table.Data>
								<Table.Data>
									{ventilador.potenciaNominal +
										' CV'}
								</Table.Data>
							</Table.Row>
						))}
					</Table.Body>
				</Table.Root>
			) : (
				<NoEquipAlert equipments="ventiladores" />
			)}
		</div>
	);
};

export default TableVentiladores;
