import { apiLink } from '../../constants/env';
import axiosInstance from '../AxiosInstance';


const apiUsuario = {
    fetchApi: async (page) => {
        const URL = `${apiLink}api/usuario?page=` + page;
        return await axiosInstance.get(URL, {
            headers: {
                'Authorization': `bearer ${localStorage.getItem('token')}`
            }
        })
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();

            })
    },
    putApi: async (id, usuario) => {
        const URL = `${apiLink}api/usuario/${id}`;
        return await axiosInstance.put(URL, usuario)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();

            })
    },
    deleteApi: async (id) => {
        const URL = `${apiLink}api/usuario/status/${id}`;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();

            })
    },

}

export default apiUsuario;

