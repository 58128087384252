import CardLayout from '../../common/CardLayout';
import TitleCardSimple from '../../common/TitleCardSimple';
import { TbSum } from 'react-icons/tb';
import CardPeriodoMedido from './CardPeriodoMedido';

import ChartBarDefault from './charts/ChartBarDefault';

const ConsumoEnergiaMensal = ({ dados, dadosDetalhado }) => {
	const valores = dados?.map((valor) => {
		return valor.consumoAtivoTotal;
	});
	const dias = dados?.map((dia) => {
		const data = new Date(dia?.dataHora);
		const getDia = data?.getDate();

		const diaFormatado = getDia < 10 ? `0${getDia}` : `${getDia}`;
		return diaFormatado;
	});

	const grafico = {
		date: dias,
		consumoTotalDiario: valores,
	};

	return (
		<>
			<div className=" text-white">
				{/* <p> fazer o fetch para a data: {formattedDate}.</p> */}
			</div>
			<div className="flex justify-center flex-col items-center gap-2 p-2 w-full h-full">
				<div className="flex flex-col w-full">
					<CardLayout>
						<div className="flex flex-col justify-center items-center">
							<TitleCardSimple>
								Consumo de Energia{' '}
								<span className="text-accent">
									(
								</span>
								mensal
								<span className="text-accent">
									)
								</span>
							</TitleCardSimple>
						</div>
						<div className="flex justify-center items-center flex-coltext-white text-sm lg:text-lg">
							<p className="py-2 flex items-center px-2 lg:text-lg">
								Acumulado total
								<TbSum className="h-5 w-5 pl-1" />
							</p>
							<p className="drop-shadow-1xl text-lg lg:text-2xl font-bold bg-gradient-to-r from-accent via-indigo-500 to-accent inline-block text-transparent bg-clip-text">
								{dadosDetalhado?.consumoAtivoTotal?.toLocaleString(
									'pt-BR',
									{
										maximumFractionDigits: 2,
									}
								)}{' '}
								kWh
							</p>
						</div>
						<div className="flex justify-center items-center">
							<CardPeriodoMedido
								ponta={dadosDetalhado?.consumoAtivoPonta?.toLocaleString(
									'pt-BR',
									{
										maximumFractionDigits: 2,
									}
								)}
								FPCapacitivo={dadosDetalhado?.consumoAtivoForaDePontaCapacitivo?.toLocaleString(
									'pt-BR',
									{
										maximumFractionDigits: 2,
									}
								)}
								FPIndutivo={dadosDetalhado?.consumoAtivoForaDePontaIndutivo?.toLocaleString(
									'pt-BR',
									{
										maximumFractionDigits: 2,
									}
								)}
								dateFPCapacitivo={
									dadosDetalhado?.data
								}
								dateFPIndutivo={
									dadosDetalhado?.data
								}
								datePonta={dadosDetalhado?.data}
								unidade={'kWh'}
							/>
						</div>
						<div className="flex justify-center items-center">
							{grafico.date !== undefined && (
								<ChartBarDefault
									chartDataProps={grafico}
									nameSerie={
										'Consumo Acumulado ( kWh ) '
									}
									typeChart="consumoTotalMensal"
								/>
							)}
						</div>
					</CardLayout>
				</div>
			</div>
		</>
	);
};

export default ConsumoEnergiaMensal;
