import { createContext } from 'react';
import { ToastContainer, toast } from 'react-toastify';

export const ToastContext = createContext();

export const ToastContextProvider = ({ children }) => {
	const sucesso = (mensagem) => {
		toast.success(`${mensagem}`, {
			position: toast.POSITION.TOP_RIGHT,
			theme: 'dark',
			className: 'toast-container',
		});
	};

	const erro = (mensagem) => {
		toast.error(`${mensagem}`, {
			position: toast.POSITION.TOP_RIGHT,
			theme: 'dark',
			className: 'toast-container',
		});
	};

	const aviso = (mensagem) => {
		toast.warn(`${mensagem}`, {
			position: toast.POSITION.TOP_RIGHT,
			theme: 'dark',
			className: 'toast-container',
		});
	};

	return (
		<ToastContext.Provider value={{ sucesso, erro, aviso }}>
			{children}
			<ToastContainer />
		</ToastContext.Provider>
	);
};
