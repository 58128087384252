import React, { useState, useEffect, useCallback } from 'react';
import Table from '../../common/table';
import NoEquipAlert from '../../common/returns/NoEquipAlert';

import apiSistemas from '../../../services/apis/apiSistemaAguaGelada';
import apiModelo from '../../../services/apis/apiModelo';
import apiMarca from '../../../services/apis/apiMarca';

const TableSistemas = ({ regimes }) => {
	const [sistemas, setSistemas] = useState([]);
	const [sistemasData, setSistemasData] = useState([]);
	const [modelos, setModelos] = useState([]);
	const [marcas, setMarcas] = useState([]);

	const fetchData = useCallback(async () => {
		try {
			if (!regimes) return;
			const requests = regimes.map((regime) =>
				apiSistemas.getByRegimeId(regime.id)
			);
			const responses = await Promise.all(requests);
			const sistemasData = responses.map((res) => res.data).flat();
			setSistemas(sistemasData);

			if (sistemasData.length > 0) {
				const models = await Promise.all(
					sistemasData.map((sistema) => {
						return fetchModelo({
							modeloID: sistema.modeloId,
						});
					})
				);
				setModelos(models);
				const brands = await Promise.all(
					models.map((model) => {
						return fetchMarca({
							marcaId: model.marcaId,
						});
					})
				);
				setMarcas(brands);
			}
		} catch (error) {
			console.error(
				`Error fetching sistemas [SobreUnidade - Sistemas]: ${error}`
			);
		}
	}, [regimes]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);

	useEffect(() => {
		if (
			sistemas.length > 0 &&
			modelos.length > 0 &&
			marcas.length > 0
		) {
			const updatedCondensadorData = sistemas.map((sistema) => {
				const modelo = modelos.find(
					(model) => model.id === sistema.modeloId
				);
				const marca = marcas.find(
					(brand) => brand.id === (modelo && modelo.marcaId)
				);
				const regimeName = regimes.find(
					(regime) => regime.id === sistema.regimeId
				).nome;
				return {
					...sistema,
					marca: marca ? marca.nome : '-',
					modelo: modelo ? modelo.nome : '-',
					regime: regimeName ? regimeName : '-',
				};
			});
			setSistemasData(updatedCondensadorData);
		}
	}, [sistemas, modelos, marcas, regimes]);

	const fetchModelo = async ({ modeloID }) => {
		try {
			const res = await apiModelo.getById(modeloID);
			return res.data;
		} catch (error) {
			console.error(
				`Erro fetching modelo [SobreUnidade - Sistemas]: ${error}`
			);
		}
	};

	const fetchMarca = async ({ marcaId }) => {
		try {
			const res = await apiMarca.getById(marcaId);
			return res.data;
		} catch (error) {
			console.error(
				`Erro fetching marca [SobreUnidade - Sistemas]: ${error}`
			);
		}
	};

	return (
		<div className="w-full">
			{sistemasData.length > 0 ? (
				<Table.Root>
					<Table.Head>
						<Table.Header>Nome</Table.Header>
						<Table.Header>Regime</Table.Header>
						<Table.Header>Marca</Table.Header>
						<Table.Header>Modelo</Table.Header>
						<Table.Header>Vazao</Table.Header>
						<Table.Header>Temp. Entrada</Table.Header>
						<Table.Header>Temp. Saida</Table.Header>
						<Table.Header>Potência</Table.Header>
						<Table.Header>Carga Térmica</Table.Header>
					</Table.Head>
					<Table.Body>
						{sistemasData.map((sistema) => (
							<Table.Row key={sistema.id}>
								<Table.Data>
									{sistema.nome}
								</Table.Data>
								<Table.Data>
									{sistema.regime}
								</Table.Data>
								<Table.Data>
									{sistema.modelo}
								</Table.Data>
								<Table.Data>
									{sistema.marca}
								</Table.Data>
								<Table.Data>
									{sistema.vazao.toLocaleString(
										'pt-BR',
										{
											maximumFractionDigits: 2,
										}
									) + ' m³/ h'}
								</Table.Data>
								<Table.Data>
									{sistema.temperaturaEntrada.toLocaleString(
										'pt-BR',
										{
											maximumFractionDigits: 2,
										}
									) + ' °C'}
								</Table.Data>
								<Table.Data>
									{sistema.temperaturaSaida.toLocaleString(
										'pt-BR',
										{
											maximumFractionDigits: 2,
										}
									) + ' °C'}
								</Table.Data>
								<Table.Data>
									{sistema.potencia.toLocaleString(
										'pt-BR',
										{
											maximumFractionDigits: 2,
										}
									) + ' CV'}
								</Table.Data>
								<Table.Data>
									{sistema.cargaTermica.toLocaleString(
										'pt-BR',
										{
											maximumFractionDigits: 2,
										}
									) + ' Kcal/ h'}
								</Table.Data>
							</Table.Row>
						))}
					</Table.Body>
				</Table.Root>
			) : (
				<NoEquipAlert equipments="sistemas de água" />
			)}
		</div>
	);
};

export default TableSistemas;
