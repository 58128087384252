
import { useState } from "react";
import { AlertDialog, AlertDialogTrigger } from "../../../../components/ui/alert-dialog";
import ListaMarca from "./ListaMarca";
export default function MarcaForm({ styles }) {
    const [key, setKey] = useState(1);
    const [pesquisar, setPesquisar] = useState('');
    const [funcaoModal, setFuncaoModal] = useState('Adicionar');

    const funcaoModalController =  {
        funcaoModal:funcaoModal,
        setFuncaoModal:setFuncaoModal,
    }

    function atualizarLista() {
        setKey(key + 1);
    }

    function pesquisarNome(e) {
        e.preventDefault();
        setPesquisar(e.target.inputPesquisar.value);
    }



    return(
        <AlertDialog>
			<div className="pt-[50px] max-w-full">
				<h1 className="text-[45px] md:ml-5 max-sm:text-center">
					Marca
				</h1>
				<h2 className="text-[30px] md:ml-5 max-sm:text-center">
					{/* Quantidade: {quantidade} */}
				</h2>

				<div className="flex content-center justify-center mt-[50px]">
					<div className="flex flex-wrap justify-center">
						<form onSubmit={(e) => pesquisarNome(e)}>
							<input
								type="text"
								className="bg-transparent rounded-l-md border border-lightBlue p-1 text-[19px] h-[40px] align-bottom "
								id="inputPesquisar"
								name="inputPesquisar"
								placeholder="insira o nome da marca"
							/>
							<button
								className="rounded-r-md bg-lightBlue w-[40px] h-[40px] max-sm:"
								title="Pesquisar"
							>
								<i className="fa-solid fa-magnifying-glass"></i>
							</button>
						</form>
						<AlertDialogTrigger>
							<div
								className="rounded-md bg-lightBlue w-[40px] h-[40px] md:ml-3 cursor-pointer max-sm:mt-4 max-sm:w-full flex justify-center items-center"
								title="Adicionar"
								onClick={() =>
									setFuncaoModal(
										'Adicionar'
									)
								}
							>
								<i className="fa-solid fa-plus"></i>
							</div>
						</AlertDialogTrigger>
					</div>
				</div>
                <ListaMarca 
                atualizarLista={atualizarLista} 
                key={key} 
                pesquisar={pesquisar}
                funcaoModalController={funcaoModalController}
                />

            </div>
        </AlertDialog>
    )
} 