import React from 'react';

export const TableRoot = ({ children }) => {
	return (
		<>
			<div className="flex flex-col h-full justify-center item-center">
				<div className="inline-block min-w-full align-middle">
					<div className="overflow-x-auto scrollbar-thin scrollbar-track-primary scrollbar-thumb-darkaccent border-[1px] border-gray-700  shadow-lg rounded-lg">
						<table className="w-full divide-y divide-glassColor text-white md:text-[0.8rem] text-sm rounded-lg mb-2">
							{children}
						</table>
					</div>
				</div>
			</div>
		</>
	);
};
