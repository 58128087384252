'use client';
import Chart from 'react-apexcharts';

const ChartViewDefault = ({ chartDataProps, typeChart, nameSerie }) => {
	let data = [];
	if (typeChart === 'ganhoEnergetico') {
		data = chartDataProps.map(
			(data) => data.leitura?.ganhoEnergetico || 0
		);
	} else if (typeChart === 'tbuAdmissao') {
		data = chartDataProps.map((data) => data.leitura?.tbuAdmissao || 0);
	} else if (typeChart === 'umidadeRelativaSaida') {
		data = chartDataProps.map(
			(data) => data.leitura?.umidadeRelativaSaida || 0
		);
	}

	const qtd = chartDataProps.map((data) => data.nome).length;
	let tamFont;

	if (qtd <= 10) {
		tamFont = '16px';
	} else if (qtd <= 20) {
		tamFont = '14px';
	} else {
		tamFont = '11px';
	}

	const options = {
		chart: {
			id: 'basic-bar',
			toolbar: {
				show: false,
			},
		},
		grid: {
			show: false,
			borderColor: '#40475D',
			strokeDashArray: 0,
		},
		plotOptions: {
			bar: {
				borderRadius: 3,
				borderRadiusApplication: 'end',
				borderRadiusWhenStacked: 'last',
				columnWidth: '90%',
				horizontal: false,
				dataLabels: {
					position: 'top',
				},
			},
		},
		dataLabels: {
			enabled: true,
			textAnchor: 'middle', //
			style: {
				colors: ['#FFF'],
				fontSize: tamFont,
			},
			offsetY: 0,
			offsetX: 0,
			dropShadow: {
				enabled: true,
				left: 2,
				top: 2,
				opacity: 0.5,
			},
			formatter: function (val, index) {
				return val.toLocaleString('pt-BR', {
					maximumFractionDigits: 2,
				});
			},
		},
		stroke: {
			width: 1,
			colors: ['#33333380'],
		},
		xaxis: {
			categories: chartDataProps.map((data) => data.nome),
			position: 'bottom',
			axisBorder: {
				show: false,
			},
			axisTicks: {
				show: false,
				color: '#40475D',
			},
			labels: {
				show: true,
				style: {
					colors: 'white',
					fontSize: tamFont,
				},
				formatter: function (val, index) {
					return val.toLocaleString('pt-BR', {
						maximumFractionDigits: 2,
					});
				},
			},
		},
		yaxis: {
			reversed: false,
			opposite: false,
			floating: false,
			axisBorder: {
				show: false,
			},
			axisTicks: {
				show: false,
				color: '#40475D',
			},
			labels: {
				show: false,
				style: {
					colors: 'white',
					fontSize: '12px',
					fontStyle: 'bold',
				},
				formatter: function (val, index) {
					return val.toLocaleString('pt-BR', {
						maximumFractionDigits: 2,
					});
				},
			},
		},
		tooltip: {
			theme: 'dark',
		},
		fill: {
			type: 'gradient',
			gradient: {
				type: 'radial',
				shadeIntensity: 1,
				gradientToColors: ['#6078ea'], // optional, if not defined - uses the shades of same color in series
				inverseColors: false,
				opacityFrom: 1,
				opacityTo: 1,
				stops: [],
				colorStops: [],
			},
		},
		legend: {
			show: true,
			showForSingleSeries: true,
			showForNullSeries: true,
			showForZeroSeries: true,
			position: 'top',
			fontStyle: 'bold',
			fontColor: 'white',
			fontSize: '16px',
			labels: {
				colors: 'white',
				useSeriesColors: false,
			},
			onItemClick: {
				toggleDataSeries: true,
			},
			onItemHover: {
				highlightDataSeries: true,
			},
		},
	};

	const series = [
		{
			name: nameSerie ? nameSerie : '',
			data: data,
			color: '#0CABF5',
		},
	];

	return (
		<div className="bg-transparent w-full h-full px-[1px]">
			<Chart
				options={options}
				series={series}
				type="bar"
				width="100%"
				height={320}
			/>
		</div>
	);
};

export default ChartViewDefault;
