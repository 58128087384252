import React from 'react';

const MainButton = ({ 
	children = "insert a text",
	onClick = () => {},
	styles = {style:"bg-gradient-to-t from-cyan-500 to-blue-700 hover:from-cyan-600 hover:to-blue-800"},
	type = "submit",
}) => {
	const {style} = styles;
	return (
		<>
			<button className={`rounded-3xl w-full h-full shadow-2xl cursor-pointer ${style}`} onClick={onClick()} type={type}>
				{children}
			</button>
		</>
	);
};

export default MainButton;
