import React, { useState } from 'react';
import { TiWarning, TiTick } from 'react-icons/ti';

const IconStatus = ({ status }) => {
	const [showDescription, setShowDescription] = useState(false);
	const statusData = [
		{
			type: 'ok',
			description: 'Corrigido',
			icon: (
				<TiTick className="text-[#00FF00] h-5 w-5 cursor-pointer" />
			),
		},
		{
			type: 'warning',
			description: 'Requer atenção',
			icon: (
				<TiWarning className="text-yellow-400 animate-pulse h-5 w-5 cursor-pointer" />
			),
		},
	];

	const currentStatus = statusData.find((item) => item.type === status);

	if (!currentStatus) return null;

	const { description, icon } = currentStatus;

	return (
		<div
			className="relative flex justify-center items-center"
			onMouseEnter={() => setShowDescription(true)}
			onMouseLeave={() => setShowDescription(false)}
		>
			{icon}
			{showDescription && (
				<div className="absolute z-10 inline-block p-2 text-sm font-semibold drop-shadow-1xl text-white transition-opacity duration-300 bg-gray-600 rounded-md shadow-sm right-16 w-32">
					<span className="">{description}</span>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 24 24"
						className="absolute -right-[10px] top-[1.5px]  h-5 w-auto text-gray-600"
						fill="currentColor"
					>
						<path d="M11.303 8l11.394 7.997L11.303 24z" />
					</svg>
				</div>
			)}
		</div>
	);
};

export default IconStatus;
