import { apiLink } from '../../constants/env';
import axiosInstance from '../AxiosInstance';

const apiCalculosCompressores = {
    calculoCompressor: async (valores) =>{
        const URL = `${apiLink}api/calculos-compressor`;
        return await axiosInstance.post(URL, valores)
        .then((res) => res)
        .catch(error => {
            console.error('Erro ao buscar os dados:', error);
            throw new Error();

        })
    },
    calculoCompressorByCompressorId: async (compressorId) =>{
        const URL = `${apiLink}api/calculos-compressor?compressorId=${compressorId}`;
        return await axiosInstance.get(URL)
        .then((res) => res)
        .catch(error => {
            console.error('Erro ao buscar os dados:', error);
            throw new Error();

        })
    },
}

export default apiCalculosCompressores;

