import { useState, useEffect, useContext, useCallback } from 'react';
import apiRegimes from '../../../../services/apis/apiRegimes';
import { ToastContext } from '../../../../contexts/ToastContext';
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from '../../../../components/ui/dialog';
import {
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
	AlertDialogTrigger,
} from '../../../../components/ui/alert-dialog';
import apiUnidade from '../../../../services/apis/apiUnidade';
import apiEmpresa from '../../../../services/apis/apiEmpresa';
import apiCondensadores from '../../../../services/apis/apiCondensadores';
import apiVentilador from '../../../../services/apis/apiVentilador';
import { useParams } from 'react-router-dom';
import apiGrupo from '../../../../services/apis/apiGrupo';
import apiMarca from '../../../../services/apis/apiMarca';
import apiModelo from '../../../../services/apis/apiModelo';
import { getParentMap } from '../../../../funcs/getParent';
import PaginationBar from '../../../../components/common/PaginationBar';
import apiEnum from '../../../../services/apis/apiEnum';
import apiAmbientes from '../../../../services/apis/apiAmbientes';
import apiEvaporador from '../../../../services/apis/apiEvaporador';

export default function ListaVentilador({
	atualizarLista,
	pesquisar,
	funcaoModalController,
}) {
	const [nome, setNome] = useState('');
	const [tipoEquipamento, setTipoEquipamento] = useState('');
	const [showCondensadorForm, setShowCondensadorForm] = useState(false);
	const [showEvaporadorForm, setShowEvaporadorForm] = useState(false);
	const [listaAmbientes, setListaAmbientes] = useState([]);
	const [listaEvaporadores, setListaEvaporadores] = useState([]);
	const [listaRegimes, setListaRegimes] = useState([]);
	const [ambienteId, setAmbienteId] = useState(0);
	const [regimeId, setRegimeId] = useState(0);
	const [tiposEquipamento, setTiposEquipamento] = useState([]);
	const [potenciaNominal, setPotenciaNominal] = useState(0);
	const [idEditar, setIdEditar] = useState();
	const [itemRemovido, setItemRemovido] = useState();
	const [idRemover, setIdRemover] = useState();
	const [listaModelos, setListaModelos] = useState([]);
	const [listaUnidades, setListaUnidades] = useState([]);
	const [listaMarcas, setListaMarcas] = useState([]);
	const [listaEmpresas, setListaEmpresas] = useState([]);
	const [listaGrupos, setListaGrupos] = useState([]);
	const [listaVentilador, setListaVentilador] = useState([]);
	const [listaRegimesTabela, setListaRegimeTabela] = useState(new Map());
	const [listaCondensadoresTabela, setListaCondensadoresTabela] = useState(new Map());
	const [listaAmbientesTabela ,setListaAmbientesTabela] = useState(new Map());
	const [listaEvaporadoresTabela, setListaEvaporadoresTabela] = useState(new Map());
	const [listaUnidadeTabela, setListaUnidadeTabela] = useState(new Map());
	const [listaEmpresaTabela, setListaEmpresaTabela] = useState(new Map());
	const [marcaId, setMarcaId] = useState(0);
	const [grupoId, setGrupoId] = useState(0);
	const [empresaId, setEmpresaId] = useState(0);
	const [regime, setRegime] = useState();
	const [unidadeId, setUnidadeId] = useState(0);
	const [modeloId, setModeloId] = useState(0);
	const { page } = useParams();
	const [pagination, setPagination] = useState();
	const [equipamentoId, setEquipamentoId] = useState(0);
	const [listaCondensadores, setListaCondensadores] = useState([]);
	const funcaoModal = funcaoModalController.funcaoModal;

	const { erro, sucesso } = useContext(ToastContext);

	const  getParentsObjects = useCallback((id, tipoEquipamento = 'Condensador') => {
		let equipamento;
		let ambiente;
		let regime;
		if(tipoEquipamento === 'Evaporador') {
			equipamento = getParentMap(listaEvaporadoresTabela, id);
			ambiente = getParentMap(listaAmbientesTabela, equipamento?.ambienteId);
			regime = getParentMap(listaRegimesTabela, ambiente?.regimeId);
		} else {
			equipamento = getParentMap(listaCondensadoresTabela, id);
			regime = getParentMap(listaRegimesTabela, equipamento?.regimeId );
		}
		const unidade = getParentMap(listaUnidadeTabela, regime?.unidadeId);
		const empresa = getParentMap(listaEmpresaTabela, unidade?.empresaId);
		return {
			equipamento: equipamento,
			ambiente:ambiente,
			regime: regime,
			unidade: unidade,
			empresa: empresa,
	}},[listaEvaporadoresTabela, listaAmbientesTabela, listaCondensadoresTabela, listaRegimesTabela, listaUnidadeTabela, listaEmpresaTabela]);

	useEffect(() => {
		apiVentilador.fetchApi(page).then((res) => {
			setListaVentilador(res.data.items);
			setPagination(res.data);
		});
		apiGrupo.fetchApi().then((res) => {
			setListaGrupos(res.data);
		});
		apiMarca.fetchApi().then((res) => {
			setListaMarcas(res.data);
		});
		apiEnum.getTiposEquipamentoVentilador().then(res => {
			setTiposEquipamento(res.data);
		});
	}, [page]);

	useEffect(() => {
		if(tipoEquipamento === 'Condensador') {
			setShowCondensadorForm(true);
			setShowEvaporadorForm(false);			
		} else if(tipoEquipamento === 'Evaporador') {
			setShowCondensadorForm(false);
			setShowEvaporadorForm(true);
		}
	},[tipoEquipamento]);

	useEffect(() => {
		marcaId !== undefined &&
			apiModelo.fetchApi(marcaId, 'Ventilador').then((res) => {
				setListaModelos(res.data);
			});
	}, [marcaId]);

	useEffect(() => {
		!isNaN(grupoId) &&
			apiEmpresa.getByGrupoApi(grupoId).then((res) => {
				setListaEmpresas(res.data);
			});
	}, [grupoId]);

	useEffect(() => {
		!isNaN(empresaId) &&
			apiUnidade.getByEmpresaApi(empresaId).then((res) => {
				setListaUnidades(res.data);
			});
	}, [empresaId]);

	useEffect(() => {
		unidadeId !== "" &&
			apiRegimes.getByUnidadeId(unidadeId).then((res) => {
				if(tipoEquipamento === 'Evaporador') {
					setListaRegimes(res.data)
				} else {
					const regime = res.data.filter(
						(elemento) =>
							elemento.tipoRegime === 'Condensação'
					)[0];
					setRegime(regime);
				}
			});
	}, [unidadeId, tipoEquipamento]);

	useEffect(() => {
		ambienteId !== undefined &&
		!isNaN(ambienteId) &&
		apiEvaporador.getByAmbienteId(ambienteId).then(res => {
			setListaEvaporadores(res.data);
		});
	},[ambienteId]);

	useEffect(() => {
		regimeId !== undefined &&
		!isNaN(regimeId) &&
		apiAmbientes.getByRegimeId(regimeId).then(res => {
			setListaAmbientes(res.data);
		});
	},[regimeId]);

	useEffect(() => {
		regime !== undefined &&
			apiCondensadores.getByRegimeId(regime.id).then((res) => {
				setListaCondensadores(res.data);
			});
	}, [regime]);

	useEffect(() => {
		listaVentilador.length > 0 &&
			listaVentilador.forEach((elemento) => {
				if(elemento.tipoEquipamento === 'Condensador') {
					apiCondensadores
						.getById(elemento.condensadorId)
						.then((res) => {
							const condensador = res.data;
							setListaCondensadoresTabela(map => new Map(map.set(condensador.id, condensador)));
						});
				} else {	
					apiEvaporador.getById(elemento.evaporadorId)
					.then((res) => {
						const evaporador = res.data;
						setListaEvaporadoresTabela(map => new Map(map.set(evaporador.id, evaporador)));
					});
				}
			});
	}, [listaVentilador]);
	
	useEffect(() => {
		listaEvaporadoresTabela.size > 0 &&
			listaEvaporadoresTabela.forEach((elemento) => {
				apiAmbientes.getById(elemento.ambienteId).then((res) => {
					const ambiente = res.data;
					setListaAmbientesTabela(map => new Map(map.set(ambiente.id, ambiente)));
				});
			});
	}, [listaEvaporadoresTabela]);

	useEffect(() => {
		if(listaCondensadoresTabela.size > 0) {
			listaCondensadoresTabela.forEach((elemento) => {
				apiRegimes.getById(elemento.regimeId).then((res) => {
					const regime = res.data;
					setListaRegimeTabela(map => new Map(map.set(regime.id, regime)));
				});
			});
		}  
		if(listaAmbientesTabela.size > 0) {
			listaAmbientesTabela.forEach((elemento) => {
				apiRegimes.getById(elemento.regimeId).then((res) => {
					const regime = res.data;
					setListaRegimeTabela(map => new Map(map.set(regime.id, regime)));
				});
			});
		}
	}, [listaCondensadoresTabela, listaAmbientesTabela]);

	useEffect(() => {
		listaRegimesTabela.size > 0 &&
			listaRegimesTabela.forEach((elemento) => {
				apiUnidade.getById(elemento.unidadeId).then((res) => {
					const unidade = res.data;
					setListaUnidadeTabela(map => new Map(map.set(unidade.id, unidade)));
				});
			});
	}, [listaRegimesTabela]);

	useEffect(() => {
		listaUnidadeTabela.size > 0 &&
			listaUnidadeTabela.forEach((elemento) => {
				apiEmpresa.getById(elemento.empresaId).then((res) => {
					const empresa = res.data;
					setListaEmpresaTabela(map => new Map(map.set(empresa.id, empresa)));
				});
			});
	}, [listaUnidadeTabela]);

	function limparInputs() {
		setNome('');
		setMarcaId(0);
		setUnidadeId(0);
		setModeloId(0);
		setEmpresaId(0);
	}
	
	function deletar(id) {
		apiVentilador
			.deleteApi(id)
			.then((res) => {
				atualizarLista();
				sucesso('Ventilador deletado com sucesso!');
			})
			.catch((error) => {
				console.error(error.message);
				erro('Erro ao deletar ventilador!');
			});
	}
	function enviar(e) {
		e.preventDefault();
		let ventilador = {
			nome: nome,
			potenciaNominal: parseFloat(potenciaNominal),
			equipamentoId: equipamentoId,
			tipoEquipamento:tipoEquipamento,
			modeloId: modeloId,
		};
		
		
		if (funcaoModal === 'Adicionar') {
			apiVentilador
				.postApi(ventilador)
				.then((res) => {
					limparInputs();
					sucesso('Ventilador adicionada com sucesso!');
					atualizarLista();
				})
				.catch((error) => {
					erro('Erro ao adicionar ventilador!');
					console.error(error.message);
				});
		} else if (funcaoModal === 'Editar') {
			apiVentilador
				.putApi(idEditar, ventilador)
				.then((res) => {
					limparInputs();
					atualizarLista();
					sucesso('Ventilador editado com sucesso!');
				})
				.catch((error) => {
					console.error(error.message);
					erro('Erro ao editar ventilador!');
				});
		}
	}

	

	return (
		<>
			<Dialog>
				<div className="w-full flex flex-col items-center mt-[40px] md:px-[200px]">
					<table className="w-full">
						<thead className="border-b w-full">
							<tr>
								<th className="text-[25px] pl-[10px] text-start">
									Nome
								</th>
								<th className="text-[25px] pl-[10px] text-start">
									Equipamento
								</th>
								<th className="text-[25px] pl-[10px] text-start">
									Unidade
								</th>
							</tr>
						</thead>
						<tbody>
							{listaVentilador.map(
								(ventilador, i) =>
									ventilador.nome
										.toUpperCase()
										.startsWith(
											pesquisar.toUpperCase()
										) && (
										<tr
											className="border-b hover:bg-gray-200 hover:bg-opacity-5"
											key={i}
										>
											<td className="pl-[10px] text-[20px]">
												{
													ventilador.nome
												}
											</td>

											<td className="pl-[10px] text-[20px]">
												{
													getParentsObjects(
														ventilador?.condensadorId | ventilador?.evaporadorId, ventilador.tipoEquipamento
													)
														?.equipamento
														?.nome
												}
											</td>
											<td className="flex justify-between py-1">
												<span className="text-[20px] pl-[10px]">
													{
														getParentsObjects(
															ventilador?.condensadorId | ventilador?.evaporadorId, ventilador.tipoEquipamento
														)
															?.unidade
															?.nome
													}
												</span>
												<span className="flex">
													<AlertDialogTrigger>
														<div
															onClick={() => {
																const {
																	unidade,
																	empresa,
																	equipamento,
																	regime,
																	ambiente,
																} =
																	getParentsObjects(
																		ventilador.condensadorId | ventilador?.evaporadorId, ventilador.tipoEquipamento
																	);
																apiModelo
																	.getById(
																		ventilador.modeloId
																	)
																	.then(
																		(
																			res
																		) => {
																			setMarcaId(
																				res
																					.data
																					.marcaId
																			);
																			setModeloId(
																				res
																					.data
																					.id
																			);
																		}
																	);

																setIdEditar(
																	ventilador.id
																);
																setTipoEquipamento(
																	ventilador.tipoEquipamento
																);
																setNome(
																	ventilador.nome
																);

																setPotenciaNominal(
																	ventilador.potenciaNominal
																);

																setGrupoId(
																	empresa.grupoId
																);

																setEmpresaId(
																	empresa.id
																);
																setUnidadeId(
																	unidade.id
																);
																setRegime(
																	regime
																);
																setRegimeId(
																	regime.id
																);
																if(ventilador.tipoEquipamento === 'Evaporador') {
																	setAmbienteId(
																		ambiente.id
																	);
																}
																setEquipamentoId(
																	equipamento.id
																);
																funcaoModalController.setFuncaoModal(
																	'Editar'
																);
															}}
														>
															<i
																className="fa-solid fa-pen-to-square text-[18px] hover:text-lightBlue"
																title="Editar"
															></i>
														</div>
													</AlertDialogTrigger>
													<DialogTrigger>
														<div
															className="mx-2.5"
															onClick={() => {
																setItemRemovido(
																	ventilador.nome
																);
																setIdRemover(
																	ventilador.id
																);
															}}
														>
															<i
																className="fa-solid fa-trash text-[18px] hover:text-lightBlue"
																title="Deletar"
															></i>
														</div>
													</DialogTrigger>
												</span>
											</td>
										</tr>
									)
							)}
						</tbody>
					</table>
					{pagination !== undefined && (
						<PaginationBar pagination={pagination} />
					)}
				</div>

				<DialogContent>
					<DialogHeader>
						<DialogTitle>
							Excluir {itemRemovido}?
						</DialogTitle>
					</DialogHeader>
					<DialogDescription>
						Todos os dados associados com {itemRemovido}{' '}
						serão removidos.
					</DialogDescription>
					<DialogFooter>
						<div
							className="bg-red-600/70 py-2 px-4 rounded-md hover:bg-red-600 hover:cursor-pointer border-slate-800 border-2 transition-all duration-200 ease-in-out"
							onClick={() => deletar(idRemover)}
						>
							<DialogClose>Excluir</DialogClose>
						</div>
					</DialogFooter>
				</DialogContent>
			</Dialog>

			<AlertDialogContent>
				<form onSubmit={(e) => enviar(e)}>
					<AlertDialogHeader>
						<AlertDialogTitle>
							{funcaoModal} Ventilador
						</AlertDialogTitle>
					</AlertDialogHeader>
					<div className="w-full h-[500px] flex justify-around my-5 px-5 flex-wrap gap-5 overflow-y-auto scrollbar-thin scrollbar-track-primary scrollbar-thumb-darkaccent">
						{/* NOME */}
						<div className="flex flex-col justify-end">
							<label
								className="text-[18px] mb-2 pl-1"
								htmlFor="nome"
							>
								Nome
							</label>
							<input
								id="nome"
								name="nome"
								type="text"
								placeholder="nome do ventilador"
								className="border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[180px]"
								value={nome}
								onChange={(e) =>
									setNome(e.target.value)
								}
								required
							/>
						</div>

						{/* CAPCIDADE NOMINAL */}
						<div className="flex flex-col justify-end">
							<label
								htmlFor="potenciaNominal"
								className="text-[16px] mb-2 pl-1"
							>
								Potência nominal
							</label>
							<input
								id="potenciaNominal"
								name="potenciaNominal"
								type="number"
								className="border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[180px]"
								value={potenciaNominal}
								onChange={(e) =>
									setPotenciaNominal(
										e.target.value
									)
								}
								required
							/>
						</div>
						{/* TIPO EQUIPAMENTO */}
						<div className="flex flex-col justify-end">
							<label
								htmlFor="tipoEquipamento"
								className="text-[18px] mb-2 pl-1"
							>
								Tipo Equipamento
							</label>
							<select
								id="tipoEquipamento"
								className="border-b text-[20px] h-[42.8px] bg-[#0b101d] rounded-t-md w-[180px]"
								name="tipoEquipamento"
								value={tipoEquipamento}
								required
								onChange={(e) =>
									setTipoEquipamento(e.target.value)
								}
							>
								<option label=" "> </option>
								{
									tiposEquipamento.map((tipo, i) => (
										<option key={i} value={tipo}>{tipo}</option>	
									))
								}
							</select>
						</div>

						{/* MARCA */}
						<div className="flex flex-col justify-end">
							<label
								className="text-[18px] mb-2 pl-1"
								htmlFor="marcaId"
							>
								Marca
							</label>
							<select
								className="border-b text-[20px] h-[42.8px] bg-[#0b101d] rounded-t-md w-[180px]"
								name="marcaId"
								id="marcaId"
								required
								value={marcaId}
								onChange={(e) =>
									setMarcaId(
										parseInt(
											e.target.value
										)
									)
								}
							>
								<option label="   "></option>
								{listaMarcas.map((marca, i) => (
									<option
										value={marca.id}
										key={i}
									>
										{marca.nome}
									</option>
								))}
							</select>
						</div>
						{/* MODELO */}
						<div className="flex flex-col justify-end">
							<label
								className="text-[18px] mb-2 pl-1"
								htmlFor="modeloVentiladorId"
							>
								Modelo
							</label>
							<select
								className="border-b text-[20px] h-[42.8px] bg-[#0b101d] rounded-t-md w-[180px]"
								name="modeloVentiladorId"
								id="modeloVentiladorId"
								value={modeloId}
								required
								onChange={(e) =>
									setModeloId(
										parseInt(
											parseInt(
												e.target
													.value
											)
										)
									)
								}
							>
								<option label="   "></option>
								{listaModelos.map(
									(modelo, i) => (
										<option
											value={
												modelo.id
											}
											key={i}
										>
											{modelo.nome}
										</option>
									)
								)}
							</select>
						</div>
						{/* GRUPOS */}
						<div className="flex flex-col justify-end">
							<label
								className="text-[18px] mb-2 pl-1"
								htmlFor="grupoId"
							>
								Grupo
							</label>
							<select
								className="border-b text-[20px] h-[42.8px] bg-[#0b101d] rounded-t-md w-[180px]"
								name="grupoId"
								id="grupoId"
								required
								value={grupoId}
								onChange={(e) =>
									setGrupoId(
										parseInt(
											e.target.value
										)
									)
								}
							>
								<option label="   "></option>
								{listaGrupos.map(
									(empresa, i) => (
										<option
											value={
												empresa.id
											}
											key={i}
										>
											{empresa.nome}
										</option>
									)
								)}
							</select>
						</div>
						{/* EMPRESAS */}
						<div className="flex flex-col justify-end">
							<label
								className="text-[18px] mb-2 pl-1"
								htmlFor="empresaId"
							>
								Empresa
							</label>
							<select
								className="border-b text-[20px] h-[42.8px] bg-[#0b101d] rounded-t-md w-[180px]"
								name="empresaId"
								id="empresaId"
								required
								value={empresaId}
								onChange={(e) =>
									setEmpresaId(
										parseInt(
											e.target.value
										)
									)
								}
							>
								<option label="   "></option>
								{listaEmpresas.map(
									(empresa, i) => (
										<option
											value={
												empresa.id
											}
											key={i}
										>
											{empresa.nome}
										</option>
									)
								)}
							</select>
						</div>
						{/* UNIDADES */}
						<div className="flex flex-col justify-end">
							<label
								className="text-[18px] mb-2 pl-1"
								htmlFor="unidadeId"
							>
								Unidade
							</label>
							<select
								className="border-b text-[20px] h-[42.8px] bg-[#0b101d] rounded-t-md w-[180px]"
								name="unidadeId"
								id="unidadeId"
								required
								value={unidadeId}
								onChange={(e) =>
									setUnidadeId(
											e.target.value
									)
								}
							>
								<option label="   "></option>
								{listaUnidades
									.filter(
										(elemento) =>
											elemento.empresaId ===
											empresaId
									)
									.map((unidade, i) => (
										<option
											value={
												unidade.id
											}
											key={i}
										>
											{unidade.nome}
										</option>
									))}
							</select>
						</div>

						{/* CONDENSADOR */}
						<div className={`flex flex-col justify-end ${!showCondensadorForm && 'hidden'}`}>
							<label
								className="text-[18px] mb-2 pl-1"
								htmlFor="equipamentoId"
							>
								Condensador
							</label>
							<select
								className="border-b text-[20px] h-[42.8px] bg-[#0b101d] rounded-t-md w-[180px]"
								name="equipamentoId"
								id="equipamentoId"
								required={showCondensadorForm}
								value={equipamentoId}
								onChange={(e) =>
									setEquipamentoId(
										parseInt(
											e.target.value
										)
									)
								}
							>
								<option label="   "></option>
								{listaCondensadores.map(
									(condensador, i) => (
										<option
											value={
												condensador.id
											}
											key={i}
										>
											{
												condensador.nome
											}
										</option>
									)
								)}
							</select>
						</div>
						{/* REGIME */}
						<div className={`flex flex-col justify-end ${!showEvaporadorForm && 'hidden'}`}>
							<label
								className="text-[18px] mb-2 pl-1"
								htmlFor="regimeId"
							>
								Regime
							</label>
							<select
								className="border-b text-[20px] h-[42.8px] bg-[#0b101d] rounded-t-md w-[180px]"
								name="regimeId"
								id="regimeId"
								required={showEvaporadorForm}
								value={regimeId}
								onChange={(e) =>
									setRegimeId(
										parseInt(
											e.target.value
										)
									)
								}
							>
								<option label="   "></option>
								{listaRegimes.map(
									(condensador, i) => (
										<option
											value={
												condensador.id
											}
											key={i}
										>
											{
												condensador.nome
											}
										</option>
									)
								)}
							</select>
						</div>

						{/* AMBIENTE */}
						<div className={`flex flex-col justify-end ${!showEvaporadorForm && 'hidden'}`}>
							<label
								className="text-[18px] mb-2 pl-1"
								htmlFor="ambienteId"
							>
								Ambiente
							</label>
							<select
								className="border-b text-[20px] h-[42.8px] bg-[#0b101d] rounded-t-md w-[180px]"
								name="ambienteId"
								id="ambienteId"
								required={showEvaporadorForm}
								value={ambienteId}
								onChange={(e) =>
									setAmbienteId(
										parseInt(
											e.target.value
										)
									)
								}
							>
								<option label="   "></option>
								{listaAmbientes.map(
									(condensador, i) => (
										<option
											value={
												condensador.id
											}
											key={i}
										>
											{
												condensador.nome
											}
										</option>
									)
								)}
							</select>
						</div>

						{/* EVAPORADOR */}
						<div className={`flex flex-col justify-end ${!showEvaporadorForm && 'hidden'}`}>
							<label
								className="text-[18px] mb-2 pl-1"
								htmlFor="evaporadorId"
							>
								Evaporador
							</label>
							<select
								className="border-b text-[20px] h-[42.8px] bg-[#0b101d] rounded-t-md w-[180px]"
								name="evaporadorId"
								id="evaporadorId"
								required={showEvaporadorForm}
								value={equipamentoId}
								onChange={(e) =>
									setEquipamentoId(
										parseInt(
											e.target.value
										)
									)
								}
							>
								<option label="   "></option>
								{listaEvaporadores.map(
									(evaporador, i) => (
										<option
											value={
												evaporador.id
											}
											key={i}
										>
											{
												evaporador.nome
											}
										</option>
									)
								)}
							</select>
						</div>
					</div>
					<AlertDialogFooter>
						<div className="flex w-full justify-end gap-x-4 p-4">
							<AlertDialogAction className="w-32">
								Confirmar
							</AlertDialogAction>
							<AlertDialogCancel className="w-32">
								Cancelar
							</AlertDialogCancel>
						</div>
					</AlertDialogFooter>
				</form>
			</AlertDialogContent>
		</>
	);
}
