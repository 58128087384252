import { apiLink } from '../../constants/env';
import axiosInstance from '../AxiosInstance';

const apiSolicitacaoSuporte = {
    send: async (form) => {
        const URL = `${apiLink}api/suporte`;
        return await axiosInstance.post(URL, form,  {
            headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
        .then((res) => res)
        .catch(error => {
            console.error('Erro ao buscar os dados:', error);
            throw new Error(error);

        })
    },
    
}

export default apiSolicitacaoSuporte;

