import React, { useCallback, useEffect, useRef, useState } from 'react';
import InputGeneric from '../common/InputGeneric';
import SwitchOnOff from '../common/SwitchOnOff';
import Table from '../common/table';
import apiCondensadores from '../../services/apis/apiCondensadores';
import apiModelo from '../../services/apis/apiModelo';
import { TableHeaderHigh, TableHeaderLow } from './TableHeaders';
import apiSimulacaoUnidade from '../../services/apis/apiSimulacaoUnidade';
import { getPctg } from '../../funcs';
import NoEquipAlert from '../common/returns/NoEquipAlert';

const TableCondensador = ({
	condensacaoId,
	bombas,
	ventiladores,
	temperatura,
	tbu,
	setCapacidadesRegimes,
}) => {
	const [condensadores, setCondensadores] = useState([]);
	const [condensadoresData, setCondensadoresData] = useState([]);
	const [modelos, setModelos] = useState([]);
	const hasRunned = useRef(false);

	useEffect(() => {
		hasRunned.current = false;
	},[tbu, temperatura]);

	const fetchData = useCallback(async () => {
		try {
			if (!condensacaoId) return;
			const res = await apiCondensadores.getByRegimeId(
				condensacaoId
			);
			const cds = res.data;
			setCondensadores(cds);

			const modelos = await Promise.all(
				cds.map((cd) => fetchModelo(cd.modeloId))
			);
			setModelos(modelos);
		} catch (error) {
			console.error(error.message);
		}
	}, [condensacaoId]);

	useEffect(() => {
		fetchData();
	}, [fetchData]);


	const fetchModelo = async (modeloId) => {
		try {
			const res = await apiModelo.getById(modeloId);
			return res.data;
		} catch (error) {
			console.error(error.message);
		}
	};

	useEffect(() => {
		if (
			condensadores.length > 0 && 
			condensadoresData.length === 0 
		) {
			const updateCds = condensadores.map((cd) => {
				return {
					...cd,
					bombas: bombas.filter((bomba) => bomba.condensadorId === cd.id),
					ventiladores: ventiladores.filter((ventilador) => ventilador.condensadorId === cd.id),
					capacidade: 100,
					status: true,
					modelo: modelos.find(
						(modelo) => modelo.id === cd.modeloId
					),
				};
			});
			setCondensadoresData(updateCds);
		}
	}, [condensadores, modelos, condensadoresData, ventiladores, bombas]);

	const getPotenciaBomba = useCallback((bombasCondensador) => {
		return bombasCondensador.reduce(
			(acumulador, valorAtual) =>
				acumulador + valorAtual.potenciaNominal,
			0
		);
	}, []);

	const getPotenciaVentiladores = useCallback((ventiladoresCondensador) => {
		return ventiladoresCondensador.reduce(
			(acumulador, valorAtual) =>
				acumulador + valorAtual.potenciaNominal,
			0
		);
	}, []);

	const atualizarRegime = useCallback(async () => {
		condensadoresData.forEach(async (condensador) => {
			setCapacidadesRegimes((current) => {
				const aux = [...current];
				const index = aux.findIndex(
					(capacidade) =>
						condensador.regimeId === capacidade.regimeId
				);
				const capacidade = aux[index];

				if (capacidade?.condensadores !== undefined) {
					capacidade.condensadores = [...condensadoresData];
				}

				return aux;
			});
		});
	}, [condensadoresData, setCapacidadesRegimes]);


	useEffect(() => {
		atualizarRegime();
	}, [atualizarRegime]);

	useEffect(() => {
		if (condensadoresData.length > 0 && !hasRunned.current) {
			hasRunned.current = true;
			condensadoresData.forEach((condensador) => {
				const aux = [...condensadoresData];
				apiSimulacaoUnidade
					.getCalculosCondensador(
						tbu,
						condensador.capacidadeNominal,
						temperatura
					)
					.then((res) => {
						const index = aux.findIndex(
							(a) => a.id === condensador.id
						);
						const novaCargaTermica = res.data;
						if (
							aux[index].cargaTermica !== novaCargaTermica
						) {
							aux[index].cargaTermica = parseFloat(
								res.data
							);
							setCondensadoresData(aux);
						}
					})
					.catch((error) => console.error(error.message));
			});
		}
	}, [condensadoresData, tbu, temperatura]);

	const handleStatus = (handleSwitch) => {
		const { equipamento } = handleSwitch;
		const status = !equipamento.status;
		setCondensadoresData((prevCondensador) => {
			const novoCondensador = [
				...prevCondensador.map((cd) => {
					if (cd.id === equipamento.id) {
						return { ...cd, status: status };
					}
					return cd;
				}),
			];
			return novoCondensador;
		});
	};

	const handleCapacidade = useCallback(
		(condensador, capacidade) => {
			const index = condensadoresData.findIndex(
				(cs) => cs.id === condensador.id
			);
			const novoCompressor = [...condensadoresData];
			novoCompressor[index].capacidade = capacidade;
			setCondensadoresData(novoCompressor);
		},
		[condensadoresData]
	);

	const getModeloById = useCallback(
		(id) => {
			return modelos.find((modelo) => modelo.id === id);
		},
		[modelos]
	);

	return (
		<>
			<div className="flex flex-col h-full justify-center item-center">
				<div className="inline-block min-w-full align-middle">
					<div className="overflow-hidden overflow-x-auto scrollbar-thin scrollbar-track-primary scrollbar-thumb-darkaccent">
						{condensadoresData.length > 0 ? (
							<table className="min-w-full divide-y divide-glassColor text-white md:text-[0.8rem] text-xs">
								<thead className="py-4">
									<tr>
										<TableHeaderHigh
											colSpan={3}
										>
											<div className="border border-accent rounded py-2">
												Condensador
											</div>
										</TableHeaderHigh>

										<TableHeaderHigh
											colSpan={2}
										>
											<div className="border border-accent rounded py-2">
												Capacidade
											</div>
										</TableHeaderHigh>
										<TableHeaderHigh
											colSpan={2}
										>
											<div className="border border-accent rounded py-2">
												Bombas
											</div>
										</TableHeaderHigh>
										<TableHeaderHigh
											colSpan={2}
										>
											<div className="border border-accent rounded py-2">
												Ventiladores
											</div>
										</TableHeaderHigh>
									</tr>
									<tr>
										<TableHeaderLow>
											TAG
										</TableHeaderLow>

										<TableHeaderLow>
											Status
										</TableHeaderLow>
										<TableHeaderLow>
											Modelo
										</TableHeaderLow>
										<TableHeaderLow>
											( % )
										</TableHeaderLow>
										<TableHeaderLow>
											(kcal/ h)
										</TableHeaderLow>
										<TableHeaderLow>
											Qtd
										</TableHeaderLow>
										<TableHeaderLow>
											Potência
										</TableHeaderLow>
										<TableHeaderLow>
											Qtd
										</TableHeaderLow>
										<TableHeaderLow>
											Potência
										</TableHeaderLow>
									</tr>
								</thead>
								<Table.Body>
									{condensadoresData.length >
										0 &&
										condensadoresData.map(
											(cd) => (
												<Table.Row
													key={
														cd.id
													}
												>
													<Table.Data className="uppercase text-accent font-bold text-[14px] 2xl:text-lg">
														{
															cd.nome
														}
													</Table.Data>

													<Table.Data>
														<SwitchOnOff
															status={
																'on'
															}
															handleSwitch={{
																handleSwitch:
																	handleStatus,
																equipamento:
																	cd,
															}}
														/>
													</Table.Data>
													<Table.Data>
														{
															getModeloById(
																cd.modeloId
															)
																?.nome
														}
													</Table.Data>
													<Table.Data className="max-w-[80px] px-2">
														<InputGeneric
															id="capPercentual"
															name="capPercentual"
															defaultValue={
																100
															}
															onChange={(
																e
															) =>
																handleCapacidade(
																	cd,
																	e
																		.target
																		.value
																)
															}
														/>
													</Table.Data>
													<Table.Data>
														{getPctg(
															cd.cargaTermica,
															cd.capacidade
														)?.toLocaleString(
															'pt-BR',
															{
																maximumFractionDigits: 2,
															}
														) ||
															0}
													</Table.Data>
													<Table.Data className="max-w-[80px] px-2">
														{
															bombas.filter(
																(
																	bomba
																) =>
																	bomba.condensadorId ===
																	cd.id
															)
																.length
														}
													</Table.Data>

													<Table.Data>
														{getPotenciaBomba(
															bombas.filter(
																(
																	bomba
																) =>
																	bomba.condensadorId ===
																	cd.id
															)
														)}
														{
															' CV'
														}
													</Table.Data>
													<Table.Data className="max-w-[80px] px-2">
														{
															ventiladores.filter(
																(
																	ventilador
																) =>
																	ventilador.condensadorId ===
																	cd.id
															)
																.length
														}
													</Table.Data>
													<Table.Data>
														{getPotenciaVentiladores(
															ventiladores.filter(
																(
																	ventilador
																) =>
																	ventilador.condensadorId ===
																	cd.id
															)
														)}
														{
															' CV'
														}
													</Table.Data>
												</Table.Row>
											)
										)}
								</Table.Body>
							</table>
						) : (
							<NoEquipAlert equipments="condensadores" />
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default TableCondensador;
