import React from 'react';

export default function NoEquipAlert({ equipments, regime = false }) {
	return (
		<div className="flex items-center justify-center w-full h-full">
			<p className="flex flex-col justify-center items-center py-4 text-center font-normal italic text-secondary text-[1rem] 2xl:text-lg drop-shadow-1xl">{`Não há ${equipments} cadastrados para ${
				regime ? 'esse regime.' : 'essa unidade.'
			}`}</p>
		</div>
	);
}
