export function TableHeaderHigh({ children, ...props }) {
	return (
		<th
			scope="col"
			className="py-1 font-semibold tracking-wider text-center uppercase text-accent drop-shadow-1xl"
			{...props}
		>
			{children}
		</th>
	);
}

export function TableHeaderLow({ children, ...props }) {
	return (
		<th
			scope="col"
			className="pb-2 pl-2 mx-auto font-semibold tracking-wider text-center uppercase text-secondary drop-shadow-1xl whitespace-nowrap"
			{...props}
		>
			{children}
		</th>
	);
}
