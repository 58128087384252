import React, { useCallback, useEffect, useRef, useState } from 'react';
import CardLayout from '../common/CardLayout';
import TitleCard from './TitleCard';
import Table from '../common/table';
import InputGeneric from '../common/InputGeneric';
import SwitchOnOff from '../common/SwitchOnOff';
import NoEquipAlert from '../common/returns/NoEquipAlert';

import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from '../../components/ui/accordion';

import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '../ui/select';
import apiMaquinaGelo from '../../services/apis/apiMaquinaGelo';
import apiSimulacaoUnidade from '../../services/apis/apiSimulacaoUnidade';
import { delayTime } from '../../constants';

export default function ListMaquinasGelo({ regimes, setCapacidadesRegimes }) {
	const [maquinasGelo, setMaquinasGelo] = useState([]);
	const debounceTimeout = useRef(null);

	useEffect(() => {
		if (maquinasGelo.length === 0) {
			regimes.forEach((regime) => {
				apiMaquinaGelo.getByRegimeId(regime.id).then((res) => {
					res.data.forEach((maquinaGelo) => {
						setMaquinasGelo((current) => [
							...current,
							{ ...maquinaGelo, status: true },
						]);
					});
				});
			});
		}
	}, [regimes, maquinasGelo]);

	useEffect(() => {
		setCapacidadesRegimes((current) => {
			const aux = [...current];
			aux.map((capacidade) => {
				return (capacidade.maquinasGelo = maquinasGelo.filter(
					(maquinaGelo) =>
						maquinaGelo.regimeId === capacidade.regimeId
				));
			});
			return aux;
		});
	}, [maquinasGelo, setCapacidadesRegimes]);

	const handleCapacidadeProducao = useCallback(
		(maquinaGelo, novaCapacidadeProducao) => {
			const index = maquinasGelo.findIndex(
				(sa) => sa.id === maquinaGelo.id
			);
			const novoMaquinaGelo = [...maquinasGelo];
			novoMaquinaGelo[index].capacidadeProducao =
				novaCapacidadeProducao === ''
					? 0
					: parseFloat(novaCapacidadeProducao);
			setMaquinasGelo(novoMaquinaGelo);
		},
		[maquinasGelo]
	);

	const trocarRegimeEquipamento = useCallback(
		(maquinaGelo, novoRegimeId) => {
			const index = maquinasGelo.findIndex(
				(a) => a.id === maquinaGelo.id
			);
			const newMaquinaGelo = [...maquinasGelo];
			newMaquinaGelo[index].regimeId = novoRegimeId;
			setMaquinasGelo(newMaquinaGelo);
		},
		[maquinasGelo]
	);

	const handleStatus = (handleSwitch) => {
		const { equipamento } = handleSwitch;
		const status = !equipamento.status;
		setMaquinasGelo((prevMaquinasGelo) => {
			const novoMaquinaGelo = [
				...prevMaquinasGelo.map((mg) => {
					if (mg.id === equipamento.id) {
						return { ...mg, status: status };
					}
					return mg;
				}),
			];
			return novoMaquinaGelo;
		});
	};

	useEffect(() => {
		if (debounceTimeout.current) {
			clearTimeout(debounceTimeout.current);
		}

		debounceTimeout.current = setTimeout(() => {
			maquinasGelo.forEach((sistemaAgua) => {
				const aux = [...maquinasGelo];
				apiSimulacaoUnidade
					.getCalculoCargaTermicaMaquinaGelo(
						sistemaAgua.id,
						sistemaAgua.capacidadeProducao
					)
					.then((res) => {
						const index = aux.findIndex(
							(a) => a.id === sistemaAgua.id
						);
						const novaCargaTermica = res.data;
						if (
							aux[index].cargaTermica !==
							novaCargaTermica
						) {
							aux[index].cargaTermica = parseFloat(
								res.data
							);
							setMaquinasGelo(aux);
						}
					})
					.catch((error) => console.error(error.message));
			});
		}, delayTime);
	}, [maquinasGelo]);

	return (
		<CardLayout>
			<Accordion
				type="single"
				defaultValue="item-1"
				collapsible="true"
			>
				<AccordionItem value="item-1">
					<AccordionTrigger>
						<TitleCard>
							Lista de Maquinas de Gelo
						</TitleCard>
					</AccordionTrigger>

					<AccordionContent>
						<div className="w-full">
							<Table.Root>
								<Table.Head>
									<Table.Header>
										Nome
									</Table.Header>
									<Table.Header>
										Status
									</Table.Header>
									<Table.Header>
										Regime
									</Table.Header>
									<Table.Header>
										Produção
										<span className="text-xs inline capitalize px-[2px]">
											(kg/dia)
										</span>
									</Table.Header>
									<Table.Header>
										Massa de Gelo
									</Table.Header>
									<Table.Header>
										Carga Térmica
									</Table.Header>
								</Table.Head>
								<Table.Body>
									{maquinasGelo.map(
										(maquinaGelo) => (
											<Table.Row
												key={
													maquinaGelo.id
												}
											>
												<Table.Data>
													{
														maquinaGelo.nome
													}
												</Table.Data>
												<Table.Data>
													<SwitchOnOff
														status="on"
														handleSwitch={{
															handleSwitch:
																handleStatus,
															equipamento:
																maquinaGelo,
														}}
													/>
												</Table.Data>
												<Table.Data>
													<Select
														id="regimeSAGSelect"
														defaultValue={
															maquinaGelo.regimeId
														}
														onValueChange={(
															id
														) =>
															trocarRegimeEquipamento(
																maquinaGelo,
																id
															)
														}
													>
														<SelectTrigger className="w-full">
															<SelectValue placeholder="Regime" />
														</SelectTrigger>
														<SelectContent>
															{regimes.map(
																(
																	regime,
																	i
																) => (
																	<SelectItem
																		key={
																			regime.id
																		}
																		value={
																			regime.id
																		}
																	>
																		{
																			regime.nome
																		}
																	</SelectItem>
																)
															)}
														</SelectContent>
													</Select>
												</Table.Data>
												<Table.Data className="px-auto">
													<InputGeneric
														className="max-w-[88px] mx-auto"
														defaultValue={
															maquinaGelo.capacidadeProducao
														}
														id={
															'temperaturaId'
														}
														name={
															'temperaturaId'
														}
														onChange={(
															e
														) =>
															handleCapacidadeProducao(
																maquinaGelo,
																e
																	.target
																	.value
															)
														}
													/>
												</Table.Data>
												<Table.Data>
													{maquinaGelo.massaGeloHora.toLocaleString(
														'pt-BR',
														{
															maximumFractionDigits: 2,
														}
													) +
														' kg/h'}
												</Table.Data>
												<Table.Data>
													{maquinaGelo.cargaTermica.toLocaleString(
														'pt-BR',
														{
															maximumFractionDigits: 2,
														}
													) +
														' Kcal/ h'}
												</Table.Data>
											</Table.Row>
										)
									)}
									{maquinasGelo.length ===
										0 && (
										<Table.Row className="text-center ">
											<th
												scope="col"
												colSpan="10"
												className="text-center"
											>
												<NoEquipAlert equipments="máquinas de gelo" />
											</th>
										</Table.Row>
									)}
								</Table.Body>
							</Table.Root>
						</div>
					</AccordionContent>
				</AccordionItem>
			</Accordion>
		</CardLayout>
	);
}
