import { apiLink } from '../../constants/env';
import axiosInstance from '../AxiosInstance';

const apiEnum = {
    getTiposUnidade: async () => {
        const URL = `${apiLink}api/enum/tipo-unidade`;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error(error.message);

            })
    },
    getTiposProduto: async () => {
        const URL = `${apiLink}api/enum/tipo-produto`;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error(error.message);

            })
    },
    getTiposMiudo: async () => {
        const URL = `${apiLink}api/enum/tipo-miudo`;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error(error.message);

            })
    },
    getTiposRegime: async () => {
        const URL = `${apiLink}api/enum/tipo-regime`;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error(error.message);

            })
    },
    getTiposAmbiente: async () => {
        const URL = `${apiLink}api/enum/tipo-ambiente`;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error(error.message);

            })
    },
    getAcionamentos: async () => {
        const URL = `${apiLink}api/enum/acionamento`;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error(error.message);

            })
    },
    getTiposEquipamento: async () => {
        const URL = `${apiLink}api/enum/tipo-equipamento`;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error(error.message);

            })
    },
    getTiposEquipamentoVentilador: async () => {
        const URL = `${apiLink}api/enum/tipo-equipamento-ventilador`;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error(error.message);

            })
    },
    getTiposOperacaoCompressor: async () => {
        const URL = `${apiLink}api/enum/tipo-operacao-compressor`;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error(error.message);

            })
    },
}

export default apiEnum;

