import React from 'react';
import SwitchOnOff from '../common/SwitchOnOff';
import Table from '../common/table';
import { TableHeaderHigh, TableHeaderLow } from './TableHeaders';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '../ui/select';

import InputGeneric from '../common/InputGeneric';
import { getPctg } from '../../funcs';
import NoEquipAlert from '../common/returns/NoEquipAlert';

const TableCompressor = ({
	AllRegimes,
	calculosCompressor,
	compressoresData,
	tiposOperacao,
	handleTemperaturaDescarga,
	handleTemperaturaSuccaoByRegimeId,
	handleOperacao,
	handleCapacidade,
	handleStatus,
}) => {
	return (
		<>
			<div className="flex flex-col h-full justify-center item-center">
				<div className="inline-block min-w-full align-middle">
					<div className="overflow-hidden overflow-x-auto scrollbar-thin scrollbar-track-primary scrollbar-thumb-darkaccent">
						{compressoresData.length > 0 ? (
							<table className="min-w-full divide-y divide-glassColor text-white md:text-[0.8rem] text-xs">
								<thead className="py-4">
									<tr>
										<TableHeaderHigh
											colSpan={4}
										>
											<div className="border border-accent rounded py-2">
												Compressor
											</div>
										</TableHeaderHigh>
										<TableHeaderHigh
											colSpan={2}
										>
											<div className="border border-accent rounded py-2">
												Transdutor
											</div>
										</TableHeaderHigh>

										<TableHeaderHigh
											colSpan={2}
										>
											<div className="border border-accent rounded py-2">
												Capacidade
											</div>
										</TableHeaderHigh>

										<TableHeaderHigh>
											<div className="border border-accent rounded py-2 px-1">
												Potência
											</div>
										</TableHeaderHigh>
									</tr>
									<tr>
										<TableHeaderLow>
											TAG
										</TableHeaderLow>
										<TableHeaderLow>
											Status
										</TableHeaderLow>
										<TableHeaderLow>
											Modelo
										</TableHeaderLow>
										<TableHeaderLow>
											Operação
										</TableHeaderLow>
										<TableHeaderLow>
											Sucção
										</TableHeaderLow>
										<TableHeaderLow>
											Descarga
										</TableHeaderLow>

										<TableHeaderLow className="px-3 text-secondary">
											(%)
										</TableHeaderLow>
										<TableHeaderLow>
											(kcal/ h)
										</TableHeaderLow>
										<TableHeaderLow>
											(kW)
										</TableHeaderLow>
									</tr>
								</thead>

								<Table.Body>
									{compressoresData.length >
										0 &&
										compressoresData.map(
											(cp) => (
												<Table.Row
													key={
														cp.id
													}
												>
													<Table.Data className="font-bold text-accent xl:text-lg">
														{
															cp.nome
														}
													</Table.Data>
													<Table.Data>
														<SwitchOnOff
															status={
																'on'
															}
															handleSwitch={{
																handleSwitch:
																	handleStatus,
																equipamento:
																	cp,
															}}
														/>
													</Table.Data>
													<Table.Data>
														{
															cp
																?.modelo
																?.nome
														}
													</Table.Data>
													<Table.Data>
														<Select
															id="tipoOperacaoId"
															defaultValue={
																cp.tipoOperacaoCompressorId
															}
															onValueChange={(
																id
															) =>
																handleOperacao(
																	cp,
																	id
																)
															}
														>
															<SelectTrigger className="w-full">
																<SelectValue placeholder="Tipo Operação" />
															</SelectTrigger>
															<SelectContent>
																{tiposOperacao
																	.filter(
																		(
																			to
																		) =>
																			to.modeloCompressorId ===
																			cp.modeloCompressorId
																	)
																	.map(
																		(
																			to,
																			i
																		) => (
																			<SelectItem
																				key={
																					to.id
																				}
																				value={
																					to.id
																				}
																			>
																				{to.nome.replaceAll(
																					'_',
																					' '
																				)}
																			</SelectItem>
																		)
																	)}
															</SelectContent>
														</Select>
													</Table.Data>
													<Table.Data>
														<Select
															id="temperaturaSuccaoId"
															defaultValue={
																cp.regimeSuccaoId
															}
															onValueChange={(
																id
															) =>
																handleTemperaturaSuccaoByRegimeId(
																	cp,
																	id
																)
															}
														>
															<SelectTrigger className="w-full">
																<SelectValue placeholder="Regime" />
															</SelectTrigger>
															<SelectContent>
																{AllRegimes.filter(
																	(
																		regime
																	) =>
																		regime.tipoRegime !==
																		'Condensação'
																).map(
																	(
																		regime,
																		i
																	) => (
																		<SelectItem
																			key={
																				regime.id
																			}
																			value={
																				regime.id
																			}
																		>
																			{
																				regime.nome
																			}
																		</SelectItem>
																	)
																)}
															</SelectContent>
														</Select>
													</Table.Data>

													<Table.Data>
														<Select
															id="temperaturaDescargaSelect"
															defaultValue={
																cp.regimeDescargaId
															}
															onValueChange={(
																id
															) =>
																handleTemperaturaDescarga(
																	cp,
																	id
																)
															}
														>
															<SelectTrigger className="w-full">
																<SelectValue placeholder="Regime" />
															</SelectTrigger>
															<SelectContent>
																{AllRegimes.map(
																	(
																		regime,
																		i
																	) => (
																		<SelectItem
																			key={
																				regime.id
																			}
																			value={
																				regime.id
																			}
																		>
																			{
																				regime.nome
																			}
																		</SelectItem>
																	)
																)}
															</SelectContent>
														</Select>
													</Table.Data>
													<Table.Data className="px-2 min-w-[10px] max-w-[80px]">
														<InputGeneric
															id="capPercent"
															name="capPercent"
															defaultValue={
																100
															}
															onChange={(
																e
															) =>
																handleCapacidade(
																	cp,
																	e
																		.target
																		.value
																)
															}
														/>
													</Table.Data>
													<Table.Data>
														{getPctg(
															calculosCompressor.get(
																cp.id
															)
																?.capacidadeRealCalculada,
															cp.capacidade
														)?.toLocaleString(
															'pt-BR',
															{
																maximumFractionDigits: 2,
															}
														)}
													</Table.Data>
													<Table.Data>
														{getPctg(
															calculosCompressor.get(
																cp.id
															)
																?.potenciaRealCalculada,
															cp.capacidade
														)?.toLocaleString(
															'pt-BR',
															{
																maximumFractionDigits: 2,
															}
														)}
													</Table.Data>
												</Table.Row>
											)
										)}
								</Table.Body>
							</table>
						) : (
							<>
								<NoEquipAlert
									equipments="compressores"
									regime={true}
								/>
							</>
						)}
					</div>
				</div>
			</div>
		</>
	);
};
export default TableCompressor;
