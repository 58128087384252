import { apiLink } from '../../constants/env';
import axiosInstance from '../AxiosInstance';

const apiMaquinaGelo = {
    fetchApi: async (page) => {
        const URL = `${apiLink}api/maquina-gelo?page=` + page;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();
            })
    },
    getById: async (id) => {
        const URL = `${apiLink}api/maquina-gelo/` + id;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();
            })
    },
    postApi: async (maquinaGelo) => {
        const URL = `${apiLink}api/maquina-gelo`;
        return await axiosInstance.post(URL, maquinaGelo)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();
            })
    },
    deleteApi: async (id) => {
        const URL = `${apiLink}api/maquina-gelo/status/${id}`;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();

            })
    },
    putApi: async (id, maquinaGelo) => {
        const URL = `${apiLink}api/maquina-gelo/${id}`;
        return await axiosInstance.put(URL, maquinaGelo)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();

            })
    },
    getByRegimeId: async (id) => {
        const URL = `${apiLink}api/maquina-gelo/regime/${id}`;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao pegar dados da máquina de gelo:', error);
                throw new Error();
            })
    },

}

export default apiMaquinaGelo; 