import { useContext } from "react";

import { AuthContext } from "../../contexts/AuthContext";

import { Outlet, useNavigate } from "react-router-dom";

export default function Admin() {
    
    const {estaAutorizado} = useContext(AuthContext);
    const navigate = useNavigate();
    
    if(estaAutorizado('SUPER_USER', 'ADMIN')) {
        return(
            <div className="text-fontColor">
                <Outlet/>
            </div>
        )
    } else {
        navigate('/login');
    }
} 