export default function Simulacao({
    potenciaRealCalculada,
    capacidadeRealCalculada,
}) {
    return(
        <div className="w-full bg-[#0b101d] text-center p-5 rounded-t-md border-b">
            <p>Capacidade Compressor: {capacidadeRealCalculada}</p>
            <p>Potência Compressor: {potenciaRealCalculada}</p>
        </div>
    )
}