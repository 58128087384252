import React, { useCallback, useEffect, useRef, useState } from 'react';
import CardLayout from '../common/CardLayout';
import TitleCard from './TitleCard';
import Table from '../common/table';
import InputGeneric from '../common/InputGeneric';
import SwitchOnOff from '../common/SwitchOnOff';

import {
	Accordion,
	AccordionContent,
	AccordionItem,
	AccordionTrigger,
} from '../../components/ui/accordion';
import {
	Select,
	SelectContent,
	SelectItem,
	SelectTrigger,
	SelectValue,
} from '../ui/select';
import apiSistemaAguaGelada from '../../services/apis/apiSistemaAguaGelada';
import apiSimulacaoUnidade from '../../services/apis/apiSimulacaoUnidade';
import { delayTime } from '../../constants';

export default function ListSistemasAgua({ regimes, setCapacidadesRegimes }) {
	const [sistemasAgua, setSistemasAgua] = useState([]);
	const debounceTimeout = useRef(null);

	useEffect(() => {
		setSistemasAgua([]);
		regimes.forEach((regime) => {
			apiSistemaAguaGelada.getByRegimeId(regime.id).then((res) => {
				res.data.forEach((sistemaAgua) => {
					setSistemasAgua((current) => [
						...current,
						{ ...sistemaAgua, status: true },
					]);
				});
			});
		});
	}, [regimes]);

	useEffect(() => {
		setCapacidadesRegimes((current) => {
			const aux = [...current];
			aux.map((capacidade) => {
				return (capacidade.sistemasAgua = sistemasAgua.filter(
					(ambiente) =>
						ambiente.regimeId === capacidade.regimeId
				));
			});
			return aux;
		});
	}, [sistemasAgua, setCapacidadesRegimes]);

	const handleTemperaturaEntrada = useCallback(
		(sistemaAgua, novaTemperaturaEntrada) => {
			const index = sistemasAgua.findIndex(
				(sa) => sa.id === sistemaAgua.id
			);
			const novoSistemaAgua = [...sistemasAgua];
			novoSistemaAgua[index].temperaturaEntrada =
				novaTemperaturaEntrada === ''
					? 0
					: parseFloat(novaTemperaturaEntrada);
			setSistemasAgua(novoSistemaAgua);
		},
		[sistemasAgua]
	);

	const handleTemperaturaSaida = useCallback(
		(sistemaAgua, novaTemperaturaSaida) => {
			const index = sistemasAgua.findIndex(
				(sa) => sa.id === sistemaAgua.id
			);
			const novoSistemaAgua = [...sistemasAgua];
			novoSistemaAgua[index].temperaturaSaida =
				novaTemperaturaSaida === ''
					? 0
					: parseFloat(novaTemperaturaSaida);
			setSistemasAgua(novoSistemaAgua);
		},
		[sistemasAgua]
	);

	const trocarRegimeEquipamento = useCallback(
		(sistemaAgua, novoRegimeId) => {
			const index = sistemasAgua.findIndex(
				(a) => a.id === sistemaAgua.id
			);
			const newSistemasAgua = [...sistemasAgua];
			newSistemasAgua[index].regimeId = novoRegimeId;
			setSistemasAgua(newSistemasAgua);
		},
		[sistemasAgua]
	);

	const handleStatus = (handleSwitch) => {
		const { equipamento } = handleSwitch;
		const status = !equipamento.status;
		setSistemasAgua((prevSistemasAgua) => {
			const novoSistemaAgua = [
				...prevSistemasAgua.map((sa) => {
					if (sa.id === equipamento.id) {
						return { ...sa, status: status };
					}
					return sa;
				}),
			];
			return novoSistemaAgua;
		});
	};

	useEffect(() => {
		if (debounceTimeout.current) {
			clearTimeout(debounceTimeout.current);
		}

		debounceTimeout.current = setTimeout(() => {
			sistemasAgua.forEach((sistemaAgua) => {
				const aux = [...sistemasAgua];
				apiSimulacaoUnidade
					.getCalculoCargaTermicaSistemaAgua(
						sistemaAgua.id,
						sistemaAgua.temperaturaEntrada,
						sistemaAgua.temperaturaSaida
					)
					.then((res) => {
						const index = aux.findIndex(
							(a) => a.id === sistemaAgua.id
						);
						const novaCargaTermica = res.data;
						if (
							aux[index].cargaTermica !==
							novaCargaTermica
						) {
							aux[index].cargaTermica = parseFloat(
								res.data
							);
							setSistemasAgua(aux);
						}
					})
					.catch((error) => console.error(error.message));
			});
		}, delayTime);
	}, [sistemasAgua]);

	return (
		<CardLayout>
			<Accordion
				type="single"
				defaultValue="item-1"
				collapsible="true"
			>
				<AccordionItem value="item-1">
					<AccordionTrigger>
						<TitleCard>
							Lista de Sistemas de Água
						</TitleCard>
					</AccordionTrigger>
					<AccordionContent>
						<div className="w-full">
							<Table.Root>
								<Table.Head>
									<Table.Header>
										Nome
									</Table.Header>
									<Table.Header>
										Status
									</Table.Header>
									<Table.Header>
										Regime
									</Table.Header>
									<Table.Header>
										Vazão
									</Table.Header>
									<Table.Header>
										Temperatura Entrada
									</Table.Header>
									<Table.Header>
										Temperatura Saída
									</Table.Header>
									<Table.Header>
										Carga Térmica
									</Table.Header>
								</Table.Head>
								<Table.Body>
									{sistemasAgua.map(
										(sistemaAgua) => (
											<Table.Row
												key={
													sistemaAgua.id
												}
											>
												<Table.Data>
													{
														sistemaAgua.nome
													}
												</Table.Data>
												<Table.Data>
													<SwitchOnOff
														status="on"
														handleSwitch={{
															handleSwitch:
																handleStatus,
															equipamento:
																sistemaAgua,
														}}
													/>
												</Table.Data>
												<Table.Data>
													<Select
														id="regimeSAGSelect"
														defaultValue={
															sistemaAgua.regimeId
														}
														onValueChange={(
															id
														) =>
															trocarRegimeEquipamento(
																sistemaAgua,
																id
															)
														}
													>
														<SelectTrigger className="w-full">
															<SelectValue placeholder="Regime" />
														</SelectTrigger>
														<SelectContent>
															{regimes.map(
																(
																	regime,
																	i
																) => (
																	<SelectItem
																		key={
																			regime.id
																		}
																		value={
																			regime.id
																		}
																	>
																		{
																			regime.nome
																		}
																	</SelectItem>
																)
															)}
														</SelectContent>
													</Select>
												</Table.Data>
												<Table.Data>
													{sistemaAgua.vazao.toLocaleString(
														'pt-BR',
														{
															maximumFractionDigits: 2,
														}
													) +
														' m³/ h'}
												</Table.Data>
												<Table.Data>
													<div className="relative rounded-md shadow-sm flex-1 mx-auto max-w-[88px]">
														<InputGeneric
															unidade="°C"
															defaultValue={
																sistemaAgua.temperaturaEntrada
															}
															id={
																'temperaturaId'
															}
															name={
																'temperaturaId'
															}
															onChange={(
																e
															) =>
																handleTemperaturaEntrada(
																	sistemaAgua,
																	e
																		.target
																		.value
																)
															}
														/>
													</div>
												</Table.Data>
												<Table.Data className="">
													<div className="relative rounded-md shadow-sm flex-1 mx-auto max-w-[88px]">
														<InputGeneric
															unidade="°C"
															defaultValue={
																sistemaAgua.temperaturaSaida
															}
															id={
																'temperaturaId'
															}
															name={
																'temperaturaId'
															}
															onChange={(
																e
															) =>
																handleTemperaturaSaida(
																	sistemaAgua,
																	e
																		.target
																		.value
																)
															}
														/>
													</div>
												</Table.Data>
												<Table.Data>
													{sistemaAgua.cargaTermica.toLocaleString(
														'pt-BR',
														{
															maximumFractionDigits: 2,
														}
													) +
														' Kcal/ h'}
												</Table.Data>
											</Table.Row>
										)
									)}
								</Table.Body>
							</Table.Root>
						</div>
					</AccordionContent>
				</AccordionItem>
			</Accordion>
		</CardLayout>
	);
}
