import React from 'react';
import { useLocation } from 'react-router-dom'; // Importe useLocation do react-router-dom

const Breadcrumb = () => {
	const { pathname } = useLocation(); // Use useLocation para obter o pathname atual

	const pathSegments = pathname
		.split('/')
		.filter((segment) => segment !== '');

	const breadcrumbItems = pathSegments.map((segment, index) => ({
		label: segment,
		href: `/${pathSegments.slice(0, index + 1).join('/')}`,
		title: `Go to ${segment}`,
	}));

	const isHomePage = pathname === '/';
	if (isHomePage) {
		return null;
	}

	return (
		<nav
			aria-label="breadcrumb"
			className="w-full p-2 text-secondary flex"
		>
			<ol className="flex top-6 left-6 h-8 w-full space-x-2">
				<li className="flex items-center">
					<a href="/" title="Back to homepage">
						<div className="flex items-center px-1 capitalize hover:text-accent transition-colors duration-200">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 20 20"
								fill="currentColor"
								className="w-5 h-5 mr-1 pr-1 text-secondary"
							>
								<path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z"></path>
							</svg>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 32 32"
								aria-hidden="true"
								fill="currentColor"
								className="w-2 h-2 mt-1 ml-1 transform rotate-90 fill-current text-secondary"
							>
								<path d="M32 30.031h-32l16-28.061z"></path>
							</svg>
						</div>
					</a>
				</li>
				{breadcrumbItems.map((item, index) => (
					<li
						key={index}
						className="flex items-center space-x-1"
					>
						{index > 0 && (
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 32 32"
								aria-hidden="true"
								fill="currentColor"
								className="w-2 h-2 mt-1 transform rotate-90 fill-current text-secondary"
							>
								<path d="M32 30.031h-32l16-28.061z"></path>
							</svg>
						)}
						<a href={item.href} title={item.title}>
							<div className="flex items-center pr-1 capitalize hover:text-accent text-md transition-colors duration-200">
								{item.label}
							</div>
						</a>
					</li>
				))}
			</ol>
		</nav>
	);
};

export default Breadcrumb;
