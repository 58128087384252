import React, { useCallback, useEffect, useState } from 'react';
import { TiEqualsOutline } from 'react-icons/ti';
import { colorsPallete } from '../../../constants';
import { MdOutlineSendToMobile } from 'react-icons/md';
import CardLayout from '../../common/CardLayout';
import PercentProgress from '../../common/PercentProgressSmall';
import StatusOnOff from '../../common/StatusOnOff';
import apiCompressores from '../../../services/apis/apiCompressores';
import apiUltimaLeituraCompressor from '../../../services/apis/Leituras/apiUltimaLeituraCompressor';
import SignalService from '../../../services/SignalService';
import { apiLink } from '../../../constants/env';
import Table from '../../common/table';
import NoEquipAlert from '../../common/returns/NoEquipAlert';
import PulseWarning from './PulseWarning';

const ListCompressores = ({ listaRegimes, leiturasRegime, listaAlarmes }) => {
	const [listaCompressores, setListaCompressores] = useState([]);
	const [listaLeiturasCompressores, setListaLeiturasCompressores] =
		useState([]);
	const [hubConnection, setHubConnection] = useState();
	const [dadoAtualizado, setDadoAtualizado] = useState();

	// GET COMPRESSORES
	useEffect(() => {
		const fecthCompressores = async () => {
			const res = await listaRegimes.map(async (regime) => {
				if (regime.tipoRegime !== 'Condensação') {
					return await apiCompressores.getByRegimeId(regime.id);
				}
			});
			const compressoresResponse = await Promise.all(res);
			const compressores = compressoresResponse.reduce((acc, compressoresRegime) => acc.concat([compressoresRegime.data]), []);
			setListaCompressores(compressores);

			const todosCompressores = [...compressores.flat()];
			const response = await Promise.all(todosCompressores.map(async (compressor) => {
				if (compressor.id !== undefined) {
					const leitura = await apiUltimaLeituraCompressor.fetchApi(compressor.id);
					if (leitura.data !== undefined) {
						return leitura.data;
					}
					console.log(`${compressor.nome} não tem dados`);
				}
			}));

			const leiturasCompressores = await Promise.all(response);

			setListaLeiturasCompressores(leiturasCompressores);
		}
		fecthCompressores();
	}, [listaRegimes]);



	// ATUALIZAÇÃO EM TEMPO REAL
	useEffect(() => {
		setHubConnection(
			SignalService.startConnection(
				`${apiLink}LeituraCompressorHub`
			)
		);
	}, []);

	useEffect(() => {
		if (hubConnection) {
			hubConnection
				.start()
				.then(() => {
					hubConnection.on(
						'AtualizarDadosCompressor',
						(message) => {
							setDadoAtualizado(message);
						}
					);
				})
				.catch((e) => console.log('Conexão falhou. ', e));
		}
	}, [hubConnection]);

	// ATUALIZAR DADOS
	useEffect(() => {
		if (dadoAtualizado) {
			const index = listaLeiturasCompressores.findIndex(
				(elemento) =>
					elemento.compressorId ===
					dadoAtualizado.compressorId
			);

			let arrayAux = [...listaLeiturasCompressores];
			if (index === -1) {
				arrayAux.push(dadoAtualizado);
			} else {
				arrayAux[index] = dadoAtualizado;
			}
			setListaLeiturasCompressores([...arrayAux]);
			setDadoAtualizado(null);
		}
	}, [dadoAtualizado, listaLeiturasCompressores]);

	const getLeituraByRegime = useCallback((id) => {
		return leiturasRegime.find((elemento) => elemento.regimeId === id);
	}, [leiturasRegime]);

	const getLeituraByCompressor = useCallback((id) => {
		return listaLeiturasCompressores.find(
			(elemento) => elemento.compressorId === id
		);
	}, [listaLeiturasCompressores]);

	const getListaCompressoresByRegimeId = useCallback((id) => {
		return listaCompressores.find(
			(elemento) => elemento[0]?.regimeSuccaoId === id)
	}, [listaCompressores]);

	return (
		<>
			<div className="flex flex-col w-full gap-4">
				{listaRegimes?.map((regime, i) => (
					<CardLayout key={i} className="mx-16">
						<div className="flex justify-center align-items-center flex-col">
							<div className="flex flex-row justify-center items-center">
								<div
									className={`text-left md:text-center px-4 py-2 text-lg md:text-2xl font-semibold ${colorsPallete[i].class}`}
								>
									{`REGIME ${regime.nome}`}
									<p className="text-sm md:text-lg font-semibold lg:px-8 block lg:inline-block text-accent drop-shadow-1xl">
										{'Capacidade '}
										<TiEqualsOutline className="inline" />
										<span className="text-white px-1">
											{getLeituraByRegime(
												regime.id
											)?.capacidadeCompressores?.toLocaleString(
												'pt-BR',
												{
													maximumFractionDigits: 2,
												}
											) || 0}{' '}
											Kcal/ h
										</span>
									</p>
									<p className="text-sm md:text-lg font-semibold block lg:inline-block text-accent drop-shadow-1xl">
										{'Potência '}
										<TiEqualsOutline className="inline" />
										<span className="text-white px-1">
											{getLeituraByRegime(
												regime.id
											)?.potenciaCompressores?.toLocaleString(
												'pt-BR',
												{
													maximumFractionDigits: 2,
												}
											) || 0}{' '}
											kW
										</span>
									</p>
									<p className="text-sm md:text-lg font-semibold lg:px-8 block lg:inline-block text-accent drop-shadow-1xl">
										{'I.E.E. '}
										<TiEqualsOutline className="inline" />
										<span className="text-white px-1">
											{getLeituraByRegime(
												regime.id
											)?.ieeCompressores?.toLocaleString(
												'pt-BR',
												{
													maximumFractionDigits: 2,
												}
											) || 0}{' '}
											Kcal/ h
										</span>
									</p>
								</div>
								<div className="md:hidden flex justify-center items-center text-accent text-2xl">
									<MdOutlineSendToMobile />
								</div>
							</div>

							<Table.Root>
								<Table.Head>
									<Table.Header>
										Status
									</Table.Header>
									<Table.Header>
										Nome
									</Table.Header>
									<Table.Header>
										Slide
									</Table.Header>
									<Table.Header span={2}>
										<div className="border-[1px] border-accent/40 rounded">
											Pressão
										</div>
									</Table.Header>
									<Table.Header span={2}>
										<div className="border-[1px] border-accent/40 rounded">
											Temperatura
										</div>
									</Table.Header>
									<Table.Header span={2}>
										<div className="border-[1px] border-accent/40 rounded">
											Óleo
										</div>
									</Table.Header>
									<Table.Header>
										Corrente
									</Table.Header>
									<Table.Header>
										Freq.
									</Table.Header>
									<Table.Header>
										Potência
									</Table.Header>
									<Table.Header>
										Eficiência
									</Table.Header>
									<Table.Header>
										I.E.E.
									</Table.Header>
									<Table.Header>
										Capacidade
									</Table.Header>
								</Table.Head>
								<Table.Head>
									<Table.Header
										islower={true}
										span={3}
									></Table.Header>
									<Table.Header
										islower={true}
									>
										Sucção
									</Table.Header>
									<Table.Header
										islower={true}
									>
										Descarga
									</Table.Header>
									<Table.Header
										islower={true}
									>
										Sucção
									</Table.Header>
									<Table.Header
										islower={true}
									>
										Descarga
									</Table.Header>
									<Table.Header
										islower={true}
									>
										Sucção
									</Table.Header>
									<Table.Header
										islower={true}
									>
										Descarga
									</Table.Header>
									<Table.Header
										islower={true}
										span={6}
									></Table.Header>
								</Table.Head>

								<Table.Body>
									{getListaCompressoresByRegimeId(
										regime.id
									)?.length > 0 ? (
										getListaCompressoresByRegimeId(
											regime.id
										)?.map(
											(
												compressor,
												i
											) =>
												Object.keys(
													compressor
												)
													.length >
													1 ? (
													<Table.Row
														key={
															i
														}
													>
														<Table.Data>
															<StatusOnOff
																status={
																	getLeituraByCompressor(
																		compressor.id
																	)
																		?.ligado
																}
															/>
														</Table.Data>
														<Table.Data>
															<div className='flex justify-center items-center'>

														<PulseWarning
															active={listaAlarmes.some(({equipamentoId}) => equipamentoId === compressor.id)}
															/>
															{
																compressor?.nome
															}
															</div>
														</Table.Data>
														<Table.Data>
															<PercentProgress
																value={
																	getLeituraByCompressor(
																		compressor.id
																	)?.slide.toLocaleString(
																		'pt-BR',
																		{
																			maximumFractionDigits: 1,
																		}
																	) ||
																	0
																}
															/>
														</Table.Data>
														<Table.Data>
		
															{getLeituraByCompressor(
																compressor.id
															)?.pressaoSuccao?.toLocaleString(
																'pt-BR',
																{
																	maximumFractionDigits: 1,
																}
															) ||
																'-'}{' '}
															Bar
														</Table.Data>
														<Table.Data>
															{getLeituraByCompressor(
																compressor.id
															)?.pressaoDescarga?.toLocaleString(
																'pt-BR',
																{
																	maximumFractionDigits: 1,
																}
															) ||
																'-'}{' '}
															Bar
														</Table.Data>
														<Table.Data>
															{getLeituraByCompressor(
																compressor.id
															)?.temperaturaSuccao?.toLocaleString(
																'pt-BR',
																{
																	maximumFractionDigits: 1,
																}
															) ||
																'-'}{' '}
															°C
														</Table.Data>
														<Table.Data>
															{getLeituraByCompressor(
																compressor.id
															)?.temperaturaDescarga?.toLocaleString(
																'pt-BR',
																{
																	maximumFractionDigits: 1,
																}
															) ||
																'-'}{' '}
															°C
														</Table.Data>
														<Table.Data>
															{getLeituraByCompressor(
																compressor.id
															)?.pressaoOleoReservatorio?.toLocaleString(
																'pt-BR',
																{
																	maximumFractionDigits: 1,
																}
															) ||
																'-'}{' '}
															Bar
														</Table.Data>
														<Table.Data>
															{getLeituraByCompressor(
																compressor.id
															)?.temperaturaOleo?.toLocaleString(
																'pt-BR',
																{
																	maximumFractionDigits: 1,
																}
															) ||
																'-'}{' '}
															°C
														</Table.Data>
														<Table.Data>
															{getLeituraByCompressor(
																compressor.id
															)?.corrente?.toLocaleString(
																'pt-BR',
																{
																	maximumFractionDigits: 1,
																}
															) ||
																'-'}{' '}
															A
														</Table.Data>
														<Table.Data>
															{getLeituraByCompressor(
																compressor.id
															)?.frequencia?.toLocaleString(
																'pt-BR',
																{
																	maximumFractionDigits: 1,
																}
															) ||
																'-'}{' '}
															Hz
														</Table.Data>
														<Table.Data>
															{getLeituraByCompressor(
																compressor.id
															)?.potenciaCalculada?.toLocaleString(
																'pt-BR',
																{
																	maximumFractionDigits: 1,
																}
															) ||
																'-'}{' '}
															kW
														</Table.Data>
														<Table.Data>
															{getLeituraByCompressor(
																compressor.id
															)?.eficiencia?.toLocaleString(
																'pt-BR',
																{
																	maximumFractionDigits: 1,
																}
															) ||
																'-'}{' '}
															%
														</Table.Data>
														<Table.Data>
															{getLeituraByCompressor(
																compressor.id
															)?.iee?.toLocaleString(
																'pt-BR',
																{
																	maximumFractionDigits: 1,
																}
															) ||
																'-'}{' '}
															Kcal/kWh
														</Table.Data>
														<Table.Data>
															{getLeituraByCompressor(
																compressor.id
															)?.capacidadeCalculada?.toLocaleString(
																'pt-BR',
																{
																	maximumFractionDigits: 2,
																}
															) ||
																'-'}{' '}
															Kcal/
															h
														</Table.Data>
													</Table.Row>
												) : null
										)
									) : (
										<Table.Row>
											<Table.Data
												className="text-center"
												span={
													15
												}
											>
												<NoEquipAlert
													equipments={
														'compressores'
													}
													regime={
														true
													}
												/>
											</Table.Data>
										</Table.Row>
									)}
								</Table.Body>
							</Table.Root>
						</div>
					</CardLayout>
				))}
			</div>
		</>
	);
};

export default ListCompressores;
