'use client';
import { Popover, Transition } from '@headlessui/react';
import React, { useState } from 'react';
import { Fragment } from 'react';
import MobileLinks from './MobileLinks';

const Hamburguer = () => {
	const [isOpen, setIsOpen] = useState(false);
	const genericHamburgerLine = `h-1 w-6 my-1 rounded-full bg-accent transition ease transform duration-300`;

	return (
		<>
			<Popover as="div" className="relative">
				<Popover.Button
					className="flex lg:hidden flex-col h-12 w-12 rounded justify-center items-center group border-none focus:outline-none"
					onClick={() => setIsOpen(!isOpen)}
				>
					<div
						className={`${genericHamburgerLine} ${
							isOpen
								? 'rotate-45 translate-y-3 opacity-90 group-hover:opacity-100'
								: 'opacity-50 group-hover:opacity-100'
						}`}
					/>
					<div
						className={`${genericHamburgerLine} ${
							isOpen
								? 'opacity-0'
								: 'opacity-50 group-hover:opacity-100'
						}`}
					/>
					<div
						className={`${genericHamburgerLine} ${
							isOpen
								? '-rotate-45 -translate-y-3 opacity-90 group-hover:opacity-100'
								: 'opacity-50 group-hover:opacity-100'
						}`}
					/>
				</Popover.Button>
				<Transition
					as={Fragment}
					enter="transition ease-out duration-200"
					enterFrom="opacity-0 -translate-y-2/3"
					enterTo="opacity-100 translate-y-0"
					leave="transition ease-in duration-150"
					leaveFrom="opacity-100 translate-y-0"
					leaveTo="opacity-0 -translate-y-2/3"
				>
					<Popover.Panel className="bg-darkaccent absolute z-10 md:-translate-x-[70%] -translate-x-[72%] translate-y-0 px-8 top-12 lg:hidden">
						<div className="flex flex-col h-full items-center p-2">
							<MobileLinks />
						</div>
					</Popover.Panel>
				</Transition>
			</Popover>
		</>
	);
};

export default Hamburguer;
