import { apiLink } from '../../../constants/env';
import axiosInstance from '../../AxiosInstance';

const apiLeituraRegime = {
    fetchApi: async (regimeId) => {
        const URL = `${apiLink}api/leitura-regime/ultima-leitura?regimeId=${regimeId}`;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error(`Erro ao buscar a último leitura do Regime [AXIOS]: ${error}`);

            })
    }
}

export default apiLeituraRegime;
