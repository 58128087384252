import { apiLink } from '../../constants/env';
import axiosInstance from '../AxiosInstance';

const apiMarca = {
    fetchApi: async () => {
        const URL = `${apiLink}api/marca`;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();

            })
    },
    pagination: async (page) => {
        const URL = `${apiLink}api/marca/page/` + page;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();

            })
    },
    getById: async (id) => {

        const URL = `${apiLink}api/marca/${id}`;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();
            })
    },
    postApi: async (marca) => {
        const URL = `${apiLink}api/marca`;
        return await axiosInstance.post(URL, marca)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();

            })
    },
    deleteApi: async (id) => {
        const URL = `${apiLink}api/marca/status/${id}`;
        return await axiosInstance.get(URL)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();

            })
    },
    putApi: async (id, marca) => {
        const URL = `${apiLink}api/marca/${id}`;
        return await axiosInstance.put(URL, marca)
            .then((res) => res)
            .catch(error => {
                console.error('Erro ao buscar os dados:', error);
                throw new Error();

            })
    }

}

export default apiMarca;

