import { useContext, useEffect, useState } from 'react';
import { ToastContext } from '../../../../contexts/ToastContext';
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogDescription,
	DialogFooter,
	DialogHeader,
	DialogTitle,
	DialogTrigger,
} from '../../../../components/ui/dialog';
import {
	AlertDialogAction,
	AlertDialogCancel,
	AlertDialogContent,
	AlertDialogFooter,
	AlertDialogHeader,
	AlertDialogTitle,
	AlertDialogTrigger,
} from '../../../../components/ui/alert-dialog';
import apiMarca from '../../../../services/apis/apiMarca';
import PaginationBar from '../../../../components/common/PaginationBar';
import { useParams } from 'react-router-dom';

export default function ListaMarca({
	atualizarLista,
	pesquisar,
	funcaoModalController,
}) {
	const [listaMarca, setListaMarca] = useState([]);
	const {page} = useParams();
	const [pagination, setPagination] = useState();
	const [idEditar, setIdEditar] = useState();
	const [nome, setNome] = useState('');
	const [itemRemovido, setItemRemovido] = useState('');
	const [idRemover, setIdRemover] = useState();
	const { erro, sucesso } = useContext(ToastContext);
	const funcaoModal = funcaoModalController.funcaoModal;

	//GET MARCA
	useEffect(() => {
		apiMarca.pagination(page).then((res) => {
			setListaMarca(res.data.items);
			setPagination(res.data)
		});
	}, [page]);

	//DELETE MARCA
	function deletar(id) {
		apiMarca
			.deleteApi(id)
			.then((res) => {
				atualizarLista();
				sucesso('Marca deletada com sucesso!');
			})
			.catch((error) => {
				console.error(error.message);
				erro('Erro ao deletar marca!');
			});
	}

	function enviar(e) {
		e.preventDefault();
		const marca = {
			nome: e.target.nome.value,
		};
		if (funcaoModal === 'Adicionar') {
			apiMarca
				.postApi(marca)
				.then((res) => {
					atualizarLista();
					setNome('');
					sucesso('Marca adicionada com sucesso!');
				})
				.catch((error) => {
					erro('Erro ao adicionar marca!');
					console.error(error.message);
				});
		} else if (funcaoModal === 'Editar') {
			apiMarca
				.putApi(idEditar, marca)
				.then((res) => {
					atualizarLista();
					sucesso('Marca editada com sucesso!');
				})
				.catch((error) => {
					console.error(error.message);
					erro('Erro ao editar marca!');
				});
		}
	}

	return (
		<>
			<Dialog>
				{/* LISTA */}
				<div className="w-full flex flex-col items-center mt-[40px] md:px-[200px]">
					<table className="table-auto w-full">
						<thead className="flex border-b">
							<tr>
								<th className="text-[25px] pl-[10px]">
									Nome
								</th>
							</tr>
						</thead>
						<tbody>
							{listaMarca.map(
								(marca, i) =>
									marca.nome
										.toUpperCase()
										.startsWith(
											pesquisar.toUpperCase()
										) && (
										<tr
											className="border-b"
											key={i}
										>
											<td className="flex justify-between py-1 hover:bg-gray-200 hover:bg-opacity-5">
												<span className="text-[20px] pl-[10px]">
													{
														marca.nome
													}
												</span>
												<span className="flex">
													<AlertDialogTrigger>
														<div
															onClick={() => {
																setIdEditar(
																	marca.id
																);
																setNome(
																	marca.nome
																);
																funcaoModalController.setFuncaoModal(
																	'Editar'
																);
															}}
														>
															<i
																className="fa-solid fa-pen-to-square text-[18px] hover:text-lightBlue"
																title="Editar"
															></i>
														</div>
													</AlertDialogTrigger>
													<DialogTrigger>
														<div
															className="mx-2.5"
															onClick={() => {
																setItemRemovido(
																	marca.nome
																);
																setIdRemover(
																	marca.id
																);
															}}
														>
															<i
																className="fa-solid fa-trash text-[18px] hover:text-lightBlue"
																title="Deletar"
															></i>
														</div>
													</DialogTrigger>
												</span>
											</td>
										</tr>
									)
							)}
						</tbody>
					</table>
					{
						pagination !== undefined &&
						<PaginationBar pagination={pagination}/>
					}
				</div>
				<DialogContent>
					<DialogHeader>
						<DialogTitle>
							Excluir {itemRemovido}?
						</DialogTitle>
					</DialogHeader>
					<DialogDescription>
						Todos os dados associados com {itemRemovido}{' '}
						serão removidos.
					</DialogDescription>
					<DialogFooter>
						<div
							className="bg-red-600/70 py-2 px-4 rounded-md hover:bg-red-600 hover:cursor-pointer border-slate-800 border-2 transition-all duration-200 ease-in-out"
							onClick={() => deletar(idRemover)}
						>
							<DialogClose>Excluir</DialogClose>
						</div>
					</DialogFooter>
				</DialogContent>
			</Dialog>

			<AlertDialogContent>
				<form onSubmit={(e) => enviar(e)}>
					<AlertDialogHeader>
						<AlertDialogTitle>
							{funcaoModal} Marca
						</AlertDialogTitle>
					</AlertDialogHeader>
					<div className="w-full flex justify-center my-5">
						<div className="flex flex-col">
							<label
								htmlFor="nome"
								className="text-[18px] mb-2 pl-1"
							>
								Nome
							</label>
							<input
								id="nome"
								className="border-b text-[20px] p-1.5 bg-[#0b101d] rounded-t-md w-[200px]"
								type="text"
								placeholder="nome da marca "
								value={nome}
								onChange={(e) =>
									setNome(e.target.value)
								}
								required
							/>
						</div>
					</div>
					<AlertDialogFooter>
						<div className="flex w-full justify-end gap-x-4 p-4">
							<AlertDialogAction className="w-32">
								Confirmar
							</AlertDialogAction>
							<AlertDialogCancel className="w-32">
								Cancelar
							</AlertDialogCancel>
						</div>
					</AlertDialogFooter>
				</form>
			</AlertDialogContent>
		</>
	);
}
