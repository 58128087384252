import { useCallback, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

export default function PaginationBar({
    pagination
}) {
    const { page, totalPages } = pagination;
    const location = useLocation();
    const navigation = useNavigate(); 

    function criarArrayPaginas() {
        var arrayPaginas = Array.apply(null, {length: totalPages + 1 - 1}).map(function(_, idx) {
            return idx + 1;
        });
        return arrayPaginas;
    }

    const getURL = useCallback(() => {
        return location.pathname.slice(0, location.pathname.lastIndexOf('/'))
    },[location]);

    function isFirstPage() {
        if(page <= 1) {
            return "pointer-events-none dark:bg-gray-900 ";
        }
        return "";    
    }

    function isLastPage() {
        if(page >= totalPages) {
            return "pointer-events-none dark:bg-gray-900";
        }
        return ""; 
    }

    useEffect(() => {
        if(pagination.items.length === 0 && page !== 1) {
            navigation(getURL() + "/1")
        }
    },[page, pagination, navigation, getURL]);

    if(totalPages <= 1 ) {
        return 
    }
    return (

        <nav aria-label="Page navigation example" className="mt-4">
            <ul className="flex items-center -space-x-px h-8 text-sm">
                <li>
                    <Link to={getURL() + "/" + (page - 1)} className={isFirstPage()}>
                        <p className={`flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white ${isFirstPage()}`}>
                            <span className="sr-only">Previous</span>
                            <svg className="w-2.5 h-2.5 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 1 1 5l4 4" />
                            </svg>
                        </p>
                    </Link>
                </li>
                {
                    criarArrayPaginas().map(num => (
                        <li key={num}>
                            {
                                num === page ?
                                <p aria-current="page" className="cursor-pointer z-10 flex items-center justify-center px-3 h-8 leading-tight text-blue-600 border border-blue-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white">{num}</p>:
                                <Link to={getURL() + "/" + num}>
                                    <p className="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">{num}</p> 
                                </Link>
                            }

                        </li>
                    ))
                }

                <li>
                    <Link to={getURL() + "/" + (page + 1)} className={isLastPage()}>
                        <p className={`flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white ${isLastPage()}`}>
                            <svg className="w-2.5 h-2.5 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 9 4-4-4-4" />
                            </svg>
                        </p>
                    </Link>
                </li>
            </ul>
        </nav>




    );
}