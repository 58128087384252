import { useState } from 'react';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { Calendar as CalendarIcon } from 'lucide-react';
import { Button } from './button';
import { Calendar } from './calendar';
import { Popover, PopoverContent, PopoverTrigger } from './popover';
import { cn } from '../../lib/utils/utils';

export function DatePicker({ onDateChange }) {
	const [date, setDate] = useState();

	const handleDateChange = (newDate) => {
		setDate(newDate);
		if (onDateChange) {
			onDateChange(newDate);
		}
	};

	return (
		<Popover>
			<PopoverTrigger asChild>
				<Button
					variant={'label'}
					className={cn(
						'w-[280px] justify-start text-left font-normal text-secondary',
						!date && 'text-muted-foreground'
					)}
				>
					<CalendarIcon className="text-white mr-2 h-4 w-4" />
					{date ? (
						format(date, 'PPP', { locale: ptBR })
					) : (
						<span className="text-secondary">
							Escolha uma data
						</span>
					)}
				</Button>
			</PopoverTrigger>
			<PopoverContent className="w-auto p-0">
				<Calendar
					mode="single"
					selected={date}
					onSelect={handleDateChange}
					initialFocus
				/>
			</PopoverContent>
		</Popover>
	);
}
