import Resistor from './svgs/Resistor';
import Indutor from './svgs/Indutor';
import Capacitor from './svgs/Capacitor';

const CardPeriodoMedido = ({
	ponta = 0,
	datePonta = '--.--.----',
	FPCapacitivo = 0,
	dateFPCapacitivo = '--.--.----',
	FPIndutivo = 0,
	dateFPIndutivo = '--.--.----',
	unidade = 'unid',
}) => {
	return (
		<>
			<div className="flex flex-wrap justify-center gap-2 w-full pb-4">
				{/* Fora de Ponta Capacitivo */}
				<div className="flex items-center p-2 border-accent rounded border-2 5xl:min-w-[320px] min-w-[300px]">
					<div className="flex flex-shrink-0 items-center justify-center h-12 w-12 border-accent/20 border-2 rounded-lg p-1">
						<Capacitor fill="#96a0af" />
					</div>
					<div className="flex-grow flex flex-col ml-2">
						<div className="flex items-center justify-between">
							<p className="text-sm 2xl:text-lg">
								{FPCapacitivo} {' ' + unidade}
							</p>
							<span className="text-gray-600 text-xs font-semibold">
								{dateFPCapacitivo}
							</span>
						</div>
						<span className="text-accent drop-shadow-1xl">
							Fora de Ponta Capacitivo
						</span>
					</div>
				</div>
				{/* Fora de Ponta Indutivo */}
				<div className="flex items-center p-2 border-accent rounded border-2 5xl:min-w-[320px] min-w-[300px]">
					<div className=" flex flex-shrink-0 items-center justify-center h-12 w-12 border-accent/20 border-2 rounded-lg p-1">
						<Indutor fill="#96a0af" />
					</div>
					<div className="flex-grow flex flex-col ml-2">
						<div className="flex items-center justify-between">
							<span className="text-sm 2xl:text-lg ">
								{FPIndutivo} {' ' + unidade}
							</span>
							<span className="text-gray-600 text-xs font-semibold">
								{dateFPIndutivo}
							</span>
						</div>
						<span className="text-emerald-600 drop-shadow-1xl">
							Fora de Ponta Indutivo
						</span>
					</div>
				</div>
				{/* Ponta */}
				<div className="flex items-center p-2 border-accent rounded border-2  5xl:min-w-[320px] min-w-[300px]">
					<div className=" flex flex-shrink-0 items-center justify-center h-12 w-12 border-accent/20 border-2 rounded-lg p-1">
						<Resistor fill="#96a0af" />
					</div>
					<div className="flex-grow flex flex-col ml-2">
						<div className="flex items-center justify-between">
							<span className="text-sm 2xl:text-lg">
								{ponta} {' ' + unidade}
							</span>
							<span className="text-gray-600 text-xs font-semibold">
								{datePonta}
							</span>
						</div>
						<span className="text-red-600 drop-shadow-1xl">
							Ponta
						</span>
					</div>
				</div>
			</div>
		</>
	);
};

export default CardPeriodoMedido;
